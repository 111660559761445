import { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import moment from "moment"
import { HeaderInfoCard } from "../components/cards/header-info-card"
import SpeciesContributionChart from "../components/charts/species-contribution-chart"
import { PlantationTimeline } from "../components/cards/plantation-timeline"
import { SpeciesSelectCarousel } from "../components/carousels/species-select-carousel"
import { FilterDataType } from "../../dashboard-scope/components/filter-data-type"
import { SpeciesPlantationFilterType, setSelectedSpeciesPlantation, setSpeciesPlantationFilter, speciesPlantationFilterCategories } from "../../../store/slices/forestSlice"
import { SpeciesPlantationChart } from "../components/charts/species-plantation-chart"
import { SpeciesDatewisePlantationChart } from "../components/charts/species-datewise-plantation-chart"
import { Dropdown } from "../../../ui/components/dropdown"
import { useLazyGetProjectPlantationDetailsQuery } from "../../our-forest/store/api"
import { IconSapling, IconSaplingSm } from "../../../../_metronic/assets/icons"

// Helper function to convert YYYY-MM to start and end dates of the month
const getFormattedDate = (date: string) => {
  const dateObject = moment(date, 'YYYY-MM')
  const startDate =  dateObject.startOf('month').format('YYYY-MM-DD')
  const endDate = dateObject.endOf('month').format('YYYY-MM-DD')
  return {startDate, endDate}
}

export const ProjectPlantationPage = (props: {project: any}) => {
  const {project} = props
  const dispatch = useDispatch()

  // Get filter state from Redux store
  const {speciesPlantationFilter, selectedSpeciesPlantation} = useSelector((state: any) => state.forest)

  // API query hook for fetching plantation details
  const [getProjectPlantationDetails, {data: projectPlantationData, isLoading: isGetProjectPlantationDetailsLoading}] = useLazyGetProjectPlantationDetailsQuery()

  // State variables for managing plantation data
  const [speciesPlantedList, setSpeciesPlantedList] = useState<any>([])
  const [selectedDate, setSelectedDate] = useState<string>("")
  const [plantationTimeLineData, setPlantationTimeLineData] = useState<any>([])
  const [availableDates, setAvailableDates] = useState<any>([])

  // State for chart data
  const [speciesPlantationChartData, setSpeciesPlantationChartData] = useState<any>({details: null, chartData: []})
  const [speciesDateWisePlantationChartData, setSpeciesDateWisePlantationChartData] = useState<any>({details: null, chartData: []})

  // Transform raw data for species contribution chart
  const getSpeciesContributionChartData = (data: any) => (
    data?.speciesPlanted?.map((item: any) => ({
      name: item.speciesName,
      count: item.treesPlanted || 0
    })).sort((a: any, b: any) => b.count - a.count) || []
  ) 

  // Transform raw data for species planted list
  const getSpeciesPlantedData = (data: any) => (
    data?.speciesPlanted?.map((item: any) => ({
      speciesName: item.speciesName,
      image: item.speciesImage,
      count: item.treesPlanted || 0,
      speciesType: item.speciesType,
    })) || []
  )

  // Handler for changing filter data type (SPECIES or DATE)
  const handleFilterDataTypeChange = (filterDataType: string) => {
    dispatch(setSpeciesPlantationFilter(filterDataType as SpeciesPlantationFilterType))
  }

  // Handler for changing selected species
  const handleSelectedSpeciesPlantationChange = (selectedSpeciesType: string) => {
    dispatch(setSelectedSpeciesPlantation(selectedSpeciesType))
  }

  // Fetch initial plantation details when project ID is available
  useEffect(() => {
    if (project?.id) {
      getProjectPlantationDetails({projectId: Number(project?.id)})
    }
  }, [project?.id])

  // Update species planted list when project data changes
  useEffect(() => {
    if (project?.speciesPlanted?.length > 0) {
      setSpeciesPlantedList(getSpeciesPlantedData(project))
    }
  }, [project?.speciesPlanted])

  // Set default selected species if none is selected
  useEffect(() => {
    if (selectedSpeciesPlantation === "" && project?.speciesPlanted?.length > 0) {
      dispatch(setSelectedSpeciesPlantation(project?.speciesPlanted?.[0]?.speciesType || ""))
    } else if (selectedSpeciesPlantation !== "" && project?.speciesPlanted?.find((item: any) => item?.speciesType === selectedSpeciesPlantation) === undefined) {
      dispatch(setSelectedSpeciesPlantation(project?.speciesPlanted?.[0]?.speciesType || ""))
    }
  }, [selectedSpeciesPlantation, project?.speciesPlanted])

  // Update timeline data when plantation data is received
  useEffect(() => {
    if (projectPlantationData?.summary) {
      setPlantationTimeLineData(projectPlantationData?.summary?.latestPlantationTimeline?.map((item: any) => ({
        month: item?.month,
        totalTreesPlanted: item?.treesPlanted,
      })))
    }
  }, [projectPlantationData])

  // Update available dates from API response
  useEffect(() => {
    if (projectPlantationData?.summary?.availableMonthsData?.length > 0) {
      setAvailableDates(projectPlantationData?.summary?.availableMonthsData?.map((item: any) => item?.month))
    }
  }, [projectPlantationData?.summary?.availableMonthsData])

  // Set default selected date if none is selected
  useEffect(() => {
    if (availableDates?.length > 0 && selectedDate === "") {
      setSelectedDate(availableDates[0])
    }
  }, [availableDates, selectedDate])

  // Fetch and update chart data based on selected filter type and values
  useEffect(() => {
    if (speciesPlantationFilter === "SPECIES" && selectedSpeciesPlantation !== "" && speciesPlantedList?.length > 0) {
      // Fetch species-wise plantation data
      getProjectPlantationDetails({
        projectId: Number(project?.id), 
        dataType: "species", 
        speciesTypes: [selectedSpeciesPlantation],
      }).then((res) => {
        setSpeciesPlantationChartData({
          details: {
            speciesName: speciesPlantedList.find((item: any) => item?.speciesType === selectedSpeciesPlantation)?.speciesName, 
            speciesImage: speciesPlantedList.find((item: any) => item?.speciesType === selectedSpeciesPlantation)?.image, 
            speciesCount: speciesPlantedList.find((item: any) => item?.speciesType === selectedSpeciesPlantation)?.count,
            speciesType: selectedSpeciesPlantation,
          },
          chartData: {
            categories: res?.data?.data?.speciesWiseChartData?.map((item: any) => item?.month), 
            speciesPlanted: res?.data?.data?.speciesWiseChartData?.map((item: any) => ({
              speciesName: item?.speciesName,
              count: item?.treesPlanted,
              plantationImages: item?.plantationImages,
            })),
          },
        })
      })
    } else if (speciesPlantationFilter === "DATE" && selectedDate !== "") {
      // Fetch date-wise plantation data
      getProjectPlantationDetails({
        projectId: Number(project?.id), 
        dataType: "date", 
        startDate: getFormattedDate(selectedDate)?.startDate,
        endDate: getFormattedDate(selectedDate)?.endDate,
      }).then((res) => {
        setSpeciesDateWisePlantationChartData({
          details: {
            totalPlanted: res?.data?.data?.dateWiseChartData?.totalTreesPlanted, 
            dateLabel: moment(selectedDate, 'YYYY-MM').format('MMMM YYYY')
          },
          chartData: {
            categories: res?.data?.data?.dateWiseChartData?.data?.map((item: any) => item?.speciesName),
            speciesPlanted: res?.data?.data?.dateWiseChartData?.data?.map((item: any) => ({
              speciesName: item?.speciesName,
              count: item?.treesPlanted,
              speciesType: item?.speciesType,
            })),
          },
        })
      })
    }
  }, [speciesPlantationFilter, selectedSpeciesPlantation, selectedDate, speciesPlantedList])

  return (
    <div className="pb-12">
      {/* Header section with plantation statistics */}
      <div className='px-6 m-0 mt-6 mb-2 d-flex justify-content-between gap-4'>
        <div className='d-flex flex-column gap-4' style={{flex: 2}}>
          <HeaderInfoCard 
            label='Total Trees Planted / Planned' 
            value={`${(project?.totalTreesPlanted || '--')}/${(project?.totalTreesPlanned || '--')}`} 
            type='white'
            icon={<IconSaplingSm style={{width: 40, height: 40}} />}
            />
          <HeaderInfoCard 
            label='Unique Species Planted' 
            value={project?.uniqueSpeciesCount} 
            icon={<IconSapling style={{width: 40, height: 40}} />}
          />
        </div>
        <div className="d-flex flex-column p-2 bordered-grey" style={{flex: 3}}>
          <h4 className='mb-2'>% DISTRIBUTION OF TOP 5 PLANTED SPECIES</h4>
          <SpeciesContributionChart 
            chartHeight='220px'
            title=''
            data={getSpeciesContributionChartData(project)}
          />
        </div>
      </div>

      {/* Timeline showing plantation progress */}
      <PlantationTimeline 
        plantationTimeLineData={plantationTimeLineData} 
        totalTreesPlanted={project?.totalTreesPlanted} 
        totalTreesPlanned={project?.totalTreesPlanned} 
        />

      {/* Filter section to switch between SPECIES and DATE views */}
      <div className='px-6 my-8 d-flex align-items-center justify-content-between gap-4'>
        <h2 className='m-0'>PRESENT DATA AS PER</h2>
        <FilterDataType filterDataType={speciesPlantationFilter} setFilterDataType={handleFilterDataTypeChange} categories={speciesPlantationFilterCategories} />
      </div>

      {/* Conditional rendering based on selected filter type */}
      {
        speciesPlantationFilter === "SPECIES" ? (
          <div className='px-6'>
            {/* Species selection carousel */}
            <SpeciesSelectCarousel 
              data={getSpeciesPlantedData(project)} 
              selectedSpeciesPlantation={selectedSpeciesPlantation} 
              setSelectedSpeciesPlantation={handleSelectedSpeciesPlantationChange} 
              />
            <div className="my-4" />
            {/* Chart showing plantation data for selected species */}
            <SpeciesPlantationChart 
              details={speciesPlantationChartData.details}
              chartData={speciesPlantationChartData.chartData}
              />
          </div>
        ) : (
          <div className='px-6'>
            {/* Date selection dropdown */}
            <Dropdown
              label=""
              options={availableDates?.map((item: any) => ({
                label: moment(item, 'YYYY-MM').format('MMM YYYY'),
                value: item
              }))}
              selectedValue={selectedDate}
              onChange={setSelectedDate}
              placeholder="Select Month"
              containerStyle={{width: '200px'}}
              disablePlaceholder
              />
            <div className="my-4" />
            {/* Chart showing species-wise plantation data for selected date */}
            <SpeciesDatewisePlantationChart 
              details={speciesDateWisePlantationChartData.details}
              chartData={speciesDateWisePlantationChartData.chartData}
              />
          </div>
        )
      }

    </div>
  )
}