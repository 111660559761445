/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react'
import ReactTablePurple from '../../../../../_metronic/partials/components/tables/ReactTablePurple'
import { NoImageIcon } from '../../../../../_metronic/assets/icons'
import ModalViewImage from '../modals/modal-view-image'

// Render components for table cells
const RenderImageAndNumberColumn = ({ value, onImageClick }: { value: {image: string, count: number}, onImageClick: (image: string) => void }) => (
  <div className="d-flex justify-content-center align-items-center">
    {
      value.image ? 
      <button
        className='remove-btn-styles'
        type='button'
        onClick={() => onImageClick(value.image)}
      >
        <img 
          src={value.image} 
          alt="species" 
          style={{width: '40px', height: '40px', borderRadius: '5px'}} 
        />
      </button> : 
      <div 
        className='d-flex justify-content-center align-items-center' 
        style={{width: '40px', height: '40px', borderRadius: '5px', backgroundColor: '#f2f2f2'}}
      >
        <NoImageIcon width={18} height={18} color='#5C5C5C' />
      </div>
    }
    <p className='m-0 mx-2'>{value.count || '--'}</p>
  </div>
)

const RenderImageColumn = ({ value, onImageClick }: { value: {image: string}, onImageClick: (image: string) => void }) => (
  <div className="d-flex justify-content-center align-items-center">
    {
      value.image ? 
      <button
        className='remove-btn-styles'
        type='button'
        onClick={() => onImageClick(value.image)}
      >
        <img 
          src={value.image} 
          alt="species" 
          style={{width: '40px', height: '40px', borderRadius: '5px'}} 
        />
      </button> : 
      <div 
        className='d-flex justify-content-center align-items-center' 
        style={{width: '40px', height: '40px', borderRadius: '5px', backgroundColor: '#f2f2f2'}}
      >
        <NoImageIcon width={18} height={18} color='#5C5C5C' />
      </div>
    }
  </div>
)

const RenderStringColumn = ({ value }: { value: string }) => (
  <div className="d-flex justify-content-center"><p className='m-0'>{value?.length > 0 ? value : '--'}</p></div>
)

const SpeciesUpdateDetailsTable = ({ listData = [] }: { listData: any[] }) => {
  const [showViewImageModal, setShowViewImageModal] = useState<boolean>(false);
  const [selectedViewImage, setSelectedViewImage] = useState<string>('');

  const handleImageClick = (image: string) => {
    setSelectedViewImage(image);
    setShowViewImageModal(true);
  }

  const headers = useMemo(
    () => [
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>CIRCUMFERENCE</p></div>,
        accessor: 'circumference',
        Cell: ({ cell }: { cell: any }) => <RenderImageAndNumberColumn value={cell.row.original.circumference} onImageClick={handleImageClick} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>HEIGHT</p></div>,
        accessor: 'height',
        Cell: ({ cell }: { cell: any }) => <RenderImageAndNumberColumn value={cell.row.original.height} onImageClick={handleImageClick} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>LEAF</p></div>,
        accessor: 'leaf',
        Cell: ({ cell }: { cell: any }) => <RenderImageColumn value={cell.row.original.leaf} onImageClick={handleImageClick} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>LAT LONG</p></div>,
        accessor: 'lat long',
        Cell: ({ cell }: { cell: any }) => <RenderStringColumn value={cell.row.original.latLong} />
      },
    ],
    []
  )

  if (listData?.length === 0) 
    return <div className='d-flex justify-content-center bordered-grey py-12'><h3 className='m-0'>No species update details found</h3></div>

  return (
    <div className='p-0 m-0'>
      <ReactTablePurple
        headerData={headers}
        tableData={listData}
        className='mt-6 custom-border'
        currentPageIndex={0}
        disableSort
        showPagination={false}
        pageSize={listData.length}
        message="No results found"
      />

      <ModalViewImage 
        show={showViewImageModal}
        onHide={() => setShowViewImageModal(false)}
        imageUrl={selectedViewImage}
      />
    </div>
  )
}

export default SpeciesUpdateDetailsTable