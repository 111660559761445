/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
import { useEffect, useState, useRef } from "react";
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { DownloadIcon, IconUpload } from '../../../../../_metronic/assets/icons'
import { useUploadScopeDataMutation } from '../../../../routing/store/api'
import { updateNotification } from "../../../../store/slices/notifier";
import { NotificationType } from "../../../../../_metronic/partials/components/types";
import AccordionWrapper from "./acoordion-wrapper";
import { uploadDataStatusSlice, UploadDataStatusType } from "../../../../store/slices/uploadDataStatus";
import { getEnvironment } from "../../../../utils/helpers";
import { PROD_SOLAR_ARISE_ORG_ID } from "../../../../utils/dummyData";

type AccordionUploadDataProps = {
  index: number;
  scopeUploadData: any;
}

const scopeOneCSVLink = 'https://mynzo.s3.ap-south-1.amazonaws.com/Scope1-Template.xlsx'
const scopeTwoCSVLink = 'https://mynzo.s3.ap-south-1.amazonaws.com/Scope2-Template.xlsx'

const AccordionUploadData = (props: AccordionUploadDataProps) => {
  const {
    scopeUploadData,
    index
  } = props;
  const intl = useIntl()
  const dispatch = useDispatch();

  const {filterUploadScopesReport} = useSelector((state: RootState) => state.filters)
  const {uploadDataStatus} = useSelector((state: RootState) => state);
  const {setUploadDataStatus} = uploadDataStatusSlice.actions;

  const [uploadScopeData] = useUploadScopeDataMutation()
  const [headTitle, setHeadTitle] = useState<string>("")
  const [csvLink, setCsvLink] = useState<string>(scopeOneCSVLink);
  const [selectedFile, setSelectedFile] = useState<any | undefined>()
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (scopeUploadData?.title?.length > 0) {
      setHeadTitle(scopeUploadData.title)
    }
  }, [scopeUploadData])

  useEffect(()=> {
    setCsvLink(`${filterUploadScopesReport === 'scope1' ? scopeOneCSVLink : scopeTwoCSVLink}`)
  }, [filterUploadScopesReport])

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {files} = event.target
    if (!files || files.length === 0) return

    const file = files[0]
    setSelectedFile([file])
    setErrorMessage(null)
  }

  const handleRemoveFile = () => {
    setSelectedFile(null)
    setErrorMessage(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const getUploadDataStatusKey = (title: string): keyof UploadDataStatusType => {
    const list: {[key: string]: keyof UploadDataStatusType} = {
      'Company Owned Vehicles': 'sOneMobileCompanyOwnedVehicles',  
      'Third Party Transportation': 'sOneMobileThirdPartyTransportation',
      'Leased Vehicles': 'sOneMobileLeasedVehicles',
      'Refrigerant Leakage': 'sOneFugitiveRefrigerantLeakage',
      'Fire Extinguishers': 'sOneFugitiveFireExtinguishers',
      'Heating Systems': 'sOneStationaryHeatingSystems',
      'Generators': 'sOneStationaryGenerators',
      'Boilers and Furnaces': 'sOneStationaryBoilersAndFurnaces',
      'Other': 'sOneOtherOther',
      'Power Purchased Agreement': 'sTwoMarketBasedMethodPowerPurchasedAgreement',
      'Natural Gas': 'sTwoLocationBasedMethodNaturalGas',
      'Electricity': 'sTwoLocationBasedMethodElectricity',
      'Other ': 'sTwoOtherOther'
    }
    return list[title] as keyof UploadDataStatusType
  }

  const handleUpload = () => {
    if (selectedFile && selectedFile.length > 0) {
      setIsUploading(true)
      setErrorMessage(null)
      const formData = new FormData()
      formData.append('s1_s2_data', selectedFile[0])
      formData.append('type', filterUploadScopesReport === 'scope1' ? 'S1' : 'S2')

      uploadScopeData(formData)
        .then((data: any) => {
          setSelectedFile(null)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          if (data?.error?.data?.message) {
            setErrorMessage('An error occurred during upload')
            dispatch(
              updateNotification({
                message: 'An error occurred during upload',
                type: NotificationType.ERROR,
              })
            )
          } else {
            dispatch(
              updateNotification({
                message: 'File uploaded successfully',
                type: NotificationType.SUCCESS,
              })
            )
            dispatch(
              setUploadDataStatus({
                field: getUploadDataStatusKey(scopeUploadData?.title),
                data: 'in_progress'
              })
            )
          }
        })
        .catch((err: any) => {
          console.log('err', err)
          setErrorMessage(err.data?.message || 'An error occurred during upload')
          dispatch(
            updateNotification({
              message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
              type: NotificationType.ERROR,
            })
          )
        })
        .finally(() => setIsUploading(false))
    } else if (!selectedFile) {
      // Trigger file input click if no file selected
      if (fileInputRef.current) {
        fileInputRef.current.click()
      } else {
        console.error('File input element not found')
      }
    }
  }

  const {profile} = useSelector((state: RootState) => state.profile);

  const getStatus = (title: string): any => {
    if (getEnvironment() === 'production' && profile?.organization?.id === PROD_SOLAR_ARISE_ORG_ID) {
      if (title.includes('Company Owned Vehicles')) {
        return 'completed'
      } 
      if (title.includes('Third Party Transportation')) {
        return 'completed'
      }
      if (title.includes('Leased Vehicles')) {
        return 'completed'
      }
      if (title.includes('Natural Gas from')) {
        return 'completed'
      }
      if (title.includes('Electricity from')) {
        return 'completed'
      }
    }
    return null
  }

  const getTitle = (title: string) => {
    if (getEnvironment() === 'production' && profile?.organization?.id !== PROD_SOLAR_ARISE_ORG_ID) {
      if (title.includes('Natural Gas from')) {
        return 'Building DG Electricity'
      }
    }
    
    return title
  }

  return (
    <AccordionWrapper
      index={index}
      status={getStatus(scopeUploadData?.title) || ((scopeUploadData?.title && uploadDataStatus[getUploadDataStatusKey(headTitle)]) || 'not_uploaded')}
      title={getTitle(scopeUploadData?.title)}
    >
      <div className='mt-8 pb-6 px-8' style={{backgroundColor: '#FFFFFF'}}>
        {/* Steps */}
        <div className='my-10'>
          {
            ['Other', 'Other '].includes(scopeUploadData?.title) && (
              <p className='m-0 mb-8 fs-5'>
                If you feel any category that is missing or doesn’t fit the current options, you can use the downloadable template to share your findings or discuss your suggestions with us at <a href='mailto:support@mynzocarbon.com'>support@mynzocarbon.com</a>.
              </p>
            )
          }
          <h4 className='mb-3' style={{fontWeight: 600}}><span style={{fontWeight: 800}}>Step 1:</span> Download Template</h4>
          <p className='m-0 fs-5'>
            Click the button below to download the data entry template.
          </p>

          <h4 className='mb-3 mt-6' style={{fontWeight: 600}}><span style={{fontWeight: 800}}>Step 2:</span> Fill Out the Template</h4>
          <p className='m-0 fs-5'>
            Enter the required information about your {scopeUploadData?.title} emissions into the template. Ensure all fields are completed accurately.
          </p>

          <h4 className='mb-3 mt-6' style={{fontWeight: 600}}><span style={{fontWeight: 800}}>Step 3:</span> Upload Template</h4>
          <p className='m-0 fs-5'>
            Once filled, upload the completed template back to the platform using the upload button below.
          </p>

          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept='.xlsx'
            style={{display: 'none'}}
          />

          <p className='mt-10 fs-5' style={{fontWeight: 700}}>
            <span style={{fontWeight: 800}}>Note:</span> You can also upload your utility bill along with the template or as a standalone document. Our system will process your data and calculate your {filterUploadScopesReport === 'scope1' ? 'Scope 1' : 'Scope 2'} emissions.
          </p>

          <p className='mt-10 fs-5'>
            Our system will process your data and calculate your {filterUploadScopesReport === 'scope1' ? 'Scope 1' : 'Scope 2'} emissions.
          </p>
        </div>

        {/* Action Buttons */}
        <div className='d-flex flex-column align-items-end gap-3'>
          <div className='d-flex justify-content-end gap-3'>
            <a
              href={scopeUploadData?.link || csvLink}
              className='custom-primary-delete-button mb-0 mx-0'
              style={{padding: '8px 30px 8px 26px', fontWeight: 600, textDecoration: 'none'}}
              >
              <DownloadIcon height={20} width={20} style={{marginRight: 5}} /> Download Template
            </a>
            <button
              type='button'
              className='custom-outline-button-v2 mb-0 mx-0'
              style={{padding: '8px 30px 8px 26px', fontWeight: 600}}
              onClick={handleUpload}
              disabled={isUploading}
              >
              <IconUpload height={14} width={14} style={{marginRight: 5}} /> 
              {isUploading ? 'Uploading...' : selectedFile ? 'Upload' : 'Upload File'}
            </button>
          </div>
          {selectedFile && (
            <div className='d-flex align-items-center gap-2'>
              <span>{selectedFile[0].name}</span>
              <button 
                type='button'
                onClick={handleRemoveFile}
                className='btn btn-icon btn-sm'
                style={{padding: '0.25rem'}}
              >
                <i className='bi bi-x fs-2 bold' />
              </button>
            </div>
          )}
          {errorMessage && (
            <p className='text-danger'>{errorMessage}</p>
          )}
        </div>
      </div>
    </AccordionWrapper>
  )
}

export default AccordionUploadData