import {useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import moment from 'moment'
import {RootState} from '../../store/store'

import {PageTitle} from '../../../_metronic/layout/core'
import {ForestImage} from '../../../_metronic/assets/icons'
import {ForestPurchaseImage} from '../../../_metronic/assets/icons'
import PurchaseForestModal from './PurchaseForestModal'
import { useLazyGetSuggestedPlansV2Query } from './store/api'
import { getLocalStorageItem } from '../../utils/storage'
import { PROD_SOLAR_ARISE_ORG_ID } from '../../utils/dummyData'
import { getEnvironment } from '../../utils/helpers'

const RenderBeforeForestPurchase = ({setModalShow, area}: any) => {
  const {profile} = useSelector((state:RootState) => state.profile)
  return(
  <>
    <div className='mb-8 position-relative'>
      <ForestImage />
      <div className='acres-text-container'>
      {
        getEnvironment() === 'production' && profile?.organization?.id === PROD_SOLAR_ARISE_ORG_ID  ? (
          <p className='acres-text'>
            1039 <span className='no-bold'>acres</span>
          </p>
        ) : (
          <p className='acres-text'>
            {area > 0 ? Number(area).toFixed(2) : '--'} <span className='no-bold'>acres</span>
          </p>
        )
      }
      </div>

    </div>
    <div className='fs-1 fw-bold mt-10  me-7 text-dark forest-h' style={{color: '#A3A3C7'}}>
      {`That's it!`}
    </div>
    <div className='fs-3 mt-2 pt-6 sub-text mb-5' style={{color: '#000000'}}>
      <h5>
      {`Click on 'Purchase' to check the forest size you need to recapture your emissions`}
      <br/> 
      and raise a purchase request.

      </h5>
    </div>
    <button
      type='button'
      className='custom-primary-delete-button'
      data-toggle='modal'
      onClick={() => setModalShow()}
    >
      Purchase Forest
    </button>
    {/* end::Message */}
  </>
)}

const RenderAfterForestPurchase = ({title}: any) => (
  <>
    <div className='mb-8 position-relative'>
      <ForestPurchaseImage />
      <p className='titles-text'>{`${title}'s Forest`}</p>
    </div>
    <div className='fs-1 fw-bold mt-10  me-7 text-dark forest-h' style={{color: '#A3A3C7'}}>
      Your forest purchase request is in process!
    </div>
    <div className='fs-3 mt-2 pt-6 sub-text mb-5' style={{color: '#000000'}}>
      <h5 className='no-bold'>
        Can’t wait? Email us at <span style={{fontWeight: 'bold'}}>support@mynzocarbon.com</span>
      </h5>
    </div>
    {/* end::Message */}
  </>
)

const ComingSoonCardAfter14Days = () => {
  const intl = useIntl()
  // const liveOnDate = new Date(getLocalStorageItem('live-on'))
  const {profile} = useSelector((state:RootState) => state.profile)
  const [modalShow, setModalShow] = useState(false);
  const [netZeroArea, setNetZeroArea] = useState(0);
  const [getSuggestedPlans, {data: suggestedPlanData}] = useLazyGetSuggestedPlansV2Query()
  useEffect(() => {
    const payload = {
      net_zero_date: '2030-12-31',
      sip_amount_per_month: null,
      assigned_date: profile?.organization?.isWorkshopOrg ? 
        moment(profile?.organization?.workshopDate).add(91, 'day').format('YYYY-MM-DD')
        : null,
    }
    getSuggestedPlans(payload)
  }, [profile]);

  useEffect(()=>{
    if(suggestedPlanData && suggestedPlanData[0]){
      const zeroArea = suggestedPlanData[0]?.net_zero_area;
      if(zeroArea){
        setNetZeroArea(zeroArea);
      }
    }

  },[suggestedPlanData]);

  const userDetails = useSelector((state: RootState) => state.profile.profile)
  const organisation = userDetails?.organization?.name;
  const isForestPurchaseInRequest: boolean = userDetails?.hasInProgressPlanLead || false

  return (
    <div className='card d-flex flex-column flex-root flex-column-fluid flex-center align-items-center text-center h-100'>
      {/* begin::SVG Illustration */}
      {!isForestPurchaseInRequest ? (
        <RenderBeforeForestPurchase area={netZeroArea || 0} setModalShow={() => setModalShow(true)} />
      ) : (
        <RenderAfterForestPurchase title={organisation} />
      )}
      <div className='background-image-f forest-bg' />

      <PurchaseForestModal show={modalShow} onHide={() => setModalShow(false)} />
      {/* end::SVG Illustration */}
      {/* begin::Message */}
    </div>
  )
}

export default ComingSoonCardAfter14Days
