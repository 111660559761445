import React from 'react'
import { IconLockWhite } from '../../../assets/icons'

const LockWrapper = ({ children, isLocked, description }: { children: React.ReactNode, isLocked: boolean, description: string }) => (
  <div className='position-relative' style={{overflow: isLocked ? 'hidden' : 'visible'}}>
    {isLocked ? (
      <>
        <div style={{ filter: 'blur(10px)', pointerEvents: 'none', userSelect: 'none' }}>
          {children}
        </div> 
        <div 
          className='d-flex align-items-start justify-content-start flex-column px-10'
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            zIndex: 1000,
            position: 'sticky',
            bottom: 200,
            left: 0,
            width: '100%',
          }}
          >
          <h1 className='m-0 mb-6 d-flex align-items-center bold'>
            <IconLockWhite color='black' style={{marginRight: 10}} />
            This page is locked
          </h1>
          <div className='py-8 px-10 bordered-grey' style={{backgroundColor: '#D0CCE0'}}>
            <p className='m-0' style={{fontSize: 16, display: 'inline'}}>
              {description}
            </p>
            <a href='mailto:support@mynzocarbon.com' className='text-primary bold mx-2' style={{textDecoration: 'underline', fontSize: 16, display: 'inline'}}>
              support@mynzocarbon.com
            </a>
          </div>
        </div>
      </>
    ) : (
      children
    )}
  </div>
)

export const LockWrapperPartial = ({ children, isLocked, description }: { children: React.ReactNode, isLocked: boolean, description: string }) => (
  <div className='position-relative' style={{height: '100%', overflow: isLocked ? 'hidden' : 'visible'}}>
    {isLocked ? (
      <>
        <div style={{ position: 'relative', height: '100%', pointerEvents: 'none', userSelect: 'none' }}>
          <div>
            {children}
          </div>
          <div style={{ 
            zIndex: 1000,
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '73%', // Blur effect on top 70% of content
            background: 'rgba(255, 255, 255, 0.0)',
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)', // For Safari support
            pointerEvents: 'none',
            userSelect: 'none'
          }} />
        </div>
        <div 
          className='d-flex align-items-start justify-content-start flex-column px-10'
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            zIndex: 1000,
            position: 'sticky',
            bottom: 200,
            left: 0,
            width: '100%',
          }}
          >
          <h1 className='m-0 mb-6 d-flex align-items-center bold'>
            <IconLockWhite color='black' style={{marginRight: 10}} />
            This page is locked
          </h1>
          <div className='py-8 px-10 bordered-grey' style={{backgroundColor: '#D0CCE0'}}>
            <p className='m-0' style={{fontSize: 16, display: 'inline'}}>
              {description}
            </p>
            <a href='mailto:support@mynzocarbon.com' className='text-primary bold mx-2' style={{textDecoration: 'underline', fontSize: 16, display: 'inline'}}>
              support@mynzocarbon.com
            </a>
          </div>
        </div>
      </>
    ) : (
      children
    )}
  </div>
)

export default LockWrapper
