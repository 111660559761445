import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment';
import { Container } from 'react-bootstrap';

const SatelliteImagesCarousel: React.FC<{data: {image: string, date: string}[] }> = (props) => {
  const {data} = props;

  const minYear = data?.[0]?.date?.split('-')[0] || moment().format('YYYY');
  const maxYear = data?.[(data?.length||1) - 1]?.date?.split('-')[0] || moment().format('YYYY');

  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [year, setYear] = useState(minYear);

  const containerRef = useRef<HTMLDivElement>(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const img = new Image();
    img.src = data?.[currentImageIndex]?.image;

    img.onload = () => {
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;

      const imageRatio = img.naturalWidth / img.naturalHeight;
      const containerRatio = containerWidth / containerHeight;

      let width;
      let height;
      if (imageRatio > containerRatio) {
        width = containerWidth;
        height = containerWidth / imageRatio;
      } else {
        height = containerHeight;
        width = containerHeight * imageRatio;
      }

      setImageDimensions({
        width,
        height
      });
    };
  }, [data, currentImageIndex]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIndex = data.findIndex(item => item.date?.split('-')[0] === event.target.value);    
    setCurrentImageIndex(newIndex);
    setYear(event.target.value);
  };

  const sliderPercentage = ((parseInt(year, 10) - parseInt(minYear, 10)) / (parseInt(maxYear, 10) - parseInt(minYear, 10))) * 100;

  return (
    <Container className="timeline-slider">
      <div
        ref={containerRef}
        style={{ 
          height: '100%', 
          width: '90%', 
          overflow: 'hidden', 
          position: 'relative', 
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <img
          src={data?.[currentImageIndex]?.image}
          alt={`Year ${data?.[currentImageIndex]?.date}`}
          style={{
            width: `100%`,
            borderRadius: '10px',
            height: `400px`,
            objectFit: 'contain'
          }}
        />
      </div>

      <div className="timeline-container-range">
        <span className="timeline-label">{minYear}</span>

        <div className="timeline-wrapper">
          <input
            type="range"
            min={minYear}
            step={1}
            max={maxYear}
            value={year}
            onChange={handleChange}
            style={{background: `linear-gradient(to right, #9268CC 0%, #463578 ${sliderPercentage}%, #ccc ${sliderPercentage}%, #ccc 100%)`}}
            className="timeline-range"
          />
          <div className="range-tooltip" style={{ left: `${sliderPercentage + 2*(50-sliderPercentage)/50}%` }}>
            {year}
          </div>
        </div>

        <span className="timeline-label">{maxYear}</span>
      </div>
    </Container>
  );
};

export default SatelliteImagesCarousel;
