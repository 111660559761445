interface HeaderInfoCardProps {
  label: string
  value: string | number
  unit: string
  type?: "blue" | "white"
}

const HeaderInfoCard: React.FC<HeaderInfoCardProps> = ({label, value, unit, type = "blue"}) => {
  if (type === "blue") {
    return (
    <div className='custom-header-card m-0 h-100 w-100'>
      <div className='chc-title'>
        <span 
          className='fs-6'
          style={{color: 'white', fontWeight: 400}}
          >
          {label}
        </span>
      </div>
      <div className='chc-content'>
        <span
          className='value'
          style={{display: 'inline-block', verticalAlign: 'baseline', color: 'white', fontSize: '2.2rem'}}
          >
          {value}
        </span>
        <span
          className='sub-label'
          style={{display: 'inline-block', verticalAlign: 'baseline', color: 'white'}}
          >
          {unit}
        </span>
      </div>
    </div>
    )
  } else {
    return (
      <div className='custom-header-card2 m-0'>
        <div className='chc-title2'>
          <span className='fs-6' style={{color: '#444444'}}>
            {(label!=='undefined') ? label?.toUpperCase() : '-'}
          </span>
        </div>
        <div className='chc-content2 position-relative'>
          <span className='value' style={{display:'inline-block', fontSize: '2.2rem'}}>
            {(value!=='undefined') ? value : '-'}
          </span>
          <span
            className='sub-label'
            style={{display: 'inline-block', verticalAlign: 'baseline', color: '#444444'}}
            >
            {unit}
          </span>
        </div>
      </div>
      )
  }
}

export {HeaderInfoCard}