import { useState } from 'react'
import { useSelector } from 'react-redux'
import { DownloadIcon } from '../../../_metronic/assets/icons'
import ScopeOneEmissionTable from './components/scope-one-emission-table'
import { downloadScopeOneReportCSV, getEnvironment } from '../../utils/helpers'
import { RootState } from '../../store/store'
import { FilterDataType } from './components/filter-data-type'
import { PROD_SOLAR_ARISE_ORG_ID } from '../../utils/dummyData'

const scopeOneTemplateLink = 'https://mynzo.s3.ap-south-1.amazonaws.com/Scope1-Report.xlsx'

const categories = ["Survey Based", "Data Based"];

const ScopeOnePage = () => {
  const {surveyBasedData, profile} = useSelector((state: RootState) => state.profile)

  const [filterDataType, setFilterDataType] = useState<string>(getEnvironment() === 'production' && profile?.organization?.id === PROD_SOLAR_ARISE_ORG_ID ? "Data Based": "Survey Based");

  return (
    <div className="blank-page-view pt-6 pb-16 px-6">
      <div className='p-0 m-0 d-flex justify-content-between align-items-end'>
        <h1 className='m-0' style={{fontWeight: 600}}>SCOPE 1</h1>
        {/* <button
          type='button'
          className='custom-primary-delete-button m-0 d-flex align-items-center'
          style={{padding: '8px 30px', fontWeight: 600}}
          onClick={()=> downloadScopeOneReportCSV(dashboardData?.scope1CategoryData?.length > 0 ? dashboardData?.scope1CategoryData : [])}
          >
          <p className='mb-0 mx-2'>DOWNLOAD REPORT</p> <DownloadIcon height={18} width={18} />
        </button> */}
        <div className='d-flex align-items-center'>
          <FilterDataType filterDataType={filterDataType} setFilterDataType={setFilterDataType} categories={categories} />
          {
            surveyBasedData?.scope1CategoryData && (
              <a
                href={scopeOneTemplateLink}
                className='custom-primary-delete-button my-0'
                style={{padding: '4px 8px', marginRight: 0, marginLeft: 20, fontWeight: 600, textDecoration: 'none'}}
                >
                <DownloadIcon height={24} width={24} />
              </a>
            )
          }
        </div>
      </div>

      <div className='mt-10'>
        <ScopeOneEmissionTable data={getEnvironment() === 'production' && profile?.organization?.id === PROD_SOLAR_ARISE_ORG_ID ? [] : ( surveyBasedData?.scope1CategoryData || [])} />
      </div>  
    </div>
  )
}

export default ScopeOnePage
