/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
import React, {useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import { useIntl } from 'react-intl'

import { RoutesPath } from '../../routing/RoutesPath';
import './styles.css';
import Timeline from './components/Timeline'
import { ForestDocsType } from './types'
import { contractsData, receiptsData, donationsData, farmerDetailsData } from './docs-data'
import DownloadableDocsCard from './components/cards/downloadable-docs-card'

type Props = {

}

const DocsListPage: React.FC<Props> = () => {

  const intl = useIntl();
  const navigate = useNavigate();
  const { state }: any = useLocation();

  const [docsData, setDocsData] = useState<any[]>([]);

  let pageTitle = 'CONTRACTS';

  if (state?.type === ForestDocsType.DONATION) {
    pageTitle = 'DONATIONS';
  } else if (state?.type === ForestDocsType.RECEIPT) {
    pageTitle = 'RECEIPTS';
  } else if (state?.type === ForestDocsType.FARMER_DETAILS) {
    pageTitle = 'FARMER DETAILS';
  }

  useEffect(() => {
    if (state?.type === ForestDocsType.CONTRACT) {
      setDocsData(contractsData);
    } else if (state?.type === ForestDocsType.FARMER_DETAILS) {
      setDocsData(farmerDetailsData);
    } else if (state?.type === ForestDocsType.DONATION) {
      setDocsData(donationsData);
    } else if (state?.type === ForestDocsType.RECEIPT) {
      setDocsData(receiptsData);
    }
  }, [state?.type]);

  return (
    <div className='blank-page-view py-8 px-12'>

      <div className='back-header-btn'>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.FOREST.ROOT)}}
        >
          <div className='back-left-arrow-btn' /> 
        </button>
      </div>

      <h3 className='m-0 mt-6'>{pageTitle}</h3>

      <div className='mt-6 d-flex justify-content-start flex-wrap' style={{gap: '10px'}}>
        {docsData.map((doc) => (
          <DownloadableDocsCard key={doc.name} data={doc} />
        ))}
      </div>
      
    </div>
  )
}

export default DocsListPage
/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */