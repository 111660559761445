export const solarAriseScopeData = {
  totalEmissions: {
    scope1: 0,
    scope2: 93660,
    scope3: 4995816.48,
    total: 5089476.48
  },
  totalEmissionDonutData: [
    {name: 'Scope 1', count: 0},
    {name: 'Scope 2', count: 93660 * 0.001},
    {name: 'Scope 3', count: 4995816.48 * 0.001}
  ],
  topCategories: [
    {title: "Purchased Goods and Services", category: "Purchased Goods and Services", emission: 4943272.4, percentage_contribution: 97.13},
    {title: "Electricity from the local grid", category: "Electricity from the local grid", emission: 92940, percentage_contribution: 1.83},
    {title: "Business Travel", category: "Business Travel", emission: 40184.12, percentage_contribution: 0.79}
  ],
  averageEmissionsPerEmployee: 2676,
  emissionByRevenue: 500000,
  totalLocationEmissionData: [
    {location: "India_Haryana_Gurugram", emissions: 161900.259, totalEmployees: 35, emissionsPerEmployee: 4625.72},
  ],
  scopeTwoLocationEmissionData: [
    {location: "India_Haryana_Gurugram", emissions: 94000, totalEmployees: 35, emissionsPerEmployee: 2685.71},
  ],
  projectedEmissionsData: {
    "2030": {
      scope1: 20000,
      scope2: 114000,
      scope3: 220000,
      recapture: 60000,
    },
    "2040": {
      scope1: 20000,
      scope2: 50000,
      scope3: 220000,
      recapture: 90000,
    },
    "2050": {
      scope1: 20000,
      scope2: 28000,
      scope3: 220000,
      recapture: 90000,
    }
  },
  reductionInitiatives: [
    {initiative: "Switch to Electric Fleet for Company-Owned Vehicles (Scope 1)", targetReduction: 7.5, targetDate: '2025', lead: 'Operations Manager', status: "in_progress"},
    {initiative: "Procurement of Renewable Energy Certificates (Scope 2)", targetReduction: 100, targetDate: '2030', lead: 'Sustainability Officer', status: "in_progress"},
    {initiative: "Expand Telecommuting and Virtual Meeting Policies (Scope 3)", targetReduction: 25, targetDate: '2025', lead: 'HR / IT Collaboration Lead', status: "completed"}
  ],
  scope1CategoryData: [
    {
      operationalBoundary: "Direct Emissions", 
      data: [
        {emissionSource: "Fuel Consumption In Company-Owned Vehicles", emissions: 15}, 
        {emissionSource: "Diesel Consumption In DG Sets", emissions: 8}, 
        {emissionSource: "Fugitive Emissions", emissions: 2}
      ], 
      totalEmissions: 25
    }
  ],
  scope2CategoryData: [
    {
      operationalBoundary: "Indirect Emissions", 
      emissionsSource: "Electricity from the generator", 
      emissionsPerEmployee: 20.43, 
      totalEmissions: 715.2
    },
    {
      operationalBoundary: "Indirect Emissions", 
      emissionsSource: "Electricity from the local grid", 
      emissionsPerEmployee: 2655.4, 
      totalEmissions: 92940
    },
  ],
  scope3CategoryData: [
    {
      operationalBoundary: "Indirect Emissions",
      data: [
        {
          emissionSource: "Business Travel",
          emissions: 40184.12,
        },
        {
          emissionSource: "Employee Commuting",
          emissions: 28060.93,
        },
        {
          emissionSource: "Purchased Goods and Services", 
          emissions: 4943272.4,
        }
      ],
      totalEmissions: 4995816.48,
    },
  ]
}