/* eslint no-bitwise: "error" */
/* eslint prefer-template: "error" */
/* eslint no-restricted-globals: "error" */

import moment from "moment";

export const getEnvironment = () => {
  if (process.env.REACT_APP_API_URL?.includes('api.mynzocarbon.com')) {
    return 'production'
  } else if (process.env.REACT_APP_API_URL?.includes('dev.mynzocarbon.com')) {
    return 'development'
  } else {
    return 'local'
  }
}

// FOR SAFARI INVALID DATE SUPPORT APART FROM 'YYYY-MM-DD'
export function convertMonthYearToDate (monthYearString:string) {
  // Define a map of month names to numeric values
  const monthMap:any = {
    'January': '01',
    'February': '02',
    'March': '03',
    'April': '04',
    'May': '05',
    'June': '06',
    'July': '07',
    'August': '08',
    'September': '09',
    'October': '10',
    'November': '11',
    'December': '12'
  };

  // Split the input string into month and year
  const [month, year] = monthYearString.split(' ');

  // Format the date to 'YYYY-MM-DD'
  const formattedDate = `${year}-${monthMap[month]}-01`;

  return formattedDate;
}

export function getDateRange(_year: string, month: string, liveOnDate: Date, isWorkshop?: boolean | undefined): { startDate: string; endDate: string } {
  if (_year === 'All') {
    const startDate = new Date(liveOnDate.getUTCFullYear(), 0, 1)
    const endDate = new Date()

    return {
      startDate: moment(startDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
      endDate: isWorkshop ? '2030-12-31' : moment(endDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
    };
  }

  const year = Number(_year)
  const currentDate = new Date();

  if (month === 'All') {
    const startDate = new Date(year, 0, 1); // January 1st of the specified year
    const endDate = new Date(year, 11, 31); // December 31st of the specified year

    if (year === currentDate.getUTCFullYear()) {
      const maxEndDate = new Date(Math.min(endDate.getTime(), currentDate.getTime()));
      return {
        startDate: moment(startDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
        endDate: moment(isWorkshop ? endDate : maxEndDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
      };
    }

    return {
      startDate: moment(startDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
      endDate: moment(endDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
    };
  }

  const monthIndex = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(month) + 1;

  if (monthIndex === 0) {
    throw new Error('Invalid month format. Please provide a valid month abbreviation (e.g., "Jan") or "All".');
  }

  const startDate = new Date(year, monthIndex - 1, 1);
  const endDate = new Date(year, monthIndex, 0); // Setting day to 0 gets the last day of the previous month

  if (monthIndex === currentDate.getUTCMonth()+1) {
    const maxEndDate = new Date(Math.min(endDate.getTime(), currentDate.getTime()));
    return {
      startDate: moment(startDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
      endDate: moment(isWorkshop ? endDate : maxEndDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
    };
  }

  return {
    startDate: moment(startDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
    endDate: moment(endDate).format('YYYY-MM-DD'), // Format as 'YYYY-MM-DD'
  };
}

export const getYearsArrayByLiveOnData = (liveOnDate:any) => {
  const currentYear = new Date().getUTCFullYear();
  const startYear = liveOnDate.getUTCFullYear();
  const yearsArray: string[] = [];

  for (let year = startYear; year <= currentYear; year += 1) {
    yearsArray.push(year.toString());
  }

  yearsArray.push('All')

  return yearsArray;
}

export function getMonthsArrayFromYear(startDate: Date, _year: string, isWorkshop?: boolean | undefined): string[] {
  if (_year === 'All') {
    return []
  }
  const year = Number(_year)
  const startYear = startDate.getUTCFullYear();
  const startMonth = startDate.getUTCMonth() + 1; // Adding 1 to convert from zero-based index to 1-based index
  const currentYear = new Date().getUTCFullYear();
  const currentMonth = new Date().getUTCMonth() + 1;

  const monthOptions = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  const monthsArray: string[] = [];

  if (startYear === year) {
    for (let month = startMonth; month <= 12; month += 1) {
      if (year > currentYear || (currentYear === year && month > currentMonth)) {
        break;
      }
      const formattedMonth = monthOptions[month - 1];
      monthsArray.push(formattedMonth);
    }
  } else {
    for (let month = 1; month <= 12; month += 1) {
      if ((year > currentYear || (currentYear === year && month > currentMonth)) && !isWorkshop) { // workshop handling
        break;
      }
      const formattedMonth = monthOptions[month - 1];
      monthsArray.push(formattedMonth);
    }
  }

  return monthsArray;
}

export const getAddressByLatLng = async (latitude:number|undefined, longitude:number|undefined) => {
  let address = '';
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${latitude || ''},${longitude || ''}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
  await fetch(url)
    .then(response => response.json())
    .then(responseJson => {
      if (responseJson.status === 'OK') {
        // console.log(responseJson?.results?.[0]?.formatted_address)
        address = responseJson?.results?.[0]?.formatted_address || "";
      }
    })
    .catch(error => {
      console.log(error);
    });
  return address;
}

export const commaSeparatedFormat = (number?: number | string): string =>  {
  // Convert the number to a string if it's a number
  const numberStr: string | undefined = typeof number === 'number' ? number.toString() : number;

  if (typeof numberStr !== 'string') {
    return ''; // or handle the case when the input is not a valid number or string
  }

  // Split the number into integer and decimal parts (if any)
  const parts: string[] = numberStr.split('.');

  // Format the integer part with commas
  const integerPart = parts[0];
  let formattedInteger = '';
  let i = integerPart.length;
  let count = 0;

  while (i > 0) {
    formattedInteger = integerPart[i - 1].concat(formattedInteger)
    i-=1;
    count+=1;
    if (count === 3 && i > 0) {
      formattedInteger = ','.concat(formattedInteger);
      count = 0;
    }
  }

  // Rejoin the integer and decimal parts (if any)
  let formattedNumber = formattedInteger;
  if (parts.length > 1) {
    formattedNumber += '.'.concat(parts[1]);
  }

  return formattedNumber;
}

export const formatBytes = (bytes: number): string =>  {

  if (bytes < 1024) {
      return String(bytes).concat(' Bytes');
  } else if (bytes < 1024 * 1024) {
      return String((bytes / 1024).toFixed(2)).concat(' KB');
  } else {
      return String((bytes / (1024 * 1024)).toFixed(2)).concat(' MB');
  }
}

export function shadeColor(colorCode: string, degree: number): string {
  // Check if the degree is within the valid range (1 to 100)
  if (degree < 1) {
    degree = 1;
  } else if (degree > 100) {
    degree = 100;
  }

  // Remove the '#' symbol from the color code
  colorCode = colorCode.replace('#', '');

  // Convert the color hex code to RGB values
  const r = parseInt(colorCode.slice(0, 2), 16);
  const g = parseInt(colorCode.slice(2, 4), 16);
  const b = parseInt(colorCode.slice(4, 6), 16);

  // Adjust the brightness based on the degree
  const v = degree / 100;

  // Calculate the new RGB values
  const rNew = Math.round(r * v);
  const gNew = Math.round(g * v);
  const bNew = Math.round(b * v);

  // Convert the updated RGB values to a hex code without padStart()
  const updatedColor = `#${(1 << 24 | rNew << 16 | gNew << 8 | bNew).toString(16).slice(1)}`; // eslint-disable-line no-bitwise
  return updatedColor;
}

export const downloadAuditReportCSV = (data: any) => {
  // define the heading for each row of the data  
  let res = 'Employees Travel\n\n';  

  const travelData = Object.values(data.travel);
  const utilitiesData = Object.values(data.utilities);

  travelData.forEach((item:any) => {
    let totEm = item.emission;
    if (totEm<10 && totEm!==0)
      totEm = totEm.toFixed(1);
    else
      totEm = Math.round(totEm)
    res = res.concat(`${item.location},,,${totEm}\n`)
    res = res.concat('Activity,Fuel Type,Distance(km),Emission(kgCO2)\n')

    const activities = Object.values(item.activities);
    activities.forEach((row: any) => {
      let em = row.emission; 
      let dis = row.distance;
      if (em<10 && em!==0)
        em = em.toFixed(1);
      else
        em = Math.round(em);
      if (dis<10 && dis!==0)
        dis = dis.toFixed(1);
      else
        dis = Math.round(dis);
      
      res = res.concat(`${row.activityModel},,${dis},${em}\n`)

      row.fuelTypes.forEach((subRow: any) => {
        let fEm = subRow.emission; 
        let fDis = subRow.distance;
        if (fEm<10 && fEm!==0)
          fEm = fEm.toFixed(1);
        else
          fEm = Math.round(fEm);
        if (fDis<10 && fDis!==0)
          fDis = fDis.toFixed(1);
        else
          fDis = Math.round(fDis);

        res = res.concat(`,${subRow.fuelType},${fDis},${fEm}\n`);
      })
    })

    res = res.concat('\n');
  })

  res = res.concat('\nPower Consumption\n\n');

  utilitiesData.forEach((row: any) => {
    let csv = '';
    let dataAddedFlag = false;
    let totEm = row.emission;
    if (totEm<10 && totEm!==0)
      totEm = totEm.toFixed(1);
    else
      totEm = Math.round(totEm)
    csv = csv.concat(`${row.location},,,${totEm}\n`)
    csv = csv.concat('Energy Type,Activity,Units(KWH/L),Emission(kgCO2)\n');

    let em = row.electricEmission; 
    const un = Math.round(row.electricUnits);
    if (em<10 && em!==0)
      em = em.toFixed(1);
    else
      em = Math.round(em);
    
    if (un !== 0 || em !== 0) {
      dataAddedFlag = true;
      csv = csv.concat(`Electricity,,${un},${em}\n`);
    }

    row.activities.forEach((subRow: any) => {
      let eEm = subRow.electricEmission; 
      const eUn = Math.round(subRow.electricUnits);
      if (eEm<10 && eEm!==0)
        eEm = eEm.toFixed(1);
      else
        eEm = Math.round(eEm);
      
      if (eUn !== 0 || eEm !== 0) {
        dataAddedFlag = true;
        csv = csv.concat(`,${subRow.activityModel},${eUn},${eEm}\n`);
      }
    })

    let gEm = row.gasEmission; 
    const gUn = Math.round(row.gasUnits);
    if (gEm<10 && gEm!==0)
      gEm = gEm.toFixed(1);
    else
      gEm = Math.round(gEm);
    
    if (gUn !== 0 || gEm !== 0) {
      dataAddedFlag = true;
      csv = csv.concat(`Natural Gas,,${gUn},${gEm}\n`);
    }

    row.activities.forEach((subRow: any) => {
      let ggEm = subRow.gasEmission; 
      const ggUn = Math.round(subRow.gasUnits);
      if (ggEm<10 && ggEm!==0)
        ggEm = ggEm.toFixed(1);
      else
        ggEm = Math.round(ggEm);
      
      if (ggUn !== 0 || ggEm !== 0) {
        dataAddedFlag = true;
        csv = csv.concat(`,${subRow.activityModel},${ggUn},${ggEm}\n`);
      }
    })

    if (dataAddedFlag) {
      csv = csv.concat('\n');
      res = res.concat(csv);
    }
  })
  
  const blob = new Blob([res], { type: 'text/csv' });
  // Create a link element
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'audit-report.csv';

  // Trigger a click event to start the download
  link.click();
  // location.reload();

  // Clean up by revoking the Blob URL and removing the link
  window.URL.revokeObjectURL(link.href);
  // document.body.removeChild(link);
}

export const downloadTabularAuditReportCSV = (data: any) => {
  // Define CSV header for Travel and Power Consumption tables
  let travelRes = 'Type,Location,Activity,Fuel Type,Distance(Km),Emissions(kgCO2)\n';
  let powerRes = 'Type,Location,Activity,Energy Type,Units(KWH/L),Emissions(kgCO2)\n';

  const travelData = Object.values(data.travel);
  const utilitiesData = Object.values(data.utilities);

  // Process Travel Data (only subrows)
  travelData.forEach((item: any) => {
    const {location} = item;

    const activities = Object.values(item.activities);
    activities.forEach((row: any) => {
      // Add Travel Data for subrows (fuel types only)
      row.fuelTypes.forEach((subRow: any) => {
        let fEm = subRow.emission;
        let fDis = subRow.distance;
        if (fEm < 10 && fEm !== 0) fEm = fEm.toFixed(1);
        else fEm = Math.round(fEm);
        if (fDis < 10 && fDis !== 0) fDis = fDis.toFixed(1);
        else fDis = Math.round(fDis);

        travelRes = travelRes.concat(`Travel,${location},${row.activityModel},${subRow.fuelType},${fDis},${fEm}\n`);
      });
    });
  });

  // Process Power Consumption Data (only subrows)
  utilitiesData.forEach((row: any) => {
    const {location} = row;

    // Sub Activities for Electricity
    row.activities.forEach((subRow: any) => {
      let eEm = subRow.electricEmission;
      const eUn = Math.round(subRow.electricUnits);
      if (eEm < 10 && eEm !== 0) eEm = eEm.toFixed(1);
      else eEm = Math.round(eEm);

      if (eUn !== 0 || eEm !== 0) {
        powerRes = powerRes.concat(`Power Consumption,${location},${subRow.activityModel},Electricity,${eUn},${eEm}\n`);
      }

      // Sub Activities for Gas
      let ggEm = subRow.gasEmission;
      const ggUn = Math.round(subRow.gasUnits);
      if (ggEm < 10 && ggEm !== 0) ggEm = ggEm.toFixed(1);
      else ggEm = Math.round(ggEm);

      if (ggUn !== 0 || ggEm !== 0) {
        powerRes = powerRes.concat(`Power Consumption,${location},${subRow.activityModel},Natural Gas,${ggUn},${ggEm}\n`);
      }
    });
  });

  // Combine Travel and Power Consumption tables with a blank line in between
  const res = travelRes.concat('\n').concat(powerRes);

  // Create CSV Blob and download it
  const blob = new Blob([res], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'audit-report.csv';
  link.click();

  window.URL.revokeObjectURL(link.href);
};

export const downloadScopeOneReportCSV = (data: any) => {
  // Define CSV headers
  let csvContent = 'Operational Boundary,Emissions Source,Emissions (kgCO2),Total Emissions (kgCO2)\n';

  // Process data
  data.forEach((item: any) => {
    // Add each emission source row
    item.data.forEach((source: any, index: number) => {
      let { emissions } = source;
      // Format emissions to 2 decimal places if less than 10 and not 0
      if (emissions < 10 && emissions !== 0) {
        emissions = emissions.toFixed(2);
      } else {
        emissions = Math.round(emissions);
      }

      csvContent = csvContent.concat(
        `${item.operationalBoundary},${source.emissionSource},${emissions},${index === item.data.length - 1 ? item.totalEmissions : ''}\n`
      );
    });
  });

  // Create and download CSV file
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'scope-one-report.csv';
  link.click();

  window.URL.revokeObjectURL(link.href);
};

export const downloadScopeTwoReportCSV = (data: any) => {
  // Define CSV headers for Category and Location tables
  let categoryRes = 'Operational Boundary,Emissions Source,Emissions (kgCO2),Total Emissions (kgCO2)\n';
  let locationRes = 'Office,City,Emissions,Total Employees,Emissions Per Employee\n';

  const {categoryData, locationData} = data;

  // Process Category Data
  categoryData.forEach((item: any) => {
    categoryRes = categoryRes.concat(
      `${item.operationalBoundary},${item.emissionsSource},${item.emissions},${item.totalEmissions}\n`
    );
  });

  // Process Location Data
  locationData.forEach((item: any) => {
    locationRes = locationRes.concat(
      `${item.office},${item.city},${item.emissions},${item.totalEmployees},${item.emissionsPerEmployee}\n`
    );
  });

  // Combine Category and Location tables with a blank line in between
  let res = categoryRes.concat('\n\nLocation\n');
  res = res.concat(locationRes);

  // Create CSV Blob and download it
  const blob = new Blob([res], { type: 'text/csv' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'scope-two-report.csv';
  link.click();

  window.URL.revokeObjectURL(link.href);
};

export const transformReportsListData = (data: any): any[] => {
  const arr = Object.values(data);
  const res:any = [];

  arr.forEach((item:any) => {
    res.push({
      id: item.id,
      reportName: item.name,
      period: `${moment(item.fromDate).format('DD/MM/YYYY')} - ${moment(item.toDate).format('DD/MM/YYYY')}`,
      generatedOn: moment(item.createdAt.slice(0,10)).format('DD/MM/YYYY'),
      data: item.data,
      status: item.status,
      version: item.version,
      orgId: item.orgId,
      orgTagId: item.orgTagId,
    })
  })

  return res;
}

export const checkIfAllReportCompleted = (data: any): boolean => {
  if (typeof data === "undefined")
    return false;

  for (let i=0; i<data.length; i+=1) {
    if (data[i].status !== "complete")
      return false;
  }

  return true;
}

export const sortDataByColumnAndSortOrder = (sortBy: string, sortOrder: string, data: any[]) => {
  const array = data;

  // Check if the sortOrder is 'asc' or 'desc', default to 'asc' if not provided or invalid
  const order = sortOrder === 'desc' ? -1 : 1;
    
  // Sort the array of objects based on the sortBy property
  array.sort((a, b) => {
      if (a[sortBy] < b[sortBy]) {
          return -1 * order; // Multiply by -1 to reverse the sort order if descending
      }
      if (a[sortBy] > b[sortBy]) {
          return 1 * order;
      }
      return 0;
  });

  return array;
}

export const tagsColorPalette = [ 
  '#F0DEFF', 
  '#D1FFE1', 
  '#FFEDED', 
  '#DFFFFD', 
  '#D8EBFF', 
  '#FFD7D7', 
  '#FFFBD2',
]

export const tagsDarkColorShade: { [key: string]: string; } = {
  '#F0DEFF': '#A843FB', 
  '#D1FFE1': '#20DA61', 
  '#FFEDED': '#F53F3F', 
  '#DFFFFD': '#2CE0D6', 
  '#D8EBFF': '#2E81DA', 
  '#FFD7D7': '#F11B1B', 
  '#FFFBD2': '#b5a719',
}

/* eslint no-restricted-globals: "error" */
/* eslint prefer-template: "error" */
/* eslint no-bitwise: "error" */