/* eslint-disable arrow-body-style */
/* eslint-disable dot-notation */

import { LockedScreensType } from "./types"

export const getLockedScreens = (lockedScreens: any): LockedScreensType => ({
  dashboardScopePage: typeof lockedScreens?.dashboard === 'number' && lockedScreens?.dashboard === 0,
  projectedEmissionsPage: typeof lockedScreens?.['dashboard-projected-emissions'] === 'number' && lockedScreens?.['dashboard-projected-emissions'] === 0,
  scopeOnePage: typeof lockedScreens?.['dashboard-scope-one'] === 'number' && lockedScreens?.['dashboard-scope-one'] === 0,
  scopeTwoPage: typeof lockedScreens?.['dashboard-scope-two'] === 'number' && lockedScreens?.['dashboard-scope-two'] === 0,
  scopeThreePage: typeof lockedScreens?.['dashboard-scope-three'] === 'number' && lockedScreens?.['dashboard-scope-three'] === 0,
  forestPage: typeof lockedScreens?.forest === 'number' && lockedScreens?.forest === 0,
  uploadDataPage: typeof lockedScreens?.['upload-data'] === 'number' && lockedScreens?.['upload-data'] === 0,
  surveyQuestionsPage: typeof lockedScreens?.['survey-questions'] === 'number' && lockedScreens?.['survey-questions'] === 0,
  fuelEmissionPage: typeof lockedScreens?.['fuel-emission'] === 'number' && lockedScreens?.['fuel-emission'] === 0,
  forestDocsPage: typeof lockedScreens?.['forest-docs'] === 'number' && lockedScreens?.['forest-docs'] === 0,
  dashboardEmissionPage: typeof lockedScreens?.['dashboard-emission'] === 'number' && lockedScreens?.['dashboard-emission'] === 0,
  detailsPage: typeof lockedScreens?.['details'] === 'number' && lockedScreens?.['details'] === 0,
  leaderboardPage: typeof lockedScreens?.['leaderboard'] === 'number' && lockedScreens?.['leaderboard'] === 0,
  purchaseForestPage: typeof lockedScreens?.['purchase-forest'] === 'number' && lockedScreens?.['purchase-forest'] === 0,
  utilityBillPage: typeof lockedScreens?.['utility-bill'] === 'number' && lockedScreens?.['utility-bill'] === 0,
  employeeUploadPage: typeof lockedScreens?.['employee-upload'] === 'number' && lockedScreens?.['employee-upload'] === 0,
  employeeDetailsPage: typeof lockedScreens?.['employee-details'] === 'number' && lockedScreens?.['employee-details'] === 0,
  customTagsPage: typeof lockedScreens?.['custom-tags'] === 'number' && lockedScreens?.['custom-tags'] === 0,
  customGroupsPage: typeof lockedScreens?.['custom-groups'] === 'number' && lockedScreens?.['custom-groups'] === 0,
  orgReportsPage: typeof lockedScreens?.['org-reports'] === 'number' && lockedScreens?.['org-reports'] === 0,
  masterDataPage: typeof lockedScreens?.['master-data'] === 'number' && lockedScreens?.['master-data'] === 0,
  quizzesPage: typeof lockedScreens?.['quizzes'] === 'number' && lockedScreens?.['quizzes'] === 0,
  challengesPage: typeof lockedScreens?.['challenges'] === 'number' && lockedScreens?.['challenges'] === 0,
  pollsPage: typeof lockedScreens?.['polls'] === 'number' && lockedScreens?.['polls'] === 0,
  wasteManagementPage: typeof lockedScreens?.['waste-management'] === 'number' && lockedScreens?.['waste-management'] === 0,
})
