interface HeaderInfoCardProps {
  label: string
  value?: string | number
  unit?: string
  type?: "blue" | "white"
  icon?: React.ReactNode
}


const HeaderInfoCard: React.FC<HeaderInfoCardProps> = (props) => {
  const {
    label, 
    value, 
    unit, 
    type = "blue",
    icon
  } = props;

  const cardStyle :any = {
    default: {
      containerStyle: {
        position: 'relative',
        borderRadius: '20px',
        padding: '1rem',
        flex: 1,
        zIndex: 0
      },
      labelStyle: {fontWeight: 600},
      valueContainerStyle: {display: 'inline-block', verticalAlign: 'bottom', zIndex: 10, position: 'relative'},
      valueStyle: {display:'inline-block', verticalAlign: 'baseline', fontSize: '2.2rem', fontWeight: 600},
      subLabelStyle: {display: 'inline-block', verticalAlign: 'baseline', marginLeft: '0.5rem', fontSize: '1rem'}
    },
    blue: {
      containerStyle: {
        backgroundColor: '#232b47',
        marginRight: '1rem !important'
      },
      labelStyle: {color: 'white', fontWeight: 600},
      valueStyle: {color: 'white',},
      subLabelStyle: {color: 'white'}
    },
    white: {
      containerStyle: {
        border: '1px solid #0A0E23',
      },
      labelStyle: {color: '#444444', fontWeight: 600},
      valueStyle: {},
      subLabelStyle: {color: '#444444'}
    }
  }

  return (
    <div style={{...cardStyle.default.containerStyle, ...cardStyle[type].containerStyle}}>
      <div style={{marginBottom: '1rem', zIndex: 10, position: 'relative'}}>
        <span style={{...cardStyle.default.labelStyle, ...cardStyle[type].labelStyle}}>
          {(typeof label !== 'undefined') ? label?.toUpperCase() : '--'}
        </span>
      </div>
      <div style={{...cardStyle.default.valueContainerStyle}}>
        <span style={{...cardStyle.default.valueStyle, ...cardStyle[type].valueStyle}}>
          {(value && value !== '0' && value !== 0) ? value : '--'}
        </span>
        <span style={{...cardStyle.default.subLabelStyle, ...cardStyle[type].subLabelStyle}}>
          {unit && unit?.length > 0 ? unit : ''}
        </span>
      </div>
      {icon && <div style={{position: 'absolute', bottom: 10, right: 10, zIndex: 0}}>{icon}</div>}
    </div>
  )
}

export { HeaderInfoCard }