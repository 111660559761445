import moment from 'moment'
import { IconSamplingFall, IconSaplingSm } from '../../../../../_metronic/assets/icons'

const COLORS = ['#C880B8', '#B77FB9', '#A47DBA', '#A47DBA', '#957BBA']

export const PlantationTimeline = (props: {plantationTimeLineData: any, totalTreesPlanted: number, totalTreesPlanned: number}) => {
  const {plantationTimeLineData, totalTreesPlanted, totalTreesPlanned} = props

  const displayData = plantationTimeLineData?.slice(0, 4)?.reverse() || []
  
  if (totalTreesPlanted < totalTreesPlanned) {
    displayData?.push({
      month: moment().format('YYYY'),
      totalTreesPlanted: totalTreesPlanned - totalTreesPlanted,
      isLastItem: true
    })
  }

  return (
    <div className='py-6 mt-4' style={{backgroundColor: '#F5F5F5'}}>
      <div className='d-flex align-items-center justify-content-center position-relative'>
        {displayData?.map((update: any, index: number, arr: any) => (
          <div key={update.month} className='d-flex align-items-center justify-content-center'>
            {/* Timeline Node */}
            <div className='d-flex flex-column align-items-center'>
              <h5 className='mt-2 fw-bold'>
                {
                  update?.isLastItem ? moment(update.month).format('YYYY') : moment(update.month).format('MMM YYYY')
                }
              </h5>
              <div className="rounded-circle d-flex align-items-center justify-content-center"
                style={{
                  width: '48px',
                  height: '48px',
                  backgroundColor: update?.isLastItem ? '#E6E6E6' : COLORS[index]
                }}>
                {update?.isLastItem ? (
                  // <i className="bi bi-tree" style={{fontSize: '24px', color: '#957BBA'}} />
                  <IconSaplingSm style={{width: '24px', height: '24px', color: '#957BBA'}} />
                ) : (
                  <i className="bi bi-check-lg text-white" style={{fontSize: '24px'}} />
                )}
              </div>
              <div className='mt-2'>
                <div className='text-center'>{update?.isLastItem ? 'Yet to plant' : 'Total planted'}</div>
                <div className='text-center fw-bold d-flex align-items-center justify-content-center'>
                  {
                    update?.totalTreesPlanted ? <IconSamplingFall style={{width: '10px', height: '10px', marginRight: '5px'}} /> : null
                  }
                  {update?.totalTreesPlanted || null}
                </div>
              </div>
            </div>
            
            {/* Connector Line */}
            {index < arr.length - 1 && !update?.isLastItem && (
              <div style={{width: '100px', paddingBottom: '10px'}}>
                <div className="m-0" style={{width: '100px', height: '2px', backgroundColor: COLORS[index]}} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}