import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { IconFillArrowLeftCircle, IconFillArrowRightCircle } from '../../../../../_metronic/assets/icons';
import { SpeciesInfoCard } from '../cards/species-info-card';

type SpeciesInfoCarouselProps = {
  data: any[]
  title?: string
}

const BKG_COLORS = ["#F8ECF5", "#F4ECF8", "#EEECF8", "#ECEEF8", "#EBEEFD", "#E5E9FC", "#D8DDF3", "#DAD8F3", "#E4D8F3", "#F8ECF5"]

export const SpeciesInfoCarousel = (props: SpeciesInfoCarouselProps) => {
  const { data, title } = props;

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    // if (data?.length < 6) {
      setIndex(0);
    // }
  }, [data]);

  return (
    <>
    <h3 className='m-0 mt-6 mb-2'>{title || 'SPECIES PLANTED'}</h3>
    <Carousel
      activeIndex={index}
      indicators={false}
      onSelect={handleSelect}
      className='pt-4 d-flex justify-content-center align-items-center'
      style={{
        height: '180px',
        backgroundImage: 'linear-gradient(to bottom, #FFFFFF,rgb(255, 255, 255))'
      }}
      prevIcon={data?.length > 5 ? <IconFillArrowLeftCircle height={32} width={32} style={{marginLeft: -10}} /> : null}
      nextIcon={data?.length > 5 ? <IconFillArrowRightCircle height={32} width={32} style={{marginRight: -10}} /> : null}
      interval={6000}
    >
      {
        data?.length === 0 && (
          <div className='d-flex justify-content-center align-items-center py-18 gap-8'>
            <h4 className='m-0 py-4'>No data found</h4>
          </div>
        )
      }
      {Array.from({ length: Math.ceil((data?.length || 0) / 5) }).map((_, slideIndex) => {
        const slideStart = slideIndex * 5;
        const slideEnd = slideStart + 5;
        const slideId = `slide-${slideStart}-${slideEnd}`;
        
        return (
          <Carousel.Item key={slideId}>
            <div className="d-flex justify-content-center align-items-center gap-8">
              {data.slice(slideStart, slideEnd).map((item: any, cardIndex: number) => (
                <div key={item.speciesName} style={{width: '12%', height: '90%'}}>
                  <SpeciesInfoCard
                    name={item.speciesName}
                    image={item.image}
                    count={item.count}
                    bkgColor={BKG_COLORS[(cardIndex || 0) % BKG_COLORS.length]}
                  />
                </div>
              ))}
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
    </>
  );
};

export default SpeciesInfoCarousel;
