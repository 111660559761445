/* eslint-disable react/no-unstable-nested-components */
import {useState, useEffect, useMemo} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useGetOrganizationsLeaderboardMutation } from '../store/api';
import ReactTable from '../../../../_metronic/partials/components/tables/ReactTable'
import { RootState } from '../../../store/store';
import { LeaderboardCategoryFilter } from './LeaderboardCategoryFilter';
import { LeaderboardActivitiesFilter } from './LeaderboardActivitiesFilter';
import { LeaderboardBarGraph } from './LeaderboardBarGraph';
import { commaSeparatedFormat, sortDataByColumnAndSortOrder } from '../../../utils/helpers';
import { LeaderboardGraphItem } from './types';

const getRange : {[key: string]: string} = {
  "daily": "daily", 
  "curr_week": "daily",
  "weekly": "weekly",
  "curr_month": "monthly",
  "monthly": "monthly",
  "6_months": "6_months",
  "curr_year": "yearly",
  "yearly": "yearly"
}

const transformToGraphData = (arr: any): LeaderboardGraphItem[] => {
  const result: LeaderboardGraphItem[] = [];
  let count = 0;
  for (let i = arr.length - 1; i >= 0; i -= 1) {
    const item = arr[i];
    result.push({
      label: item.groupName,
      value: item.totalEmissions
    })

    count += 1;
    if (count === 5) 
      break;
  }
  return result;
}

const RenderHeaderCell = ({value}: {value:string}) => (
  <div>{value}</div>
)
const RenderHeaderCellCentered = ({value}: {value:string}) => (
  <div style={{display: 'inline', marginLeft: '20%'}}>
    {value}
  </div>
)
const RenderGroupName = ({value, employeeCount}: {value:string, employeeCount:number}) => (
  <div>{value}</div>
)
const RenderTotalEmissions = ({value}: {value:number}) => (
  <div className="d-flex justify-content-center">{value < 1 ? '<1' : commaSeparatedFormat(Math.round(value))}</div>
)
const RenderAvgEmissions = ({value}: {value:number}) => (
  <div className="d-flex justify-content-center">{value < 1 ? '<1' : commaSeparatedFormat(Math.round(value))}</div>
)
const RenderAvgMqiEmissions = ({value}: {value:number}) => {
  let val = value < 1 ? '<1' : commaSeparatedFormat(Math.round(value))
  val = value < 0 ? '<0' : val;
  return (
    <div className="d-flex justify-content-center">{val}</div>
  )
}

export const DashboardLeaderboard = () => {
  const {filterLeaderboardCategory, filterLeaderboardActivities, filterLeaderboardTimePeriod} = useSelector((state: RootState) => state.filters)

  const [leaderboardData, setLeaderboardData] = useState<any[]>([]);
  const [leaderboardGraphData, setLeaderboardGraphData] = useState<LeaderboardGraphItem[]>([]);
  const [sortBy, setSortBy] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');

  const [getLeaderboardData, {data: leaderboardResponseData}] = useGetOrganizationsLeaderboardMutation();

  const onSortChange = (columnId: string, order: string) => {
    if (columnId !== sortBy)
      setSortBy(columnId)
    if (order !== sortOrder) 
      setSortOrder(order)
  }

  useEffect(() => {
    let startDate: string;
    let endDate: string;
    switch (filterLeaderboardTimePeriod) {
      case "daily": 
        startDate = moment().utc().subtract(1, 'days').format('YYYY-MM-DD');
        endDate = startDate;
        break;
      case "curr_week":
        endDate = moment().utc().subtract(1, 'days').format('YYYY-MM-DD');
        startDate = moment().utc().subtract(moment(endDate).day(), 'days').format('YYYY-MM-DD');
        break;
      case "weekly":
        startDate = moment().utc().subtract(moment().utc().day() + 6, 'days').format('YYYY-MM-DD');
        endDate = moment().utc().subtract(moment().utc().day(), 'days').format('YYYY-MM-DD');
        break;
      case "curr_month":
        startDate = moment().utc().startOf('month').format('YYYY-MM-DD');
        endDate = moment().utc().subtract(1, 'days').format('YYYY-MM-DD');
        break;
      case "monthly":
        startDate = moment().utc().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        endDate = moment().utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        break;
      case "6_months":
        startDate = moment().utc().subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
        endDate = moment().utc().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        break;
      case "curr_year":
        startDate = moment().utc().startOf('year').format('YYYY-MM-DD');
        endDate = moment().utc().subtract(1, 'days').format('YYYY-MM-DD');
        break;
      case "yearly":
        startDate = moment().utc().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
        endDate = moment().utc().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
        break;
      default: 
        startDate = moment().utc().subtract(moment().utc().day() + 6, 'days').startOf('day').format('YYYY-MM-DD');
        endDate = moment().utc().subtract(moment().utc().day(), 'days').startOf('day').format('YYYY-MM-DD');
        break;
    }
    getLeaderboardData({
      startDate,
      endDate,
      range: getRange[filterLeaderboardTimePeriod],
      category: filterLeaderboardCategory,
      activities: filterLeaderboardActivities,
    })
  }, [
    filterLeaderboardCategory, 
    filterLeaderboardActivities, 
    filterLeaderboardTimePeriod
  ])

  useEffect(() => {
    if (leaderboardResponseData?.data?.leaderboard) {
      const list = [...leaderboardResponseData?.data?.leaderboard || null];
      const reverseList = list.reverse();
      setLeaderboardData(reverseList);
      setLeaderboardGraphData(transformToGraphData(leaderboardResponseData?.data?.leaderboard))
    }
  }, [leaderboardResponseData])

  useEffect(() => {
    if (sortBy.length>0 && sortOrder.length>0) {
      const arr = sortDataByColumnAndSortOrder(sortBy, sortOrder, leaderboardData);
      setLeaderboardData([...arr]);
    }
  }, [sortBy, sortOrder])

  const headersWithMqi = useMemo(
    () => [
      {
        Header: () => (
          <RenderHeaderCell value={filterLeaderboardCategory.toUpperCase()} />
        ),
        accessor: "groupName",
        disableSortBy: true,
        Cell: ({cell}: {cell: any}) => (
          <RenderGroupName value={cell.row.original.groupName} employeeCount={cell.row.original.employeeCount} />
        )
      },
      {
        Header: () => (
          <RenderHeaderCellCentered value="TOTAL EMISSIONS (kgCO2)" />
        ),
        accessor: "totalEmissions", 
        Cell: ({cell}: {cell: any}) => (
          <RenderTotalEmissions value={cell.row.original.totalEmissions || 0} />
        )
      },
      {
        Header: () => (
          <RenderHeaderCellCentered value="PER PERSON EMISSIONS (kgCO2)" />
        ), 
        accessor: "avgEmissions", 
        Cell: ({cell}: {cell: any}) => (
          <RenderAvgEmissions value={cell.row.original.avgEmissions || 0} />
        )
      },
      {
        Header: () => (
          <RenderHeaderCellCentered value="AVG MQI" />
        ), 
        accessor: "avgMqi", 
        Cell: ({cell}: {cell: any}) => (
          <RenderAvgMqiEmissions value={cell.row.original?.avgMqi || 0} />
        )
      }
    ],
    [filterLeaderboardCategory]
  );

  const headers = useMemo(
    () => [
      {
        Header: () => (
          <RenderHeaderCell value={filterLeaderboardCategory.toUpperCase()} />
        ),
        accessor: "groupName",
        disableSortBy: true,
        Cell: ({cell}: {cell: any}) => (
          <RenderGroupName value={cell.row.original.groupName} employeeCount={cell.row.original.employeeCount} />
        )
      },
      {
        Header: () => (
          <RenderHeaderCellCentered value="TOTAL EMISSIONS (kgCO2)" />
        ),
        accessor: "totalEmissions", 
        Cell: ({cell}: {cell: any}) => (
          <RenderTotalEmissions value={cell.row.original.totalEmissions || 0} />
        )
      },
      {
        Header: () => (
          <RenderHeaderCellCentered value="PER PERSON EMISSIONS (kgCO2)" />
        ), 
        accessor: "avgEmissions", 
        Cell: ({cell}: {cell: any}) => (
          <RenderAvgEmissions value={cell.row.original.avgEmissions || 0} />
        )
      },
    ],
    [filterLeaderboardCategory]
  );

  return (
    <div className="my-5 px-12">
      <LeaderboardCategoryFilter />
      <LeaderboardActivitiesFilter />
      <LeaderboardBarGraph leaderboard={leaderboardGraphData} />
      <ReactTable
        headerData={(leaderboardData.length>0 && Object.keys(leaderboardData[0]).includes("avgMqi")) ? headersWithMqi : headers}
        tableData={leaderboardData}
        currentPageIndex={0}
        className="custom-border"
        manualSort
        onSort={onSortChange}
        showPagination={false}
        manualPagination
        message="No data found!"
      />
    </div>
  )
}
/* eslint-disable react/no-unstable-nested-components */
