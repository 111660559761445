type SpeciesSurvivedChartProps = {
  data?: {
    speciesPlanted: number[]
    percentSurvived: number[]
    categories: {
      speciesName: string
      image: string
    }[]
  }
}

const SpeciesSurvivedChart = (props: SpeciesSurvivedChartProps) => {
  const { data } = props

  return (
    <div className="bordered-grey px-6 pt-6 pb-12 d-flex flex-column gap-8" style={{
      background: 'white',
      borderRadius: '8px',
      minHeight: '440px',
      maxHeight: '440px',
      overflowY: 'auto',
      scrollbarColor: '#413C80 #ebebeb',
      borderColor: '#7F7F7F'
    }}>
      {
        data?.categories.length === 0 ? (
          <div className="d-flex align-items-center justify-content-center" style={{flexGrow: 1}}>
            <h4 className="m-0">No species found</h4>
          </div>
        ) : (
          data?.categories?.map((species, index) => (
            <div className="d-flex flex-column w-100 gap-4" key={species.speciesName}>
              <div className="d-flex flex-end justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <img 
                    src={species.image} 
                    alt={species.speciesName}
                    style={{
                      width: '44px',
                      height: '44px',
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                  />
                  <div className="d-flex flex-column gap-2 mx-2">
                    <h4 className="m-0" style={{fontWeight: 700}}>
                      {species.speciesName}
                    </h4>
                    <h6 className="m-0">
                      PLANTED - {data.speciesPlanted[index]}
                    </h6>
                  </div>
                </div>
                <h5 className="m-0" style={{
                  color: '#413C80',
                  fontWeight: 700
                }}>
                  {data.percentSurvived[index]}%
                </h5>
              </div>
              
              <div className="d-flex flex-grow-1 position-relative" style={{
                height: '5px',
                background: '#E5E7EB',
                borderRadius: '12px',
                overflow: 'hidden'
              }}>
                <div style={{
                  width: `${0 || data.percentSurvived[index]}%`,
                  height: '100%',
                  borderRadius: '12px',
                  backgroundColor: '#413C80',
                  transition: 'width 0.3s ease'
                }} />
              </div>
            </div>
          ))
        )
      }
    </div>
  )
}

export { SpeciesSurvivedChart }