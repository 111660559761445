import { useLocation, useNavigate } from "react-router-dom";
import { RoutesPath } from "../../../routing/RoutesPath";
import GrowersListTable from "../components/tables/growers-list-table";

export const GrowerDetailsPage = () => {
  const {growersList} = useLocation().state as {growersList: any};
  const navigate = useNavigate();

  return (
    <div className='blank-page-view py-2 px-8 mb-10'>
      <div className='back-header-btn mt-8 mb-6 px-6' style={{marginLeft: '0px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.FOREST_DASHBOARD.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h2 className='m-0 mx-4 bold'>GROWER DETAILS</h2>
      </div>

      <div className="px-6">
        <GrowersListTable listData={growersList?.reverse()} />
      </div>
    </div>
  )
}