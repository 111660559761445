/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import {updateProfile} from '../store/slices/profile'
import {RoutesPath} from './RoutesPath'
import {PageDataProvider} from '../../_metronic/layout/core'
import {AsideDefault} from '../../_metronic/layout/components/aside/AsideDefault'
import {HeaderWrapper} from '../../_metronic/layout/components/header/HeaderWrapper'
import {Content} from '../../_metronic/layout/components/Content'
// import {DashboardWrapper} from '../modules/dashboard/DashboardWrapper'
// import Departments from '../modules/dashboard/Departments'
import Settings from '../modules/settings/Settings'
import {ScrollTop} from '../../_metronic/layout/components/ScrollTop'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {useGetProfileDetailsQuery} from './store/api'
import {Loader} from '../../_metronic/partials'
import ComingSoonCard from '../modules/our-forest/ComingSoonCard'
import Notifier from '../../_metronic/partials/components/notifier/Notifier'
import { DashboardWrapper } from '../modules/dashboard/DashboardWrapper'
import { DashboardDetails } from '../modules/dashboard/details/DashboardDetails'
import { DashboardLeaderboard} from '../modules/dashboard/leaderboard/DashboardLeaderboard'
import {DivisionWrapper} from '../modules/dashboard/divisions/DivisionWrapper'
import {DepartmentWrapper} from '../modules/dashboard/departments/DepartmentWrapper'
import {LocationWrapper} from '../modules/dashboard/locations/LocationWrapper'
import {EditProfile} from '../modules/settings/EditProfile'
import {ChangePassword} from '../modules/settings/ChangePassword'
import {EmployeeDetails} from '../modules/employee/EmployeeDetails'
// import {EmployeeWrapper} from '../modules/employees/EmployeeWrapper'
import {EditEmployeeDetails} from '../modules/employee/EditEmployeeDetails'
import {AddNewEmployee} from '../modules/employee/AddNewEmployee'
import ComingSoonCardAfter14Days from '../modules/our-forest/ComingSoonCardAfter14Days'
import {getLocalStorageItem} from '../utils/storage'
// import AddExistingForest from '../modules/our-forest/AddExistingForest'
// import AllocateProjects from '../modules/our-forest/AllocateProjects'
// import ProjectList from '../modules/our-forest/ProjectList'
// import OurForestWrapper from '../modules/our-forest/OurForestWrapper'
import './styles.css'
import ProjectList from '../modules/our-forest/ProjectList'
import ProjectUpdates from '../modules/our-forest/ProjectUpdates'
import AllocateProjects from '../modules/our-forest/AllocateProjects'
import Configuration from '../modules/configuration/Configuration'
import AddUtilityBillPage from '../modules/configuration/AddUtilityBillPage'
import UtilitiesPage from '../modules/configuration/UtilitiesPage'
import EmptyUtilitiesPage from '../modules/configuration/EmptyUtilitiesPage'
import EmptyEmployeesPage from '../modules/employee/EmptyEmployeesPage'
import UploadEmployeeCSV from '../modules/employee/UploadEmployeeCSV'
import AuditableReportsPage from '../modules/configuration/audit-reports/audit-report-page'
import CustomTagsPage from '../modules/configuration/custom-tags'
import MasterDataPage from '../modules/configuration/master-data/MasterData'
import { RootState } from '../store/store'
import OrgReportsPage from '../modules/configuration/audit-reports'
import CustomGroupsPage from '../modules/configuration/custom-groups'
import ChallengesPage from '../modules/configuration/challenges'
import CreateChallengesPage from '../modules/configuration/challenges/screens/create-challenges'
import ChallengeDetailsPage from '../modules/configuration/challenges/screens/challenge-details'
import AttributesPage from '../modules/configuration/attributes'
import CreateAttributesPage from '../modules/configuration/attributes/screens/create-attributes-page'
import AttributeDetailsPage from '../modules/configuration/attributes/screens/attribute-details-page'
import QuizzesPage from '../modules/configuration/quizzes'
import QuizDetailsPage from '../modules/configuration/quizzes/screens/quiz-details'
import PollsPage from '../modules/configuration/polls'
import CreatePollsPage from '../modules/configuration/polls/screens/create-poll'
import PollDetailsPage from '../modules/configuration/polls/screens/poll-details'
import ForestPage from '../modules/forest'
import ForestDetailsPage from '../modules/forest/forest-update-page'
import DocsListPage from '../modules/forest/docs-list-page'
import AttributeToEmployees from '../modules/forest/attribute-to-employees-page'
import DashboardPage from '../modules/dashboard-scope'
import ScopeOnePage from '../modules/dashboard-scope/scope-one-page'
import ScopeTwoPage from '../modules/dashboard-scope/scope-two-page'
import UploadPage from '../modules/configuration/upload-data'
import ProjectedEmissionsPage from '../modules/dashboard-scope/projected-emissions-page'
import SurveyQuestionsPage from '../modules/configuration/survey-questions'
import ScopeThreePage from '../modules/dashboard-scope/scope-three-page'
import { getDateRange, getEnvironment } from '../utils/helpers'
import { updateDashboardDataTypeToggle, updateDateFilter } from '../store/slices/filters'
import GrowerForestUpdateImagesPage from '../modules/forest/grower-forest-update-images-page'
import GrowerForestSpeciesUpdatesPage from '../modules/forest/grower-forest-species-updates-page'
import FuelEmissionPage from '../modules/dashboard-scope/fuel-emission-page'
import ForestDashboardPage from '../modules/forest-dashboard'
import { ForestSiteDetailsPage } from '../modules/forest-dashboard/pages/forest-site-details-page'
import { ForestPlantationAndUpdatePage } from '../modules/forest-dashboard/pages/forest-plantation-and-update-page'
import { GrowerDetailsPage } from '../modules/forest-dashboard/pages/grower-details-page'
import LockWrapper, { LockWrapperPartial } from '../../_metronic/partials/components/lock-wrapper/LockWrapper'
import { PROD_LNT_ORG_ID, MYNZO_ORG_ID, STAGING_LNT_LIKE_ORG_ID, STAGING_NAANDI_ORG_ID, PROD_NAANDI_ORG_ID } from '../utils/dummyData'


const PrivateLayout = () => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const location = useLocation()
  const dispatch = useDispatch()
  const {data: profileDetails, isLoading} = useGetProfileDetailsQuery()
  const { filterCategory, filterMonth, filterYear } = useSelector((state: RootState) => state.filters)
  const {profile, defaultEngagement, lockedScreens} = useSelector((state: RootState) => state.profile)
  const [selectedMonth, setMonth] = useState<string>('All')

  const [daysSinceLiveOnDate, setDaysSinceLiveOnDate] = useState<number>(0)

  const liveOnDate = new Date(getLocalStorageItem('live-on'))

  useEffect(() => {
    // Calculate the number of days since the live on date
    const currentDate = new Date()
    const timeDiff = currentDate.getTime() - liveOnDate.getTime()
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))
    setDaysSinceLiveOnDate(daysDiff)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    if (liveOnDate && profile) {
      const dateRange = getDateRange(filterYear, filterMonth, liveOnDate, profile?.organization?.isWorkshopOrg); 
      dispatch(updateDateFilter(dateRange))
    }
  }, [filterMonth, filterYear, profile])

  useEffect(() => {
    if (profileDetails) dispatch(updateProfile(profileDetails))
  }, [profileDetails])

  useEffect(() => {
    if (defaultEngagement) 
      dispatch(updateDashboardDataTypeToggle(defaultEngagement === 'PROFILE_BASED' ? "Profile Based" : "Auto-tracking"))
  }, [defaultEngagement])

  if (isLoading || !profile?.organization?.id) return <Loader />

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid bkg-white' id='kt_wrapper'>
          <HeaderWrapper />
          <div
            id='kt_content'
            ref={scrollRef}
            className='content content-height d-flex flex-column custom-margin overflow-auto'
          >
            <div className='d-flex flex-column-fluid'>
              <Content>
                <Routes>
                  <Route
                    path={RoutesPath.AUTH_ALL}
                    element={
                      <Navigate 
                        to={
                          (getEnvironment() === 'production' && profile?.organization?.id === PROD_LNT_ORG_ID) ? RoutesPath.FOREST_DASHBOARD.ROOT : 
                          (!lockedScreens?.dashboardScopePage ? RoutesPath.DASHBOARD.ROOT : RoutesPath.DASHBOARD_EMISSION.ROOT)} 
                      />
                    }
                  />
                  <Route
                    path={RoutesPath.DEFAULT}
                    element={
                      <Navigate 
                        to={
                          (getEnvironment() === 'production' && profile?.organization?.id === PROD_LNT_ORG_ID) ? RoutesPath.FOREST_DASHBOARD.ROOT : 
                          (!lockedScreens?.dashboardScopePage ? RoutesPath.DASHBOARD.ROOT : RoutesPath.DASHBOARD_EMISSION.ROOT)
                        } 
                      />
                    }
                  />
                  {/* Pages */}

                  <Route
                    path={RoutesPath.DASHBOARD.ROOT}
                    element={
                      <LockWrapper isLocked={lockedScreens?.dashboardScopePage} description='This page displays information Scope1, Scope 2 and Scope 3 emissions and its details. For more details, contact support. '>
                        <DashboardPage />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.DASHBOARD.SCOPE_ONE}
                    element={
                      <LockWrapper isLocked={lockedScreens?.scopeOnePage} description='This page displays information about Scope 1 emissions and its details. For more details, contact support. '>
                        <ScopeOnePage />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.DASHBOARD.SCOPE_TWO}
                    element={
                      <LockWrapper isLocked={lockedScreens?.scopeTwoPage} description='This page displays information about Scope 2 emissions and its details. For more details, contact support. '>
                        <ScopeTwoPage />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.DASHBOARD.SCOPE_THREE}
                    element={
                      <LockWrapper isLocked={lockedScreens?.scopeThreePage} description='This page displays information about Scope 3 emissions and its details. For more details, contact support. '>
                        <ScopeThreePage />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.DASHBOARD.FUEL_EMISSION}
                    element={
                      <LockWrapperPartial isLocked={(getEnvironment() === 'production' && profile?.organization?.id === PROD_LNT_ORG_ID) || lockedScreens?.fuelEmissionPage} description='This page displays information about Fuel emissions and its details. For more details, contact support. '>
                        <FuelEmissionPage />
                      </LockWrapperPartial>
                    }
                  />
                  <Route
                    path={RoutesPath.SURVEY_QUESTIONS}
                    element={
                      <LockWrapper isLocked={lockedScreens?.surveyQuestionsPage} description="This page displays the survey questions for your company to calculate your company's Scope 1, Scope 2 and Scope 3 emissions. For more details, contact support.">
                        <SurveyQuestionsPage />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.DASHBOARD.PROJECTED_EMISSIONS}
                    element={
                      <LockWrapper isLocked={lockedScreens?.projectedEmissionsPage} description='This page displays information about Scope 1, Scope 2 and Scope 3 emissions and its details. For more details, contact support. '>
                        <ProjectedEmissionsPage />
                      </LockWrapper>
                    }
                  />
                  
                  <Route
                    path={RoutesPath.DASHBOARD_EMISSION.ROOT}
                    element={
                      <LockWrapper isLocked={lockedScreens?.dashboardEmissionPage} description='This page shows emission data'>
                        <DashboardWrapper month={filterMonth} />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.DASHBOARD_EMISSION.DETAILS}
                    element={
                      <LockWrapper isLocked={lockedScreens?.detailsPage} description='This page shows emission data'>
                        <DashboardDetails month={filterMonth} category={filterCategory} />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.DASHBOARD_EMISSION.LEADERBOARD}
                    element={
                      <LockWrapper isLocked={lockedScreens?.leaderboardPage} description='This page shows leaderboard data'>
                        <DashboardLeaderboard />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.FOREST_DASHBOARD.ROOT}
                    element={
                      <LockWrapper isLocked={lockedScreens?.forestPage} description="This page displays details about the purchased forest and the carbon emissions recaptured through it. For more details, contact support. ">
                        <ForestDashboardPage />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.FOREST_DASHBOARD.FOREST_SITE_DETAILS}
                    element={<ForestSiteDetailsPage />}
                  />
                  <Route
                    path={RoutesPath.FOREST_DASHBOARD.PLANTATION_AND_UPDATES}
                    element={<ForestPlantationAndUpdatePage />}
                  />
                  <Route
                    path={RoutesPath.FOREST_DASHBOARD.GROWER_DETAILS}
                    element={<GrowerDetailsPage />}
                  />
                  <Route
                    path={RoutesPath.FOREST.ROOT}
                    element={
                      <LockWrapper isLocked={lockedScreens.forestPage} description="This page displays details about the purchased forest and the carbon emissions recaptured through it. For more details, contact support. ">
                        <ForestPage />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.FOREST.FOREST_DETAILS}
                    element={<ForestDetailsPage />}
                  />
                  <Route
                    path={RoutesPath.FOREST.GROWER_FOREST_UPDATE_IMAGES}
                    element={<GrowerForestUpdateImagesPage />}
                  />
                  <Route
                    path={RoutesPath.FOREST.GROWER_FOREST_SPECIES_UPDATES}
                    element={<GrowerForestSpeciesUpdatesPage />}
                  />
                  <Route
                    path={RoutesPath.FOREST.DOCS_LIST}
                    element={
                      <LockWrapper isLocked={lockedScreens.forestDocsPage} description="This page displays the documents for the purchased forest. For more details, contact support.">
                        <DocsListPage />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.FOREST.ATTRIBUTE_TO_EMPLOYEES}
                    element={<AttributeToEmployees />}
                  />
                  {/* <Route
                    path={RoutesPath.DASHBOARD_EMISSION.DIVISIONS}
                    element={<DivisionWrapper month={selectedMonth} />}
                  />
                  <Route
                    path={RoutesPath.DASHBOARD_EMISSION.DEPARTMENTS}
                    element={<DepartmentWrapper month={selectedMonth} />}
                  />
                  <Route
                    path={RoutesPath.DASHBOARD_EMISSION.LOCATIONS}
                    element={<LocationWrapper month={selectedMonth} />} 
                  /> */}
                  <Route 
                    path={RoutesPath.UPLOAD_DATA} 
                    element={
                      <LockWrapperPartial isLocked={lockedScreens.uploadDataPage} description='This page allows you to upload data for various categories of Scope 1 and 2 emissions. After uploading, you can view the data on the emissions page. '>
                        <UploadPage />
                      </LockWrapperPartial>
                    } 
                  />
                  <Route path={RoutesPath.EMPLOYEES.ROOT} element={
                    <LockWrapper isLocked={lockedScreens.employeeDetailsPage} description="This page displays information about the employees. For more details, contact support. ">
                      <EmployeeDetails />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.SETTINGS} element={<Settings />} />
                  <Route path={RoutesPath.CONFIGURATION.ROOT} element={
                    <LockWrapper isLocked={lockedScreens.utilityBillPage} description="This page displays information about the utility bills. For more details, contact support. ">
                      <Configuration />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.CONFIGURATION.UTILITY} element={
                    <LockWrapper isLocked={lockedScreens.utilityBillPage} description="This page displays information about the utility bills. For more details, contact support. ">
                      <UtilitiesPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.CONFIGURATION.EMPTY_UTILITY} element={
                    <LockWrapper isLocked={lockedScreens.utilityBillPage} description="This page displays information about the utility bills. For more details, contact support. ">
                      <EmptyUtilitiesPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.CONFIGURATION.ADD_UTILITY} element={
                    <LockWrapper isLocked={lockedScreens.utilityBillPage} description="This page displays information about the utility bills. For more details, contact support. ">
                      <AddUtilityBillPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.ORG_REPORTS.ROOT} element={
                    <LockWrapper isLocked={lockedScreens.orgReportsPage} description="This page displays information about the organization emissions report. For more details, contact support. ">
                      <OrgReportsPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.ORG_REPORTS.REPORT} element={
                    <LockWrapper isLocked={lockedScreens.orgReportsPage} description="This page displays information about the organization emissions report. For more details, contact support. ">
                      <AuditableReportsPage />
                    </LockWrapper>
                  } />
                  <Route 
                    path={RoutesPath.QUIZZES.ROOT} 
                    element={
                      <LockWrapper isLocked={lockedScreens.quizzesPage} description="This page displays information about the quizzes. For more details, contact support. ">
                        <QuizzesPage />
                      </LockWrapper>
                    } 
                  />
                  <Route path={RoutesPath.QUIZZES.QUIZZES_DETAILS} element={
                    <LockWrapper isLocked={lockedScreens.quizzesPage} description="This page displays information about the quizzes. For more details, contact support. ">
                      <QuizDetailsPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.CHALLENGES.ROOT} element={
                    <LockWrapper isLocked={lockedScreens.challengesPage} description="This page displays information about the challenges. For more details, contact support. ">
                      <ChallengesPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.CHALLENGES.CREATE_CHALLENGES} element={
                    <LockWrapper isLocked={lockedScreens.challengesPage} description="This page displays information about the challenges. For more details, contact support. ">
                      <CreateChallengesPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.CHALLENGES.CHALLENGE_DETAILS} element={
                    <LockWrapper isLocked={lockedScreens.challengesPage} description="This page displays information about the challenges. For more details, contact support. ">
                      <ChallengeDetailsPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.POLLS.ROOT} element={
                    <LockWrapper isLocked={lockedScreens.pollsPage} description="This page displays information about the polls. For more details, contact support. ">
                      <PollsPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.POLLS.CREATE_POLL} element={
                    <LockWrapper isLocked={lockedScreens.pollsPage} description="This page displays information about the polls. For more details, contact support. ">
                      <CreatePollsPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.ATTRIBUTES.ROOT} element={
                    <LockWrapper isLocked={lockedScreens.wasteManagementPage} description="This page displays information about the waste management. For more details, contact support. ">
                      <AttributesPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.ATTRIBUTES.CREATE_ATTRIBUTES} element={
                    <LockWrapper isLocked={lockedScreens.wasteManagementPage} description="This page displays information about the waste management. For more details, contact support. ">
                      <CreateAttributesPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.ATTRIBUTES.DETAILS} element={
                    <LockWrapper isLocked={lockedScreens.wasteManagementPage} description="This page displays information about the waste management. For more details, contact support. ">
                      <AttributeDetailsPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.CUSTOM_TAGS} element={
                    <LockWrapper isLocked={lockedScreens.customTagsPage} description="This page displays information about the custom tags. For more details, contact support. ">
                      <CustomTagsPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.CUSTOM_GROUPS} element={
                    <LockWrapper isLocked={lockedScreens.customGroupsPage} description="This page displays information about the custom groups. For more details, contact support. ">
                      <CustomGroupsPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.MASTER_DATA} element={
                    <LockWrapper isLocked={lockedScreens.masterDataPage} description="This page displays information about the master data. For more details, contact support. ">
                      <MasterDataPage />
                    </LockWrapper>
                  } />
                  {/* <Route path={RoutesPath.OUR_FOREST.ROOT} element={<ComingSoonCard />} /> */}
                  <Route
                    path={RoutesPath.OUR_FOREST.ROOT}
                    // element={
                    //   daysSinceLiveOnDate <= 14 ? <ComingSoonCard /> : <ComingSoonCardAfter14Days />
                    // }
                    element={
                      <LockWrapper isLocked={lockedScreens?.purchaseForestPage} description='This page shows the list of forests for the organization. For more details, contact support. '>
                        <ProjectList />
                      </LockWrapper>
                    }
                  />
                  <Route
                    path={RoutesPath.OUR_FOREST.ALLOCATE_PROJECT}
                    element={
                      <LockWrapper isLocked={lockedScreens.purchaseForestPage} description='This page shows the list of allocated projects for the organization. For more details, contact support. '>
                        <AllocateProjects />
                      </LockWrapper>
                    }
                  />
                  <Route path={RoutesPath.PROFILE_EDIT} element={<EditProfile />} />
                  <Route path={RoutesPath.OUR_FOREST.PROJECT_UPDATE} element={<ProjectUpdates />} />
                  <Route path={RoutesPath.CHANGE_PASSWORD} element={<ChangePassword />} />
                  <Route path={RoutesPath.EMPLOYEES.EMPLOYEES_DETAILS} element={
                    <LockWrapper isLocked={lockedScreens.employeeDetailsPage} description="This page displays information about the employees. For more details, contact support. ">
                      <EmployeeDetails />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.EMPLOYEES.EDIT_EMPLOYEE} element={
                    <LockWrapper isLocked={lockedScreens.employeeDetailsPage} description="This page is for editing employee details. For more details, contact support. ">
                      <EditEmployeeDetails />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.EMPLOYEES.ADD_EMPLOYEE} element={
                    <LockWrapper isLocked={lockedScreens.employeeDetailsPage} description="This page is for adding new employees. For more details, contact support. ">
                      <AddNewEmployee />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.EMPLOYEES.EMPTY_EMPLOYEE} element={
                    <LockWrapper isLocked={lockedScreens.employeeDetailsPage} description="This page displays information about the employee data. For more details, contact support. ">
                      <EmptyEmployeesPage />
                    </LockWrapper>
                  } />
                  <Route path={RoutesPath.EMPLOYEES.UPLOAD_CSV} element={
                    <LockWrapper isLocked={lockedScreens.employeeUploadPage} description="This page is for uploading employee data. For more details, contact support. ">
                      <UploadEmployeeCSV />
                    </LockWrapper>
                  } />

                  <Route
                    path={RoutesPath.LOGIN}
                    element={
                      <Navigate to={
                        (getEnvironment() === 'production' && profile?.organization?.id === PROD_LNT_ORG_ID) ? 
                        RoutesPath.FOREST_DASHBOARD.ROOT : 
                        (!lockedScreens.dashboardScopePage ? RoutesPath.DASHBOARD.ROOT : RoutesPath.DASHBOARD_EMISSION.ROOT)
                      } />}
                  />
                  {/* <Route index element={<DashboardWrapper />} /> */}
                  <Route path={RoutesPath.ALL} element={<Navigate to={RoutesPath.ERROR_404} />} />
                  <Route path={RoutesPath.ERROR_ALL} element={<ErrorsPage />} />
                </Routes>
                <Notifier />
              </Content>
            </div>
          </div>
        </div>
      </div>

      <ScrollTop />
    </PageDataProvider>
  )
}

export {PrivateLayout}
