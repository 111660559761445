import { IconForestDonation } from "../../../../../_metronic/assets/icons"
import HeaderActionCard from "./header-action-card"
import { HeaderInfoCard } from "./header-info-card"

export const GrowerInfoCards = (props: {growerInfo: any}) => {
  const {growerInfo} = props
  return (
    <div className='px-6 mx-0 mt-6 mb-4 d-flex flex-column gap-4'>
      <h2 className='m-0'>GROWERS INFORMATION</h2>
      <div className='d-flex justify-content-between gap-4'>  
        <HeaderInfoCard 
          label="Number of Growers Providing Updates"
          value={growerInfo?.growersCount}
          type="white"
        />
        <HeaderInfoCard 
          label="Employment Generated"
          value={growerInfo?.employmentGuaranteed}
          type="white"
        />
        <HeaderActionCard 
          title="Grower Details"
          description="Name and emails ids of people providing updates"
          type="white"
          icon={<IconForestDonation height={28} width={28} />}
          growersList={growerInfo?.growersList}
        />
      </div>
    </div>
  )
}