/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Carousel } from 'react-bootstrap'
import { IconFillArrowLeftCircle, IconFillArrowRightCircle } from '../../../../../_metronic/assets/icons'

type ModalViewImageCarouselTypes = {
  show: boolean
  onHide: () => void
  images: string[]
}

const height = '600px'
const width = '800px'
const imgMaxHeight = '500px'
const imgMaxWidth = '700px'

export default function ModalViewImageCarousel(props: ModalViewImageCarouselTypes) {
  const { onHide, images } = props
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex)
  }

  return (
    <Modal 
      {...props} 
      aria-labelledby='contained-modal-title-vcenter' 
      centered 
      dialogClassName="view-image-challenges-modal-width"
      size="lg"
    >
      <Modal.Body style={{height, width, alignSelf: 'center', backgroundColor: '#2E2E2E', borderRadius: '10px'}}>
        <button 
          type='button' 
          className='m-0 p-0 remove-btn-styles modal-header-cross-btn' 
          onClick={onHide}
          style={{
            position: 'absolute',
            right: '20px',
            top: '20px',
            zIndex: 1000,
            height: '30px', 
            width: '30px', 
            borderRadius: '30px', 
            backgroundColor: '#ededed',
            boxShadow: '1px 1px 7px 2px rgba(28,28,28,0.2)'
          }}
        >
          <i className="bi bi-x-lg m-0 p-0" style={{fontSize: '16px', color: 'black'}} />
        </button>

        <h4 className='text-white pb-8'>PLANTATION IMAGES</h4>

        {
          (typeof images === 'undefined' || images?.length === 0) ? (
            <div className='d-flex justify-content-center align-items-center' style={{background: 'transparent', alignSelf: 'center', height: '90%', width: '100%'}}>
              <h4 className='text-white pb-8'>No images found</h4>
            </div>
          ) : (
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              indicators
              interval={null}
              prevIcon={<IconFillArrowLeftCircle height={40} width={40} style={{marginLeft: 20}} />}
              nextIcon={<IconFillArrowRightCircle height={40} width={40} style={{marginRight: 20}} />}
              style={{background: 'transparent', alignSelf: 'center', height: '90%', width: '100%'}}
            >
              {images.map((image, idx) => (
                <Carousel.Item key={`image-${idx}`}>
                  <div className='d-flex justify-content-center align-items-center' style={{width: '90%', height: '90%', margin: 'auto'}}>
                    <img
                      src={image}
                      alt={`Plantation ${idx + 1}`}
                      style={{
                        maxHeight: imgMaxHeight,
                        maxWidth: imgMaxWidth,
                        objectFit: 'contain',
                        borderRadius: '10px'
                      }}
                    />
                  </div>
                </Carousel.Item>
              ))}
            </Carousel>
          )
        }

      </Modal.Body>
    </Modal>
  )
}