import React from 'react';
import { useSelector } from 'react-redux';
import { SDGImg1, SDGImg4, SDGImg2, SDGImg3 } from '../../../../../_metronic/assets/images';
import { RootState } from '../../../../store/store';

interface SDGCardProps {
  number: number;
  title: string;
  description: string;
}

const SDGCard: React.FC<SDGCardProps> = (props) => {
  const { number, title, description } = props;

  const { profile } = useSelector((state: RootState) => state.profile);

  let SDGIcon = SDGImg1;
  if (number === 2) SDGIcon = SDGImg2;
  if (number === 3) SDGIcon = SDGImg3;
  if (number === 4) SDGIcon = SDGImg4;

  return (
    <div className="bordered-grey" style={{maxWidth: '25%'}}>
      <div className="p-4 d-flex justify-content-between align-items-end" style={{borderBottom: '1px solid #d8d8d8'}}>
        <div>
          <h3 className="m-0 text-2xl font-bold">SDG {number}</h3>
          <h6 className="m-0 text-purple-700 text-xl" style={{color: '#4C3791'}}>{title}</h6>
        </div>
        <SDGIcon height={60} width={60} />
      </div>
      <p className="m-0 text-gray-700 text-lg p-4">
        {description}
      </p>
    </div>
  );
};

export default SDGCard;

// Example usage:
/*
<SDGCard 
  number={1}
  title="No Poverty"
  description="Agroforestry increases farmers' income through diversified revenue streams such as timber, fruits, and crops."
/>
*/ 