export const DUMMY_ORG_ID = 2;
export const DEMO_ORG_ID = 1;
export const PROD_CNW_ORG_ID = 90;
export const PROD_LNT_ORG_ID = 92;
export const MYNZO_ORG_ID = 3;
export const STAGING_LNT_LIKE_ORG_ID = 121;
export const PROD_SOLAR_ARISE_ORG_ID = 5;
export const STAGING_NAANDI_ORG_ID = 125;
export const PROD_NAANDI_ORG_ID = 124;

// getEnvironment() === 'production' &&