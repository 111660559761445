import SDGCard from "./sdg-card"
import { SDGImg1, SDGImg4, SDGImg2, SDGImg3 } from '../../../../../_metronic/assets/images';

const sdgGoals = [
  {
    number: 1,
    title: 'No Poverty',
    description: 'Agroforestry increases farmers income through diversified revenue streams such as timber, fruits, and crops.',
    image: <SDGImg1 height={60} width={60} />
  },
  {
    number: 2,
    title: 'Zero Hunger',
    description: 'Tree-based farming enhances food security by providing edible crops even during droughts.',
    image: <SDGImg2 height={60} width={60} />
  },
  {
    number: 3,
    title: 'Climate Action',
    description: 'Agroforests act as natural carbon sinks, mitigating climate change through long-term sequestration.',
    image: <SDGImg3 height={60} width={60} />
  },
  {
    number: 4,
    title: 'Life on Land',
    description: 'Agroforests mimic natural ecosystems, preserving biodiversity and restoring degraded lands.',
    image: <SDGImg4 height={60} width={60} />
  }
]


const SDGGoals = ({showSDGGoals}: {showSDGGoals: number[]}) => {

  const filteredSDGGoals = sdgGoals.filter((goal) => showSDGGoals.includes(goal.number))

  if (filteredSDGGoals.length === 0) return null

  return (
    <div className='row px-6 mx-0 mt-10'>
      <h2 className='m-0 mb-4 mx-0 px-0'>MEETING SDG GOALS</h2>
      <div className='col-12 px-0 d-flex flex-column position-relative' style={{backgroundColor: 'white'}}>
        <div className='d-flex flex-row space-between justify-content-between flex-1 gap-4'>
          {
            filteredSDGGoals.map((goal) => (
              <SDGCard 
                key={goal.number} 
                number={goal.number} 
                title={goal.title} 
                description={goal.description} 
                image={goal.image}
                />
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default SDGGoals
