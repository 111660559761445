/* eslint-disable global-require */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { RoutesPath } from '../../../../routing/RoutesPath';
import { IconArrowRightCircle } from '../../../../../_metronic/assets/icons';

type Props = {
  title: string;
  description: string;
  icon: React.ReactNode;
  type: string;
  growersList: any;
}

const HeaderActionCard: React.FC<Props> = (props) => {
  const { title, description, icon, type, growersList } = props;
  const navigate = useNavigate();

  // onClick={() => navigate(RoutesPath.FOREST.DOCS_LIST, {state: {type}})}

  return (
    <button 
      type='button' 
      className='forest-docs-page-card col-4 mb-2 remove-button-styles' 
      onClick={() => navigate(RoutesPath.FOREST_DASHBOARD.GROWER_DETAILS, {state: {growersList}})}
      >
      <div className='d-flex flex-column gap-4'>
        <div className='d-flex flex-row gap-2 align-items-center'>
          {icon}
          <h4 className='m-0 mx-2'>{title}</h4>
        </div>
        <p className='m-0 mx-2'>{description}</p>
      </div>
      <div className='d-flex flex-row gap-2 align-items-center'>
        <IconArrowRightCircle height={18} width={18} />
      </div>
    </button>
  )
}

export default HeaderActionCard;