/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo } from 'react'
import ReactTablePurple from '../../../../../_metronic/partials/components/tables/ReactTablePurple'

const RenderStringColumn = ({ value }: { value: string }) => (
  <div className="d-flex justify-content-center"><p className='m-0'>{value || '--'}</p></div>
)

const GrowersListTable = ({ listData = [] }: { listData: any[] }) => {
  const headers = useMemo(
    () => [
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>NAME</p></div>,
        accessor: 'growerName',
        Cell: ({ cell }: { cell: any }) => <RenderStringColumn value={cell.row.original.growerName} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>EMAIL</p></div>,
        accessor: 'email',
        Cell: ({ cell }: { cell: any }) => <RenderStringColumn value={cell.row.original.email} />
      },
    ],
    []
  )

  if (listData?.length === 0) 
    return <div className='d-flex justify-content-center bordered-grey py-12'><h3 className='m-0'>No growers found</h3></div>

  return (
    <div className='p-0 m-0'>
      <ReactTablePurple
        headerData={headers}
        tableData={listData}
        className='mt-6 custom-border'
        currentPageIndex={0}
        disableSort
        showPagination={false}
        pageSize={listData.length}
        message="No results found"
      />
    </div>
  )
}

export default GrowersListTable