import {createSlice} from '@reduxjs/toolkit'

export type SpeciesPlantationFilterType = "SPECIES" | "DATE"
export const speciesPlantationFilterCategories: SpeciesPlantationFilterType[] = ["SPECIES", "DATE"]

export type ForestType = {
  selectedProjectId: string | number
  isOnPlantationPage: boolean
  speciesPlantationFilter: SpeciesPlantationFilterType
  selectedSpeciesPlantation: string
}

const initialState: ForestType = {
  selectedProjectId: 0,
  isOnPlantationPage: true,
  speciesPlantationFilter: "SPECIES",
  selectedSpeciesPlantation: ""
}

export const forestSlice = createSlice({
  name: 'forestFilter',
  initialState,
  reducers: {
    setSelectedProjectId: (state, action) => {
      state.selectedProjectId = action.payload
    },
    setIsOnPlantationPage: (state, action) => {
      state.isOnPlantationPage = action.payload
    },
    setSpeciesPlantationFilter: (state, action) => {
      state.speciesPlantationFilter = action.payload
    },
    setSelectedSpeciesPlantation: (state, action) => {
      state.selectedSpeciesPlantation = action.payload
    },
  },
})

export const {
  setSelectedProjectId, 
  setIsOnPlantationPage, 
  setSpeciesPlantationFilter, 
  setSelectedSpeciesPlantation
} = forestSlice.actions

export default forestSlice.reducer
