import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { IconFillArrowLeftCircle, IconFillArrowRightCircle } from '../../../../../_metronic/assets/icons';
import { SpeciesSelectCard } from '../cards/species-select-card';

const BKG_COLORS = ["#F8ECF5", "#F4ECF8", "#EEECF8", "#ECEEF8", "#EBEEFD", "#E5E9FC", "#D8DDF3", "#DAD8F3", "#E4D8F3", "#F8ECF5"]

type SpeciesSelectCarouselProps = {
  data: any
  selectedSpeciesPlantation: string
  setSelectedSpeciesPlantation: (selectedSpeciesPlantation: string) => void
}

export const SpeciesSelectCarousel = (props: SpeciesSelectCarouselProps) => {
  const { data, selectedSpeciesPlantation, setSelectedSpeciesPlantation } = props;

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex: number) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    if (data?.length < 6) {
      setIndex(0);
    }
  }, [data]);

  return (
    <Carousel
      activeIndex={index}
      indicators={false}
      onSelect={handleSelect}
      className='pt-2 mt-4 d-flex justify-content-center align-items-center'
      style={{
        height: '180px',
        backgroundColor: '#F5F5F5',
        background: "#FFF"
        // backgroundImage: 'linear-gradient(to bottom, #FFFFFF,rgb(244, 244, 244))'
      }}
      interval={null}
      prevIcon={data?.length > 5 ? <IconFillArrowLeftCircle height={32} width={32} style={{marginLeft: -10}} /> : null}
      nextIcon={data?.length > 5 ? <IconFillArrowRightCircle height={32} width={32} style={{marginRight: -10}} /> : null}
    >
      {Array.from({ length: Math.ceil((data?.length || 0) / 5) }).map((_, slideIndex) => {
        const slideStart = slideIndex * 5;
        const slideEnd = slideStart + 5;
        const slideId = `slide-${slideStart}-${slideEnd}`;
        
        return (
          <Carousel.Item key={slideId}>
            <div className="d-flex justify-content-center align-items-center gap-8">
              {data.slice(slideStart, slideEnd).map((item: any, cardIndex: number) => (
                <div 
                  key={item.speciesType}
                  className='species-select-card-hover'
                  style={{
                    borderRadius: '20px', 
                    margin: '10px 0px', 
                    width: '12%',
                    height: '90%',
                    }}>
                  <SpeciesSelectCard
                    name={item.speciesName}
                    image={item.image}
                    count={item.count}
                    bkgColor={BKG_COLORS[(cardIndex || 0) % BKG_COLORS.length]}
                    isSelected={selectedSpeciesPlantation === item.speciesType}
                    onClick={() => setSelectedSpeciesPlantation(item.speciesType)}
                  />
                </div>
              ))}
            </div>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
};

export default SpeciesSelectCarousel;
