import { DivisionWrapper } from '../divisions/DivisionWrapper'
import { DepartmentWrapper } from '../departments/DepartmentWrapper'
import { LocationWrapper } from '../locations/LocationWrapper'

type Props = {
  month: string
  category: string
}

export function DashboardDetails({month, category}: Props) {
  if (category==='Division') {
    return <DivisionWrapper month={month} />
  } if (category==='Department') {
    return <DepartmentWrapper month={month} />
  }
  return <LocationWrapper month={month} />
}
