/* eslint-disable consistent-return */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleMap, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api'
import { RoutesPath } from '../../../../routing/RoutesPath';

type PlotDataType = {
  id: string | number
  name: string
  data: {
    totalTreesPlanted: number
    totalTreesPlanned: number
    uniqueSpeciesPlanted: number
    forestData?: {
      recaptureToDate: number
      lastPlantationDate: string
      speciesPlanted: {
        speciesName: string
        image: string
        count: number
      }[]
      satelliteImages: {
        image: string
        date: string
      }[]
    }
  }
  latitude: string
  longitude: string
}

type Props = {
  mapPlotData?: {
    type: 'project-sites' | 'forest-sites'
    data: PlotDataType[]
  }
  forceRerender: number | string
}

const MapProjectSites: React.FC<Props> = (props) => {
  const {mapPlotData, forceRerender} = props
  const navigate = useNavigate()

  const [center, setCenter] = useState<{lat: number, lng: number} | null>(null)
  const [map, setMap] = useState<any>(null)
  const [selectedMarker, setSelectedMarker] = useState<PlotDataType | null>(null)
  const [defaultZoom, setDefaultZoom] = useState<{zoom: number, maxZoom: number}>(mapPlotData?.type !== 'project-sites' ? {zoom: 10, maxZoom: 18} : {zoom: 2, maxZoom: 12})

  // Add a new ref to track if initial zoom has been set
  const zoomInitialized = useRef(false);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '', 
  })

  useEffect(() => {
    if (mapPlotData && mapPlotData?.data?.length > 0) {
      // Reset states
      setMap(null)
      setSelectedMarker(null)
      setCenter(null)
      zoomInitialized.current = false;  // Reset zoom initialization flag

      // Set center after a small delay to ensure proper reinitialization
      setTimeout(() => {
        setDefaultZoom(mapPlotData?.type !== 'project-sites' ? {zoom: 10, maxZoom: 18} : {zoom: 2, maxZoom: 12})
        setCenter({
          lat: parseFloat(mapPlotData.data[0].latitude),
          lng: parseFloat(mapPlotData.data[0].longitude)
        })
      }, 1000)
    }
  }, [mapPlotData, forceRerender])

  const onLoad = React.useCallback((tmap:any) => {
    if (center) {
      const bounds = new window.google.maps.LatLngBounds(center);
      tmap.fitBounds(bounds);
      setMap(tmap)

      // Create an intersection observer to detect when map is visible
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && !zoomInitialized.current) {
            const newZoom = mapPlotData?.type !== 'project-sites' ? 10 : 2;
            tmap.setZoom(newZoom);
            zoomInitialized.current = true;
            observer.disconnect();
          }
        },
        { threshold: 0.1 }
      );

      setTimeout(() => {
        setDefaultZoom(mapPlotData?.type !== 'project-sites' ? {zoom: 10, maxZoom: 18} : {zoom: 2, maxZoom: 12})
        if (mapPlotData?.data?.[0]?.latitude && mapPlotData?.data?.[0]?.longitude) {
          setCenter({
            lat: parseFloat(mapPlotData.data[0].latitude),
            lng: parseFloat(mapPlotData.data[0].longitude)
          })
        } else {
          setCenter(null)
        }
      }, 1000)

      // Start observing the map container
      const mapElement = tmap.getDiv();
      if (mapElement) {
        observer.observe(mapElement);
      }
    }
  }, [center, mapPlotData?.type])

  const onUnmount = React.useCallback((tmap:any) => {
    setMap(null)
    setCenter(null)
  }, [])

  if (isLoaded && (!mapPlotData || !center)) {
    return (
      <div className='d-flex justify-content-center align-items-center bordered-grey' style={{height: '94%'}}>
        <h4 className='text-center m-0'>
          No data found
        </h4>
      </div>
    )
  }

  return (
    <div key={forceRerender} style={{ height: "94%", width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: 5 }}>
      {(!isLoaded && !center) && (
          <div className='d-flex justify-content-center align-items-center bordered-grey' style={{height: '94%'}}>
            <h4 className='text-center m-0'>
              Fetching Map..
            </h4>
          </div>
        ) 
      }
      {
        (isLoaded && center && mapPlotData) && (
          <GoogleMap
            key={forceRerender}
            mapContainerStyle={{width: '100%', height: '100%', borderRadius: 10}}
            center={center}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              clickableIcons:false,
              zoomControl:true,
              fullscreenControl:true,
              mapTypeId: 'hybrid',
              zoom: defaultZoom.zoom,
              maxZoom: defaultZoom.maxZoom,
              minZoom: 4, 
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            >
            {  
              mapPlotData?.data?.map((plot) => (
                <Marker
                  key={plot.id}
                  position={{
                    lat: parseFloat(plot.latitude),
                    lng: parseFloat(plot.longitude)
                  }}
                  onClick={() => setSelectedMarker(plot)}
                />
              ))
            }
            {selectedMarker && (
              <InfoWindow
                position={{lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude)}}
                onCloseClick={() => setSelectedMarker(null)}
                options={{
                  pixelOffset: new google.maps.Size(0, -30),
                }}
              >
                <>
                <h5 title={selectedMarker.name} className='mb-4 mx-0 bold position-absolute' style={{top: 18, zIndex: 1000}}>{`${selectedMarker.name?.slice(0, 12)}${selectedMarker.name?.length > 12 ? '...' : ''}`}</h5>
                <div className='pb-6' style={{paddingLeft: 0, paddingRight: 15}}>
                  <p className='mb-1 mx-0 d-flex justify-content-between' style={{fontWeight: '600'}}><span>Total Trees Planned:</span> <span className='bold' style={{marginLeft: 10}}>{selectedMarker.data.totalTreesPlanned}</span></p>
                  <p className='mb-1 mx-0 d-flex justify-content-between' style={{fontWeight: '600'}}><span>Total Trees Planted:</span> <span className='bold' style={{marginLeft: 10}}>{selectedMarker.data.totalTreesPlanted}</span></p>
                  <p className='mb-1 mx-0 d-flex justify-content-between' style={{fontWeight: '600'}}><span>Unique Species Planted:</span> <span className='bold' style={{marginLeft: 10}}>{selectedMarker.data.uniqueSpeciesPlanted}</span></p>
                  {
                    mapPlotData.type === 'forest-sites' && (
                      <button 
                        type='button' 
                        className='btn btn-primary remove-btn-styles mt-2 py-1 w-100'
                        style={{color: 'white', fontWeight: '600'}}
                        onClick={() => {
                          navigate(RoutesPath.FOREST_DASHBOARD.FOREST_SITE_DETAILS, {
                            state: {
                              data: mapPlotData.data.find((plot) => plot.id === selectedMarker.id)
                            }
                          })
                        }}
                      >
                        View Details
                      </button>
                    )
                  }
                </div>
                </>
              </InfoWindow>
            )}
          </GoogleMap>
        )
      }
    </div>
  )
}

export default MapProjectSites