import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RoutesPath } from '../../../routing/RoutesPath'
import { RootState } from '../../../store/store'
import { ProjectPlantationPage } from './plantation-page'
import { ProjectUpdatePage } from './update-page'
import { setIsOnPlantationPage } from '../../../store/slices/forestSlice'

const BUTTON_STYLE = {
  border: '1px solid #D1D1D1',
  borderBottomWidth: 0,
  background: 'none',
  color: 'black',
  fontWeight: 500,
  fontSize: '16px'
}

export const ForestPlantationAndUpdatePage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {project} = location.state as {project: any}

  const {isOnPlantationPage} = useSelector((state: RootState) => state.forest)

  return (
    <div className='blank-page-view py-2 mb-10'>
      <div className='px-6 back-header-btn mt-4 mb-6' style={{marginLeft: '0px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.FOREST_DASHBOARD.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h2 className='mb-0 mx-4'>{project?.name?.toUpperCase()}</h2>
      </div>
      <div className='d-flex px-6'>
        <div className='d-flex flex-column flex-grow-1'>
          <button
            type='button'
            className='px-4 py-2 remove-btn-styles flex-grow-1'
            style={BUTTON_STYLE}
            onClick={() => dispatch(setIsOnPlantationPage(true))}
            >
            PLANTATION
          </button>
          <div className='flex-grow-1' style={{height: '6px', background: isOnPlantationPage ? 'linear-gradient(to right, #37287B, #976ED6)' : '#D1D1D1'}} />
        </div>
        <div className='d-flex flex-column flex-grow-1'>
          <button
            type='button' 
            className='px-4 py-2 remove-btn-styles flex-grow-1'
            style={BUTTON_STYLE}
            onClick={() => dispatch(setIsOnPlantationPage(false))}
            >
            UPDATE
          </button>
          <div className='flex-grow-1' style={{height: '6px', background: isOnPlantationPage ? '#D1D1D1' : 'linear-gradient(to right, #37287B, #976ED6)'}} />
        </div>
      </div>

      {
        isOnPlantationPage ? (
          <ProjectPlantationPage project={project} />
        ) : (
          <ProjectUpdatePage project={project} />
        )
      }

    </div>
  )
}