import moment from 'moment'
import Modal from 'react-bootstrap/Modal'

export default function ModalFuelSavedHistory(props:any) {
  const { onHide, data } = props

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Body style={{padding: 0}}>
        <button 
          type='button' 
          className='remove-btn-styles modal-header-cross-btn d-flex align-items-center justify-content-center position-absolute' 
          onClick={onHide} 
          style={{
            right: -10, 
            top: -10, 
            backgroundColor: 'black', 
            borderRadius: '50%', 
            height: 30, 
            width: 30, 
            zIndex: 1000
            }}
          >
          <i className="bi bi-x-lg" style={{fontSize: '16px', fontWeight: 700, color: 'white'}} />
        </button>
        <h2 
          className='m-0 text-center' 
          style={{fontWeight: 700, padding: '10px 20px', backgroundColor: '#D7D3E4', borderRadius: '6px 6px 0 0'}}
          >
          {data?.fuelSource}
        </h2>
        <div className='d-flex align-items-center justify-content-between gap-12' style={{padding: '15px 0px', borderBottom: '1px solid rgb(156, 156, 156)'}}>
          <h5 className='m-0 text-center' style={{flex: 1, fontWeight: 700}}>MONTH</h5>
          <h5 className='m-0 text-center' style={{flex: 1, fontWeight: 700}}>FUEL CONSUMED</h5>
          <h5 className='m-0 text-center' style={{flex: 1, fontWeight: 700}}>SAVED EMISSIONS (tCO2e)</h5>
        </div>
        <div className='overflow-y-auto' style={{height: '500px'}}>
          {
            data?.data?.map((item: any, index: number) => (
              <div 
                className='d-flex align-items-center justify-content-between gap-12' 
                key={`${item.date}-${item?.fuelConsumed}-${item?.savedEmissions}`} 
                style={{borderBottom: '1px solid #D7D3E4', backgroundColor: index % 2 === 1 ? 'white' : '#FAFAFC'}}
                >
                <p className='my-4 fs-5' style={{flex: 1, fontWeight: 600, textAlign: 'center'}}>{item?.date ? moment(item.date).format("MMMM 'YY") : '--'}</p>
                <p className='my-4 fs-5' style={{flex: 1, fontWeight: 600, textAlign: 'center'}}>{item?.fuelConsumed || '--'}</p>
                <p className='my-4 fs-5' style={{flex: 1, fontWeight: 600, textAlign: 'center'}}>{item?.savedEmissions || '--'}</p>
              </div>
            ))
          }
          <div className="my-12" />
        </div>
      </Modal.Body>
    </Modal>
  )
}