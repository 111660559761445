import { MarkerIcon } from "../../../../../_metronic/assets/icons";

const getLocationString = (location: {village?: string, state?: string, country?: string}) => {
  if (!location) return '';
  const parts = [];
  if (location.village) parts.push(location.village);
  if (location.state) parts.push(location.state);
  if (location.country) parts.push(location.country);
  return parts.join(', ');
}

export const HeaderTitleCard = (props: any) => {
  const {
    title,
    image,
    location,
    icon,
  } = props;

  const locationString = getLocationString(location);

  return (
    <div className="d-flex flex-row py-4 gap-6 align-items-center" style={{border: '2px solid #E0E0E0', borderWidth: "0px 0px 2px 0px"}}>
      {
        image?.length > 0 && 
        <img 
          src={image} 
          alt={title} 
          style={{
            width: 50,
            height: 50,
            borderRadius: 10,
            objectFit: 'cover'
          }}
        />
      }
      {
        icon && 
        <div className="d-flex flex-row gap-2 align-items-center">
          {icon}
        </div>
      }
      <div className="d-flex flex-column gap-2 justify-content-between">
        <h2 className="m-0" style={{fontSize: 20, fontWeight: 700}}>{title?.toUpperCase()}</h2>
        {
          locationString && (
            <div className='d-flex flex-row gap-2 align-items-center px-0'>
              <MarkerIcon color="black" height={16} style={{marginLeft: 0}} />
              <h6 className="m-0" style={{fontSize: 14, color: '#808080'}}>
                {locationString}
              </h6>
            </div>
          )
        }
      </div>
    </div>
  )
}