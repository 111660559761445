import { NoImageIcon } from "../../../../../_metronic/assets/icons";

export const SpeciesInfoCard = (props: any) => {
  const {
    name,
    image,
    count,
    bkgColor
  } = props;

  return (
    <div 
      className="py-6 d-flex align-items-center flex-column gap-2 self-center"
      style={{
        backgroundColor: bkgColor || '#ECEEF8',
        borderRadius: 20,
        padding: 10,
      }}
      title={name || ""}
      data-bs-toggle="tooltip"
      data-bs-placement="top" 
      data-bs-delay='{"show":10,}'
    >
      {
        image ? (
          <img src={image} alt={name} style={{width: 60, height: 60, borderRadius: '50%'}} />
        ) : (
          <div className="p-4 d-flex justify-content-center align-items-center" style={{width: 60, height: 60, borderRadius: '50%', backgroundColor: '#d9d9d9'}}>
            <NoImageIcon height={35} width={35} color="#808080" />
          </div>
        )
      }
      <h2 className="m-0" style={{fontSize: 13, fontWeight: 600, userSelect: 'none'}}>
        {(name||"").slice(0, 8) + (name?.length > 8 ? "..." : "")}
      </h2>
      <h2 className="m-0" style={{fontSize: 14, fontWeight: 600, userSelect: 'none'}}>{count || ''}</h2>
    </div>
  )
}