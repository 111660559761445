export const forestDashboardData: {
  forestPlantationChartData: {
    type: 'PURCHASED' | 'PLANNED';
    data: {
      date: string;
      areaDone: number;
      areaTarget: number;
    }[];
  };
  totalForestData: {
    showSDGGoals: number[];
    employmentGenerated: number;
    totalAreaPurchased: number;
    recapture: {
      recapturedPerYear: number;
      recapturedThisYear: number;
      recapturedToDate: number;
    },
    plantation: {
      numberOfProjectSites: number;
      totalTreesPlanted: number;
      totalTreesPlanned: number;
      survivedPercentage: number;
      uniqueSpeciesPlanted: number;
      speciesPlanted: {
        speciesName: string;
        image: string;
        count: number;
      }[];
    }
  };
  projectListData: {
    id: string | number;
    name: string;
    image: string;
    details: {
      totalTreesPlanted: number;
      totalTreesPlanned: number;
      uniqueSpeciesPlanted: number;
    }
    location: {
      village: string;
      state: string;
      country: string;
      latitude: string;
      longitude: string;
    }
  }[];
  projectDetailsData: {
    [id: string | number]: {
      name: string;
      employmentGenerated: number;
      numberOfForestSites: number;
      recapture: {
        recaptureToDate: number;
      }
      plantation: {
        totalTreesPlanted: number;
        totalTreesPlanned: number;
        uniqueSpeciesPlanted: number;
        survivedPercentage: number;
        speciesPlanted: {
          speciesName: string;
          image: string;
          count: number;
          countSpeciesUpdated: number;
          plantationImages?: any; // to remove
        }[];
        dateWiseSpeciesPlanted: {
          date: string;
          totalTreesPlanted: number;
          speciesPlanted: {
            speciesName: string;
            image: string;
            count: number;
            plantationImages: string[];
            countSpeciesUpdated?: any; // to remove
          }[];
        }[];
        dateWiseSpeciesUpdated: {
          date: string;
          speciesUpdated: {
            speciesName: string;
            image: string;
            count?: any; // to remove
            plantationImages?: any; // to remove
            countSpeciesUpdated?: any; // to remove
            updateDetails: {
              circumference?: number | null;
              height?: number | null;
              circumferenceImage?: string | null;
              heightImage?: string | null;
              leafImage?: string | null;
              latitude: string;
              longitude: string;
            }[];
          }[];
        }[];
      }
      forestSites: {
        name: string;
        recapture: {
          recaptureToDate: number;
        }
        location: {
          latitude: string;
          longitude: string;
        }
        plantation: {
          totalTreesPlanted: number;
          totalTreesPlanned: number;
          uniqueSpeciesPlanted: number;
          survivedPercentage: number;
          lastPlantationDate: string;
          speciesPlanted: {
            speciesName: string;
            image: string;
            count: number;
            plantationImages?: any;
            countSpeciesUpdated?: any;
          }[];
        },
        satelliteImages: {
          image: string;
          date: string;
        }[];
      }[];
    }
  },
  growersData: {
    email: string;
    projectId: string | number;
  }[]
} = {
    "forestPlantationChartData": {
        "type": "PLANNED",
        "data": [
            {
                "date": "2020-04-01",
                "areaTarget": 881,
                "areaDone": 800
            },
            {
                "date": "2021-04-01",
                "areaTarget": 6891,
                "areaDone": 5973
            },
            {
                "date": "2022-04-01",
                "areaTarget": 3702,
                "areaDone": 3432
            },
            {
                "date": "2022-10-04",
                "areaTarget": 1806,
                "areaDone": 1550
            },
            {
                "date": "2022-12-03",
                "areaTarget": 4000,
                "areaDone": 4000
            },
            {
                "date": "2022-12-21",
                "areaTarget": 1333,
                "areaDone": 1333
            },
            {
                "date": "2022-12-29",
                "areaTarget": 1333,
                "areaDone": 1333
            },
            {
                "date": "2022-12-31",
                "areaTarget": 1334,
                "areaDone": 1334
            },
            {
                "date": "2023-01-01",
                "areaTarget": 4643,
                "areaDone": 4643
            },
            {
                "date": "2023-02-01",
                "areaTarget": 4643,
                "areaDone": 4643
            },
            {
                "date": "2023-02-02",
                "areaTarget": 200,
                "areaDone": 200
            },
            {
                "date": "2023-03-01",
                "areaTarget": 4643,
                "areaDone": 4643
            },
            {
                "date": "2023-03-21",
                "areaTarget": 192,
                "areaDone": 50
            },
            {
                "date": "2023-03-31",
                "areaTarget": 1000,
                "areaDone": 1000
            },
            {
                "date": "2023-04-01",
                "areaTarget": 29567,
                "areaDone": 28372
            },
            {
                "date": "2023-06-05",
                "areaTarget": 243,
                "areaDone": 100
            },
            {
                "date": "2023-07-01",
                "areaTarget": 4407,
                "areaDone": 4150
            },
            {
                "date": "2023-07-05",
                "areaTarget": 400,
                "areaDone": 400
            },
            {
                "date": "2023-08-01",
                "areaTarget": 666,
                "areaDone": 666
            },
            {
                "date": "2023-08-04",
                "areaTarget": 666,
                "areaDone": 666
            },
            {
                "date": "2023-08-07",
                "areaTarget": 666,
                "areaDone": 666
            },
            {
                "date": "2023-08-09",
                "areaTarget": 1000,
                "areaDone": 1000
            },
            {
                "date": "2023-08-10",
                "areaTarget": 666,
                "areaDone": 666
            },
            {
                "date": "2023-08-12",
                "areaTarget": 6426,
                "areaDone": 6260
            },
            {
                "date": "2023-08-13",
                "areaTarget": 2666,
                "areaDone": 2666
            },
            {
                "date": "2023-08-14",
                "areaTarget": 12165,
                "areaDone": 12165
            },
            {
                "date": "2023-08-15",
                "areaTarget": 88131,
                "areaDone": 76319
            },
            {
                "date": "2023-08-16",
                "areaTarget": 58034,
                "areaDone": 54051
            },
            {
                "date": "2023-08-17",
                "areaTarget": 47515,
                "areaDone": 42372
            },
            {
                "date": "2023-08-18",
                "areaTarget": 8374,
                "areaDone": 6208
            },
            {
                "date": "2023-08-19",
                "areaTarget": 666,
                "areaDone": 666
            },
            {
                "date": "2023-08-22",
                "areaTarget": 8599,
                "areaDone": 7266
            },
            {
                "date": "2023-08-23",
                "areaTarget": 11800,
                "areaDone": 11800
            },
            {
                "date": "2023-08-25",
                "areaTarget": 3332,
                "areaDone": 3166
            },
            {
                "date": "2023-08-28",
                "areaTarget": 675,
                "areaDone": 675
            },
            {
                "date": "2023-08-30",
                "areaTarget": 200,
                "areaDone": 200
            },
            {
                "date": "2023-09-30",
                "areaTarget": 200,
                "areaDone": 200
            },
            {
                "date": "2023-10-05",
                "areaTarget": 3507,
                "areaDone": 3250
            },
            {
                "date": "2023-12-18",
                "areaTarget": 1525,
                "areaDone": 1525
            },
            {
                "date": "2023-12-23",
                "areaTarget": 1500,
                "areaDone": 1500
            },
            {
                "date": "2024-01-31",
                "areaTarget": 200,
                "areaDone": 200
            },
            {
                "date": "2024-02-03",
                "areaTarget": 350,
                "areaDone": 100
            },
            {
                "date": "2024-02-11",
                "areaTarget": 1000,
                "areaDone": 1000
            },
            {
                "date": "2024-02-14",
                "areaTarget": 700,
                "areaDone": 700
            },
            {
                "date": "2024-02-17",
                "areaTarget": 500,
                "areaDone": 500
            },
            {
                "date": "2024-03-22",
                "areaTarget": 500,
                "areaDone": 500
            },
            {
                "date": "2024-03-23",
                "areaTarget": 500,
                "areaDone": 500
            },
            {
                "date": "2024-03-29",
                "areaTarget": 2000,
                "areaDone": 2000
            },
            {
                "date": "2024-03-30",
                "areaTarget": 3813,
                "areaDone": 3500
            },
            {
                "date": "2024-03-31",
                "areaTarget": 1000,
                "areaDone": 1000
            },
            {
                "date": "2024-04-20",
                "areaTarget": 912,
                "areaDone": 912
            },
            {
                "date": "2024-05-06",
                "areaTarget": 20,
                "areaDone": 20
            },
            {
                "date": "2024-05-20",
                "areaTarget": 50,
                "areaDone": 50
            },
            {
                "date": "2024-06-05",
                "areaTarget": 2244,
                "areaDone": 578
            },
            {
                "date": "2024-06-07",
                "areaTarget": 484,
                "areaDone": 227
            },
            {
                "date": "2024-07-01",
                "areaTarget": 24,
                "areaDone": 24
            },
            {
                "date": "2024-07-02",
                "areaTarget": 28,
                "areaDone": 28
            },
            {
                "date": "2024-07-04",
                "areaTarget": 5042,
                "areaDone": 5042
            },
            {
                "date": "2024-07-15",
                "areaTarget": 100,
                "areaDone": 100
            },
            {
                "date": "2024-07-16",
                "areaTarget": 10000,
                "areaDone": 10000
            },
            {
                "date": "2024-07-19",
                "areaTarget": 3333,
                "areaDone": 2000
            },
            {
                "date": "2024-07-24",
                "areaTarget": 3000,
                "areaDone": 3000
            },
            {
                "date": "2024-07-27",
                "areaTarget": 2500,
                "areaDone": 2500
            },
            {
                "date": "2024-07-30",
                "areaTarget": 900,
                "areaDone": 900
            },
            {
                "date": "2024-08-01",
                "areaTarget": 5742,
                "areaDone": 5742
            },
            {
                "date": "2024-08-02",
                "areaTarget": 800,
                "areaDone": 800
            },
            {
                "date": "2024-08-03",
                "areaTarget": 1210,
                "areaDone": 1210
            },
            {
                "date": "2024-08-04",
                "areaTarget": 500,
                "areaDone": 500
            },
            {
                "date": "2024-08-05",
                "areaTarget": 9320,
                "areaDone": 9320
            },
            {
                "date": "2024-08-06",
                "areaTarget": 3305,
                "areaDone": 3305
            },
            {
                "date": "2024-08-07",
                "areaTarget": 830,
                "areaDone": 830
            },
            {
                "date": "2024-08-08",
                "areaTarget": 4000,
                "areaDone": 4000
            },
            {
                "date": "2024-08-09",
                "areaTarget": 150,
                "areaDone": 150
            },
            {
                "date": "2024-08-10",
                "areaTarget": 250,
                "areaDone": 250
            },
            {
                "date": "2024-08-12",
                "areaTarget": 13710,
                "areaDone": 13710
            },
            {
                "date": "2024-08-13",
                "areaTarget": 2820,
                "areaDone": 2820
            },
            {
                "date": "2024-08-14",
                "areaTarget": 4013,
                "areaDone": 3700
            },
            {
                "date": "2024-08-15",
                "areaTarget": 15230,
                "areaDone": 14230
            },
            {
                "date": "2024-08-16",
                "areaTarget": 604,
                "areaDone": 520
            },
            {
                "date": "2024-08-17",
                "areaTarget": 14075,
                "areaDone": 12157
            },
            {
                "date": "2024-08-19",
                "areaTarget": 103,
                "areaDone": 75
            },
            {
                "date": "2024-08-21",
                "areaTarget": 3831,
                "areaDone": 3775
            },
            {
                "date": "2024-08-22",
                "areaTarget": 2820,
                "areaDone": 2820
            },
            {
                "date": "2024-08-23",
                "areaTarget": 5706,
                "areaDone": 5540
            },
            {
                "date": "2024-08-24",
                "areaTarget": 292,
                "areaDone": 240
            },
            {
                "date": "2024-08-26",
                "areaTarget": 902,
                "areaDone": 850
            },
            {
                "date": "2024-08-27",
                "areaTarget": 3772,
                "areaDone": 3720
            },
            {
                "date": "2024-08-28",
                "areaTarget": 4256,
                "areaDone": 4152
            },
            {
                "date": "2024-08-29",
                "areaTarget": 16488,
                "areaDone": 16384
            },
            {
                "date": "2024-08-30",
                "areaTarget": 22511,
                "areaDone": 17407
            },
            {
                "date": "2024-08-31",
                "areaTarget": 8309,
                "areaDone": 8205
            },
            {
                "date": "2024-09-01",
                "areaTarget": 416,
                "areaDone": 416
            },
            {
                "date": "2024-09-02",
                "areaTarget": 988,
                "areaDone": 988
            },
            {
                "date": "2024-09-03",
                "areaTarget": 4719,
                "areaDone": 4620
            },
            {
                "date": "2024-09-04",
                "areaTarget": 8724,
                "areaDone": 8625
            },
            {
                "date": "2024-09-05",
                "areaTarget": 5545,
                "areaDone": 5496
            },
            {
                "date": "2024-09-06",
                "areaTarget": 2820,
                "areaDone": 2820
            },
            {
                "date": "2024-09-09",
                "areaTarget": 5764,
                "areaDone": 3414
            },
            {
                "date": "2024-09-10",
                "areaTarget": 1550,
                "areaDone": 1445
            },
            {
                "date": "2024-09-11",
                "areaTarget": 592,
                "areaDone": 539
            },
            {
                "date": "2024-09-12",
                "areaTarget": 488,
                "areaDone": 435
            },
            {
                "date": "2024-09-13",
                "areaTarget": 2738,
                "areaDone": 2685
            },
            {
                "date": "2024-09-14",
                "areaTarget": 13107,
                "areaDone": 13054
            },
            {
                "date": "2024-09-17",
                "areaTarget": 363,
                "areaDone": 310
            },
            {
                "date": "2024-09-18",
                "areaTarget": 763,
                "areaDone": 710
            },
            {
                "date": "2024-09-19",
                "areaTarget": 468,
                "areaDone": 415
            },
            {
                "date": "2024-09-20",
                "areaTarget": 5000,
                "areaDone": 5000
            },
            {
                "date": "2024-09-22",
                "areaTarget": 2820,
                "areaDone": 2820
            },
            {
                "date": "2024-09-25",
                "areaTarget": 2285,
                "areaDone": 2257
            },
            {
                "date": "2024-09-26",
                "areaTarget": 178,
                "areaDone": 150
            },
            {
                "date": "2024-09-27",
                "areaTarget": 196,
                "areaDone": 140
            },
            {
                "date": "2024-09-28",
                "areaTarget": 5996,
                "areaDone": 5655
            },
            {
                "date": "2024-09-29",
                "areaTarget": 38,
                "areaDone": 10
            },
            {
                "date": "2024-09-30",
                "areaTarget": 2898,
                "areaDone": 2870
            },
            {
                "date": "2024-10-01",
                "areaTarget": 1312,
                "areaDone": 1256
            },
            {
                "date": "2024-10-02",
                "areaTarget": 88,
                "areaDone": 88
            },
            {
                "date": "2024-10-04",
                "areaTarget": 856,
                "areaDone": 800
            },
            {
                "date": "2024-10-08",
                "areaTarget": 2820,
                "areaDone": 2820
            },
            {
                "date": "2024-10-09",
                "areaTarget": 378,
                "areaDone": 350
            },
            {
                "date": "2024-10-10",
                "areaTarget": 78,
                "areaDone": 50
            },
            {
                "date": "2024-10-12",
                "areaTarget": 628,
                "areaDone": 600
            },
            {
                "date": "2024-10-13",
                "areaTarget": 500,
                "areaDone": 500
            },
            {
                "date": "2024-10-14",
                "areaTarget": 561,
                "areaDone": 452
            },
            {
                "date": "2024-10-15",
                "areaTarget": 353,
                "areaDone": 300
            },
            {
                "date": "2024-10-16",
                "areaTarget": 3048,
                "areaDone": 3020
            },
            {
                "date": "2024-10-17",
                "areaTarget": 253,
                "areaDone": 200
            },
            {
                "date": "2024-10-18",
                "areaTarget": 228,
                "areaDone": 200
            },
            {
                "date": "2024-10-19",
                "areaTarget": 228,
                "areaDone": 200
            },
            {
                "date": "2024-10-21",
                "areaTarget": 1222,
                "areaDone": 1194
            },
            {
                "date": "2024-10-22",
                "areaTarget": 303,
                "areaDone": 250
            },
            {
                "date": "2024-10-23",
                "areaTarget": 1189,
                "areaDone": 1080
            },
            {
                "date": "2024-10-24",
                "areaTarget": 3554,
                "areaDone": 3420
            },
            {
                "date": "2024-10-25",
                "areaTarget": 653,
                "areaDone": 600
            },
            {
                "date": "2024-10-26",
                "areaTarget": 173,
                "areaDone": 120
            },
            {
                "date": "2024-10-27",
                "areaTarget": 588,
                "areaDone": 482
            },
            {
                "date": "2024-10-28",
                "areaTarget": 674,
                "areaDone": 568
            },
            {
                "date": "2024-10-29",
                "areaTarget": 871,
                "areaDone": 765
            },
            {
                "date": "2024-10-30",
                "areaTarget": 338,
                "areaDone": 285
            },
            {
                "date": "2024-11-29",
                "areaTarget": 1700,
                "areaDone": 1700
            },
            {
                "date": "2024-12-01",
                "areaTarget": 128,
                "areaDone": 100
            },
            {
                "date": "2024-12-12",
                "areaTarget": 2000,
                "areaDone": 2000
            },
            {
                "date": "2024-12-16",
                "areaTarget": 2000,
                "areaDone": 2000
            },
            {
                "date": "2025-08-17",
                "areaTarget": 2000,
                "areaDone": 2000
            },
            {
                "date": "2025-09-25",
                "areaTarget": 1000,
                "areaDone": 1000
            }
        ]
    },
    "totalForestData": {
        "totalAreaPurchased": 0,
        "showSDGGoals": [
            3,4
        ],
        "employmentGenerated": 0,
        "recapture": {
            "recapturedPerYear": 0,
            "recapturedThisYear": 0,
            "recapturedToDate": 0
        },
        "plantation": {
            "numberOfProjectSites": 29,
            "totalTreesPlanted": 566976,
            "totalTreesPlanned": 613126,
            "survivedPercentage": 100,
            "uniqueSpeciesPlanted": 67,
            "speciesPlanted": [
                {
                    "speciesName": "Mahogany (Mahagoni)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                    "count": 7694
                },
                {
                    "speciesName": "Ashok",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                    "count": 11129
                },
                {
                    "speciesName": "Sagwan (Teak, Tectona grandis)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                    "count": 41515
                },
                {
                    "speciesName": "Jackfruit (Kathal)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                    "count": 17252
                },
                {
                    "speciesName": "Lemon",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                    "count": 18342
                },
                {
                    "speciesName": "Debdaru",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                    "count": 2275
                },
                {
                    "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                    "count": 8924
                },
                {
                    "speciesName": "Kala Sheesham",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                    "count": 951
                },
                {
                    "speciesName": "Neem (Bevu)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                    "count": 59606
                },
                {
                    "speciesName": "Eucalyptus",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                    "count": 13546
                },
                {
                    "speciesName": "Vachellia nilotica",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/vachellia-nilotica.png",
                    "count": 100
                },
                {
                    "speciesName": "Ficus benghalensis (Banyan)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ficus-benghalensis-banyan.png",
                    "count": 167
                },
                {
                    "speciesName": "Parosa",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                    "count": 1448
                },
                {
                    "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                    "count": 2073
                },
                {
                    "speciesName": "Imli (Tamarind)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                    "count": 4004
                },
                {
                    "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                    "count": 5049
                },
                {
                    "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                    "count": 19577
                },
                {
                    "speciesName": "Akashi (Acacia)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                    "count": 8475
                },
                {
                    "speciesName": "Kasuarina",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                    "count": 2525
                },
                {
                    "speciesName": "Badam (Indian Almond,Jungli Badam)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                    "count": 4700
                },
                {
                    "speciesName": "Baula",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                    "count": 614
                },
                {
                    "speciesName": "Radha Chuda",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/radha-chuda.png",
                    "count": 250
                },
                {
                    "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                    "count": 45900
                },
                {
                    "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                    "count": 5296
                },
                {
                    "speciesName": "Arjun (Terminalia arjuna)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                    "count": 7452
                },
                {
                    "speciesName": "Sitafal (Custard Apple)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                    "count": 26760
                },
                {
                    "speciesName": "Royal Poinciana",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                    "count": 404
                },
                {
                    "speciesName": "Kaner",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                    "count": 65
                },
                {
                    "speciesName": "Charal",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                    "count": 1312
                },
                {
                    "speciesName": "Kanjal",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                    "count": 1354
                },
                {
                    "speciesName": "Bel (Belpatra, Wood Apple)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                    "count": 1272
                },
                {
                    "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                    "count": 12172
                },
                {
                    "speciesName": "Krishnachuda",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/krishnachuda.png",
                    "count": 667
                },
                {
                    "speciesName": "Kanak Champa",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                    "count": 951
                },
                {
                    "speciesName": "Swarna Champa",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                    "count": 951
                },
                {
                    "speciesName": "Kaju (Cashew)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                    "count": 6344
                },
                {
                    "speciesName": "Chikku (Sapota)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                    "count": 10315
                },
                {
                    "speciesName": "Snake Plant",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/snake-plant.png",
                    "count": 1333
                },
                {
                    "speciesName": "Palm Tree",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/palm-tree.png",
                    "count": 1333
                },
                {
                    "speciesName": "Bamboo",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                    "count": 27545
                },
                {
                    "speciesName": "Atta Tree",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/atta-tree.png",
                    "count": 775
                },
                {
                    "speciesName": "Sapetha",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                    "count": 4801
                },
                {
                    "speciesName": "Sukhchain",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                    "count": 4801
                },
                {
                    "speciesName": "Pongamia",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                    "count": 1092
                },
                {
                    "speciesName": "Cascabela thevetia",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                    "count": 1092
                },
                {
                    "speciesName": "Bougainvillea",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                    "count": 1092
                },
                {
                    "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                    "count": 1092
                },
                {
                    "speciesName": "Bauhinia variegata",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                    "count": 1092
                },
                {
                    "speciesName": "Leptospermum scoparium",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                    "count": 1092
                },
                {
                    "speciesName": "Pomegranate",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                    "count": 1355
                },
                {
                    "speciesName": "Madhukamni",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/madhukamni.png",
                    "count": 17
                },
                {
                    "speciesName": "Rose, Jasmine",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                    "count": 1990
                },
                {
                    "speciesName": "Karanj",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                    "count": 1074
                },
                {
                    "speciesName": "Bargad (Banyan Tree)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bargad-banyan-tree.png",
                    "count": 83
                },
                {
                    "speciesName": "Silver Oak",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                    "count": 10419
                },
                {
                    "speciesName": "Blueberry",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/blueberry.png",
                    "count": 564
                },
                {
                    "speciesName": "Bengal Currant",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                    "count": 649
                },
                {
                    "speciesName": "Mango (Mangifera indica)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                    "count": 56905
                },
                {
                    "speciesName": "Guava (Amrud, Psidium)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                    "count": 78110
                },
                {
                    "speciesName": "Litchi",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/litchi.png",
                    "count": 564
                },
                {
                    "speciesName": "Sal",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                    "count": 3619
                },
                {
                    "speciesName": "Indian Medlar",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/indian-medlar.png",
                    "count": 13
                },
                {
                    "speciesName": "Golden Duranta",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/golden-duranta.png",
                    "count": 1333
                },
                {
                    "speciesName": "Raatrani (Night-blooming Jasmine)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/raatrani-night-blooming-jasmine.png",
                    "count": 556
                },
                {
                    "speciesName": "Coconut Tree",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                    "count": 1225
                },
                {
                    "speciesName": "Deamia testudo (Dog Tail Cactus)",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                    "count": 1092
                },
                {
                    "speciesName": "Cherry",
                    "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                    "count": 4475
                }
            ]
        }
    },
    "projectListData": [
        {
            "id": "P01",
            "name": "Sahibganj-RWSS",
            "image": "",
            "details": {
                "totalTreesPlanted": 15120,
                "totalTreesPlanned": 15120,
                "uniqueSpeciesPlanted": 9
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "25.224413",
                "longitude": "87.643056"
            }
        },
        {
            "id": "P02",
            "name": "107MLD capacity Southwest Guwahati WSP",
            "image": "",
            "details": {
                "totalTreesPlanted": 20,
                "totalTreesPlanned": 1520,
                "uniqueSpeciesPlanted": 3
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "26.163746",
                "longitude": "91.669422"
            }
        },
        {
            "id": "P03",
            "name": "Ballia WSS",
            "image": "",
            "details": {
                "totalTreesPlanted": 8040,
                "totalTreesPlanned": 9040,
                "uniqueSpeciesPlanted": 8
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "25.778586",
                "longitude": "84.107777"
            }
        },
        {
            "id": "P04",
            "name": "Firozabad WSS Pkg-2",
            "image": "",
            "details": {
                "totalTreesPlanted": 14659,
                "totalTreesPlanned": 14659,
                "uniqueSpeciesPlanted": 9
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "27.1718",
                "longitude": "78.2148"
            }
        },
        {
            "id": "P05",
            "name": "Water Supply Project to Jagatsinghpur District",
            "image": "",
            "details": {
                "totalTreesPlanted": 10100,
                "totalTreesPlanned": 14100,
                "uniqueSpeciesPlanted": 4
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "20.328",
                "longitude": "86.339"
            }
        },
        {
            "id": "P06",
            "name": "Koraput and Nabarangpur WSP",
            "image": "",
            "details": {
                "totalTreesPlanted": 3100,
                "totalTreesPlanned": 4100,
                "uniqueSpeciesPlanted": 8
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "19.237004",
                "longitude": "82.487069"
            }
        },
        {
            "id": "P07",
            "name": "MEGA PWS IN 11 BLOCK OF GANJAM DISTRICT",
            "image": "",
            "details": {
                "totalTreesPlanted": 16050,
                "totalTreesPlanned": 16050,
                "uniqueSpeciesPlanted": 6
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "19.8588",
                "longitude": "84.5893"
            }
        },
        {
            "id": "P08",
            "name": "Chittorgarh WSP Package - 1",
            "image": "",
            "details": {
                "totalTreesPlanted": 15000,
                "totalTreesPlanned": 15000,
                "uniqueSpeciesPlanted": 21
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "25.0109707",
                "longitude": "75.0733413"
            }
        },
        {
            "id": "P09",
            "name": "Masalia Ranishwar LIS",
            "image": "",
            "details": {
                "totalTreesPlanted": 10669,
                "totalTreesPlanned": 10669,
                "uniqueSpeciesPlanted": 13
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "24.0458057",
                "longitude": "87.4217135"
            }
        },
        {
            "id": "P10",
            "name": "MLIP Cluster XXII",
            "image": "",
            "details": {
                "totalTreesPlanted": 12767,
                "totalTreesPlanned": 14767,
                "uniqueSpeciesPlanted": 9
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "20.617947",
                "longitude": "85.535904"
            }
        },
        {
            "id": "P11",
            "name": "Cluster-XIX",
            "image": "",
            "details": {
                "totalTreesPlanted": 46290,
                "totalTreesPlanned": 46290,
                "uniqueSpeciesPlanted": 13
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "20.4813",
                "longitude": "86.2712"
            }
        },
        {
            "id": "P12",
            "name": "MLIP Cluster XX",
            "image": "",
            "details": {
                "totalTreesPlanted": 22250,
                "totalTreesPlanned": 23250,
                "uniqueSpeciesPlanted": 12
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "20.705",
                "longitude": "80.7161"
            }
        },
        {
            "id": "P13",
            "name": "MLIP CLUSTER XXV",
            "image": "",
            "details": {
                "totalTreesPlanted": 31030,
                "totalTreesPlanned": 31030,
                "uniqueSpeciesPlanted": 3
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "19.240024",
                "longitude": "82.777831"
            }
        },
        {
            "id": "P14",
            "name": "Makeup water & Ash Disposal system, NTPC Talcher",
            "image": "",
            "details": {
                "totalTreesPlanted": 20,
                "totalTreesPlanned": 5020,
                "uniqueSpeciesPlanted": 3
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "21.034377",
                "longitude": "85.16209"
            }
        },
        {
            "id": "P15",
            "name": "Bandra WwTF",
            "image": "",
            "details": {
                "totalTreesPlanted": 111000,
                "totalTreesPlanned": 111000,
                "uniqueSpeciesPlanted": 9
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "19.045475",
                "longitude": "72.826869"
            }
        },
        {
            "id": "P16",
            "name": "Fazilka & Ferozepur WSS",
            "image": "",
            "details": {
                "totalTreesPlanted": 27650,
                "totalTreesPlanned": 30000,
                "uniqueSpeciesPlanted": 10
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "30.601518",
                "longitude": "74.39223"
            }
        },
        {
            "id": "P17",
            "name": "Mandya RWS",
            "image": "",
            "details": {
                "totalTreesPlanted": 9620,
                "totalTreesPlanned": 17620,
                "uniqueSpeciesPlanted": 6
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "12.66682",
                "longitude": "76.457225"
            }
        },
        {
            "id": "P18",
            "name": "Dharwad RWS",
            "image": "",
            "details": {
                "totalTreesPlanted": 35100,
                "totalTreesPlanned": 45100,
                "uniqueSpeciesPlanted": 9
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "15.398",
                "longitude": "75.0011"
            }
        },
        {
            "id": "P19",
            "name": "Sitapur Tubewell Scheme Pkg 5",
            "image": "",
            "details": {
                "totalTreesPlanted": 16128,
                "totalTreesPlanned": 16128,
                "uniqueSpeciesPlanted": 12
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "27.296442",
                "longitude": "80.810488"
            }
        },
        {
            "id": "P20",
            "name": "Balrampur Tubewell Scheme Pkg -3",
            "image": "",
            "details": {
                "totalTreesPlanted": 350,
                "totalTreesPlanned": 350,
                "uniqueSpeciesPlanted": 4
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "27.461362",
                "longitude": "82.133368"
            }
        },
        {
            "id": "P21",
            "name": "Gonda Tubewell scheme PKG-4",
            "image": "",
            "details": {
                "totalTreesPlanted": 22590,
                "totalTreesPlanned": 22590,
                "uniqueSpeciesPlanted": 5
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "27.21833",
                "longitude": "81.901025"
            }
        },
        {
            "id": "P22",
            "name": "Gonda Tubewell scheme PKG-5",
            "image": "",
            "details": {
                "totalTreesPlanted": 1515,
                "totalTreesPlanned": 1515,
                "uniqueSpeciesPlanted": 4
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "27.1912244",
                "longitude": "81.6237652"
            }
        },
        {
            "id": "P23",
            "name": "Varanasi Tubewell Scheme Package 4",
            "image": "",
            "details": {
                "totalTreesPlanted": 6540,
                "totalTreesPlanned": 6540,
                "uniqueSpeciesPlanted": 5
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "25.545013",
                "longitude": "82.982891"
            }
        },
        {
            "id": "P24",
            "name": "Ghazipur Tubewell Scheme Package 3",
            "image": "",
            "details": {
                "totalTreesPlanted": 5750,
                "totalTreesPlanned": 5750,
                "uniqueSpeciesPlanted": 5
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "25.5491898",
                "longitude": "83.3367576"
            }
        },
        {
            "id": "P25",
            "name": "Prayagraj Tubewell Scheme-Pkg-5",
            "image": "",
            "details": {
                "totalTreesPlanted": 2000,
                "totalTreesPlanned": 5300,
                "uniqueSpeciesPlanted": 8
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "25.183733",
                "longitude": "81.879115"
            }
        },
        {
            "id": "P26",
            "name": "Prayagraj Tubewell Scheme- Pkg-6",
            "image": "",
            "details": {
                "totalTreesPlanted": 3850,
                "totalTreesPlanned": 7350,
                "uniqueSpeciesPlanted": 8
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "25.361561",
                "longitude": "82.189255"
            }
        },
        {
            "id": "P27",
            "name": "HMLIP",
            "image": "",
            "details": {
                "totalTreesPlanted": 34065,
                "totalTreesPlanned": 34065,
                "uniqueSpeciesPlanted": 25
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "22.913354",
                "longitude": "76.060858"
            }
        },
        {
            "id": "P28",
            "name": "KMLIS",
            "image": "",
            "details": {
                "totalTreesPlanted": 45795,
                "totalTreesPlanned": 45795,
                "uniqueSpeciesPlanted": 17
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "22.16",
                "longitude": "74.67"
            }
        },
        {
            "id": "P29",
            "name": "24/7 Hubli Dharwad Water Supply Project",
            "image": "",
            "details": {
                "totalTreesPlanted": 39908,
                "totalTreesPlanned": 43408,
                "uniqueSpeciesPlanted": 9
            },
            "location": {
                "village": "",
                "state": "",
                "country": "",
                "latitude": "15.35",
                "longitude": "75.1167"
            }
        }
    ],
    "projectDetailsData": {
        "P01": {
            "name": "Sahibganj-RWSS",
            "employmentGenerated": 0,
            "numberOfForestSites": 6,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 15120,
                "totalTreesPlanned": 15120,
                "uniqueSpeciesPlanted": 9,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Mahogany (Mahagoni)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                        "count": 3797,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3797
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 3067,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3067
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 3767,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3767
                    },
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 3000,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3000
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 5,
                        "plantationImages": [],
                        "countSpeciesUpdated": 5
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 705,
                        "plantationImages": [],
                        "countSpeciesUpdated": 705
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 30,
                        "plantationImages": [],
                        "countSpeciesUpdated": 30
                    },
                    {
                        "speciesName": "Debdaru",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                        "count": 25,
                        "plantationImages": [],
                        "countSpeciesUpdated": 25
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 725,
                        "plantationImages": [],
                        "countSpeciesUpdated": 725
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950
                            }
                        ],
                        "totalTreesPlanted": 11800
                    },
                    {
                        "date": "2023-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2024-05-06",
                        "speciesPlanted": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            }
                        ],
                        "totalTreesPlanted": 20
                    },
                    {
                        "date": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Debdaru",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ],
                        "totalTreesPlanted": 100
                    },
                    {
                        "date": "2024-09-20",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 767,
                                "plantationImages": [],
                                "countSpeciesUpdated": 767
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 767,
                                "plantationImages": [],
                                "countSpeciesUpdated": 767
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ],
                        "totalTreesPlanted": 3000
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-23",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-05-06",
                        "speciesUpdated": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Debdaru",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-20",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 767,
                                "plantationImages": [],
                                "countSpeciesUpdated": 767,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 767,
                                "plantationImages": [],
                                "countSpeciesUpdated": 767,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "GAP 9",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.224413",
                        "longitude": "87.643056"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 11800,
                        "totalTreesPlanned": 11800,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 2950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2950
                            }
                        ]
                    }
                },
                {
                    "name": "Fire Brigade",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.232318",
                        "longitude": "87.649847"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Store Yard",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.039382",
                        "longitude": "87.667113"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 20,
                        "totalTreesPlanned": 20,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-05-06",
                        "speciesPlanted": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            }
                        ]
                    }
                },
                {
                    "name": "Model College Rajmahal",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.020474",
                        "longitude": "87.79888"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Debdaru",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                },
                {
                    "name": "E.Raiway Ground",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.237995",
                        "longitude": "87.64224"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2800,
                        "totalTreesPlanned": 2800,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-20",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            }
                        ]
                    }
                },
                {
                    "name": "Jharna Vihar Park",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.236095",
                        "longitude": "87.638"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-20",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ]
                    }
                }
            ]
        },
        "P02": {
            "name": "107MLD capacity Southwest Guwahati WSP",
            "employmentGenerated": 0,
            "numberOfForestSites": 1,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 20,
                "totalTreesPlanned": 1520,
                "uniqueSpeciesPlanted": 3,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 7,
                        "plantationImages": [],
                        "countSpeciesUpdated": 7
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 7,
                        "plantationImages": [],
                        "countSpeciesUpdated": 7
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 7,
                        "plantationImages": [],
                        "countSpeciesUpdated": 7
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            }
                        ],
                        "totalTreesPlanted": 20
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-06-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "107 MLD WTP Area",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "26.163746",
                        "longitude": "91.669422"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 20,
                        "totalTreesPlanned": 20,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            }
                        ]
                    }
                }
            ]
        },
        "P03": {
            "name": "Ballia WSS",
            "employmentGenerated": 0,
            "numberOfForestSites": 6,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 8040,
                "totalTreesPlanned": 9040,
                "uniqueSpeciesPlanted": 8,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Eucalyptus",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                        "count": 600,
                        "plantationImages": [],
                        "countSpeciesUpdated": 600
                    },
                    {
                        "speciesName": "Vachellia nilotica",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/vachellia-nilotica.png",
                        "count": 100,
                        "plantationImages": [],
                        "countSpeciesUpdated": 100
                    },
                    {
                        "speciesName": "Ficus benghalensis (Banyan)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ficus-benghalensis-banyan.png",
                        "count": 100,
                        "plantationImages": [],
                        "countSpeciesUpdated": 100
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 1448,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1448
                    },
                    {
                        "speciesName": "Parosa",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                        "count": 1448,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1448
                    },
                    {
                        "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                        "count": 1448,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1448
                    },
                    {
                        "speciesName": "Imli (Tamarind)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                        "count": 1448,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1448
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 1448,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1448
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-08-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Vachellia nilotica",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/vachellia-nilotica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Ficus benghalensis (Banyan)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ficus-benghalensis-banyan.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 300
                    },
                    {
                        "date": "2023-08-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ],
                        "totalTreesPlanted": 2500
                    },
                    {
                        "date": "2023-08-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            }
                        ],
                        "totalTreesPlanted": 3500
                    },
                    {
                        "date": "2023-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            }
                        ],
                        "totalTreesPlanted": 740
                    },
                    {
                        "date": "2024-03-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 500
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-08-23",
                        "speciesUpdated": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-06-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Vachellia nilotica",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/vachellia-nilotica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ficus benghalensis (Banyan)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ficus-benghalensis-banyan.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-25",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-18",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-12",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-03-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Middha, ballia",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.778586",
                        "longitude": "84.107777"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                },
                {
                    "name": "WTP & IBPS-1",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.846915",
                        "longitude": "83.796751"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Vachellia nilotica",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/vachellia-nilotica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Ficus benghalensis (Banyan)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ficus-benghalensis-banyan.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Bhikpur, Hanumanganj",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.803895",
                        "longitude": "84.140446"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2500,
                        "totalTreesPlanned": 2500,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                },
                {
                    "name": "Dumri, Dubhar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.79892",
                        "longitude": "83.95914"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3500,
                        "totalTreesPlanned": 3500,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 700,
                                "plantationImages": [],
                                "countSpeciesUpdated": 700
                            }
                        ]
                    }
                },
                {
                    "name": "SMMTD collage ballia",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.763882",
                        "longitude": "84.143858"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 740,
                        "totalTreesPlanned": 740,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 148,
                                "plantationImages": [],
                                "countSpeciesUpdated": 148
                            }
                        ]
                    }
                },
                {
                    "name": "Gurwan, Hanumanganj",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.789948",
                        "longitude": "84.10539"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-03-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Parosa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/parosa.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                }
            ]
        },
        "P04": {
            "name": "Firozabad WSS Pkg-2",
            "employmentGenerated": 0,
            "numberOfForestSites": 15,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 14659,
                "totalTreesPlanned": 14659,
                "uniqueSpeciesPlanted": 9,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 1182,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1182
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 1182,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1182
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 6532,
                        "plantationImages": [],
                        "countSpeciesUpdated": 6532
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 1182,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1182
                    },
                    {
                        "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                        "count": 1182,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1182
                    },
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 850,
                        "plantationImages": [],
                        "countSpeciesUpdated": 850
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 850,
                        "plantationImages": [],
                        "countSpeciesUpdated": 850
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 850,
                        "plantationImages": [],
                        "countSpeciesUpdated": 850
                    },
                    {
                        "speciesName": "Sitafal (Custard Apple)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                        "count": 850,
                        "plantationImages": [],
                        "countSpeciesUpdated": 850
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            }
                        ],
                        "totalTreesPlanted": 1008
                    },
                    {
                        "date": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207
                            }
                        ],
                        "totalTreesPlanted": 1036
                    },
                    {
                        "date": "2023-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            }
                        ],
                        "totalTreesPlanted": 1165
                    },
                    {
                        "date": "2024-02-11",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            }
                        ],
                        "totalTreesPlanted": 1000
                    },
                    {
                        "date": "2024-02-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            }
                        ],
                        "totalTreesPlanted": 700
                    },
                    {
                        "date": "2024-02-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-03-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-03-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-03-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2024-03-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ],
                        "totalTreesPlanted": 1000
                    },
                    {
                        "date": "2024-03-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ],
                        "totalTreesPlanted": 1000
                    },
                    {
                        "date": "2024-08-26",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-08-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-08-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-08-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183
                            }
                        ],
                        "totalTreesPlanted": 916
                    },
                    {
                        "date": "2024-09-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            }
                        ],
                        "totalTreesPlanted": 416
                    },
                    {
                        "date": "2024-09-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            }
                        ],
                        "totalTreesPlanted": 418
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-18",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 207,
                                "plantationImages": [],
                                "countSpeciesUpdated": 207,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-02-11",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-02-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-02-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-03-22",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-03-23",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-03-29",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-03-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-03-31",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-26",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-27",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-29",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-31",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 183,
                                "plantationImages": [],
                                "countSpeciesUpdated": 183,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-02",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Deenbandhu Inter college",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.1718",
                        "longitude": "78.2148"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1008,
                        "totalTreesPlanned": 1008,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 202,
                                "plantationImages": [],
                                "countSpeciesUpdated": 202
                            }
                        ]
                    }
                },
                {
                    "name": "Prathmik Vidyalaya Bharatpura, Firozabad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.206928",
                        "longitude": "78.315964"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 531,
                        "totalTreesPlanned": 531,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 106,
                                "plantationImages": [],
                                "countSpeciesUpdated": 106
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 106,
                                "plantationImages": [],
                                "countSpeciesUpdated": 106
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 106,
                                "plantationImages": [],
                                "countSpeciesUpdated": 106
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 106,
                                "plantationImages": [],
                                "countSpeciesUpdated": 106
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 106,
                                "plantationImages": [],
                                "countSpeciesUpdated": 106
                            }
                        ]
                    }
                },
                {
                    "name": "Composite school Kapawali",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.158229",
                        "longitude": "78.460739"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 505,
                        "totalTreesPlanned": 505,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 101,
                                "plantationImages": [],
                                "countSpeciesUpdated": 101
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 101,
                                "plantationImages": [],
                                "countSpeciesUpdated": 101
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 101,
                                "plantationImages": [],
                                "countSpeciesUpdated": 101
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 101,
                                "plantationImages": [],
                                "countSpeciesUpdated": 101
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 101,
                                "plantationImages": [],
                                "countSpeciesUpdated": 101
                            }
                        ]
                    }
                },
                {
                    "name": "RRM Inter college, Firozabad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.292216",
                        "longitude": "78.361657"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1165,
                        "totalTreesPlanned": 1165,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            }
                        ]
                    }
                },
                {
                    "name": "Sirsaganj Tehsil",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.048247",
                        "longitude": "78.7051429"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-02-11",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            }
                        ]
                    }
                },
                {
                    "name": "Primary School, Nayabash",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.272456",
                        "longitude": "78.46145"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 700,
                        "totalTreesPlanned": 700,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-02-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            }
                        ]
                    }
                },
                {
                    "name": "Primary school, Kotla",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.272128",
                        "longitude": "78.46174"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-02-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Narkhi",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.264672",
                        "longitude": "78.422518"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-03-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Nagla Gangaram",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.27467",
                        "longitude": "78.428739"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-03-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                },
                {
                    "name": "Nagla Khar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.195615",
                        "longitude": "78.307668"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-03-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Nandpur,Shikohabad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.073135",
                        "longitude": "78.629556"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-03-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Dhatri",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.078428",
                        "longitude": "78.624125"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-03-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Jarauli Khurd, Primary School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.173803",
                        "longitude": "78.335907"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-03-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "District Jail",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.20305",
                        "longitude": "78.383689"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3000,
                        "totalTreesPlanned": 3000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-26",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            }
                        ]
                    }
                },
                {
                    "name": "Dau dayal Mahila PG college",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.198468",
                        "longitude": "78.397682"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1250,
                        "totalTreesPlanned": 1250,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                }
            ]
        },
        "P05": {
            "name": "Water Supply Project to Jagatsinghpur District",
            "employmentGenerated": 0,
            "numberOfForestSites": 3,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 10100,
                "totalTreesPlanned": 14100,
                "uniqueSpeciesPlanted": 4,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 2525,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2525
                    },
                    {
                        "speciesName": "Akashi (Acacia)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                        "count": 2525,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2525
                    },
                    {
                        "speciesName": "Eucalyptus",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                        "count": 2525,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2525
                    },
                    {
                        "speciesName": "Kasuarina",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                        "count": 2525,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2525
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650
                            },
                            {
                                "speciesName": "Kasuarina",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650
                            }
                        ],
                        "totalTreesPlanted": 6600
                    },
                    {
                        "date": "2024-07-19",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Kasuarina",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Kasuarina",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            }
                        ],
                        "totalTreesPlanted": 1500
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-22",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kasuarina",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-19",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kasuarina",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kasuarina",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Dainlo high school and Posal store",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.328",
                        "longitude": "86.339"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 6600,
                        "totalTreesPlanned": 6600,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650
                            },
                            {
                                "speciesName": "Kasuarina",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                                "count": 1650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1650
                            }
                        ]
                    }
                },
                {
                    "name": "Karatutha,Patapur,Kujanga",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.286601",
                        "longitude": "86.491946"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2000,
                        "totalTreesPlanned": 2000,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-07-19",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Kasuarina",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                },
                {
                    "name": "Jagannath High School,Kolar,Jaipur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.3425012",
                        "longitude": "86.3786221"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1500,
                        "totalTreesPlanned": 1500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Kasuarina",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kasuarina.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            }
                        ]
                    }
                }
            ]
        },
        "P06": {
            "name": "Koraput and Nabarangpur WSP",
            "employmentGenerated": 0,
            "numberOfForestSites": 4,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 3100,
                "totalTreesPlanned": 4100,
                "uniqueSpeciesPlanted": 8,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 525,
                        "plantationImages": [],
                        "countSpeciesUpdated": 525
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Badam (Indian Almond,Jungli Badam)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                        "count": 525,
                        "plantationImages": [],
                        "countSpeciesUpdated": 525
                    },
                    {
                        "speciesName": "Debdaru",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Baula",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                        "count": 525,
                        "plantationImages": [],
                        "countSpeciesUpdated": 525
                    },
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Radha Chuda",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/radha-chuda.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 525,
                        "plantationImages": [],
                        "countSpeciesUpdated": 525
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Debdaru",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Radha Chuda",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/radha-chuda.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2024-02-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ],
                        "totalTreesPlanted": 100
                    },
                    {
                        "date": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ],
                        "totalTreesPlanted": 1000
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Debdaru",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Radha Chuda",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/radha-chuda.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-02-03",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Anwesha Girl Hostel, Nabarangpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.237004",
                        "longitude": "82.487069"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2000,
                        "totalTreesPlanned": 2000,
                        "uniqueSpeciesPlanted": 8,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Debdaru",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Radha Chuda",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/radha-chuda.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                },
                {
                    "name": "Koraput Store",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "18.827375",
                        "longitude": "82.625563"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 600,
                        "totalTreesPlanned": 850,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-02-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            }
                        ]
                    }
                },
                {
                    "name": "Koraput WTP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "18.776231",
                        "longitude": "82.73168"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                }
            ]
        },
        "P07": {
            "name": "MEGA PWS IN 11 BLOCK OF GANJAM DISTRICT",
            "employmentGenerated": 0,
            "numberOfForestSites": 13,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 16050,
                "totalTreesPlanned": 16050,
                "uniqueSpeciesPlanted": 6,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 2551,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2551
                    },
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 2551,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2551
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 2551,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2551
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 2800,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2800
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 2800,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2800
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 2800,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2800
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-05-20",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            }
                        ],
                        "totalTreesPlanted": 50
                    },
                    {
                        "date": "2024-07-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            }
                        ],
                        "totalTreesPlanted": 100
                    },
                    {
                        "date": "2024-07-24",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ],
                        "totalTreesPlanted": 3000
                    },
                    {
                        "date": "2024-07-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            }
                        ],
                        "totalTreesPlanted": 2500
                    },
                    {
                        "date": "2024-07-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            }
                        ],
                        "totalTreesPlanted": 900
                    },
                    {
                        "date": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ],
                        "totalTreesPlanted": 3000
                    },
                    {
                        "date": "2025-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2024-08-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-07-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2024-10-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-10-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            }
                        ],
                        "totalTreesPlanted": 1000
                    },
                    {
                        "date": "2024-10-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ],
                        "totalTreesPlanted": 500
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-05-20",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-24",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-27",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2025-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-21",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-13",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-25",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "NETENGA STORE",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.8588",
                        "longitude": "84.5893"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 150,
                        "totalTreesPlanned": 150,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-05-20",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "KENDUPADAR GOVT COLLEGE",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.7416",
                        "longitude": "84.6487"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3000,
                        "totalTreesPlanned": 3000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-07-24",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "ANA GOVT HSS KALINGAPADAR SCHOOL",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.1402",
                        "longitude": "84.7326"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2500,
                        "totalTreesPlanned": 2500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-07-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            }
                        ]
                    }
                },
                {
                    "name": "Madhyakhanda Nodal high school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.3406",
                        "longitude": "84.922"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-07-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "Prakalpa high school, K. Salabana",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.736781",
                        "longitude": "85.052932"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 400,
                        "totalTreesPlanned": 400,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-07-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            }
                        ]
                    }
                },
                {
                    "name": "Nettanga Dinabandhu Bidyapitha, Nettanga",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.84867493",
                        "longitude": "84.59423052"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3000,
                        "totalTreesPlanned": 3000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Kirapalli Store, Ambapua",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.8393",
                        "longitude": "84.65"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2000,
                        "totalTreesPlanned": 2000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2025-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            }
                        ]
                    }
                },
                {
                    "name": "HALANDAKHOLA PRIMARY SCHOOL",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.037",
                        "longitude": "84.661627"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "GAYAGANDA WTP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.168057",
                        "longitude": "84.746622"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2000,
                        "totalTreesPlanned": 2000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-07-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            }
                        ]
                    }
                },
                {
                    "name": "BANAMAHURI-BUDHMango (Mangifera indica)BA ROAD",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.5616",
                        "longitude": "84.86166833"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "BORIPADAR SIMACHAL HIGH SCHOOL",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.8558",
                        "longitude": "84.70084"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            }
                        ]
                    }
                },
                {
                    "name": "Odisha Adarsha Vidyalaya",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.3399",
                        "longitude": "84.9049"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                }
            ]
        },
        "P08": {
            "name": "Chittorgarh WSP Package - 1",
            "employmentGenerated": 0,
            "numberOfForestSites": 6,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 15000,
                "totalTreesPlanned": 15000,
                "uniqueSpeciesPlanted": 21,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 393,
                        "plantationImages": [],
                        "countSpeciesUpdated": 393
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 1410,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1410
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 1410,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1410
                    },
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 1410,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1410
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 1354,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1354
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 1410,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1410
                    },
                    {
                        "speciesName": "Charal",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                        "count": 1312,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1312
                    },
                    {
                        "speciesName": "Kanjal",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                        "count": 1354,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1354
                    },
                    {
                        "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                        "count": 1354,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1354
                    },
                    {
                        "speciesName": "Pomegranate",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Royal Poinciana",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                        "count": 404,
                        "plantationImages": [],
                        "countSpeciesUpdated": 404
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 123,
                        "plantationImages": [],
                        "countSpeciesUpdated": 123
                    },
                    {
                        "speciesName": "Kaju (Cashew)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                        "count": 56,
                        "plantationImages": [],
                        "countSpeciesUpdated": 56
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 123,
                        "plantationImages": [],
                        "countSpeciesUpdated": 123
                    },
                    {
                        "speciesName": "Baula",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                        "count": 56,
                        "plantationImages": [],
                        "countSpeciesUpdated": 56
                    },
                    {
                        "speciesName": "Sitafal (Custard Apple)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                        "count": 992,
                        "plantationImages": [],
                        "countSpeciesUpdated": 992
                    },
                    {
                        "speciesName": "Kaner",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                        "count": 42,
                        "plantationImages": [],
                        "countSpeciesUpdated": 42
                    },
                    {
                        "speciesName": "Ficus benghalensis (Banyan)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ficus-benghalensis-banyan.png",
                        "count": 67,
                        "plantationImages": [],
                        "countSpeciesUpdated": 67
                    },
                    {
                        "speciesName": "Bel (Belpatra, Wood Apple)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                        "count": 67,
                        "plantationImages": [],
                        "countSpeciesUpdated": 67
                    },
                    {
                        "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                        "count": 67,
                        "plantationImages": [],
                        "countSpeciesUpdated": 67
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-07-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ],
                        "totalTreesPlanted": 3000
                    },
                    {
                        "date": "2024-08-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-08-07",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            }
                        ],
                        "totalTreesPlanted": 500
                    },
                    {
                        "date": "2024-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Ficus benghalensis (Banyan)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ficus-benghalensis-banyan.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ],
                        "totalTreesPlanted": 1000
                    },
                    {
                        "date": "2024-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            }
                        ],
                        "totalTreesPlanted": 9500
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-07-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-07",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ficus benghalensis (Banyan)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ficus-benghalensis-banyan.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "WTP Anwelhera",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.0109707",
                        "longitude": "75.0733413"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3000,
                        "totalTreesPlanned": 3000,
                        "uniqueSpeciesPlanted": 11,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-07-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                },
                {
                    "name": "PHED Begun",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.053858",
                        "longitude": "75.013302"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 9,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            }
                        ]
                    }
                },
                {
                    "name": "PHED Marna",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.077961",
                        "longitude": "75.044446"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 11,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-07",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 42,
                                "plantationImages": [],
                                "countSpeciesUpdated": 42
                            }
                        ]
                    }
                },
                {
                    "name": "Govt Rayata",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.00875924",
                        "longitude": "75.11859092"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 10,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 45,
                                "plantationImages": [],
                                "countSpeciesUpdated": 45
                            }
                        ]
                    }
                },
                {
                    "name": "Luv Kush Vatika",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.027525",
                        "longitude": "75.017239"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 14,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Royal Poinciana",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/royal-poinciana.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Ficus benghalensis (Banyan)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ficus-benghalensis-banyan.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ]
                    }
                },
                {
                    "name": "Ghati",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "24.87742714",
                        "longitude": "74.9883065"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 9500,
                        "totalTreesPlanned": 9500,
                        "uniqueSpeciesPlanted": 9,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Charal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/charal.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Kanjal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanjal.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 950,
                                "plantationImages": [],
                                "countSpeciesUpdated": 950
                            }
                        ]
                    }
                }
            ]
        },
        "P09": {
            "name": "Masalia Ranishwar LIS",
            "employmentGenerated": 0,
            "numberOfForestSites": 2,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 10669,
                "totalTreesPlanned": 10669,
                "uniqueSpeciesPlanted": 13,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 951,
                        "plantationImages": [],
                        "countSpeciesUpdated": 951
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 1451,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1451
                    },
                    {
                        "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                        "count": 951,
                        "plantationImages": [],
                        "countSpeciesUpdated": 951
                    },
                    {
                        "speciesName": "Kanak Champa",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                        "count": 951,
                        "plantationImages": [],
                        "countSpeciesUpdated": 951
                    },
                    {
                        "speciesName": "Swarna Champa",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                        "count": 951,
                        "plantationImages": [],
                        "countSpeciesUpdated": 951
                    },
                    {
                        "speciesName": "Kala Sheesham",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                        "count": 951,
                        "plantationImages": [],
                        "countSpeciesUpdated": 951
                    },
                    {
                        "speciesName": "Mahogany (Mahagoni)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                        "count": 1451,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1451
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 500,
                        "plantationImages": [],
                        "countSpeciesUpdated": 500
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 500,
                        "plantationImages": [],
                        "countSpeciesUpdated": 500
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 500,
                        "plantationImages": [],
                        "countSpeciesUpdated": 500
                    },
                    {
                        "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                        "count": 500,
                        "plantationImages": [],
                        "countSpeciesUpdated": 500
                    },
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 500,
                        "plantationImages": [],
                        "countSpeciesUpdated": 500
                    },
                    {
                        "speciesName": "Arjun (Terminalia arjuna)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                        "count": 500,
                        "plantationImages": [],
                        "countSpeciesUpdated": 500
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-07",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-10",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-19",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96
                            }
                        ],
                        "totalTreesPlanted": 675
                    },
                    {
                        "date": "2024-12-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2024-12-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ],
                        "totalTreesPlanted": 2000
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-07",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-10",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-13",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-19",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-22",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-25",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 95,
                                "plantationImages": [],
                                "countSpeciesUpdated": 95,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 96,
                                "plantationImages": [],
                                "countSpeciesUpdated": 96,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-12-12",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-12-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Workmen Habitat, Ranishwar College, Bharat seva sangh aashram, Fatehpur School, Lal baba aashram shadipur, Utkramit madhya Vidyalaya Satala, St. Claret's School, Church, Swasth kendra hospital Baskuli & Batching Plant",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "24.0458057",
                        "longitude": "87.4217135"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 6669,
                        "totalTreesPlanned": 6669,
                        "uniqueSpeciesPlanted": 7,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 951,
                                "plantationImages": [],
                                "countSpeciesUpdated": 951
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 951,
                                "plantationImages": [],
                                "countSpeciesUpdated": 951
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 951,
                                "plantationImages": [],
                                "countSpeciesUpdated": 951
                            },
                            {
                                "speciesName": "Kanak Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kanak-champa.png",
                                "count": 951,
                                "plantationImages": [],
                                "countSpeciesUpdated": 951
                            },
                            {
                                "speciesName": "Swarna Champa",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/swarna-champa.png",
                                "count": 951,
                                "plantationImages": [],
                                "countSpeciesUpdated": 951
                            },
                            {
                                "speciesName": "Kala Sheesham",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kala-sheesham.png",
                                "count": 951,
                                "plantationImages": [],
                                "countSpeciesUpdated": 951
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 951,
                                "plantationImages": [],
                                "countSpeciesUpdated": 951
                            }
                        ]
                    }
                },
                {
                    "name": "Gobinddih-Dumka, Jahirthan, Near Barrage office & Office Back Side and Workmen Habitat",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "24.051028, \n24.051889, \n24.003472, \n24.049528",
                        "longitude": "87.293083, 87.292500, 87.398917, 87.281694"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4000,
                        "totalTreesPlanned": 4000,
                        "uniqueSpeciesPlanted": 8,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-12-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                }
            ]
        },
        "P10": {
            "name": "MLIP Cluster XXII",
            "employmentGenerated": 0,
            "numberOfForestSites": 38,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 12767,
                "totalTreesPlanned": 14767,
                "uniqueSpeciesPlanted": 9,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 5432,
                        "plantationImages": [],
                        "countSpeciesUpdated": 5432
                    },
                    {
                        "speciesName": "Kaju (Cashew)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                        "count": 4371,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4371
                    },
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 197,
                        "plantationImages": [],
                        "countSpeciesUpdated": 197
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 1921,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1921
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 371,
                        "plantationImages": [],
                        "countSpeciesUpdated": 371
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 190,
                        "plantationImages": [],
                        "countSpeciesUpdated": 190
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 226,
                        "plantationImages": [],
                        "countSpeciesUpdated": 226
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 33,
                        "plantationImages": [],
                        "countSpeciesUpdated": 33
                    },
                    {
                        "speciesName": "Baula",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                        "count": 33,
                        "plantationImages": [],
                        "countSpeciesUpdated": 33
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-08-24",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            }
                        ],
                        "totalTreesPlanted": 240
                    },
                    {
                        "date": "2024-08-26",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 117,
                                "plantationImages": [],
                                "countSpeciesUpdated": 117
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 117,
                                "plantationImages": [],
                                "countSpeciesUpdated": 117
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 117,
                                "plantationImages": [],
                                "countSpeciesUpdated": 117
                            }
                        ],
                        "totalTreesPlanted": 350
                    },
                    {
                        "date": "2024-08-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 260,
                                "plantationImages": [],
                                "countSpeciesUpdated": 260
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 260,
                                "plantationImages": [],
                                "countSpeciesUpdated": 260
                            }
                        ],
                        "totalTreesPlanted": 520
                    },
                    {
                        "date": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 379,
                                "plantationImages": [],
                                "countSpeciesUpdated": 379
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 379,
                                "plantationImages": [],
                                "countSpeciesUpdated": 379
                            }
                        ],
                        "totalTreesPlanted": 757
                    },
                    {
                        "date": "2024-08-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ],
                        "totalTreesPlanted": 814
                    },
                    {
                        "date": "2024-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1037,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1037
                            }
                        ],
                        "totalTreesPlanted": 1037
                    },
                    {
                        "date": "2024-08-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 592,
                                "plantationImages": [],
                                "countSpeciesUpdated": 592
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 592,
                                "plantationImages": [],
                                "countSpeciesUpdated": 592
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190
                            }
                        ],
                        "totalTreesPlanted": 1755
                    },
                    {
                        "date": "2024-09-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 144,
                                "plantationImages": [],
                                "countSpeciesUpdated": 144
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 144,
                                "plantationImages": [],
                                "countSpeciesUpdated": 144
                            }
                        ],
                        "totalTreesPlanted": 287
                    },
                    {
                        "date": "2024-09-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 181,
                                "plantationImages": [],
                                "countSpeciesUpdated": 181
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 181,
                                "plantationImages": [],
                                "countSpeciesUpdated": 181
                            }
                        ],
                        "totalTreesPlanted": 362
                    },
                    {
                        "date": "2024-09-10",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 173,
                                "plantationImages": [],
                                "countSpeciesUpdated": 173
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 173,
                                "plantationImages": [],
                                "countSpeciesUpdated": 173
                            }
                        ],
                        "totalTreesPlanted": 345
                    },
                    {
                        "date": "2024-09-11",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 193,
                                "plantationImages": [],
                                "countSpeciesUpdated": 193
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 193,
                                "plantationImages": [],
                                "countSpeciesUpdated": 193
                            }
                        ],
                        "totalTreesPlanted": 385
                    },
                    {
                        "date": "2024-09-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 165,
                                "plantationImages": [],
                                "countSpeciesUpdated": 165
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 165,
                                "plantationImages": [],
                                "countSpeciesUpdated": 165
                            }
                        ],
                        "totalTreesPlanted": 330
                    },
                    {
                        "date": "2024-09-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 175,
                                "plantationImages": [],
                                "countSpeciesUpdated": 175
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 175,
                                "plantationImages": [],
                                "countSpeciesUpdated": 175
                            }
                        ],
                        "totalTreesPlanted": 350
                    },
                    {
                        "date": "2024-09-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 178,
                                "plantationImages": [],
                                "countSpeciesUpdated": 178
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 178,
                                "plantationImages": [],
                                "countSpeciesUpdated": 178
                            }
                        ],
                        "totalTreesPlanted": 355
                    },
                    {
                        "date": "2024-09-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 138,
                                "plantationImages": [],
                                "countSpeciesUpdated": 138
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 138,
                                "plantationImages": [],
                                "countSpeciesUpdated": 138
                            }
                        ],
                        "totalTreesPlanted": 275
                    },
                    {
                        "date": "2024-09-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 155,
                                "plantationImages": [],
                                "countSpeciesUpdated": 155
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 155,
                                "plantationImages": [],
                                "countSpeciesUpdated": 155
                            }
                        ],
                        "totalTreesPlanted": 310
                    },
                    {
                        "date": "2024-09-19",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 58,
                                "plantationImages": [],
                                "countSpeciesUpdated": 58
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 58,
                                "plantationImages": [],
                                "countSpeciesUpdated": 58
                            }
                        ],
                        "totalTreesPlanted": 115
                    },
                    {
                        "date": "2024-10-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ],
                        "totalTreesPlanted": 250
                    },
                    {
                        "date": "2024-10-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190
                            }
                        ],
                        "totalTreesPlanted": 380
                    },
                    {
                        "date": "2024-10-24",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 238,
                                "plantationImages": [],
                                "countSpeciesUpdated": 238
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 185,
                                "plantationImages": [],
                                "countSpeciesUpdated": 185
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 53,
                                "plantationImages": [],
                                "countSpeciesUpdated": 53
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 53,
                                "plantationImages": [],
                                "countSpeciesUpdated": 53
                            }
                        ],
                        "totalTreesPlanted": 530
                    },
                    {
                        "date": "2024-10-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2024-10-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            }
                        ],
                        "totalTreesPlanted": 300
                    },
                    {
                        "date": "2024-10-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2024-10-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 383
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            }
                        ],
                        "totalTreesPlanted": 765
                    },
                    {
                        "date": "2024-10-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            }
                        ],
                        "totalTreesPlanted": 100
                    },
                    {
                        "date": "2024-10-26",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            }
                        ],
                        "totalTreesPlanted": 120
                    },
                    {
                        "date": "2024-10-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 382,
                                "plantationImages": [],
                                "countSpeciesUpdated": 382
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ],
                        "totalTreesPlanted": 482
                    },
                    {
                        "date": "2024-10-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 468,
                                "plantationImages": [],
                                "countSpeciesUpdated": 468
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ],
                        "totalTreesPlanted": 568
                    },
                    {
                        "date": "2024-10-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            }
                        ],
                        "totalTreesPlanted": 285
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-08-24",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-26",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 117,
                                "plantationImages": [],
                                "countSpeciesUpdated": 117,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 117,
                                "plantationImages": [],
                                "countSpeciesUpdated": 117,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 117,
                                "plantationImages": [],
                                "countSpeciesUpdated": 117,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-27",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 260,
                                "plantationImages": [],
                                "countSpeciesUpdated": 260,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 260,
                                "plantationImages": [],
                                "countSpeciesUpdated": 260,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 379,
                                "plantationImages": [],
                                "countSpeciesUpdated": 379,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 379,
                                "plantationImages": [],
                                "countSpeciesUpdated": 379,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-29",
                        "speciesUpdated": [
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1037,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1037,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-31",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 592,
                                "plantationImages": [],
                                "countSpeciesUpdated": 592,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 592,
                                "plantationImages": [],
                                "countSpeciesUpdated": 592,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-03",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 144,
                                "plantationImages": [],
                                "countSpeciesUpdated": 144,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 144,
                                "plantationImages": [],
                                "countSpeciesUpdated": 144,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 181,
                                "plantationImages": [],
                                "countSpeciesUpdated": 181,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 181,
                                "plantationImages": [],
                                "countSpeciesUpdated": 181,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-10",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 173,
                                "plantationImages": [],
                                "countSpeciesUpdated": 173,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 173,
                                "plantationImages": [],
                                "countSpeciesUpdated": 173,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-11",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 193,
                                "plantationImages": [],
                                "countSpeciesUpdated": 193,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 193,
                                "plantationImages": [],
                                "countSpeciesUpdated": 193,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-12",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 165,
                                "plantationImages": [],
                                "countSpeciesUpdated": 165,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 165,
                                "plantationImages": [],
                                "countSpeciesUpdated": 165,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-13",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 175,
                                "plantationImages": [],
                                "countSpeciesUpdated": 175,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 175,
                                "plantationImages": [],
                                "countSpeciesUpdated": 175,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 178,
                                "plantationImages": [],
                                "countSpeciesUpdated": 178,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 178,
                                "plantationImages": [],
                                "countSpeciesUpdated": 178,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 138,
                                "plantationImages": [],
                                "countSpeciesUpdated": 138,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 138,
                                "plantationImages": [],
                                "countSpeciesUpdated": 138,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-18",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 155,
                                "plantationImages": [],
                                "countSpeciesUpdated": 155,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 155,
                                "plantationImages": [],
                                "countSpeciesUpdated": 155,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-19",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 58,
                                "plantationImages": [],
                                "countSpeciesUpdated": 58,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 58,
                                "plantationImages": [],
                                "countSpeciesUpdated": 58,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-22",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-23",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-24",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 238,
                                "plantationImages": [],
                                "countSpeciesUpdated": 238,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 185,
                                "plantationImages": [],
                                "countSpeciesUpdated": 185,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 53,
                                "plantationImages": [],
                                "countSpeciesUpdated": 53,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 53,
                                "plantationImages": [],
                                "countSpeciesUpdated": 53,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-29",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 383,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-25",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-26",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-27",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 382,
                                "plantationImages": [],
                                "countSpeciesUpdated": 382,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 468,
                                "plantationImages": [],
                                "countSpeciesUpdated": 468,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Gohirakhala",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.617947",
                        "longitude": "85.535904"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 8096,
                        "totalTreesPlanned": 9147,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-24",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 3433,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3433
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 3816,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3816
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 197,
                                "plantationImages": [],
                                "countSpeciesUpdated": 197
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 477,
                                "plantationImages": [],
                                "countSpeciesUpdated": 477
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 181,
                                "plantationImages": [],
                                "countSpeciesUpdated": 181
                            }
                        ]
                    }
                },
                {
                    "name": "Dhenkanal Sadar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.645508",
                        "longitude": "85.581372"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1491,
                        "totalTreesPlanned": 1647,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 180,
                                "plantationImages": [],
                                "countSpeciesUpdated": 180
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 55,
                                "plantationImages": [],
                                "countSpeciesUpdated": 55
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 875,
                                "plantationImages": [],
                                "countSpeciesUpdated": 875
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 190,
                                "plantationImages": [],
                                "countSpeciesUpdated": 190
                            }
                        ]
                    }
                },
                {
                    "name": "Pandua",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "NA",
                        "longitude": "NA"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1159,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                },
                {
                    "name": "Raniakata",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.8859405",
                        "longitude": "84.444977"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 680,
                        "totalTreesPlanned": 892,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-24",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 193,
                                "plantationImages": [],
                                "countSpeciesUpdated": 193
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 193,
                                "plantationImages": [],
                                "countSpeciesUpdated": 193
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 226,
                                "plantationImages": [],
                                "countSpeciesUpdated": 226
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Baula",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/baula.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            }
                        ]
                    }
                },
                {
                    "name": "Bhubanpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.529807",
                        "longitude": "85.42858"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1500,
                        "totalTreesPlanned": 1659,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1126,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1126
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 376,
                                "plantationImages": [],
                                "countSpeciesUpdated": 376
                            }
                        ]
                    }
                }
            ]
        },
        "P11": {
            "name": "Cluster-XIX",
            "employmentGenerated": 0,
            "numberOfForestSites": 14,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 46290,
                "totalTreesPlanned": 46290,
                "uniqueSpeciesPlanted": 13,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 4032,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4032
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 6021,
                        "plantationImages": [],
                        "countSpeciesUpdated": 6021
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 6021,
                        "plantationImages": [],
                        "countSpeciesUpdated": 6021
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 1630,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1630
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 3619,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3619
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 3619,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3619
                    },
                    {
                        "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                        "count": 1630,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1630
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 3619,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3619
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 1630,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1630
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 3619,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3619
                    },
                    {
                        "speciesName": "Badam (Indian Almond,Jungli Badam)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                        "count": 3619,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3619
                    },
                    {
                        "speciesName": "Arjun (Terminalia arjuna)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                        "count": 1630,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1630
                    },
                    {
                        "speciesName": "Sal",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                        "count": 3619,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3619
                    },
                    {
                        "speciesName": "Bamboo",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                        "count": 1989,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1989
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-03-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            }
                        ],
                        "totalTreesPlanted": 1000
                    },
                    {
                        "date": "2023-02-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1166,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1166
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1166,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1166
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1166,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1166
                            }
                        ],
                        "totalTreesPlanted": 3500
                    },
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            }
                        ],
                        "totalTreesPlanted": 4000
                    },
                    {
                        "date": "2023-10-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ],
                        "totalTreesPlanted": 3000
                    },
                    {
                        "date": "2023-07-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            }
                        ],
                        "totalTreesPlanted": 400
                    },
                    {
                        "date": "2024-07-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ],
                        "totalTreesPlanted": 10000
                    },
                    {
                        "date": "2024-08-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 662,
                                "plantationImages": [],
                                "countSpeciesUpdated": 662
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 662,
                                "plantationImages": [],
                                "countSpeciesUpdated": 662
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 662,
                                "plantationImages": [],
                                "countSpeciesUpdated": 662
                            }
                        ],
                        "totalTreesPlanted": 12250
                    },
                    {
                        "date": "2024-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            }
                        ],
                        "totalTreesPlanted": 6350
                    },
                    {
                        "date": "2024-09-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            }
                        ],
                        "totalTreesPlanted": 2430
                    },
                    {
                        "date": "2024-08-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            }
                        ],
                        "totalTreesPlanted": 3160
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-03-31",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-02-02",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1166,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1166,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1166,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1166,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1166,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1166,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-10-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-07-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-29",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 1292,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1292,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 662,
                                "plantationImages": [],
                                "countSpeciesUpdated": 662,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 662,
                                "plantationImages": [],
                                "countSpeciesUpdated": 662,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 662,
                                "plantationImages": [],
                                "countSpeciesUpdated": 662,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-31",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Dadhibamanapur UPUG School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.4813",
                        "longitude": "86.2712"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-03-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            }
                        ]
                    }
                },
                {
                    "name": "Govt. Primary School, Biragobindapur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.1547",
                        "longitude": "86.0528"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-02-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ]
                    }
                },
                {
                    "name": "Anwesha Hostel",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.235957",
                        "longitude": "86.089742"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2500,
                        "totalTreesPlanned": 2500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            }
                        ]
                    }
                },
                {
                    "name": "Kalaragadia School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.262761",
                        "longitude": "86.091101"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            }
                        ]
                    }
                },
                {
                    "name": "Trilochana School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.258846",
                        "longitude": "86.109667"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4000,
                        "totalTreesPlanned": 4000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            }
                        ]
                    }
                },
                {
                    "name": "Bishnupriya Balasharm",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.1904",
                        "longitude": "86.2273"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3000,
                        "totalTreesPlanned": 3000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-10-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Murabali Store Yard",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.232227",
                        "longitude": "86.118654"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 400,
                        "totalTreesPlanned": 400,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-07-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            }
                        ]
                    }
                },
                {
                    "name": "Millsahi, Gohira (Forest land)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.232075",
                        "longitude": "86.087857"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 10000,
                        "totalTreesPlanned": 10000,
                        "uniqueSpeciesPlanted": 10,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-07-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Budhikud Forest",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.356147",
                        "longitude": "86.076749"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 6300,
                        "totalTreesPlanned": 6300,
                        "uniqueSpeciesPlanted": 10,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 630,
                                "plantationImages": [],
                                "countSpeciesUpdated": 630
                            }
                        ]
                    }
                },
                {
                    "name": "Gram Panchayat Ramchandrapur Sasan, Baliburei",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.238632",
                        "longitude": "86.329816"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 6350,
                        "totalTreesPlanned": 6350,
                        "uniqueSpeciesPlanted": 9,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 706
                            }
                        ]
                    }
                },
                {
                    "name": "Baiyababa Football Field, Sailang",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.222113",
                        "longitude": "86.084539"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3200,
                        "totalTreesPlanned": 3200,
                        "uniqueSpeciesPlanted": 9,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 356,
                                "plantationImages": [],
                                "countSpeciesUpdated": 356
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 356,
                                "plantationImages": [],
                                "countSpeciesUpdated": 356
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 356,
                                "plantationImages": [],
                                "countSpeciesUpdated": 356
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 356,
                                "plantationImages": [],
                                "countSpeciesUpdated": 356
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 356,
                                "plantationImages": [],
                                "countSpeciesUpdated": 356
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 356,
                                "plantationImages": [],
                                "countSpeciesUpdated": 356
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 356,
                                "plantationImages": [],
                                "countSpeciesUpdated": 356
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 356,
                                "plantationImages": [],
                                "countSpeciesUpdated": 356
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 356,
                                "plantationImages": [],
                                "countSpeciesUpdated": 356
                            }
                        ]
                    }
                },
                {
                    "name": "Sailong Individual land",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.227104",
                        "longitude": "86.0980029"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2750,
                        "totalTreesPlanned": 2750,
                        "uniqueSpeciesPlanted": 9,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 306,
                                "plantationImages": [],
                                "countSpeciesUpdated": 306
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 306,
                                "plantationImages": [],
                                "countSpeciesUpdated": 306
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 306,
                                "plantationImages": [],
                                "countSpeciesUpdated": 306
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 306,
                                "plantationImages": [],
                                "countSpeciesUpdated": 306
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 306,
                                "plantationImages": [],
                                "countSpeciesUpdated": 306
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 306,
                                "plantationImages": [],
                                "countSpeciesUpdated": 306
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 306,
                                "plantationImages": [],
                                "countSpeciesUpdated": 306
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 306,
                                "plantationImages": [],
                                "countSpeciesUpdated": 306
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 306,
                                "plantationImages": [],
                                "countSpeciesUpdated": 306
                            }
                        ]
                    }
                },
                {
                    "name": "Barigaon UGME School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.184838",
                        "longitude": "86.12031"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2430,
                        "totalTreesPlanned": 2430,
                        "uniqueSpeciesPlanted": 9,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 270,
                                "plantationImages": [],
                                "countSpeciesUpdated": 270
                            }
                        ]
                    }
                },
                {
                    "name": "Jhadakundi Individual land",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.206005",
                        "longitude": "86.076264"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3160,
                        "totalTreesPlanned": 3160,
                        "uniqueSpeciesPlanted": 9,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            },
                            {
                                "speciesName": "Sal",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sal.png",
                                "count": 351,
                                "plantationImages": [],
                                "countSpeciesUpdated": 351
                            }
                        ]
                    }
                }
            ]
        },
        "P12": {
            "name": "MLIP Cluster XX",
            "employmentGenerated": 0,
            "numberOfForestSites": 6,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 22250,
                "totalTreesPlanned": 23250,
                "uniqueSpeciesPlanted": 12,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 38,
                        "plantationImages": [],
                        "countSpeciesUpdated": 38
                    },
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 2480,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2480
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 38,
                        "plantationImages": [],
                        "countSpeciesUpdated": 38
                    },
                    {
                        "speciesName": "Pomegranate",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                        "count": 13,
                        "plantationImages": [],
                        "countSpeciesUpdated": 13
                    },
                    {
                        "speciesName": "Indian Medlar",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/indian-medlar.png",
                        "count": 13,
                        "plantationImages": [],
                        "countSpeciesUpdated": 13
                    },
                    {
                        "speciesName": "Akashi (Acacia)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                        "count": 4167,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4167
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 4275,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4275
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 3500,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3500
                    },
                    {
                        "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                        "count": 4275,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4275
                    },
                    {
                        "speciesName": "Krishnachuda",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/krishnachuda.png",
                        "count": 667,
                        "plantationImages": [],
                        "countSpeciesUpdated": 667
                    },
                    {
                        "speciesName": "Atta Tree",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/atta-tree.png",
                        "count": 775,
                        "plantationImages": [],
                        "countSpeciesUpdated": 775
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 25,
                        "plantationImages": [],
                        "countSpeciesUpdated": 25
                    },
                    {
                        "speciesName": "Debdaru",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                        "count": 2000,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2000
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-03-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Indian Medlar",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/indian-medlar.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            }
                        ],
                        "totalTreesPlanted": 50
                    },
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ],
                        "totalTreesPlanted": 5000
                    },
                    {
                        "date": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Krishnachuda",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/krishnachuda.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Atta Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/atta-tree.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775
                            }
                        ],
                        "totalTreesPlanted": 3100
                    },
                    {
                        "date": "2023-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ],
                        "totalTreesPlanted": 100
                    },
                    {
                        "date": "2024-03-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Debdaru",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2024-09-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            }
                        ],
                        "totalTreesPlanted": 3333
                    },
                    {
                        "date": "2024-09-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833
                            }
                        ],
                        "totalTreesPlanted": 3333
                    },
                    {
                        "date": "2024-09-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 834,
                                "plantationImages": [],
                                "countSpeciesUpdated": 834
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 834,
                                "plantationImages": [],
                                "countSpeciesUpdated": 834
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 834,
                                "plantationImages": [],
                                "countSpeciesUpdated": 834
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 834,
                                "plantationImages": [],
                                "countSpeciesUpdated": 834
                            }
                        ],
                        "totalTreesPlanted": 3334
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-03-21",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Indian Medlar",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/indian-medlar.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Krishnachuda",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/krishnachuda.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Atta Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/atta-tree.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-06-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-03-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Debdaru",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-03",
                        "speciesUpdated": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 833,
                                "plantationImages": [],
                                "countSpeciesUpdated": 833,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 834,
                                "plantationImages": [],
                                "countSpeciesUpdated": 834,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 834,
                                "plantationImages": [],
                                "countSpeciesUpdated": 834,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 834,
                                "plantationImages": [],
                                "countSpeciesUpdated": 834,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 834,
                                "plantationImages": [],
                                "countSpeciesUpdated": 834,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Panchayat Upper primary school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.705",
                        "longitude": "80.7161"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-03-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Indian Medlar",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/indian-medlar.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            }
                        ]
                    }
                },
                {
                    "name": "Dharampur, Bhinjharpur veterinary, Jajpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.6881800,\n20.72686672",
                        "longitude": "86.4564650,\n 86.4039955"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 5000,
                        "totalTreesPlanned": 5000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Rautra High School, Jajpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.2183",
                        "longitude": "86.6478"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2000,
                        "totalTreesPlanned": 2000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Krishnachuda",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/krishnachuda.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            }
                        ]
                    }
                },
                {
                    "name": "Dharampur, Bhinjharpur veterinary, Jajpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.714299",
                        "longitude": "86.434093"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3100,
                        "totalTreesPlanned": 3100,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Atta Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/atta-tree.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 775,
                                "plantationImages": [],
                                "countSpeciesUpdated": 775
                            }
                        ]
                    }
                },
                {
                    "name": "Bagamara Store Yard, Jajpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.6908",
                        "longitude": "80.4403"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                },
                {
                    "name": "Balamukuli, Jajpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.708051",
                        "longitude": "86.446898"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2000,
                        "totalTreesPlanned": 2000,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-03-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Debdaru",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/debdaru.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000
                            }
                        ]
                    }
                },
                {
                    "name": "AUL & Salianda",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "20.666335,\n 20.64965",
                        "longitude": "86.598422, \n86.62906"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 10000,
                        "totalTreesPlanned": 10096,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 2500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2500
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 2500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2500
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 2500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2500
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 2500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2500
                            }
                        ]
                    }
                }
            ]
        },
        "P13": {
            "name": "MLIP CLUSTER XXV",
            "employmentGenerated": 0,
            "numberOfForestSites": 11,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 31030,
                "totalTreesPlanned": 31030,
                "uniqueSpeciesPlanted": 3,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 10813,
                        "plantationImages": [],
                        "countSpeciesUpdated": 10813
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 10813,
                        "plantationImages": [],
                        "countSpeciesUpdated": 10813
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 9403,
                        "plantationImages": [],
                        "countSpeciesUpdated": 9403
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-08-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 1410,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1410
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1410,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1410
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-08-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-08-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-08-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-09-06",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-09-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-09-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-09-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-10-08",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-10-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-10-24",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 943,
                                "plantationImages": [],
                                "countSpeciesUpdated": 943
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 943,
                                "plantationImages": [],
                                "countSpeciesUpdated": 943
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 943,
                                "plantationImages": [],
                                "countSpeciesUpdated": 943
                            }
                        ],
                        "totalTreesPlanted": 2830
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-08-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 1410,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1410,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1410,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1410,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-13",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-21",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-29",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-06",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-22",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-08",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-24",
                        "speciesUpdated": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 943,
                                "plantationImages": [],
                                "countSpeciesUpdated": 943,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 943,
                                "plantationImages": [],
                                "countSpeciesUpdated": 943,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 943,
                                "plantationImages": [],
                                "countSpeciesUpdated": 943,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Manchagram",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.240024",
                        "longitude": "82.777831"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 1410,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1410
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1410,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1410
                            }
                        ]
                    }
                },
                {
                    "name": "Phupugram",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.236652",
                        "longitude": "82.765924"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ]
                    }
                },
                {
                    "name": "Pujariguda high school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.3002",
                        "longitude": "82.690036"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ]
                    }
                },
                {
                    "name": "Dahana",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.22445",
                        "longitude": "82.677367"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ]
                    }
                },
                {
                    "name": "Agnipur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.237419",
                        "longitude": "82.495343"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-06",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ]
                    }
                },
                {
                    "name": "Koraput",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.137866",
                        "longitude": "82.338625"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ]
                    }
                },
                {
                    "name": "Khatiguda",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.240764",
                        "longitude": "82.793187"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ]
                    }
                },
                {
                    "name": "Mengra",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.346942",
                        "longitude": "82.675948"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ]
                    }
                },
                {
                    "name": "Kumakote",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.297183",
                        "longitude": "82.715093"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-08",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ]
                    }
                },
                {
                    "name": "Kunjaniguda",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.137866",
                        "longitude": "82.338625"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 940,
                                "plantationImages": [],
                                "countSpeciesUpdated": 940
                            }
                        ]
                    }
                },
                {
                    "name": "Nabarangapur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.27023",
                        "longitude": "82.651552"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2830,
                        "totalTreesPlanned": 2830,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-24",
                        "speciesPlanted": [
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 943,
                                "plantationImages": [],
                                "countSpeciesUpdated": 943
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 943,
                                "plantationImages": [],
                                "countSpeciesUpdated": 943
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 943,
                                "plantationImages": [],
                                "countSpeciesUpdated": 943
                            }
                        ]
                    }
                }
            ]
        },
        "P14": {
            "name": "Makeup water & Ash Disposal system, NTPC Talcher",
            "employmentGenerated": 0,
            "numberOfForestSites": 1,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 20,
                "totalTreesPlanned": 5020,
                "uniqueSpeciesPlanted": 3,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 7,
                        "plantationImages": [],
                        "countSpeciesUpdated": 7
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 7,
                        "plantationImages": [],
                        "countSpeciesUpdated": 7
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 7,
                        "plantationImages": [],
                        "countSpeciesUpdated": 7
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            }
                        ],
                        "totalTreesPlanted": 20
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-08-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Sunakhani Store yard",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "21.034377",
                        "longitude": "85.16209"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 20,
                        "totalTreesPlanned": 20,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            }
                        ]
                    }
                }
            ]
        },
        "P15": {
            "name": "Bandra WwTF",
            "employmentGenerated": 0,
            "numberOfForestSites": 23,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 111000,
                "totalTreesPlanned": 111000,
                "uniqueSpeciesPlanted": 9,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Snake Plant",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/snake-plant.png",
                        "count": 1333,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1333
                    },
                    {
                        "speciesName": "Palm Tree",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/palm-tree.png",
                        "count": 1333,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1333
                    },
                    {
                        "speciesName": "Golden Duranta",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/golden-duranta.png",
                        "count": 1333,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1333
                    },
                    {
                        "speciesName": "Bamboo",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                        "count": 25000,
                        "plantationImages": [],
                        "countSpeciesUpdated": 25000
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 41500,
                        "plantationImages": [],
                        "countSpeciesUpdated": 41500
                    },
                    {
                        "speciesName": "Sitafal (Custard Apple)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                        "count": 15500,
                        "plantationImages": [],
                        "countSpeciesUpdated": 15500
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 8333,
                        "plantationImages": [],
                        "countSpeciesUpdated": 8333
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 8333,
                        "plantationImages": [],
                        "countSpeciesUpdated": 8333
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 8333,
                        "plantationImages": [],
                        "countSpeciesUpdated": 8333
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2022-12-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Snake Plant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/snake-plant.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Palm Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/palm-tree.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Golden Duranta",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/golden-duranta.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            }
                        ],
                        "totalTreesPlanted": 4000
                    },
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 25000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25000
                            }
                        ],
                        "totalTreesPlanted": 25000
                    },
                    {
                        "date": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13500
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 13500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13500
                            }
                        ],
                        "totalTreesPlanted": 27000
                    },
                    {
                        "date": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 8333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8333
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 8333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8333
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 8333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8333
                            }
                        ],
                        "totalTreesPlanted": 25000
                    },
                    {
                        "date": "2024-08-08",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000
                            }
                        ],
                        "totalTreesPlanted": 4000
                    },
                    {
                        "date": "2024-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13610,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13610
                            }
                        ],
                        "totalTreesPlanted": 13610
                    },
                    {
                        "date": "2024-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 7350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7350
                            }
                        ],
                        "totalTreesPlanted": 7350
                    },
                    {
                        "date": "2024-08-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 5040,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5040
                            }
                        ],
                        "totalTreesPlanted": 5040
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2022-12-03",
                        "speciesUpdated": [
                            {
                                "speciesName": "Snake Plant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/snake-plant.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Palm Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/palm-tree.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Golden Duranta",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/golden-duranta.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 25000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 13500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 8333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 8333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 8333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-08",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-12",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13610,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13610,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 7350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7350,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-23",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 5040,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5040,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Bandra WwTF",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.045475",
                        "longitude": "72.826869"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4000,
                        "totalTreesPlanned": 4000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2022-12-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Snake Plant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/snake-plant.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Palm Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/palm-tree.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Golden Duranta",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/golden-duranta.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            }
                        ]
                    }
                },
                {
                    "name": "Mumbai Port",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.008864",
                        "longitude": "72.855408"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 25000,
                        "totalTreesPlanned": 25000,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 25000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25000
                            }
                        ]
                    }
                },
                {
                    "name": "Indapur & Saswad Pune",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "18.39099909",
                        "longitude": "73.95100839"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 27000,
                        "totalTreesPlanned": 27000,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13500
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 13500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13500
                            }
                        ]
                    }
                },
                {
                    "name": "Vashala Maharashtra",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "19.595494",
                        "longitude": "73.481025"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 25000,
                        "totalTreesPlanned": 25000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 8333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8333
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 8333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8333
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 8333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8333
                            }
                        ]
                    }
                },
                {
                    "name": "Balipudi Tq- Indapur Dist -PUNE",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "18.1759",
                        "longitude": "74.9384"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 15710,
                        "totalTreesPlanned": 15710,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-08",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 2000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2000
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13710,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13710
                            }
                        ]
                    }
                },
                {
                    "name": "Varkute Budruk Tq- Indapur Dist -PUNE",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "18.4669",
                        "longitude": "73.8265"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1500,
                        "totalTreesPlanned": 1500,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1500
                            }
                        ]
                    }
                },
                {
                    "name": "Bijwadi Tq- Indapur Dist -PUNE",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "18.1458",
                        "longitude": "74.9589"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Shelgaon Tq- Indapur Dist -PUNE",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "18.1046",
                        "longitude": "74.8419"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 7490,
                        "totalTreesPlanned": 7490,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 7490,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7490
                            }
                        ]
                    }
                },
                {
                    "name": "Takali bhigwani Dist -PUNE",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "18.159",
                        "longitude": "74.9659"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4300,
                        "totalTreesPlanned": 4300,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 4300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4300
                            }
                        ]
                    }
                }
            ]
        },
        "P16": {
            "name": "Fazilka & Ferozepur WSS",
            "employmentGenerated": 0,
            "numberOfForestSites": 6,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 27650,
                "totalTreesPlanned": 30000,
                "uniqueSpeciesPlanted": 10,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                        "count": 4801,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4801
                    },
                    {
                        "speciesName": "Arjun (Terminalia arjuna)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                        "count": 5322,
                        "plantationImages": [],
                        "countSpeciesUpdated": 5322
                    },
                    {
                        "speciesName": "Sapetha",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                        "count": 4801,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4801
                    },
                    {
                        "speciesName": "Sukhchain",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                        "count": 4801,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4801
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 5322,
                        "plantationImages": [],
                        "countSpeciesUpdated": 5322
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 521,
                        "plantationImages": [],
                        "countSpeciesUpdated": 521
                    },
                    {
                        "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                        "count": 521,
                        "plantationImages": [],
                        "countSpeciesUpdated": 521
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 521,
                        "plantationImages": [],
                        "countSpeciesUpdated": 521
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 521,
                        "plantationImages": [],
                        "countSpeciesUpdated": 521
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 521,
                        "plantationImages": [],
                        "countSpeciesUpdated": 521
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2022-12-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            }
                        ],
                        "totalTreesPlanted": 1333
                    },
                    {
                        "date": "2022-12-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            }
                        ],
                        "totalTreesPlanted": 1333
                    },
                    {
                        "date": "2022-12-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            }
                        ],
                        "totalTreesPlanted": 1334
                    },
                    {
                        "date": "2023-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800
                            }
                        ],
                        "totalTreesPlanted": 9000
                    },
                    {
                        "date": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200
                            }
                        ],
                        "totalTreesPlanted": 11000
                    },
                    {
                        "date": "2024-04-20",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            }
                        ],
                        "totalTreesPlanted": 912
                    },
                    {
                        "date": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            }
                        ],
                        "totalTreesPlanted": 912
                    },
                    {
                        "date": "2024-09-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            }
                        ],
                        "totalTreesPlanted": 912
                    },
                    {
                        "date": "2024-09-09",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131
                            }
                        ],
                        "totalTreesPlanted": 914
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2022-12-21",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2022-12-29",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2022-12-31",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1800,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 2200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-04-20",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-09",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Sohangarh Primary school, Panchayat land, Grain mandi, Near canal, Dumping yard",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "30.601518\n 30.407040\n 30.4039\n 30.612995\n 30.613388",
                        "longitude": "74.39223\n 74.211871\n 74.1952\n 74.392879\n 74.388293"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4000,
                        "totalTreesPlanned": 4000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2022-12-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 801,
                                "plantationImages": [],
                                "countSpeciesUpdated": 801
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 801,
                                "plantationImages": [],
                                "countSpeciesUpdated": 801
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 801,
                                "plantationImages": [],
                                "countSpeciesUpdated": 801
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 801,
                                "plantationImages": [],
                                "countSpeciesUpdated": 801
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 801,
                                "plantationImages": [],
                                "countSpeciesUpdated": 801
                            }
                        ]
                    }
                },
                {
                    "name": "Ganga kalan village and its surroundings",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "30.573",
                        "longitude": "74.3411"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 6000,
                        "totalTreesPlanned": 6000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1200
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 1200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1200
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 1200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1200
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 1200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1200
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1200
                            }
                        ]
                    }
                },
                {
                    "name": "Chak saido ke village and its surroundings",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "30.5777",
                        "longitude": "74.3219"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3000,
                        "totalTreesPlanned": 3000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            }
                        ]
                    }
                },
                {
                    "name": "Roranwala Village and its surroundings",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "30.57439",
                        "longitude": "74.395"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4000,
                        "totalTreesPlanned": 4000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 800
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 800
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 800
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 800
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 800,
                                "plantationImages": [],
                                "countSpeciesUpdated": 800
                            }
                        ]
                    }
                },
                {
                    "name": "Sohangarg Village and its surroundings",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "30.6015",
                        "longitude": "74.392"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 7000,
                        "totalTreesPlanned": 7000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1400
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 1400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1400
                            },
                            {
                                "speciesName": "Sapetha",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sapetha.png",
                                "count": 1400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1400
                            },
                            {
                                "speciesName": "Sukhchain",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sukhchain.png",
                                "count": 1400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1400
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1400
                            }
                        ]
                    }
                },
                {
                    "name": "Sohangarh, Chak manthan wala, Gattianwala bodla, Gattianwala jattan,Jhandwala \n Hadiwala etc",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "30.6015\n 30.613905\n 30.651628\n 30.403118\n 30.437484\n 30.635291",
                        "longitude": "74.392\n 74.394661\n 74.353274\n 74.207396\n 74.217135\n 74.403752"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3650,
                        "totalTreesPlanned": 6000,
                        "uniqueSpeciesPlanted": 7,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-04-20",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 521,
                                "plantationImages": [],
                                "countSpeciesUpdated": 521
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 521,
                                "plantationImages": [],
                                "countSpeciesUpdated": 521
                            },
                            {
                                "speciesName": "Kadamba (Kadam, Neolamarckia cadamba)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kadamba-kadam-neolamarckia-cadamba.png",
                                "count": 521,
                                "plantationImages": [],
                                "countSpeciesUpdated": 521
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 521,
                                "plantationImages": [],
                                "countSpeciesUpdated": 521
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 521,
                                "plantationImages": [],
                                "countSpeciesUpdated": 521
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 521,
                                "plantationImages": [],
                                "countSpeciesUpdated": 521
                            },
                            {
                                "speciesName": "Arjun (Terminalia arjuna)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/arjun-terminalia-arjuna.png",
                                "count": 521,
                                "plantationImages": [],
                                "countSpeciesUpdated": 521
                            }
                        ]
                    }
                }
            ]
        },
        "P17": {
            "name": "Mandya RWS",
            "employmentGenerated": 0,
            "numberOfForestSites": 8,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 9620,
                "totalTreesPlanned": 17620,
                "uniqueSpeciesPlanted": 6,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 623,
                        "plantationImages": [],
                        "countSpeciesUpdated": 623
                    },
                    {
                        "speciesName": "Imli (Tamarind)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                        "count": 2406,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2406
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 623,
                        "plantationImages": [],
                        "countSpeciesUpdated": 623
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 2406,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2406
                    },
                    {
                        "speciesName": "Akashi (Acacia)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                        "count": 1783,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1783
                    },
                    {
                        "speciesName": "Eucalyptus",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                        "count": 1783,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1783
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275
                            }
                        ],
                        "totalTreesPlanted": 1100
                    },
                    {
                        "date": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1706
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 1483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1483
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1483
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 1706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1706
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 223,
                                "plantationImages": [],
                                "countSpeciesUpdated": 223
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 223,
                                "plantationImages": [],
                                "countSpeciesUpdated": 223
                            }
                        ],
                        "totalTreesPlanted": 6820
                    },
                    {
                        "date": "2023-08-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 425,
                                "plantationImages": [],
                                "countSpeciesUpdated": 425
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 425,
                                "plantationImages": [],
                                "countSpeciesUpdated": 425
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ],
                        "totalTreesPlanted": 1700
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 1483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1483,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1483,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 1706,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1706,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 223,
                                "plantationImages": [],
                                "countSpeciesUpdated": 223,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 223,
                                "plantationImages": [],
                                "countSpeciesUpdated": 223,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-18",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 425,
                                "plantationImages": [],
                                "countSpeciesUpdated": 425,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 425,
                                "plantationImages": [],
                                "countSpeciesUpdated": 425,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "kitturani Chennamma school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "12.66682",
                        "longitude": "76.457225"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1100,
                        "totalTreesPlanned": 1100,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 275,
                                "plantationImages": [],
                                "countSpeciesUpdated": 275
                            }
                        ]
                    }
                },
                {
                    "name": "KR Pete Forest area",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "12.683163",
                        "longitude": "76.457295"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 5930,
                        "totalTreesPlanned": 5930,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1483
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 1483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1483
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1483
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 1483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1483
                            }
                        ]
                    }
                },
                {
                    "name": "Betrayana koppulu forest area (NML)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "12.824519",
                        "longitude": "76.701944"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1200,
                        "totalTreesPlanned": 1200,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Akashi (Acacia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/akashi-acacia.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            }
                        ]
                    }
                },
                {
                    "name": "Moraji Desai Residential School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "12.719456",
                        "longitude": "76.689979"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Brahmadevarahalli school NML",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "12.7192",
                        "longitude": "76.6907"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                },
                {
                    "name": "BGS Ground NML",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "12.7530653",
                        "longitude": "76.7389541"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 340,
                        "totalTreesPlanned": 340,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 85,
                                "plantationImages": [],
                                "countSpeciesUpdated": 85
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 85,
                                "plantationImages": [],
                                "countSpeciesUpdated": 85
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 85,
                                "plantationImages": [],
                                "countSpeciesUpdated": 85
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 85,
                                "plantationImages": [],
                                "countSpeciesUpdated": 85
                            }
                        ]
                    }
                },
                {
                    "name": "Brilliant Valley School NML",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "12.805164",
                        "longitude": "76.785498"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 350,
                        "totalTreesPlanned": 350,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            }
                        ]
                    }
                }
            ]
        },
        "P18": {
            "name": "Dharwad RWS",
            "employmentGenerated": 0,
            "numberOfForestSites": 39,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 35100,
                "totalTreesPlanned": 45100,
                "uniqueSpeciesPlanted": 9,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 7826,
                        "plantationImages": [],
                        "countSpeciesUpdated": 7826
                    },
                    {
                        "speciesName": "Chikku (Sapota)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                        "count": 10315,
                        "plantationImages": [],
                        "countSpeciesUpdated": 10315
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 10415,
                        "plantationImages": [],
                        "countSpeciesUpdated": 10415
                    },
                    {
                        "speciesName": "Kaju (Cashew)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                        "count": 1917,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1917
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 1463,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1463
                    },
                    {
                        "speciesName": "Rose, Jasmine",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                        "count": 847,
                        "plantationImages": [],
                        "countSpeciesUpdated": 847
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 100,
                        "plantationImages": [],
                        "countSpeciesUpdated": 100
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2022-10-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 517,
                                "plantationImages": [],
                                "countSpeciesUpdated": 517
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 517,
                                "plantationImages": [],
                                "countSpeciesUpdated": 517
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 517,
                                "plantationImages": [],
                                "countSpeciesUpdated": 517
                            }
                        ],
                        "totalTreesPlanted": 1550
                    },
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 3483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3483
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 4147,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4147
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 4147,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4147
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 367,
                                "plantationImages": [],
                                "countSpeciesUpdated": 367
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 622,
                                "plantationImages": [],
                                "countSpeciesUpdated": 622
                            }
                        ],
                        "totalTreesPlanted": 15050
                    },
                    {
                        "date": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 3310,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3310
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 3310,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3310
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 525,
                                "plantationImages": [],
                                "countSpeciesUpdated": 525
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 225,
                                "plantationImages": [],
                                "countSpeciesUpdated": 225
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 2868,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2868
                            }
                        ],
                        "totalTreesPlanted": 10400
                    },
                    {
                        "date": "2023-07-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 1383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1383
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1383
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 1383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1383
                            }
                        ],
                        "totalTreesPlanted": 4150
                    },
                    {
                        "date": "2023-10-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            }
                        ],
                        "totalTreesPlanted": 250
                    },
                    {
                        "date": "2024-06-07",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2024-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            }
                        ],
                        "totalTreesPlanted": 3000
                    },
                    {
                        "date": "2024-09-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ],
                        "totalTreesPlanted": 500
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2022-10-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 517,
                                "plantationImages": [],
                                "countSpeciesUpdated": 517,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 517,
                                "plantationImages": [],
                                "countSpeciesUpdated": 517,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 517,
                                "plantationImages": [],
                                "countSpeciesUpdated": 517,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 3483,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3483,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 4147,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4147,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 4147,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4147,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 367,
                                "plantationImages": [],
                                "countSpeciesUpdated": 367,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 622,
                                "plantationImages": [],
                                "countSpeciesUpdated": 622,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 3310,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3310,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 3310,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3310,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 525,
                                "plantationImages": [],
                                "countSpeciesUpdated": 525,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 225,
                                "plantationImages": [],
                                "countSpeciesUpdated": 225,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 2868,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2868,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-07-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 1383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1383,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1383,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 1383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1383,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-10-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-06-07",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Yarikoppa",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.398",
                        "longitude": "75.0011"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1850,
                        "totalTreesPlanned": 2106,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2022-10-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 617,
                                "plantationImages": [],
                                "countSpeciesUpdated": 617
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 617,
                                "plantationImages": [],
                                "countSpeciesUpdated": 617
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 617,
                                "plantationImages": [],
                                "countSpeciesUpdated": 617
                            }
                        ]
                    }
                },
                {
                    "name": "Timmapur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.544475",
                        "longitude": "75.045794"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 8600,
                        "totalTreesPlanned": 8600,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 2150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2150
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 2150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2150
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 2150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2150
                            }
                        ]
                    }
                },
                {
                    "name": "Rottigwad GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.315",
                        "longitude": "75.372"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "Hiregujal GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.2355",
                        "longitude": "75.3942"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "Chaklabbi GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.2672",
                        "longitude": "75.3405"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                },
                {
                    "name": "Benachi GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4193557",
                        "longitude": "74.75132718"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            }
                        ]
                    }
                },
                {
                    "name": "Honnapur GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.44174551",
                        "longitude": "74.84758723"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Aravatagi GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.44953629",
                        "longitude": "74.81386286"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ]
                    }
                },
                {
                    "name": "Malali GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.14111644",
                        "longitude": "75.17376948"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                },
                {
                    "name": "Narendra GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.50926467",
                        "longitude": "74.97733445"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Garag GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.57172845",
                        "longitude": "74.92972468"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Mugad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.44463164",
                        "longitude": "74.90919513"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Kamdolli",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.20483748",
                        "longitude": "75.25094263"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "Gudengatti GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.31414696",
                        "longitude": "75.259974"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "Heballi GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4864025",
                        "longitude": "75.1292504"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "Lokur GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.60038959",
                        "longitude": "74.97148687"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Uppinabetageri GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.61157355",
                        "longitude": "75.0066279"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Tegur GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.54652219",
                        "longitude": "74.83700627"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 400,
                        "totalTreesPlanned": 400,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            }
                        ]
                    }
                },
                {
                    "name": "Yadavad GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.56037351",
                        "longitude": "74.99318132"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Yeraguppi GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.30963175",
                        "longitude": "75.31156493"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "Hangarki GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.5954037",
                        "longitude": "74.92756107"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Karadigudda GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.56445924",
                        "longitude": "75.03996781"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            }
                        ]
                    }
                },
                {
                    "name": "Kotur GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.53992927",
                        "longitude": "74.90543809"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Govt school,Amminabhavi",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.523",
                        "longitude": "75.142"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4150,
                        "totalTreesPlanned": 4150,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-07-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 1383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1383
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1383
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 1383,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1383
                            }
                        ]
                    }
                },
                {
                    "name": "Tavarageri Govt School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.2054",
                        "longitude": "74.948"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-10-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            }
                        ]
                    }
                },
                {
                    "name": "Kyarkoppa",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.15256",
                        "longitude": "74.961"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 400,
                        "totalTreesPlanned": 400,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 133,
                                "plantationImages": [],
                                "countSpeciesUpdated": 133
                            }
                        ]
                    }
                },
                {
                    "name": "Mammigati GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.50897106",
                        "longitude": "74.94367142"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Saundatti GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.75698884",
                        "longitude": "75.12570026"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 800,
                        "totalTreesPlanned": 800,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            }
                        ]
                    }
                },
                {
                    "name": "Kurbagatti GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.54239261",
                        "longitude": "74.96367843"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1100,
                        "totalTreesPlanned": 1100,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 367,
                                "plantationImages": [],
                                "countSpeciesUpdated": 367
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 367,
                                "plantationImages": [],
                                "countSpeciesUpdated": 367
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 367,
                                "plantationImages": [],
                                "countSpeciesUpdated": 367
                            }
                        ]
                    }
                },
                {
                    "name": "Hallikeri GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35261785",
                        "longitude": "74.91565508"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "Nigadi GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.39554746",
                        "longitude": "74.9440014"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                },
                {
                    "name": "Mansur GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.40574949",
                        "longitude": "74.97300764"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                },
                {
                    "name": "Managundi GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.38656844",
                        "longitude": "74.97353697"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            }
                        ]
                    }
                },
                {
                    "name": "Devara Hubbali GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.39888243",
                        "longitude": "74.92510928"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Kaju (Cashew)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaju-cashew.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                },
                {
                    "name": "Tadakoda GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.6072705",
                        "longitude": "74.90798751"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4000,
                        "totalTreesPlanned": 4000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1333
                            }
                        ]
                    }
                },
                {
                    "name": "Savadatti",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.752",
                        "longitude": "75.1253"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-06-07",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Kalghatagi Taluk",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.18",
                        "longitude": "74.97"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3000,
                        "totalTreesPlanned": 3000,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            }
                        ]
                    }
                },
                {
                    "name": "Pudakalkatti",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.44",
                        "longitude": "74.996"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Chikku (Sapota)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/chikku-sapota.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                }
            ]
        },
        "P19": {
            "name": "Sitapur Tubewell Scheme Pkg 5",
            "employmentGenerated": 0,
            "numberOfForestSites": 9,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 16128,
                "totalTreesPlanned": 16128,
                "uniqueSpeciesPlanted": 12,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Eucalyptus",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                        "count": 2740,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2740
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 3532,
                        "plantationImages": [],
                        "countSpeciesUpdated": 3532
                    },
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 2909,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2909
                    },
                    {
                        "speciesName": "Litchi",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/litchi.png",
                        "count": 564,
                        "plantationImages": [],
                        "countSpeciesUpdated": 564
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 1693,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1693
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 1044,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1044
                    },
                    {
                        "speciesName": "Blueberry",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/blueberry.png",
                        "count": 564,
                        "plantationImages": [],
                        "countSpeciesUpdated": 564
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 480,
                        "plantationImages": [],
                        "countSpeciesUpdated": 480
                    },
                    {
                        "speciesName": "Bengal Currant",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                        "count": 649,
                        "plantationImages": [],
                        "countSpeciesUpdated": 649
                    },
                    {
                        "speciesName": "Bel (Belpatra, Wood Apple)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                        "count": 649,
                        "plantationImages": [],
                        "countSpeciesUpdated": 649
                    },
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 649,
                        "plantationImages": [],
                        "countSpeciesUpdated": 649
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 649,
                        "plantationImages": [],
                        "countSpeciesUpdated": 649
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1593,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1593
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1593,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1593
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 1593,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1593
                            }
                        ],
                        "totalTreesPlanted": 4780
                    },
                    {
                        "date": "2024-08-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2024-08-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Litchi",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/litchi.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            },
                            {
                                "speciesName": "Blueberry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/blueberry.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            }
                        ],
                        "totalTreesPlanted": 2820
                    },
                    {
                        "date": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            }
                        ],
                        "totalTreesPlanted": 2400
                    },
                    {
                        "date": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 228,
                                "plantationImages": [],
                                "countSpeciesUpdated": 228
                            }
                        ],
                        "totalTreesPlanted": 228
                    },
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Bengal Currant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            }
                        ],
                        "totalTreesPlanted": 3500
                    },
                    {
                        "date": "2023-09-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Bengal Currant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2024-01-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Bengal Currant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            }
                        ],
                        "totalTreesPlanted": 200
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1593,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1593,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1593,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1593,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 1593,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1593,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-31",
                        "speciesUpdated": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-22",
                        "speciesUpdated": [
                            {
                                "speciesName": "Litchi",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/litchi.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Blueberry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/blueberry.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-06-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 228,
                                "plantationImages": [],
                                "countSpeciesUpdated": 228,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bengal Currant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-09-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bengal Currant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-01-31",
                        "speciesUpdated": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bengal Currant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Rewari, Sitapur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.296442",
                        "longitude": "80.810488"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 6780,
                        "totalTreesPlanned": 6780,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 2260,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2260
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 2260,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2260
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 2260,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2260
                            }
                        ]
                    }
                },
                {
                    "name": "Chander Secondary school, Rasoolpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.53761",
                        "longitude": "81.157941"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2820,
                        "totalTreesPlanned": 2820,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-22",
                        "speciesPlanted": [
                            {
                                "speciesName": "Litchi",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/litchi.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            },
                            {
                                "speciesName": "Blueberry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/blueberry.png",
                                "count": 564,
                                "plantationImages": [],
                                "countSpeciesUpdated": 564
                            }
                        ]
                    }
                },
                {
                    "name": "Allipur Village Sidhouli Block",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.236525",
                        "longitude": "80.77217"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2400,
                        "totalTreesPlanned": 2400,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 480,
                                "plantationImages": [],
                                "countSpeciesUpdated": 480
                            }
                        ]
                    }
                },
                {
                    "name": "Khurda",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.372521",
                        "longitude": "80.8836"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 98,
                        "totalTreesPlanned": 98,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 98,
                                "plantationImages": [],
                                "countSpeciesUpdated": 98
                            }
                        ]
                    }
                },
                {
                    "name": "Precast yard",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.620895",
                        "longitude": "80.734103"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 130,
                        "totalTreesPlanned": 130,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 130,
                                "plantationImages": [],
                                "countSpeciesUpdated": 130
                            }
                        ]
                    }
                },
                {
                    "name": "Jawahirpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.530566",
                        "longitude": "80.564593"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3500,
                        "totalTreesPlanned": 3500,
                        "uniqueSpeciesPlanted": 6,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Bengal Currant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 583,
                                "plantationImages": [],
                                "countSpeciesUpdated": 583
                            }
                        ]
                    }
                },
                {
                    "name": "Barmi",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.470516",
                        "longitude": "80.5436"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 6,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-09-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Bengal Currant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            }
                        ]
                    }
                },
                {
                    "name": "Primary school paharpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.502209",
                        "longitude": "80.734514"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 6,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-01-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Bengal Currant",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bengal-currant.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            }
                        ]
                    }
                }
            ]
        },
        "P20": {
            "name": "Balrampur Tubewell Scheme Pkg -3",
            "employmentGenerated": 0,
            "numberOfForestSites": 1,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 350,
                "totalTreesPlanned": 350,
                "uniqueSpeciesPlanted": 4,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 88,
                        "plantationImages": [],
                        "countSpeciesUpdated": 88
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 88,
                        "plantationImages": [],
                        "countSpeciesUpdated": 88
                    },
                    {
                        "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                        "count": 88,
                        "plantationImages": [],
                        "countSpeciesUpdated": 88
                    },
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 88,
                        "plantationImages": [],
                        "countSpeciesUpdated": 88
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            }
                        ],
                        "totalTreesPlanted": 350
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Shekharpur School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.461362",
                        "longitude": "82.133368"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 350,
                        "totalTreesPlanned": 350,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            }
                        ]
                    }
                }
            ]
        },
        "P21": {
            "name": "Gonda Tubewell scheme PKG-4",
            "employmentGenerated": 0,
            "numberOfForestSites": 14,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 22590,
                "totalTreesPlanned": 22590,
                "uniqueSpeciesPlanted": 5,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 5898,
                        "plantationImages": [],
                        "countSpeciesUpdated": 5898
                    },
                    {
                        "speciesName": "Eucalyptus",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                        "count": 5898,
                        "plantationImages": [],
                        "countSpeciesUpdated": 5898
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 5398,
                        "plantationImages": [],
                        "countSpeciesUpdated": 5398
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 5398,
                        "plantationImages": [],
                        "countSpeciesUpdated": 5398
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 5013,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5013
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 5013,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5013
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 5013,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5013
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 5013,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5013
                            }
                        ],
                        "totalTreesPlanted": 20050
                    },
                    {
                        "date": "2023-12-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381
                            }
                        ],
                        "totalTreesPlanted": 1525
                    },
                    {
                        "date": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            }
                        ],
                        "totalTreesPlanted": 15
                    },
                    {
                        "date": "2024-08-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ],
                        "totalTreesPlanted": 1000
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 5013,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5013,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 5013,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5013,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 5013,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5013,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 5013,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5013,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-12-18",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-06-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-27",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Katauli",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.21833",
                        "longitude": "81.901025"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2000,
                        "totalTreesPlanned": 2000,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                },
                {
                    "name": "Parsia Rani",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.214976",
                        "longitude": "81.903991"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1400,
                        "totalTreesPlanned": 1400,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            }
                        ]
                    }
                },
                {
                    "name": "Bhairampur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.301973",
                        "longitude": "81.881486"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                },
                {
                    "name": "Belwa Bham",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.235651",
                        "longitude": "81.732003"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4000,
                        "totalTreesPlanned": 4000,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Bichuri",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.277971",
                        "longitude": "81.85326"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4000,
                        "totalTreesPlanned": 4000,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Kochwa",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.30197",
                        "longitude": "81.881474"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2600,
                        "totalTreesPlanned": 2600,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 650
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 650
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 650
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 650,
                                "plantationImages": [],
                                "countSpeciesUpdated": 650
                            }
                        ]
                    }
                },
                {
                    "name": "Belsar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.13589",
                        "longitude": "81.75433"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1050,
                        "totalTreesPlanned": 1050,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 263,
                                "plantationImages": [],
                                "countSpeciesUpdated": 263
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 263,
                                "plantationImages": [],
                                "countSpeciesUpdated": 263
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 263,
                                "plantationImages": [],
                                "countSpeciesUpdated": 263
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 263,
                                "plantationImages": [],
                                "countSpeciesUpdated": 263
                            }
                        ]
                    }
                },
                {
                    "name": "Umari",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "26.89738",
                        "longitude": "81.842274"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                },
                {
                    "name": "Tarabganj Tehsil",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.98563",
                        "longitude": "82.26535"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3000,
                        "totalTreesPlanned": 3000,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            }
                        ]
                    }
                },
                {
                    "name": "Puraini",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.11422",
                        "longitude": "82.12043"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1525,
                        "totalTreesPlanned": 1525,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-12-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 381,
                                "plantationImages": [],
                                "countSpeciesUpdated": 381
                            }
                        ]
                    }
                },
                {
                    "name": "Chandha",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "26.96416",
                        "longitude": "82.16607"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 15,
                        "totalTreesPlanned": 15,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            }
                        ]
                    }
                },
                {
                    "name": "Kanchanpur Diha",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.083955",
                        "longitude": "81.689806"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Eucalyptus",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/eucalyptus.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                }
            ]
        },
        "P22": {
            "name": "Gonda Tubewell scheme PKG-5",
            "employmentGenerated": 0,
            "numberOfForestSites": 2,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 1515,
                "totalTreesPlanned": 1515,
                "uniqueSpeciesPlanted": 4,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 379,
                        "plantationImages": [],
                        "countSpeciesUpdated": 379
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 379,
                        "plantationImages": [],
                        "countSpeciesUpdated": 379
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 379,
                        "plantationImages": [],
                        "countSpeciesUpdated": 379
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-12-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            }
                        ],
                        "totalTreesPlanted": 1500
                    },
                    {
                        "date": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            }
                        ],
                        "totalTreesPlanted": 15
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-12-23",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-06-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Kamalpur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.1912244",
                        "longitude": "81.6237652"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1500,
                        "totalTreesPlanned": 1500,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-12-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            }
                        ]
                    }
                },
                {
                    "name": "Naypur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "27.01137",
                        "longitude": "82.07661"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 15,
                        "totalTreesPlanned": 15,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-06-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 4,
                                "plantationImages": [],
                                "countSpeciesUpdated": 4
                            }
                        ]
                    }
                }
            ]
        },
        "P23": {
            "name": "Varanasi Tubewell Scheme Package 4",
            "employmentGenerated": 0,
            "numberOfForestSites": 7,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 6540,
                "totalTreesPlanned": 6540,
                "uniqueSpeciesPlanted": 5,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 2054,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2054
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 1667,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1667
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 2054,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2054
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 387,
                        "plantationImages": [],
                        "countSpeciesUpdated": 387
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 387,
                        "plantationImages": [],
                        "countSpeciesUpdated": 387
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1798,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1798
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 1667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1667
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1798,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1798
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131
                            }
                        ],
                        "totalTreesPlanted": 5520
                    },
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            }
                        ],
                        "totalTreesPlanted": 20
                    },
                    {
                        "date": "2023-08-09",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ],
                        "totalTreesPlanted": 1000
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-12",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1798,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1798,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 1667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1667,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1798,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1798,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 131,
                                "plantationImages": [],
                                "countSpeciesUpdated": 131,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-09",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Mohanidih",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.545013",
                        "longitude": "82.982891"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 5000,
                        "totalTreesPlanned": 5000,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1667
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 1667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1667
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1667,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1667
                            }
                        ]
                    }
                },
                {
                    "name": "Precast Yard",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.407568",
                        "longitude": "82.999872"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 10,
                        "totalTreesPlanned": 10,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            }
                        ]
                    }
                },
                {
                    "name": "Venipur GP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.426255",
                        "longitude": "82.950457"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 350,
                        "totalTreesPlanned": 350,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            }
                        ]
                    }
                },
                {
                    "name": "Raisipatti",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.396836",
                        "longitude": "82.928884"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 150,
                        "totalTreesPlanned": 150,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 38,
                                "plantationImages": [],
                                "countSpeciesUpdated": 38
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 38,
                                "plantationImages": [],
                                "countSpeciesUpdated": 38
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 38,
                                "plantationImages": [],
                                "countSpeciesUpdated": 38
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 38,
                                "plantationImages": [],
                                "countSpeciesUpdated": 38
                            }
                        ]
                    }
                },
                {
                    "name": "Dindaspur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.314473",
                        "longitude": "82.834998"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 20,
                        "totalTreesPlanned": 20,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            }
                        ]
                    }
                },
                {
                    "name": "Precast Yard",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.407568",
                        "longitude": "82.999872"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 10,
                        "totalTreesPlanned": 10,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            }
                        ]
                    }
                },
                {
                    "name": "Mohanidih",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "23.74",
                        "longitude": "82.64"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-09",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                }
            ]
        },
        "P24": {
            "name": "Ghazipur Tubewell Scheme Package 3",
            "employmentGenerated": 0,
            "numberOfForestSites": 3,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 5750,
                "totalTreesPlanned": 5750,
                "uniqueSpeciesPlanted": 5,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 1150,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1150
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 1150,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1150
                    },
                    {
                        "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                        "count": 1150,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1150
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 1150,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1150
                    },
                    {
                        "speciesName": "Mahogany (Mahagoni)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                        "count": 1150,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1150
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2023-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            }
                        ],
                        "totalTreesPlanted": 1750
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-13",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Sonhouli ground deokali",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.5491898",
                        "longitude": "83.3367576"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 5750,
                        "totalTreesPlanned": 5750,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1150
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1150
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1150
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1150
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 1150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1150
                            }
                        ]
                    }
                }
            ]
        },
        "P25": {
            "name": "Prayagraj Tubewell Scheme-Pkg-5",
            "employmentGenerated": 0,
            "numberOfForestSites": 1,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 2000,
                "totalTreesPlanned": 5300,
                "uniqueSpeciesPlanted": 8,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 250,
                        "plantationImages": [],
                        "countSpeciesUpdated": 250
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            }
                        ],
                        "totalTreesPlanted": 666
                    },
                    {
                        "date": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84
                            }
                        ],
                        "totalTreesPlanted": 668
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 84,
                                "plantationImages": [],
                                "countSpeciesUpdated": 84,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Amritsarovar, Gram panchayat area in Amba & Joknai",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.183733,25.6791, 25.200736",
                        "longitude": "81.879115, 82.0508, 81.839185"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2000,
                        "totalTreesPlanned": 2000,
                        "uniqueSpeciesPlanted": 8,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                }
            ]
        },
        "P26": {
            "name": "Prayagraj Tubewell Scheme- Pkg-6",
            "employmentGenerated": 0,
            "numberOfForestSites": 1,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 3850,
                "totalTreesPlanned": 7350,
                "uniqueSpeciesPlanted": 8,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 481,
                        "plantationImages": [],
                        "countSpeciesUpdated": 481
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 481,
                        "plantationImages": [],
                        "countSpeciesUpdated": 481
                    },
                    {
                        "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                        "count": 481,
                        "plantationImages": [],
                        "countSpeciesUpdated": 481
                    },
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 481,
                        "plantationImages": [],
                        "countSpeciesUpdated": 481
                    },
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 481,
                        "plantationImages": [],
                        "countSpeciesUpdated": 481
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 481,
                        "plantationImages": [],
                        "countSpeciesUpdated": 481
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 481,
                        "plantationImages": [],
                        "countSpeciesUpdated": 481
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 481,
                        "plantationImages": [],
                        "countSpeciesUpdated": 481
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            }
                        ],
                        "totalTreesPlanted": 1283
                    },
                    {
                        "date": "2023-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            }
                        ],
                        "totalTreesPlanted": 1283
                    },
                    {
                        "date": "2023-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161
                            }
                        ],
                        "totalTreesPlanted": 1284
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2023-08-15",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 161,
                                "plantationImages": [],
                                "countSpeciesUpdated": 161,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Poly technique College, Gram Panchayat, Gaushala in Bahariya & Primary School Nree in Bl- Bahariya, Rajapur Chaubara gram sabha, primary school, panchayat bhawan, Boundary wall, Gp- Devapur in Sashon",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "25.361561,25.3602,25.595186,23.3627, 25.58, 25.438",
                        "longitude": "82.189255., 82.1804,81.835109,82.1899, 81.73, 81.846"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3850,
                        "totalTreesPlanned": 3850,
                        "uniqueSpeciesPlanted": 8,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-08-15",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 481,
                                "plantationImages": [],
                                "countSpeciesUpdated": 481
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 481,
                                "plantationImages": [],
                                "countSpeciesUpdated": 481
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 481,
                                "plantationImages": [],
                                "countSpeciesUpdated": 481
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 481,
                                "plantationImages": [],
                                "countSpeciesUpdated": 481
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 481,
                                "plantationImages": [],
                                "countSpeciesUpdated": 481
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 481,
                                "plantationImages": [],
                                "countSpeciesUpdated": 481
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 481,
                                "plantationImages": [],
                                "countSpeciesUpdated": 481
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 481,
                                "plantationImages": [],
                                "countSpeciesUpdated": 481
                            }
                        ]
                    }
                }
            ]
        },
        "P27": {
            "name": "HMLIP",
            "employmentGenerated": 0,
            "numberOfForestSites": 8,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 34065,
                "totalTreesPlanned": 34065,
                "uniqueSpeciesPlanted": 25,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                        "count": 1779,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1779
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 687,
                        "plantationImages": [],
                        "countSpeciesUpdated": 687
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 2404,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2404
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 2404,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2404
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 2335,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2335
                    },
                    {
                        "speciesName": "Sitafal (Custard Apple)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                        "count": 2404,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2404
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 2960,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2960
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 2335,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2335
                    },
                    {
                        "speciesName": "Bamboo",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                        "count": 556,
                        "plantationImages": [],
                        "countSpeciesUpdated": 556
                    },
                    {
                        "speciesName": "Raatrani (Night-blooming Jasmine)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/raatrani-night-blooming-jasmine.png",
                        "count": 556,
                        "plantationImages": [],
                        "countSpeciesUpdated": 556
                    },
                    {
                        "speciesName": "Bel (Belpatra, Wood Apple)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                        "count": 556,
                        "plantationImages": [],
                        "countSpeciesUpdated": 556
                    },
                    {
                        "speciesName": "Badam (Indian Almond,Jungli Badam)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                        "count": 556,
                        "plantationImages": [],
                        "countSpeciesUpdated": 556
                    },
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 1717,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1717
                    },
                    {
                        "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                        "count": 625,
                        "plantationImages": [],
                        "countSpeciesUpdated": 625
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 625,
                        "plantationImages": [],
                        "countSpeciesUpdated": 625
                    },
                    {
                        "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                        "count": 625,
                        "plantationImages": [],
                        "countSpeciesUpdated": 625
                    },
                    {
                        "speciesName": "Pongamia",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                        "count": 1092,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1092
                    },
                    {
                        "speciesName": "Cascabela thevetia",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                        "count": 1092,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1092
                    },
                    {
                        "speciesName": "Bougainvillea",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                        "count": 1092,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1092
                    },
                    {
                        "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                        "count": 1092,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1092
                    },
                    {
                        "speciesName": "Deamia testudo (Dog Tail Cactus)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                        "count": 1092,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1092
                    },
                    {
                        "speciesName": "Bauhinia variegata",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                        "count": 1092,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1092
                    },
                    {
                        "speciesName": "Leptospermum scoparium",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                        "count": 1092,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1092
                    },
                    {
                        "speciesName": "Rose, Jasmine",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                        "count": 1092,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1092
                    },
                    {
                        "speciesName": "Pomegranate",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                        "count": 1092,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1092
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            }
                        ],
                        "totalTreesPlanted": 5000
                    },
                    {
                        "date": "2024-08-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Raatrani (Night-blooming Jasmine)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/raatrani-night-blooming-jasmine.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            }
                        ],
                        "totalTreesPlanted": 5000
                    },
                    {
                        "date": "2024-08-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 687,
                                "plantationImages": [],
                                "countSpeciesUpdated": 687
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 687,
                                "plantationImages": [],
                                "countSpeciesUpdated": 687
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 687,
                                "plantationImages": [],
                                "countSpeciesUpdated": 687
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 687,
                                "plantationImages": [],
                                "countSpeciesUpdated": 687
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            }
                        ],
                        "totalTreesPlanted": 5492
                    },
                    {
                        "date": "2023-01-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            }
                        ],
                        "totalTreesPlanted": 4643
                    },
                    {
                        "date": "2023-02-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            }
                        ],
                        "totalTreesPlanted": 4643
                    },
                    {
                        "date": "2023-03-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            }
                        ],
                        "totalTreesPlanted": 4643
                    },
                    {
                        "date": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            }
                        ],
                        "totalTreesPlanted": 4644
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Raatrani (Night-blooming Jasmine)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/raatrani-night-blooming-jasmine.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 687,
                                "plantationImages": [],
                                "countSpeciesUpdated": 687,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 687,
                                "plantationImages": [],
                                "countSpeciesUpdated": 687,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 687,
                                "plantationImages": [],
                                "countSpeciesUpdated": 687,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 687,
                                "plantationImages": [],
                                "countSpeciesUpdated": 687,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-01-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-02-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-03-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-04-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Three locations at Compartment No. 431 (Near Kitolakurd Village), \n Compartment No. 434 (Near Godna Village) & \n Compartment No. 452 (Near Beragada Village) of\n  Dewas District (Near Kantaphod location)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.3151\n 22.3258\n 22.3331\n 22.3238\n 22.3058",
                        "longitude": "76.3326\n 76.303\n 76.3022\n 76.3047\n 76.3713"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 5000,
                        "totalTreesPlanned": 5000,
                        "uniqueSpeciesPlanted": 8,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            }
                        ]
                    }
                },
                {
                    "name": "katkut forest garden (beet Neem (Bevu)kheda) compartment no .183,193",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.2353\n 22.2639",
                        "longitude": "76.9.23\n 76.6.41"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 5000,
                        "totalTreesPlanned": 5000,
                        "uniqueSpeciesPlanted": 7,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Bamboo",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bamboo.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Raatrani (Night-blooming Jasmine)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/raatrani-night-blooming-jasmine.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Bel (Belpatra, Wood Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bel-belpatra-wood-apple.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Badam (Indian Almond,Jungli Badam)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/badam-indian-almond-jungli-badam.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 556,
                                "plantationImages": [],
                                "countSpeciesUpdated": 556
                            }
                        ]
                    }
                },
                {
                    "name": "Compartment no 434,431,452,chandrakeshar forestB(0 km difference 3rd & 1st)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.3243\n 22.548879\n 22.331",
                        "longitude": "76.3044\n 76.564373\n 76.290"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 5000,
                        "totalTreesPlanned": 5000,
                        "uniqueSpeciesPlanted": 8,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Sahajan (Chirol,Drumstick Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sahajan-chirol-drumstick-tree.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            }
                        ]
                    }
                },
                {
                    "name": "Ujjain",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "23.1586244",
                        "longitude": "75.8048074"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4643,
                        "totalTreesPlanned": 4643,
                        "uniqueSpeciesPlanted": 17,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-01-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            }
                        ]
                    }
                },
                {
                    "name": "Dewas",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.72891",
                        "longitude": "76.241699"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4643,
                        "totalTreesPlanned": 4643,
                        "uniqueSpeciesPlanted": 17,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-02-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            }
                        ]
                    }
                },
                {
                    "name": "Kooptank",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.632989",
                        "longitude": "76.3418853"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4643,
                        "totalTreesPlanned": 4643,
                        "uniqueSpeciesPlanted": 17,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-03-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            }
                        ]
                    }
                },
                {
                    "name": "Kantaphode",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.5839283",
                        "longitude": "76.5755114"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 5136,
                        "totalTreesPlanned": 5136,
                        "uniqueSpeciesPlanted": 18,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pongamia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pongamia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 335,
                                "plantationImages": [],
                                "countSpeciesUpdated": 335
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 335,
                                "plantationImages": [],
                                "countSpeciesUpdated": 335
                            },
                            {
                                "speciesName": "Cascabela thevetia",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cascabela-thevetia.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bougainvillea",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bougainvillea.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 335,
                                "plantationImages": [],
                                "countSpeciesUpdated": 335
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 335,
                                "plantationImages": [],
                                "countSpeciesUpdated": 335
                            },
                            {
                                "speciesName": "Ocimum tenuiflorum (Holy Basil)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ocimum-tenuiflorum-holy-basil.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 335,
                                "plantationImages": [],
                                "countSpeciesUpdated": 335
                            },
                            {
                                "speciesName": "Deamia testudo (Dog Tail Cactus)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/deamia-testudo-dog-tail-cactus.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Bauhinia variegata",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bauhinia-variegata.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Leptospermum scoparium",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/leptospermum-scoparium.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 335,
                                "plantationImages": [],
                                "countSpeciesUpdated": 335
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 335,
                                "plantationImages": [],
                                "countSpeciesUpdated": 335
                            },
                            {
                                "speciesName": "Pomegranate",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pomegranate.png",
                                "count": 273,
                                "plantationImages": [],
                                "countSpeciesUpdated": 273
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            }
                        ]
                    }
                }
            ]
        },
        "P28": {
            "name": "KMLIS",
            "employmentGenerated": 0,
            "numberOfForestSites": 46,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 45795,
                "totalTreesPlanned": 45795,
                "uniqueSpeciesPlanted": 17,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Kaner",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                        "count": 23,
                        "plantationImages": [],
                        "countSpeciesUpdated": 23
                    },
                    {
                        "speciesName": "Madhukamni",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/madhukamni.png",
                        "count": 17,
                        "plantationImages": [],
                        "countSpeciesUpdated": 17
                    },
                    {
                        "speciesName": "Ashok",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                        "count": 284,
                        "plantationImages": [],
                        "countSpeciesUpdated": 284
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 10402,
                        "plantationImages": [],
                        "countSpeciesUpdated": 10402
                    },
                    {
                        "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                        "count": 2217,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2217
                    },
                    {
                        "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                        "count": 646,
                        "plantationImages": [],
                        "countSpeciesUpdated": 646
                    },
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 9553,
                        "plantationImages": [],
                        "countSpeciesUpdated": 9553
                    },
                    {
                        "speciesName": "Sitafal (Custard Apple)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                        "count": 7014,
                        "plantationImages": [],
                        "countSpeciesUpdated": 7014
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 2210,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2210
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 6084,
                        "plantationImages": [],
                        "countSpeciesUpdated": 6084
                    },
                    {
                        "speciesName": "Jackfruit (Kathal)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                        "count": 2598,
                        "plantationImages": [],
                        "countSpeciesUpdated": 2598
                    },
                    {
                        "speciesName": "Karanj",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                        "count": 1074,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1074
                    },
                    {
                        "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                        "count": 700,
                        "plantationImages": [],
                        "countSpeciesUpdated": 700
                    },
                    {
                        "speciesName": "Rose, Jasmine",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                        "count": 51,
                        "plantationImages": [],
                        "countSpeciesUpdated": 51
                    },
                    {
                        "speciesName": "Bargad (Banyan Tree)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bargad-banyan-tree.png",
                        "count": 83,
                        "plantationImages": [],
                        "countSpeciesUpdated": 83
                    },
                    {
                        "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                        "count": 1550,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1550
                    },
                    {
                        "speciesName": "Imli (Tamarind)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                        "count": 150,
                        "plantationImages": [],
                        "countSpeciesUpdated": 150
                    },
                    {
                        "speciesName": "Lemon",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                        "count": 1150,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1150
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2024-06-07",
                        "speciesPlanted": [
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Madhukamni",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/madhukamni.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            }
                        ],
                        "totalTreesPlanted": 27
                    },
                    {
                        "date": "2024-07-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Madhukamni",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/madhukamni.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            }
                        ],
                        "totalTreesPlanted": 28
                    },
                    {
                        "date": "2024-07-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Madhukamni",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/madhukamni.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7
                            }
                        ],
                        "totalTreesPlanted": 42
                    },
                    {
                        "date": "2024-07-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            }
                        ],
                        "totalTreesPlanted": 24
                    },
                    {
                        "date": "2024-08-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            }
                        ],
                        "totalTreesPlanted": 250
                    },
                    {
                        "date": "2024-08-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            }
                        ],
                        "totalTreesPlanted": 800
                    },
                    {
                        "date": "2024-08-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 403,
                                "plantationImages": [],
                                "countSpeciesUpdated": 403
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 403,
                                "plantationImages": [],
                                "countSpeciesUpdated": 403
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 403,
                                "plantationImages": [],
                                "countSpeciesUpdated": 403
                            }
                        ],
                        "totalTreesPlanted": 1210
                    },
                    {
                        "date": "2024-08-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375
                            }
                        ],
                        "totalTreesPlanted": 1500
                    },
                    {
                        "date": "2024-09-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1997,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1997
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1439,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1439
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1522,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1522
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 2022,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2022
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1058,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1058
                            },
                            {
                                "speciesName": "Bargad (Banyan Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bargad-banyan-tree.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 558,
                                "plantationImages": [],
                                "countSpeciesUpdated": 558
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 1120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1120
                            }
                        ],
                        "totalTreesPlanted": 9879
                    },
                    {
                        "date": "2024-08-06",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1653,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1653
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1653,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1653
                            }
                        ],
                        "totalTreesPlanted": 3305
                    },
                    {
                        "date": "2024-08-07",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 110,
                                "plantationImages": [],
                                "countSpeciesUpdated": 110
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 110,
                                "plantationImages": [],
                                "countSpeciesUpdated": 110
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 110,
                                "plantationImages": [],
                                "countSpeciesUpdated": 110
                            }
                        ],
                        "totalTreesPlanted": 330
                    },
                    {
                        "date": "2024-08-09",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            }
                        ],
                        "totalTreesPlanted": 150
                    },
                    {
                        "date": "2024-08-10",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            }
                        ],
                        "totalTreesPlanted": 250
                    },
                    {
                        "date": "2024-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ],
                        "totalTreesPlanted": 100
                    },
                    {
                        "date": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 116,
                                "plantationImages": [],
                                "countSpeciesUpdated": 116
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49
                            }
                        ],
                        "totalTreesPlanted": 395
                    },
                    {
                        "date": "2024-09-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            }
                        ],
                        "totalTreesPlanted": 2500
                    },
                    {
                        "date": "2024-09-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            }
                        ],
                        "totalTreesPlanted": 1250
                    },
                    {
                        "date": "2024-09-09",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1250
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1250
                            }
                        ],
                        "totalTreesPlanted": 2500
                    },
                    {
                        "date": "2024-09-10",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1100
                            }
                        ],
                        "totalTreesPlanted": 1100
                    },
                    {
                        "date": "2024-09-11",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51
                            }
                        ],
                        "totalTreesPlanted": 154
                    },
                    {
                        "date": "2024-09-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26
                            }
                        ],
                        "totalTreesPlanted": 105
                    },
                    {
                        "date": "2024-09-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            }
                        ],
                        "totalTreesPlanted": 570
                    },
                    {
                        "date": "2024-09-19",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            }
                        ],
                        "totalTreesPlanted": 300
                    },
                    {
                        "date": "2024-09-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            }
                        ],
                        "totalTreesPlanted": 1000
                    },
                    {
                        "date": "2024-09-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            }
                        ],
                        "totalTreesPlanted": 35
                    },
                    {
                        "date": "2024-09-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            }
                        ],
                        "totalTreesPlanted": 400
                    },
                    {
                        "date": "2024-08-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ],
                        "totalTreesPlanted": 100
                    },
                    {
                        "date": "2024-08-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350
                            }
                        ],
                        "totalTreesPlanted": 1700
                    },
                    {
                        "date": "2024-09-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 831,
                                "plantationImages": [],
                                "countSpeciesUpdated": 831
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 831,
                                "plantationImages": [],
                                "countSpeciesUpdated": 831
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 168,
                                "plantationImages": [],
                                "countSpeciesUpdated": 168
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 168,
                                "plantationImages": [],
                                "countSpeciesUpdated": 168
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 168,
                                "plantationImages": [],
                                "countSpeciesUpdated": 168
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 168,
                                "plantationImages": [],
                                "countSpeciesUpdated": 168
                            }
                        ],
                        "totalTreesPlanted": 2335
                    },
                    {
                        "date": "2024-08-31",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62
                            }
                        ],
                        "totalTreesPlanted": 374
                    },
                    {
                        "date": "2024-09-20",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ],
                        "totalTreesPlanted": 2000
                    },
                    {
                        "date": "2024-09-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 633,
                                "plantationImages": [],
                                "countSpeciesUpdated": 633
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 633,
                                "plantationImages": [],
                                "countSpeciesUpdated": 633
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333
                            }
                        ],
                        "totalTreesPlanted": 2200
                    },
                    {
                        "date": "2025-09-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ],
                        "totalTreesPlanted": 1000
                    },
                    {
                        "date": "2024-09-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 2500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2500
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 2500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2500
                            }
                        ],
                        "totalTreesPlanted": 5000
                    },
                    {
                        "date": "2024-11-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 567,
                                "plantationImages": [],
                                "countSpeciesUpdated": 567
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 567,
                                "plantationImages": [],
                                "countSpeciesUpdated": 567
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 567,
                                "plantationImages": [],
                                "countSpeciesUpdated": 567
                            }
                        ],
                        "totalTreesPlanted": 1700
                    },
                    {
                        "date": "2024-10-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 365,
                                "plantationImages": [],
                                "countSpeciesUpdated": 365
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 365,
                                "plantationImages": [],
                                "countSpeciesUpdated": 365
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 365,
                                "plantationImages": [],
                                "countSpeciesUpdated": 365
                            }
                        ],
                        "totalTreesPlanted": 1094
                    },
                    {
                        "date": "2024-10-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            }
                        ],
                        "totalTreesPlanted": 88
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2024-06-07",
                        "speciesUpdated": [
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Madhukamni",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/madhukamni.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-02",
                        "speciesUpdated": [
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Madhukamni",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/madhukamni.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Madhukamni",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/madhukamni.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 7,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-07-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-02",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-03",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 403,
                                "plantationImages": [],
                                "countSpeciesUpdated": 403,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 403,
                                "plantationImages": [],
                                "countSpeciesUpdated": 403,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 403,
                                "plantationImages": [],
                                "countSpeciesUpdated": 403,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 375,
                                "plantationImages": [],
                                "countSpeciesUpdated": 375,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1997,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1997,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1439,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1439,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1522,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1522,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 2022,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2022,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1058,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1058,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Bargad (Banyan Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bargad-banyan-tree.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 558,
                                "plantationImages": [],
                                "countSpeciesUpdated": 558,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 1120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1120,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-06",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1653,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1653,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1653,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1653,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-07",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 110,
                                "plantationImages": [],
                                "countSpeciesUpdated": 110,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 110,
                                "plantationImages": [],
                                "countSpeciesUpdated": 110,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 110,
                                "plantationImages": [],
                                "countSpeciesUpdated": 110,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-09",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-10",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-12",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 116,
                                "plantationImages": [],
                                "countSpeciesUpdated": 116,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-05",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-09",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1250,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-10",
                        "speciesUpdated": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-11",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-12",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-02",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-19",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-03",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-18",
                        "speciesUpdated": [
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-21",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-27",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 350,
                                "plantationImages": [],
                                "countSpeciesUpdated": 350,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-13",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 831,
                                "plantationImages": [],
                                "countSpeciesUpdated": 831,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 831,
                                "plantationImages": [],
                                "countSpeciesUpdated": 831,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 168,
                                "plantationImages": [],
                                "countSpeciesUpdated": 168,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 168,
                                "plantationImages": [],
                                "countSpeciesUpdated": 168,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 168,
                                "plantationImages": [],
                                "countSpeciesUpdated": 168,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 168,
                                "plantationImages": [],
                                "countSpeciesUpdated": 168,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-31",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 62,
                                "plantationImages": [],
                                "countSpeciesUpdated": 62,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-20",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-25",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 633,
                                "plantationImages": [],
                                "countSpeciesUpdated": 633,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 633,
                                "plantationImages": [],
                                "countSpeciesUpdated": 633,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 333,
                                "plantationImages": [],
                                "countSpeciesUpdated": 333,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2025-09-25",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 2500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 2500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 2500,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-11-29",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 567,
                                "plantationImages": [],
                                "countSpeciesUpdated": 567,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 567,
                                "plantationImages": [],
                                "countSpeciesUpdated": 567,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 567,
                                "plantationImages": [],
                                "countSpeciesUpdated": 567,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-21",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 365,
                                "plantationImages": [],
                                "countSpeciesUpdated": 365,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 365,
                                "plantationImages": [],
                                "countSpeciesUpdated": 365,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 365,
                                "plantationImages": [],
                                "countSpeciesUpdated": 365,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-02",
                        "speciesUpdated": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "Site office",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.16",
                        "longitude": "74.67"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 97,
                        "totalTreesPlanned": 97,
                        "uniqueSpeciesPlanted": 6,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-06-07",
                        "speciesPlanted": [
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Madhukamni",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/madhukamni.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            }
                        ]
                    }
                },
                {
                    "name": "Client Office Kukshi",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.13",
                        "longitude": "74.59"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 24,
                        "totalTreesPlanned": 24,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-07-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Kaner",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/kaner.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            }
                        ]
                    }
                },
                {
                    "name": "Konda Village Govt Land",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.12",
                        "longitude": "74.74"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            }
                        ]
                    }
                },
                {
                    "name": "Lohari Farming Land 01 &2",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.18",
                        "longitude": "74.9"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 800,
                        "totalTreesPlanned": 800,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 267,
                                "plantationImages": [],
                                "countSpeciesUpdated": 267
                            }
                        ]
                    }
                },
                {
                    "name": "Borud",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.2",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1210,
                        "totalTreesPlanned": 1210,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 403,
                                "plantationImages": [],
                                "countSpeciesUpdated": 403
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 403,
                                "plantationImages": [],
                                "countSpeciesUpdated": 403
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 403,
                                "plantationImages": [],
                                "countSpeciesUpdated": 403
                            }
                        ]
                    }
                },
                {
                    "name": "Tawlai Bujurg (Manawar)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.18",
                        "longitude": "75.24"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2774,
                        "totalTreesPlanned": 2774,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 694,
                                "plantationImages": [],
                                "countSpeciesUpdated": 694
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 694,
                                "plantationImages": [],
                                "countSpeciesUpdated": 694
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 694,
                                "plantationImages": [],
                                "countSpeciesUpdated": 694
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 694,
                                "plantationImages": [],
                                "countSpeciesUpdated": 694
                            }
                        ]
                    }
                },
                {
                    "name": "Deher Village",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.07",
                        "longitude": "74.73"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3305,
                        "totalTreesPlanned": 3305,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-06",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1653,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1653
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1653,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1653
                            }
                        ]
                    }
                },
                {
                    "name": "Gulati Farming land Manawar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.16",
                        "longitude": "74.71"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 330,
                        "totalTreesPlanned": 330,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-07",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 110,
                                "plantationImages": [],
                                "countSpeciesUpdated": 110
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 110,
                                "plantationImages": [],
                                "countSpeciesUpdated": 110
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 110,
                                "plantationImages": [],
                                "countSpeciesUpdated": 110
                            }
                        ]
                    }
                },
                {
                    "name": "Nisarpur Service Road",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.11",
                        "longitude": "74.83"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 150,
                        "totalTreesPlanned": 150,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-09",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            }
                        ]
                    }
                },
                {
                    "name": "Dusera Maidan",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.1",
                        "longitude": "74.83"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-10",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            }
                        ]
                    }
                },
                {
                    "name": "Lohari Gurukulam Academy",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.06",
                        "longitude": "74.55"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Lohari Village",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.2",
                        "longitude": "74.92"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ]
                    }
                },
                {
                    "name": "Ghatgoan Village",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.23",
                        "longitude": "74.94"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2500,
                        "totalTreesPlanned": 2500,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            }
                        ]
                    }
                },
                {
                    "name": "Borud(16kms apart)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.11",
                        "longitude": "74.91"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1250,
                        "totalTreesPlanned": 1250,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-05",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 625,
                                "plantationImages": [],
                                "countSpeciesUpdated": 625
                            }
                        ]
                    }
                },
                {
                    "name": "Jhapri",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.1",
                        "longitude": "74.83"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3500,
                        "totalTreesPlanned": 3500,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-09",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1750
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1750
                            }
                        ]
                    }
                },
                {
                    "name": "Narmada nagar (Village & GH)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.09",
                        "longitude": "74.91"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1100,
                        "totalTreesPlanned": 1100,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-10",
                        "speciesPlanted": [
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 1100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1100
                            }
                        ]
                    }
                },
                {
                    "name": "Nisapur L&T Office",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.16",
                        "longitude": "74.67"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 154,
                        "totalTreesPlanned": 154,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-11",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51
                            },
                            {
                                "speciesName": "Rose, Jasmine",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/rose-jasmine.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51
                            }
                        ]
                    }
                },
                {
                    "name": "Saskiya Naveen School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.11",
                        "longitude": "74.91"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 330,
                        "totalTreesPlanned": 330,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Bargad (Banyan Tree)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/bargad-banyan-tree.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 83,
                                "plantationImages": [],
                                "countSpeciesUpdated": 83
                            }
                        ]
                    }
                },
                {
                    "name": "SDM Office",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.14",
                        "longitude": "74.71"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 105,
                        "totalTreesPlanned": 105,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 26,
                                "plantationImages": [],
                                "countSpeciesUpdated": 26
                            }
                        ]
                    }
                },
                {
                    "name": "Lohari Farming Land 04",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.21",
                        "longitude": "74.91"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 570,
                        "totalTreesPlanned": 570,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            },
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 143,
                                "plantationImages": [],
                                "countSpeciesUpdated": 143
                            }
                        ]
                    }
                },
                {
                    "name": "Sali",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.35",
                        "longitude": "75.03"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-19",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Amla (Gooseberry, Phyllanthus emblica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/amla-gooseberry-phyllanthus-emblica.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            }
                        ]
                    }
                },
                {
                    "name": "Umari Village",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.2",
                        "longitude": "74.92"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-03",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Gulmohar (Gold Mohar, Delonix regia)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/gulmohar-gold-mohar-delonix-regia.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            }
                        ]
                    }
                },
                {
                    "name": "Narmada Nagr Girls Hostel",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.11",
                        "longitude": "74.91"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 35,
                        "totalTreesPlanned": 35,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Ashok",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/ashok.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            }
                        ]
                    }
                },
                {
                    "name": "Narmada nagar(1.6km)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.11",
                        "longitude": "74.9"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 400,
                        "totalTreesPlanned": 400,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            }
                        ]
                    }
                },
                {
                    "name": "Borud(Same name but far from Borud)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.16",
                        "longitude": "74.99"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 1,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Manawar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.23",
                        "longitude": "75.08"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 195,
                        "totalTreesPlanned": 195,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49
                            },
                            {
                                "speciesName": "Karanj",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/karanj.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 49,
                                "plantationImages": [],
                                "countSpeciesUpdated": 49
                            }
                        ]
                    }
                },
                {
                    "name": "Bhabriya Village",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.08",
                        "longitude": "50.81"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 300,
                        "totalTreesPlanned": 300,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Imli (Tamarind)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/imli-tamarind.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            }
                        ]
                    }
                },
                {
                    "name": "Dedla Village",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.21",
                        "longitude": "75.02"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1675,
                        "totalTreesPlanned": 1675,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 558,
                                "plantationImages": [],
                                "countSpeciesUpdated": 558
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 558,
                                "plantationImages": [],
                                "countSpeciesUpdated": 558
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 558,
                                "plantationImages": [],
                                "countSpeciesUpdated": 558
                            }
                        ]
                    }
                },
                {
                    "name": "Pipli",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.27",
                        "longitude": "74.97"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1325,
                        "totalTreesPlanned": 1325,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 663,
                                "plantationImages": [],
                                "countSpeciesUpdated": 663
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 663,
                                "plantationImages": [],
                                "countSpeciesUpdated": 663
                            }
                        ]
                    }
                },
                {
                    "name": "Silkuan",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.19",
                        "longitude": "74.72"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1384,
                        "totalTreesPlanned": 1384,
                        "uniqueSpeciesPlanted": 6,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-13",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 230,
                                "plantationImages": [],
                                "countSpeciesUpdated": 230
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 230,
                                "plantationImages": [],
                                "countSpeciesUpdated": 230
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 230,
                                "plantationImages": [],
                                "countSpeciesUpdated": 230
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 230,
                                "plantationImages": [],
                                "countSpeciesUpdated": 230
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 230,
                                "plantationImages": [],
                                "countSpeciesUpdated": 230
                            },
                            {
                                "speciesName": "Jackfruit (Kathal)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jackfruit-kathal.png",
                                "count": 230,
                                "plantationImages": [],
                                "countSpeciesUpdated": 230
                            }
                        ]
                    }
                },
                {
                    "name": "Jhapri",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.2",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 4600,
                        "totalTreesPlanned": 4600,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-20",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1150
                            },
                            {
                                "speciesName": "Lemon",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/lemon.png",
                                "count": 1150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1150
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1150
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 1150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1150
                            }
                        ]
                    }
                },
                {
                    "name": "Susari",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.17",
                        "longitude": "74.75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 5600,
                        "totalTreesPlanned": 5600,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 1120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1120
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 1120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1120
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 1120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1120
                            },
                            {
                                "speciesName": "Sisam (Sheesam, Dalbergia sissoo)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sisam-sheesam-dalbergia-sissoo.png",
                                "count": 1120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1120
                            },
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 1120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1120
                            }
                        ]
                    }
                },
                {
                    "name": "Budhia Khedi",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.21",
                        "longitude": "75.11"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 6000,
                        "totalTreesPlanned": 6000,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2025-09-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 3000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3000
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 3000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3000
                            }
                        ]
                    }
                },
                {
                    "name": "Singhna",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.18",
                        "longitude": "74.97"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2700,
                        "totalTreesPlanned": 2700,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 900,
                                "plantationImages": [],
                                "countSpeciesUpdated": 900
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 900,
                                "plantationImages": [],
                                "countSpeciesUpdated": 900
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 900,
                                "plantationImages": [],
                                "countSpeciesUpdated": 900
                            }
                        ]
                    }
                },
                {
                    "name": "Konda Village Govt Land",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.12",
                        "longitude": "74.74"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1094,
                        "totalTreesPlanned": 1094,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 365,
                                "plantationImages": [],
                                "countSpeciesUpdated": 365
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 365,
                                "plantationImages": [],
                                "countSpeciesUpdated": 365
                            },
                            {
                                "speciesName": "Sitafal (Custard Apple)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sitafal-custard-apple.png",
                                "count": 365,
                                "plantationImages": [],
                                "countSpeciesUpdated": 365
                            }
                        ]
                    }
                },
                {
                    "name": "EMRC School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "22.1",
                        "longitude": "71.31"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 88,
                        "totalTreesPlanned": 88,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-02",
                        "speciesPlanted": [
                            {
                                "speciesName": "Pipal (Peepal, Pepal, Ficus religiosa)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/pipal-peepal-pepal-ficus-religiosa.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            }
                        ]
                    }
                }
            ]
        },
        "P29": {
            "name": "24/7 Hubli Dharwad Water Supply Project",
            "employmentGenerated": 0,
            "numberOfForestSites": 127,
            "recapture": {
                "recaptureToDate": 0
            },
            "plantation": {
                "totalTreesPlanted": 39908,
                "totalTreesPlanned": 43408,
                "uniqueSpeciesPlanted": 9,
                "survivedPercentage": 100,
                "speciesPlanted": [
                    {
                        "speciesName": "Neem (Bevu)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                        "count": 6172,
                        "plantationImages": [],
                        "countSpeciesUpdated": 6172
                    },
                    {
                        "speciesName": "Cherry",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                        "count": 4475,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4475
                    },
                    {
                        "speciesName": "Mango (Mangifera indica)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                        "count": 4475,
                        "plantationImages": [],
                        "countSpeciesUpdated": 4475
                    },
                    {
                        "speciesName": "Silver Oak",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                        "count": 10419,
                        "plantationImages": [],
                        "countSpeciesUpdated": 10419
                    },
                    {
                        "speciesName": "Sagwan (Teak, Tectona grandis)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                        "count": 10419,
                        "plantationImages": [],
                        "countSpeciesUpdated": 10419
                    },
                    {
                        "speciesName": "Guava (Amrud, Psidium)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                        "count": 1697,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1697
                    },
                    {
                        "speciesName": "Mahogany (Mahagoni)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                        "count": 1296,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1296
                    },
                    {
                        "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                        "count": 378,
                        "plantationImages": [],
                        "countSpeciesUpdated": 378
                    },
                    {
                        "speciesName": "Coconut Tree",
                        "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                        "count": 1225,
                        "plantationImages": [],
                        "countSpeciesUpdated": 1225
                    }
                ],
                "dateWiseSpeciesPlanted": [
                    {
                        "date": "2020-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 175,
                                "plantationImages": [],
                                "countSpeciesUpdated": 175
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 175,
                                "plantationImages": [],
                                "countSpeciesUpdated": 175
                            }
                        ],
                        "totalTreesPlanted": 800
                    },
                    {
                        "date": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 703,
                                "plantationImages": [],
                                "countSpeciesUpdated": 703
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 703,
                                "plantationImages": [],
                                "countSpeciesUpdated": 703
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 703,
                                "plantationImages": [],
                                "countSpeciesUpdated": 703
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 1932,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1932
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1932,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1932
                            }
                        ],
                        "totalTreesPlanted": 5973
                    },
                    {
                        "date": "2022-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 896,
                                "plantationImages": [],
                                "countSpeciesUpdated": 896
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 896,
                                "plantationImages": [],
                                "countSpeciesUpdated": 896
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 546,
                                "plantationImages": [],
                                "countSpeciesUpdated": 546
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 546,
                                "plantationImages": [],
                                "countSpeciesUpdated": 546
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 546,
                                "plantationImages": [],
                                "countSpeciesUpdated": 546
                            }
                        ],
                        "totalTreesPlanted": 3432
                    },
                    {
                        "date": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 3076,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3076
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 3076,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3076
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 3076,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3076
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 7416,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7416
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 7416,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7416
                            }
                        ],
                        "totalTreesPlanted": 23728
                    },
                    {
                        "date": "2024-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2024-08-17",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 48,
                                "plantationImages": [],
                                "countSpeciesUpdated": 48
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 185,
                                "plantationImages": [],
                                "countSpeciesUpdated": 185
                            }
                        ],
                        "totalTreesPlanted": 745
                    },
                    {
                        "date": "2024-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 46,
                                "plantationImages": [],
                                "countSpeciesUpdated": 46
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 46,
                                "plantationImages": [],
                                "countSpeciesUpdated": 46
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 46,
                                "plantationImages": [],
                                "countSpeciesUpdated": 46
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            }
                        ],
                        "totalTreesPlanted": 170
                    },
                    {
                        "date": "2024-08-19",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ],
                        "totalTreesPlanted": 75
                    },
                    {
                        "date": "2024-10-04",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 221,
                                "plantationImages": [],
                                "countSpeciesUpdated": 221
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 221,
                                "plantationImages": [],
                                "countSpeciesUpdated": 221
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 221,
                                "plantationImages": [],
                                "countSpeciesUpdated": 221
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 221,
                                "plantationImages": [],
                                "countSpeciesUpdated": 221
                            }
                        ],
                        "totalTreesPlanted": 800
                    },
                    {
                        "date": "2024-08-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 126,
                                "plantationImages": [],
                                "countSpeciesUpdated": 126
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 126,
                                "plantationImages": [],
                                "countSpeciesUpdated": 126
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51
                            }
                        ],
                        "totalTreesPlanted": 355
                    },
                    {
                        "date": "2024-09-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 39,
                                "plantationImages": [],
                                "countSpeciesUpdated": 39
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 39,
                                "plantationImages": [],
                                "countSpeciesUpdated": 39
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 39,
                                "plantationImages": [],
                                "countSpeciesUpdated": 39
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 39,
                                "plantationImages": [],
                                "countSpeciesUpdated": 39
                            }
                        ],
                        "totalTreesPlanted": 155
                    },
                    {
                        "date": "2024-09-29",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3
                            }
                        ],
                        "totalTreesPlanted": 10
                    },
                    {
                        "date": "2024-10-12",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150
                            }
                        ],
                        "totalTreesPlanted": 600
                    },
                    {
                        "date": "2024-10-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63
                            }
                        ],
                        "totalTreesPlanted": 252
                    },
                    {
                        "date": "2024-10-09",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88
                            }
                        ],
                        "totalTreesPlanted": 350
                    },
                    {
                        "date": "2024-10-24",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 15,
                                "plantationImages": [],
                                "countSpeciesUpdated": 15
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 15,
                                "plantationImages": [],
                                "countSpeciesUpdated": 15
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 15,
                                "plantationImages": [],
                                "countSpeciesUpdated": 15
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 15,
                                "plantationImages": [],
                                "countSpeciesUpdated": 15
                            }
                        ],
                        "totalTreesPlanted": 60
                    },
                    {
                        "date": "2024-12-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ],
                        "totalTreesPlanted": 100
                    },
                    {
                        "date": "2024-09-25",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 14,
                                "plantationImages": [],
                                "countSpeciesUpdated": 14
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 14,
                                "plantationImages": [],
                                "countSpeciesUpdated": 14
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 14,
                                "plantationImages": [],
                                "countSpeciesUpdated": 14
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 14,
                                "plantationImages": [],
                                "countSpeciesUpdated": 14
                            }
                        ],
                        "totalTreesPlanted": 57
                    },
                    {
                        "date": "2024-09-26",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ],
                        "totalTreesPlanted": 150
                    },
                    {
                        "date": "2024-09-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35
                            }
                        ],
                        "totalTreesPlanted": 140
                    },
                    {
                        "date": "2024-09-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            }
                        ],
                        "totalTreesPlanted": 50
                    },
                    {
                        "date": "2024-10-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 77,
                                "plantationImages": [],
                                "countSpeciesUpdated": 77
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 52,
                                "plantationImages": [],
                                "countSpeciesUpdated": 52
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 77,
                                "plantationImages": [],
                                "countSpeciesUpdated": 77
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ],
                        "totalTreesPlanted": 256
                    },
                    {
                        "date": "2024-10-19",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2024-10-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2024-10-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ],
                        "totalTreesPlanted": 200
                    },
                    {
                        "date": "2024-10-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 317,
                                "plantationImages": [],
                                "countSpeciesUpdated": 317
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 317,
                                "plantationImages": [],
                                "countSpeciesUpdated": 317
                            }
                        ],
                        "totalTreesPlanted": 700
                    },
                    {
                        "date": "2024-10-10",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            }
                        ],
                        "totalTreesPlanted": 50
                    },
                    {
                        "date": "2024-10-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ],
                        "totalTreesPlanted": 100
                    }
                ],
                "dateWiseSpeciesUpdated": [
                    {
                        "date": "2020-04-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 175,
                                "plantationImages": [],
                                "countSpeciesUpdated": 175,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 175,
                                "plantationImages": [],
                                "countSpeciesUpdated": 175,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2021-04-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 703,
                                "plantationImages": [],
                                "countSpeciesUpdated": 703,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 703,
                                "plantationImages": [],
                                "countSpeciesUpdated": 703,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 703,
                                "plantationImages": [],
                                "countSpeciesUpdated": 703,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 1932,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1932,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1932,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1932,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2022-04-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 896,
                                "plantationImages": [],
                                "countSpeciesUpdated": 896,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 896,
                                "plantationImages": [],
                                "countSpeciesUpdated": 896,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 546,
                                "plantationImages": [],
                                "countSpeciesUpdated": 546,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 546,
                                "plantationImages": [],
                                "countSpeciesUpdated": 546,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 546,
                                "plantationImages": [],
                                "countSpeciesUpdated": 546,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2023-04-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 3076,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3076,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 3076,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3076,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 3076,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3076,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 7416,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7416,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 7416,
                                "plantationImages": [],
                                "countSpeciesUpdated": 7416,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-17",
                        "speciesUpdated": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 233,
                                "plantationImages": [],
                                "countSpeciesUpdated": 233,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 48,
                                "plantationImages": [],
                                "countSpeciesUpdated": 48,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 185,
                                "plantationImages": [],
                                "countSpeciesUpdated": 185,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 46,
                                "plantationImages": [],
                                "countSpeciesUpdated": 46,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 46,
                                "plantationImages": [],
                                "countSpeciesUpdated": 46,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 33,
                                "plantationImages": [],
                                "countSpeciesUpdated": 33,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 46,
                                "plantationImages": [],
                                "countSpeciesUpdated": 46,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-19",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-04",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 221,
                                "plantationImages": [],
                                "countSpeciesUpdated": 221,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 221,
                                "plantationImages": [],
                                "countSpeciesUpdated": 221,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 221,
                                "plantationImages": [],
                                "countSpeciesUpdated": 221,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 221,
                                "plantationImages": [],
                                "countSpeciesUpdated": 221,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-08-21",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 126,
                                "plantationImages": [],
                                "countSpeciesUpdated": 126,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 126,
                                "plantationImages": [],
                                "countSpeciesUpdated": 126,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 51,
                                "plantationImages": [],
                                "countSpeciesUpdated": 51,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-28",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 39,
                                "plantationImages": [],
                                "countSpeciesUpdated": 39,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 39,
                                "plantationImages": [],
                                "countSpeciesUpdated": 39,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 39,
                                "plantationImages": [],
                                "countSpeciesUpdated": 39,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 39,
                                "plantationImages": [],
                                "countSpeciesUpdated": 39,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-29",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 3,
                                "plantationImages": [],
                                "countSpeciesUpdated": 3,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-12",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 150,
                                "plantationImages": [],
                                "countSpeciesUpdated": 150,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-14",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 63,
                                "plantationImages": [],
                                "countSpeciesUpdated": 63,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-09",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 88,
                                "plantationImages": [],
                                "countSpeciesUpdated": 88,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-24",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 15,
                                "plantationImages": [],
                                "countSpeciesUpdated": 15,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 15,
                                "plantationImages": [],
                                "countSpeciesUpdated": 15,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 15,
                                "plantationImages": [],
                                "countSpeciesUpdated": 15,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 15,
                                "plantationImages": [],
                                "countSpeciesUpdated": 15,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-12-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-25",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 14,
                                "plantationImages": [],
                                "countSpeciesUpdated": 14,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 14,
                                "plantationImages": [],
                                "countSpeciesUpdated": 14,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 14,
                                "plantationImages": [],
                                "countSpeciesUpdated": 14,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 14,
                                "plantationImages": [],
                                "countSpeciesUpdated": 14,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-26",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-27",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-09-30",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-01",
                        "speciesUpdated": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 77,
                                "plantationImages": [],
                                "countSpeciesUpdated": 77,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 52,
                                "plantationImages": [],
                                "countSpeciesUpdated": 52,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 77,
                                "plantationImages": [],
                                "countSpeciesUpdated": 77,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-19",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-18",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-16",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-23",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 317,
                                "plantationImages": [],
                                "countSpeciesUpdated": 317,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 317,
                                "plantationImages": [],
                                "countSpeciesUpdated": 317,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-10",
                        "speciesUpdated": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2024-10-21",
                        "speciesUpdated": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25,
                                "updateDetails": [
                                    {
                                        "latitude": "",
                                        "longitude": ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            "forestSites": [
                {
                    "name": "MOORAJI DESAI RESIDENTIAL PU SCIENCE COLLEGE",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2020-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Government Higher Primary School, Navanagar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3667",
                        "longitude": "75.1333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2020-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Harobelwadi MS Pipe Yard",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.2667"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2020-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                },
                {
                    "name": "Hire ulligeri govt. primary school?",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3833",
                        "longitude": "75.5333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            }
                        ]
                    }
                },
                {
                    "name": "Harebelovadi Govt.? primary school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.2667"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 120,
                        "totalTreesPlanned": 120,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 60,
                                "plantationImages": [],
                                "countSpeciesUpdated": 60
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 60,
                                "plantationImages": [],
                                "countSpeciesUpdated": 60
                            }
                        ]
                    }
                },
                {
                    "name": "Govt PU College, Mahadev Layout?",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 25,
                        "totalTreesPlanned": 25,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 5,
                                "plantationImages": [],
                                "countSpeciesUpdated": 5
                            }
                        ]
                    }
                },
                {
                    "name": "Workers camp Amminbhavi",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3667",
                        "longitude": "75.5167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Gulganjikoppa water tank Dharwad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 56,
                        "totalTreesPlanned": 56,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 11,
                                "plantationImages": [],
                                "countSpeciesUpdated": 11
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 11,
                                "plantationImages": [],
                                "countSpeciesUpdated": 11
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 11,
                                "plantationImages": [],
                                "countSpeciesUpdated": 11
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 11,
                                "plantationImages": [],
                                "countSpeciesUpdated": 11
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 11,
                                "plantationImages": [],
                                "countSpeciesUpdated": 11
                            }
                        ]
                    }
                },
                {
                    "name": "Madarmaddi Govt school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4167",
                        "longitude": "75.2167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                },
                {
                    "name": "Govt. school Dharwad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4333",
                        "longitude": "75.0167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 46,
                        "totalTreesPlanned": 46,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 9,
                                "plantationImages": [],
                                "countSpeciesUpdated": 9
                            }
                        ]
                    }
                },
                {
                    "name": "Sana college Hubballi",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                },
                {
                    "name": "PHQ govt. school, PHQ Urdhu school, Moulana azad english mediam school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 90,
                        "totalTreesPlanned": 90,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 18,
                                "plantationImages": [],
                                "countSpeciesUpdated": 18
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 18,
                                "plantationImages": [],
                                "countSpeciesUpdated": 18
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 18,
                                "plantationImages": [],
                                "countSpeciesUpdated": 18
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 18,
                                "plantationImages": [],
                                "countSpeciesUpdated": 18
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 18,
                                "plantationImages": [],
                                "countSpeciesUpdated": 18
                            }
                        ]
                    }
                },
                {
                    "name": "Shree ram associates, govt. Urdhu school Navanagar, Moulana azad English medium school Navanagar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3667",
                        "longitude": "75.1333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            }
                        ]
                    }
                },
                {
                    "name": "Ali Public school Hosayellapur Dharwad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "75.0333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 60,
                        "totalTreesPlanned": 60,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 30,
                                "plantationImages": [],
                                "countSpeciesUpdated": 30
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 30,
                                "plantationImages": [],
                                "countSpeciesUpdated": 30
                            }
                        ]
                    }
                },
                {
                    "name": "Chikk ulligere? govt. school?",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4",
                        "longitude": "75.5167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 30,
                        "totalTreesPlanned": 30,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 6,
                                "plantationImages": [],
                                "countSpeciesUpdated": 6
                            }
                        ]
                    }
                },
                {
                    "name": "Govt. school Inamhongal",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3",
                        "longitude": "75.4833"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 45,
                        "totalTreesPlanned": 45,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 23,
                                "plantationImages": [],
                                "countSpeciesUpdated": 23
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 23,
                                "plantationImages": [],
                                "countSpeciesUpdated": 23
                            }
                        ]
                    }
                },
                {
                    "name": "Yemmikeri Govt school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.2667",
                        "longitude": "75.5167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            }
                        ]
                    }
                },
                {
                    "name": "Saptapur Govt school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.3167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 55,
                        "totalTreesPlanned": 55,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 28,
                                "plantationImages": [],
                                "countSpeciesUpdated": 28
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 28,
                                "plantationImages": [],
                                "countSpeciesUpdated": 28
                            }
                        ]
                    }
                },
                {
                    "name": "Shivaji circle Govt school No.2",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            }
                        ]
                    }
                },
                {
                    "name": "Madhihal Govt school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3167",
                        "longitude": "75.45"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 36,
                        "totalTreesPlanned": 36,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 18,
                                "plantationImages": [],
                                "countSpeciesUpdated": 18
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 18,
                                "plantationImages": [],
                                "countSpeciesUpdated": 18
                            }
                        ]
                    }
                },
                {
                    "name": "Ashraya colony Govt school Sattur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3167",
                        "longitude": "75.4667"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 60,
                        "totalTreesPlanned": 60,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            }
                        ]
                    }
                },
                {
                    "name": "Hubli",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                },
                {
                    "name": "Mahadev layout Hubli",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            }
                        ]
                    }
                },
                {
                    "name": "The global school Tadsin koppa , dharward",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "75.0167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 600,
                        "totalTreesPlanned": 600,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 120
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 120
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 120
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 120
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 120,
                                "plantationImages": [],
                                "countSpeciesUpdated": 120
                            }
                        ]
                    }
                },
                {
                    "name": "IIIT COLLEGE ,DHARWARD",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 700,
                        "totalTreesPlanned": 700,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            }
                        ]
                    }
                },
                {
                    "name": "PIPE YARD ,IIIT",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "THE GLOBAL SCHOOL TADSINKOPPA , DHARWARD",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "75.0167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 600,
                        "totalTreesPlanned": 600,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 300,
                                "plantationImages": [],
                                "countSpeciesUpdated": 300
                            }
                        ]
                    }
                },
                {
                    "name": "WTP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 20,
                        "totalTreesPlanned": 20,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            }
                        ]
                    }
                },
                {
                    "name": "Gabbur water tank",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.25"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            }
                        ]
                    }
                },
                {
                    "name": "Hubballi P1P2 office",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 20,
                        "totalTreesPlanned": 20,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            }
                        ]
                    }
                },
                {
                    "name": "Mahadev layout water tank",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 60,
                        "totalTreesPlanned": 60,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 12,
                                "plantationImages": [],
                                "countSpeciesUpdated": 12
                            }
                        ]
                    }
                },
                {
                    "name": "SRI chennakeshava government polytechnic , bankapur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.2167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                },
                {
                    "name": "Government primary school, bankapur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.2167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Sadashiva petta village",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4",
                        "longitude": "75.4667"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Government primary school , sadashiva petta",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4",
                        "longitude": "75.4667"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                },
                {
                    "name": "Gram Panchayat Kalwad village (*Updated in April)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4167",
                        "longitude": "75.25"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 700,
                        "totalTreesPlanned": 700,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2021-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            }
                        ]
                    }
                },
                {
                    "name": "Kendriya vidyalaya no :1 rajanagar,hubli",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2022-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                },
                {
                    "name": "POST OFFICE , HUBBALLI",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2022-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "G.H.P.S NO.13 ASHOK NAGAR HUBLI CITY",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2022-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 125,
                                "plantationImages": [],
                                "countSpeciesUpdated": 125
                            }
                        ]
                    }
                },
                {
                    "name": "Govt polytechnic college bengapur",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.2167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1200,
                        "totalTreesPlanned": 1200,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2022-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 240,
                                "plantationImages": [],
                                "countSpeciesUpdated": 240
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 240,
                                "plantationImages": [],
                                "countSpeciesUpdated": 240
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 240,
                                "plantationImages": [],
                                "countSpeciesUpdated": 240
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 240,
                                "plantationImages": [],
                                "countSpeciesUpdated": 240
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 240,
                                "plantationImages": [],
                                "countSpeciesUpdated": 240
                            }
                        ]
                    }
                },
                {
                    "name": "WTP",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3833",
                        "longitude": "75.5333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1282,
                        "totalTreesPlanned": 1282,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2022-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 256,
                                "plantationImages": [],
                                "countSpeciesUpdated": 256
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 256,
                                "plantationImages": [],
                                "countSpeciesUpdated": 256
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 256,
                                "plantationImages": [],
                                "countSpeciesUpdated": 256
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 256,
                                "plantationImages": [],
                                "countSpeciesUpdated": 256
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 256,
                                "plantationImages": [],
                                "countSpeciesUpdated": 256
                            }
                        ]
                    }
                },
                {
                    "name": "Govt Kannada School Nekarcolony Vidyanagar Hubli.",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2022-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 100,
                                "plantationImages": [],
                                "countSpeciesUpdated": 100
                            }
                        ]
                    }
                },
                {
                    "name": "Siddarodhamath Amminabhavi",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.5333",
                        "longitude": "75.05"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 3000,
                        "totalTreesPlanned": 3000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 600,
                                "plantationImages": [],
                                "countSpeciesUpdated": 600
                            }
                        ]
                    }
                },
                {
                    "name": "DC compound",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 650,
                        "totalTreesPlanned": 650,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 325,
                                "plantationImages": [],
                                "countSpeciesUpdated": 325
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 325,
                                "plantationImages": [],
                                "countSpeciesUpdated": 325
                            }
                        ]
                    }
                },
                {
                    "name": "WTP Proposed - Amminbhavi",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.5167",
                        "longitude": "75.05"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 800,
                        "totalTreesPlanned": 800,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            }
                        ]
                    }
                },
                {
                    "name": "Murugamat Near APMC",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4667",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2000,
                        "totalTreesPlanned": 2000,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 1000,
                                "plantationImages": [],
                                "countSpeciesUpdated": 1000
                            }
                        ]
                    }
                },
                {
                    "name": "Saptagiri Heritage Zone",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3833",
                        "longitude": "75.0833"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 400,
                        "totalTreesPlanned": 400,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            }
                        ]
                    }
                },
                {
                    "name": "Keligiri Tapovan",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "74.9667"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 800,
                        "totalTreesPlanned": 800,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 400,
                                "plantationImages": [],
                                "countSpeciesUpdated": 400
                            }
                        ]
                    }
                },
                {
                    "name": "Saptagiri Park Garden",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4333",
                        "longitude": "75.0167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 400,
                        "totalTreesPlanned": 400,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            }
                        ]
                    }
                },
                {
                    "name": "Ayodhya Nagar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3167",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 500,
                                "plantationImages": [],
                                "countSpeciesUpdated": 500
                            }
                        ]
                    }
                },
                {
                    "name": "NR Betta",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 167,
                                "plantationImages": [],
                                "countSpeciesUpdated": 167
                            }
                        ]
                    }
                },
                {
                    "name": "Savadatti Yellamma(Renuka)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.75",
                        "longitude": "75.1333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 700,
                        "totalTreesPlanned": 700,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 140,
                                "plantationImages": [],
                                "countSpeciesUpdated": 140
                            }
                        ]
                    }
                },
                {
                    "name": "Hilton Park",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3667",
                        "longitude": "75.1"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 360,
                        "totalTreesPlanned": 360,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 180,
                                "plantationImages": [],
                                "countSpeciesUpdated": 180
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 180,
                                "plantationImages": [],
                                "countSpeciesUpdated": 180
                            }
                        ]
                    }
                },
                {
                    "name": "MR Nagar, Gopanakoppa, Sarawati pura Existing ELSR/GLSR",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.1333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 770,
                        "totalTreesPlanned": 770,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 385,
                                "plantationImages": [],
                                "countSpeciesUpdated": 385
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 385,
                                "plantationImages": [],
                                "countSpeciesUpdated": 385
                            }
                        ]
                    }
                },
                {
                    "name": "Karwar Road Tank",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.0833"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                },
                {
                    "name": "Govt. School - Mayakar Colony",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3833",
                        "longitude": "75.0667"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Govt. Amaragol School",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3833",
                        "longitude": "75.0667"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            }
                        ]
                    }
                },
                {
                    "name": "Govt. High school Gamana gatti",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3667",
                        "longitude": "75.15"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            }
                        ]
                    }
                },
                {
                    "name": "Zone 6 O&M Office",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "KCP Park",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 20,
                                "plantationImages": [],
                                "countSpeciesUpdated": 20
                            }
                        ]
                    }
                },
                {
                    "name": "Kendriya Vidyalaya Dharwad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4167",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 60,
                        "totalTreesPlanned": 60,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 30,
                                "plantationImages": [],
                                "countSpeciesUpdated": 30
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 30,
                                "plantationImages": [],
                                "countSpeciesUpdated": 30
                            }
                        ]
                    }
                },
                {
                    "name": "Nagara Pradamika Arogya Kendra",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4333",
                        "longitude": "75.55"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 70,
                        "totalTreesPlanned": 70,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 35,
                                "plantationImages": [],
                                "countSpeciesUpdated": 35
                            }
                        ]
                    }
                },
                {
                    "name": "Shalmala Nagar, Tejaswi Nagar Dharwad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4",
                        "longitude": "75.1"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            }
                        ]
                    }
                },
                {
                    "name": "Sri GG Chopra college Savdatti",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.75",
                        "longitude": "75.1"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1000,
                        "totalTreesPlanned": 1000,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 200,
                                "plantationImages": [],
                                "countSpeciesUpdated": 200
                            }
                        ]
                    }
                },
                {
                    "name": "Shri Kumareshwara English Medium High school savdatti",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.75",
                        "longitude": "75.0333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 360,
                        "totalTreesPlanned": 360,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 72,
                                "plantationImages": [],
                                "countSpeciesUpdated": 72
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 72,
                                "plantationImages": [],
                                "countSpeciesUpdated": 72
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 72,
                                "plantationImages": [],
                                "countSpeciesUpdated": 72
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 72,
                                "plantationImages": [],
                                "countSpeciesUpdated": 72
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 72,
                                "plantationImages": [],
                                "countSpeciesUpdated": 72
                            }
                        ]
                    }
                },
                {
                    "name": "Cooperative bank, marevada",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.5333",
                        "longitude": "75.1"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 110,
                        "totalTreesPlanned": 110,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 22,
                                "plantationImages": [],
                                "countSpeciesUpdated": 22
                            }
                        ]
                    }
                },
                {
                    "name": "Govt. school Marevada",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.7333",
                        "longitude": "75.1"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Shri K M Mamami College Savdatti, Karikatti road",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.75",
                        "longitude": "75.1"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 976,
                        "totalTreesPlanned": 976,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 195,
                                "plantationImages": [],
                                "countSpeciesUpdated": 195
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 195,
                                "plantationImages": [],
                                "countSpeciesUpdated": 195
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 195,
                                "plantationImages": [],
                                "countSpeciesUpdated": 195
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 195,
                                "plantationImages": [],
                                "countSpeciesUpdated": 195
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 195,
                                "plantationImages": [],
                                "countSpeciesUpdated": 195
                            }
                        ]
                    }
                },
                {
                    "name": "SG Shintri PU college, savdatti",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.7667",
                        "longitude": "75.05"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 280,
                        "totalTreesPlanned": 280,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 56,
                                "plantationImages": [],
                                "countSpeciesUpdated": 56
                            }
                        ]
                    }
                },
                {
                    "name": "Murarji Desai School, Timmapura",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.5333",
                        "longitude": "75.05"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 900,
                        "totalTreesPlanned": 900,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 180,
                                "plantationImages": [],
                                "countSpeciesUpdated": 180
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 180,
                                "plantationImages": [],
                                "countSpeciesUpdated": 180
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 180,
                                "plantationImages": [],
                                "countSpeciesUpdated": 180
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 180,
                                "plantationImages": [],
                                "countSpeciesUpdated": 180
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 180,
                                "plantationImages": [],
                                "countSpeciesUpdated": 180
                            }
                        ]
                    }
                },
                {
                    "name": "Govt primary school Amminbhavi (Boys)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.5333",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            }
                        ]
                    }
                },
                {
                    "name": "KLE school, Hubli",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 150,
                        "totalTreesPlanned": 150,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 30,
                                "plantationImages": [],
                                "countSpeciesUpdated": 30
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 30,
                                "plantationImages": [],
                                "countSpeciesUpdated": 30
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 30,
                                "plantationImages": [],
                                "countSpeciesUpdated": 30
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 30,
                                "plantationImages": [],
                                "countSpeciesUpdated": 30
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 30,
                                "plantationImages": [],
                                "countSpeciesUpdated": 30
                            }
                        ]
                    }
                },
                {
                    "name": "Marewada Society Office",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4333",
                        "longitude": "75.0167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 400,
                        "totalTreesPlanned": 400,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 80,
                                "plantationImages": [],
                                "countSpeciesUpdated": 80
                            }
                        ]
                    }
                },
                {
                    "name": "Satturu opposite to IIIT",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3833",
                        "longitude": "75.15"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2452,
                        "totalTreesPlanned": 2452,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 490,
                                "plantationImages": [],
                                "countSpeciesUpdated": 490
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 490,
                                "plantationImages": [],
                                "countSpeciesUpdated": 490
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 490,
                                "plantationImages": [],
                                "countSpeciesUpdated": 490
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 490,
                                "plantationImages": [],
                                "countSpeciesUpdated": 490
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 490,
                                "plantationImages": [],
                                "countSpeciesUpdated": 490
                            }
                        ]
                    }
                },
                {
                    "name": "BCM Boys Hostel, Hilton park",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.15"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            }
                        ]
                    }
                },
                {
                    "name": "Keshawapur (Naveen Park)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.15"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 800,
                        "totalTreesPlanned": 800,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 160,
                                "plantationImages": [],
                                "countSpeciesUpdated": 160
                            }
                        ]
                    }
                },
                {
                    "name": "Tabiba land tank",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3333",
                        "longitude": "75.0833"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            }
                        ]
                    }
                },
                {
                    "name": "KLE Technology university",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.0333"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 250,
                        "totalTreesPlanned": 250,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Tarihal Village",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.35",
                        "longitude": "75.05"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 40,
                                "plantationImages": [],
                                "countSpeciesUpdated": 40
                            }
                        ]
                    }
                },
                {
                    "name": "Navnagar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3833",
                        "longitude": "75.05"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                },
                {
                    "name": "WTP Amminbhavi Govt school - Shri Shanteswar High school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.5333",
                        "longitude": "75"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 150,
                        "totalTreesPlanned": 150,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            }
                        ]
                    }
                },
                {
                    "name": "GulganjiKoppa ELSR",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.45",
                        "longitude": "75.1167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 1500,
                        "totalTreesPlanned": 1500,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 750,
                                "plantationImages": [],
                                "countSpeciesUpdated": 750
                            }
                        ]
                    }
                },
                {
                    "name": "Ganesh Colony, Hubli",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3167",
                        "longitude": "75.0167"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 120,
                        "totalTreesPlanned": 120,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 60,
                                "plantationImages": [],
                                "countSpeciesUpdated": 60
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 60,
                                "plantationImages": [],
                                "countSpeciesUpdated": 60
                            }
                        ]
                    }
                },
                {
                    "name": "Mrutunjaya High school, Murgamatt",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4667",
                        "longitude": "75.0524"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 320,
                        "totalTreesPlanned": 320,
                        "uniqueSpeciesPlanted": 5,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2023-04-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 64,
                                "plantationImages": [],
                                "countSpeciesUpdated": 64
                            },
                            {
                                "speciesName": "Cherry",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/cheery.png",
                                "count": 64,
                                "plantationImages": [],
                                "countSpeciesUpdated": 64
                            },
                            {
                                "speciesName": "Mango (Mangifera indica)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mango-mangifera-indica.png",
                                "count": 64,
                                "plantationImages": [],
                                "countSpeciesUpdated": 64
                            },
                            {
                                "speciesName": "Silver Oak",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/silver-oak.png",
                                "count": 64,
                                "plantationImages": [],
                                "countSpeciesUpdated": 64
                            },
                            {
                                "speciesName": "Sagwan (Teak, Tectona grandis)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/sagwan-teak-tectona-grandis.png",
                                "count": 64,
                                "plantationImages": [],
                                "countSpeciesUpdated": 64
                            }
                        ]
                    }
                },
                {
                    "name": "WTP New construction area",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.5386",
                        "longitude": "75.0524"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 390,
                        "totalTreesPlanned": 418,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 98,
                                "plantationImages": [],
                                "countSpeciesUpdated": 98
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 98,
                                "plantationImages": [],
                                "countSpeciesUpdated": 98
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 98,
                                "plantationImages": [],
                                "countSpeciesUpdated": 98
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 98,
                                "plantationImages": [],
                                "countSpeciesUpdated": 98
                            }
                        ]
                    }
                },
                {
                    "name": "Kanavi honnapura",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3694",
                        "longitude": "75.0078"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 910,
                        "totalTreesPlanned": 994,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 303,
                                "plantationImages": [],
                                "countSpeciesUpdated": 303
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 303,
                                "plantationImages": [],
                                "countSpeciesUpdated": 303
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 303,
                                "plantationImages": [],
                                "countSpeciesUpdated": 303
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 303,
                                "plantationImages": [],
                                "countSpeciesUpdated": 303
                            }
                        ]
                    }
                },
                {
                    "name": "Neerasagar dam",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3284",
                        "longitude": "74.9822"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 2090,
                        "totalTreesPlanned": 2370,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 524,
                                "plantationImages": [],
                                "countSpeciesUpdated": 524
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 524,
                                "plantationImages": [],
                                "countSpeciesUpdated": 524
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 524,
                                "plantationImages": [],
                                "countSpeciesUpdated": 524
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 524,
                                "plantationImages": [],
                                "countSpeciesUpdated": 524
                            }
                        ]
                    }
                },
                {
                    "name": "MR Nagar Murughamath",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3284",
                        "longitude": "75.0213"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 107,
                        "totalTreesPlanned": 135,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 27,
                                "plantationImages": [],
                                "countSpeciesUpdated": 27
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 27,
                                "plantationImages": [],
                                "countSpeciesUpdated": 27
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 27,
                                "plantationImages": [],
                                "countSpeciesUpdated": 27
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 27,
                                "plantationImages": [],
                                "countSpeciesUpdated": 27
                            }
                        ]
                    }
                },
                {
                    "name": "Shri N G B Govt school Shirur (Raj nagar)",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4626",
                        "longitude": "75.5004"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 150,
                        "totalTreesPlanned": 150,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-08-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 75,
                                "plantationImages": [],
                                "countSpeciesUpdated": 75
                            }
                        ]
                    }
                },
                {
                    "name": "Gulaganjikoppa",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4597",
                        "longitude": "75.4432"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 150,
                        "totalTreesPlanned": 150,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-26",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Joga yallapur school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.0971",
                        "longitude": "75.0078"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 40,
                        "totalTreesPlanned": 40,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 10,
                                "plantationImages": [],
                                "countSpeciesUpdated": 10
                            }
                        ]
                    }
                },
                {
                    "name": "Kalyan nagar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4589",
                        "longitude": "74.9947"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-27",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                },
                {
                    "name": "Madar Teresa school vanashree nagar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4412",
                        "longitude": "75.0078"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                },
                {
                    "name": "Yattinagudda",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4589",
                        "longitude": "74.9903"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-30",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 13,
                                "plantationImages": [],
                                "countSpeciesUpdated": 13
                            }
                        ]
                    }
                },
                {
                    "name": "Bharati nagara Dharwad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4879",
                        "longitude": "74.9922"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 30,
                        "totalTreesPlanned": 30,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-09-28",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 8,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 8,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 8,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 8,
                                "plantationImages": [],
                                "countSpeciesUpdated": 8
                            }
                        ]
                    }
                },
                {
                    "name": "Gulaganjikoppa ELSR",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4554",
                        "longitude": "75.4432"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 156,
                        "totalTreesPlanned": 156,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 52,
                                "plantationImages": [],
                                "countSpeciesUpdated": 52
                            },
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 52,
                                "plantationImages": [],
                                "countSpeciesUpdated": 52
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 52,
                                "plantationImages": [],
                                "countSpeciesUpdated": 52
                            }
                        ]
                    }
                },
                {
                    "name": "Yarikoppa Govt high school",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.0971",
                        "longitude": "75.0193"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-01",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                },
                {
                    "name": "MR Sakshara school Hubballi- budda vihar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3284",
                        "longitude": "75.12"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 152,
                        "totalTreesPlanned": 152,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-14",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 38,
                                "plantationImages": [],
                                "countSpeciesUpdated": 38
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 38,
                                "plantationImages": [],
                                "countSpeciesUpdated": 38
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 38,
                                "plantationImages": [],
                                "countSpeciesUpdated": 38
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 38,
                                "plantationImages": [],
                                "countSpeciesUpdated": 38
                            }
                        ]
                    }
                },
                {
                    "name": "Sanmati nagar Dharwad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3717",
                        "longitude": "74.9911"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-19",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Bharati nagara Dharwad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4578",
                        "longitude": "74.9922"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-18",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 50,
                                "plantationImages": [],
                                "countSpeciesUpdated": 50
                            }
                        ]
                    }
                },
                {
                    "name": "Neharu nagar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4554",
                        "longitude": "74.972"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-16",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ]
                    }
                },
                {
                    "name": "MR Nagra Tank",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4546",
                        "longitude": "75.0213"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 200,
                        "totalTreesPlanned": 200,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 67,
                                "plantationImages": [],
                                "countSpeciesUpdated": 67
                            }
                        ]
                    }
                },
                {
                    "name": "P1 P2 Hubli",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4626",
                        "longitude": "75.1412"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 50,
                        "totalTreesPlanned": 50,
                        "uniqueSpeciesPlanted": 3,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-10",
                        "speciesPlanted": [
                            {
                                "speciesName": "Jamun( Blackberry, Black Plum, Malabar Plum, Syzygium cumini)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/jamun-blackberry-black-plum-malabar-plum-syzygium-cumini.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 17,
                                "plantationImages": [],
                                "countSpeciesUpdated": 17
                            }
                        ]
                    }
                },
                {
                    "name": "HDPE pipeline dharwad",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.4589",
                        "longitude": "75.0078"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 500,
                        "totalTreesPlanned": 500,
                        "uniqueSpeciesPlanted": 2,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-23",
                        "speciesPlanted": [
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            },
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 250,
                                "plantationImages": [],
                                "countSpeciesUpdated": 250
                            }
                        ]
                    }
                },
                {
                    "name": "MR Sakshara school Hubballi- budda vihar",
                    "recapture": {
                        "recaptureToDate": 0
                    },
                    "location": {
                        "latitude": "15.3717",
                        "longitude": "75.12"
                    },
                    "satelliteImages": [],
                    "plantation": {
                        "totalTreesPlanted": 100,
                        "totalTreesPlanned": 100,
                        "uniqueSpeciesPlanted": 4,
                        "survivedPercentage": 100,
                        "lastPlantationDate": "2024-10-21",
                        "speciesPlanted": [
                            {
                                "speciesName": "Neem (Bevu)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/neem-bevu.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Guava (Amrud, Psidium)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/guava-amrud-psidium.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Mahogany (Mahagoni)",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/mahogany-mahagoni.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            },
                            {
                                "speciesName": "Coconut Tree",
                                "image": "https://mynzo.s3.ap-south-1.amazonaws.com/species/coconut-tree.png",
                                "count": 25,
                                "plantationImages": [],
                                "countSpeciesUpdated": 25
                            }
                        ]
                    }
                }
            ]
        }
    },
    "growersData": []
}