/* eslint-disable react/no-unstable-nested-components */
import React, { useMemo, useState } from 'react'
import { commaSeparatedFormat } from '../../../utils/helpers'
import ReactTablePurple from '../../../../_metronic/partials/components/tables/ReactTablePurple'
import ModalFuelSavedHistory from './modal-fuel-saved-history'

// Render components for table cells
const RenderEmissionSource = ({ value, disabled }: { value: string, disabled: boolean }) => (
  <div className="d-flex justify-content-center px-4">
    <p className='m-0 text-center'>{value}</p>
  </div>
)

const RenderNumber = ({ value, disabled }: { value: number, disabled: boolean }) => (
  <div className="d-flex justify-content-center px-4">
    <p className='m-0 text-center'>{value ? commaSeparatedFormat(value) : '--'}</p>
  </div>
)

const RenderSavedNumber = ({ value, disabled, setShowModal, setSelectedData, data }: { value: number, disabled: boolean, setShowModal: (show: boolean) => void, setSelectedData: (data: any) => void, data: any }) => (
  <div className='d-flex justify-content-center'>
    <button 
      type='button' 
      className="d-flex justify-content-center remove-btn-styles px-4" 
      style={{pointerEvents: disabled ? 'none' : 'auto'}} 
      disabled={disabled}
      onClick={() => {
        setSelectedData(data)
        setTimeout(() => {
          setShowModal(true)
        }, 400)
      }}
    >
      <p className='m-0 text-center text-primary'>{value ? commaSeparatedFormat(value) : '--'}</p>
    </button>
  </div>
)

const FuelEmissionTable = ({ listData = [] }: { listData: any[] }) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null)

  const headers = useMemo(
    () => [
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>FUEL SOURCE</p></div>,
        accessor: 'fuelSource',
        Cell: ({ cell }: { cell: any }) => <RenderEmissionSource value={cell.row.original.fuelSource} disabled={cell.row.original.disabled} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>FUEL CONSUMED</p></div>,
        accessor: 'fuelConsumed',
        Cell: ({ cell }: { cell: any }) => <RenderNumber value={Math.round(cell.row.original.fuelConsumed)} disabled={cell.row.original.disabled} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>SAVED EMISSIONS (tCO2e)</p></div>,
        accessor: 'savedEmissions',
        Cell: ({ cell }: { cell: any }) => 
          <RenderSavedNumber 
            value={cell.row.original.savedEmissions}
            data={{data: cell.row.original.data, fuelSource: cell.row.original.fuelSource, fuelConsumed: cell.row.original.fuelConsumed, savedEmissions: cell.row.original.savedEmissions}}
            disabled={cell.row.original.disabled} 
            setShowModal={setShowModal} 
            setSelectedData={setSelectedData}
          />
      },
    ],
    []
  )

  if (listData?.length === 0) 
    return <div className='d-flex justify-content-center bordered-grey py-12'><h3 className='m-0'>No fuel emission data found</h3></div>

  return (
    <>
      <div className='p-0 m-0'>
        <ReactTablePurple
          headerData={headers}
          tableData={listData.reverse()}
          className='mt-6 custom-border'
          currentPageIndex={0}
          disableSort
          showPagination={false}
          pageSize={listData.length}
          message="No results found"
          />
      </div>
      <ModalFuelSavedHistory
        data={selectedData}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  )
}

export default FuelEmissionTable