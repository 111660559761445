import { useEffect, useState } from "react";
import moment from "moment";

export const useSetForestPlantationChartData = (forestPlantationChartData: any) => {
  let areaDoneLabel = 'Trees Planted';
  let areaTargetLabel = 'Trees Planned';
  
  if (forestPlantationChartData?.type === 'PURCHASED') {
    areaDoneLabel = 'Acres Purchased';
    areaTargetLabel = 'Acres Purchased';
  }

  const [chartForestPlantationData, setChartForestPlantationData] = useState<any>({
    series: [{name: areaDoneLabel, data: []}, {name: areaTargetLabel, data: []}],
    categories: []
  });

  useEffect(() => {
    if (forestPlantationChartData?.data && forestPlantationChartData.data.length > 0) {
      const series: any[] = [{name: areaTargetLabel, data: []}, {name: areaDoneLabel, data: []}];
      const categories: string[] = [];

      forestPlantationChartData.data.forEach((data: any) => {
        categories.push(moment(data.date).format('MMM YYYY'));
        series[0].data.push(data.target);
        series[1].data.push(data.done);
      });

      setChartForestPlantationData({series, categories});
    }
  }, [forestPlantationChartData]);

  if (!forestPlantationChartData) {
    return {
      chartForestPlantationData: {
        series: [{name: areaDoneLabel, data: []}, {name: areaTargetLabel, data: []}],
        categories: []
      }
    }
  }

  return {
    chartForestPlantationData
  }
}