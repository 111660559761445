import { RenderStatusPill } from "./render-status-pill";

interface AccordionWrapperProps {
  index: number;
  children: React.ReactNode;
  status?: 'completed' | 'in_progress' | 'not_uploaded';
  title: string;
}

const getIndexedAccordion = (index: number, children: React.ReactNode, title: string, status?: 'completed' | 'in_progress' | 'not_uploaded') => {
  const allAccordions: { [key: string]: React.ReactElement } = {
    '0': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='oneSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-oneSection' 
              aria-expanded="false" 
              aria-controls='panel-oneSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-oneSection' className="accordion-collapse collapse" aria-labelledby='oneSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '1': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twoSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twoSection' 
              aria-expanded="false" 
              aria-controls='panel-twoSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twoSection' className="accordion-collapse collapse" aria-labelledby='twoSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '2': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='threeSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-threeSection' 
              aria-expanded="false" 
              aria-controls='panel-threeSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-threeSection' className="accordion-collapse collapse" aria-labelledby='threeSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '3': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fourSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fourSection' 
              aria-expanded="false" 
              aria-controls='panel-fourSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-fourSection' className="accordion-collapse collapse" aria-labelledby='fourSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '4': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fiveSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fiveSection' 
              aria-expanded="false" 
              aria-controls='panel-fiveSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-fiveSection' className="accordion-collapse collapse" aria-labelledby='fiveSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '5': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='sixSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-sixSection' 
              aria-expanded="false" 
              aria-controls='panel-sixSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-sixSection' className="accordion-collapse collapse" aria-labelledby='sixSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '6': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='sevenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-sevenSection' 
              aria-expanded="false" 
              aria-controls='panel-sevenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-sevenSection' className="accordion-collapse collapse" aria-labelledby='sevenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '7': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='eightSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-eightSection' 
              aria-expanded="false" 
              aria-controls='panel-eightSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-eightSection' className="accordion-collapse collapse" aria-labelledby='eightSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '8': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='nineSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-nineSection' 
              aria-expanded="false" 
              aria-controls='panel-nineSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-nineSection' className="accordion-collapse collapse" aria-labelledby='nineSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '9': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='tenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-tenSection' 
              aria-expanded="false" 
              aria-controls='panel-tenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-tenSection' className="accordion-collapse collapse" aria-labelledby='tenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '10': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='elevenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-elevenSection' 
              aria-expanded="false" 
              aria-controls='panel-elevenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-elevenSection' className="accordion-collapse collapse" aria-labelledby='elevenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '11': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twelveSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twelveSection' 
              aria-expanded="false" 
              aria-controls='panel-twelveSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twelveSection' className="accordion-collapse collapse" aria-labelledby='twelveSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '12': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirteenSection' 
              aria-expanded="false" 
              aria-controls='panel-thirteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirteenSection' className="accordion-collapse collapse" aria-labelledby='thirteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '13': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fourteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fourteenSection' 
              aria-expanded="false" 
              aria-controls='panel-fourteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-fourteenSection' className="accordion-collapse collapse" aria-labelledby='fourteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '14': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fifteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fifteenSection' 
              aria-expanded="false" 
              aria-controls='panel-fifteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-fifteenSection' className="accordion-collapse collapse" aria-labelledby='fifteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '15': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='sixteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-sixteenSection' 
              aria-expanded="false" 
              aria-controls='panel-sixteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-sixteenSection' className="accordion-collapse collapse" aria-labelledby='sixteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '16': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='seventeenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-seventeenSection' 
              aria-expanded="false" 
              aria-controls='panel-seventeenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-seventeenSection' className="accordion-collapse collapse" aria-labelledby='seventeenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '17': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='eighteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-eighteenSection' 
              aria-expanded="false" 
              aria-controls='panel-eighteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-eighteenSection' className="accordion-collapse collapse" aria-labelledby='eighteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '18': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='nineteenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-nineteenSection' 
              aria-expanded="false" 
              aria-controls='panel-nineteenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-nineteenSection' className="accordion-collapse collapse" aria-labelledby='nineteenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '19': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentySection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentySection' 
              aria-expanded="false" 
              aria-controls='panel-twentySection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentySection' className="accordion-collapse collapse" aria-labelledby='twentySection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '20': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentyOneSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentyOneSection' 
              aria-expanded="false" 
              aria-controls='panel-twentyOneSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentyOneSection' className="accordion-collapse collapse" aria-labelledby='twentyOneSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '21': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentyTwoSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentyTwoSection' 
              aria-expanded="false" 
              aria-controls='panel-twentyTwoSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentyTwoSection' className="accordion-collapse collapse" aria-labelledby='twentyTwoSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '22': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentyThreeSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentyThreeSection' 
              aria-expanded="false" 
              aria-controls='panel-twentyThreeSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentyThreeSection' className="accordion-collapse collapse" aria-labelledby='twentyThreeSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '23': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentyFourSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentyFourSection' 
              aria-expanded="false" 
              aria-controls='panel-twentyFourSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentyFourSection' className="accordion-collapse collapse" aria-labelledby='twentyFourSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '24': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentyFiveSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentyFiveSection' 
              aria-expanded="false" 
              aria-controls='panel-twentyFiveSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentyFiveSection' className="accordion-collapse collapse" aria-labelledby='twentyFiveSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '25': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentySixSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentySixSection' 
              aria-expanded="false" 
              aria-controls='panel-twentySixSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentySixSection' className="accordion-collapse collapse" aria-labelledby='twentySixSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '26': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentySevenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentySevenSection' 
              aria-expanded="false" 
              aria-controls='panel-twentySevenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentySevenSection' className="accordion-collapse collapse" aria-labelledby='twentySevenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '27': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentyEightSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentyEightSection' 
              aria-expanded="false" 
              aria-controls='panel-twentyEightSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentyEightSection' className="accordion-collapse collapse" aria-labelledby='twentyEightSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '28': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='twentyNineSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-twentyNineSection' 
              aria-expanded="false" 
              aria-controls='panel-twentyNineSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-twentyNineSection' className="accordion-collapse collapse" aria-labelledby='twentyNineSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '29': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtySection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtySection' 
              aria-expanded="false" 
              aria-controls='panel-thirtySection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtySection' className="accordion-collapse collapse" aria-labelledby='thirtySection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '30': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtyOneSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtyOneSection' 
              aria-expanded="false" 
              aria-controls='panel-thirtyOneSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtyOneSection' className="accordion-collapse collapse" aria-labelledby='thirtyOneSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '31': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtyTwoSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtyTwoSection' 
              aria-expanded="false" 
              aria-controls='panel-thirtyTwoSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtyTwoSection' className="accordion-collapse collapse" aria-labelledby='thirtyTwoSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '32': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtyThreeSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtyThreeSection' 
              aria-expanded="false" 
              aria-controls='panel-thirtyThreeSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtyThreeSection' className="accordion-collapse collapse" aria-labelledby='thirtyThreeSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '33': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtyFourSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtyFourSection' 
              aria-expanded="false" 
              aria-controls='panel-thirtyFourSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtyFourSection' className="accordion-collapse collapse" aria-labelledby='thirtyFourSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '34': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtyFiveSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtyFiveSection' 
              aria-expanded="false" 
              aria-controls='panel-thirtyFiveSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtyFiveSection' className="accordion-collapse collapse" aria-labelledby='thirtyFiveSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '35': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtySixSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtySixSection' 
              aria-expanded="false" 
              aria-controls='panel-thirtySixSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtySixSection' className="accordion-collapse collapse" aria-labelledby='thirtySixSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '36': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtySevenSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtySevenSection' 
              aria-expanded="false" 
              aria-controls='panel-thirtySevenSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtySevenSection' className="accordion-collapse collapse" aria-labelledby='thirtySevenSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '37': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtyEightSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtyEightSection' 
              aria-expanded="false" 
              aria-controls='panel-thirtyEightSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtyEightSection' className="accordion-collapse collapse" aria-labelledby='thirtyEightSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '38': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='thirtyNineSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-thirtyNineSection' 
              aria-expanded="false" 
              aria-controls='panel-thirtyNineSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-thirtyNineSection' className="accordion-collapse collapse" aria-labelledby='thirtyNineSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '39': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fortySection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fortySection' 
              aria-expanded="false" 
              aria-controls='panel-fortySection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-fortySection' className="accordion-collapse collapse" aria-labelledby='fortySection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>,
    '40': 
      <div className="accordion bordered-grey mb-4" id="accordion" style={{borderRadius: 10, overflow: 'hidden'}}>
        <div className="accordion-item" style={{border: 'none'}}>
          <h2 className="accordion-header py-2" id='fortyOneSection'>
            <button 
              className="accordion-button collapsed fs-3 bold bg-white" 
              type="button" 
              data-bs-toggle="collapse" 
              data-bs-target='#panel-fortyOneSection' 
              aria-expanded="false" 
              aria-controls='panel-fortyOneSection'
            >
              <p className='report-sec-header' style={{color: "black", fontWeight: 600}}>{title}</p>
            </button>
            {status && (
              <div className='accordion-right-header' style={{display: 'flex', paddingRight: 20, justifyContent: 'flex-end'}}>
                <RenderStatusPill status={status} />
              </div>
            )}
          </h2>
          <div id='panel-fortyOneSection' className="accordion-collapse collapse" aria-labelledby='fortyOneSection'>
            <div className="accordion-body">
              {children}
            </div>
          </div>
        </div>
      </div>
  }

  return allAccordions[index]
}

export default function AccordionWrapper({index, children, status, title}: AccordionWrapperProps) {
  return getIndexedAccordion(index, children, title, status)
}