/* eslint-disable react/no-unstable-nested-components */

import React, { useMemo } from 'react'
import { commaSeparatedFormat } from '../../../utils/helpers'
import ReactTablePurple from '../../../../_metronic/partials/components/tables/ReactTablePurple'

// Render components for table cells
const RenderText = ({ value }: { value: string }) => (
  <div className="d-flex justify-content-center py-10 px-2"><p className='m-0' style={{textAlign: 'center'}}>{value}</p></div>
)

const RenderNumber = ({ value }: { value: number }) => (
  <div className="d-flex justify-content-center"><p className='m-0' style={{textAlign: 'center'}}>{commaSeparatedFormat(value)}</p></div>
)

const ScopeTwoEmissionTable = ({ listData = [] }: { listData: any[] }) => {
  const headers = useMemo(
    () => [
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>Operational Boundary</p></div>,
        accessor: 'operationalBoundary',
        Cell: ({ cell }: { cell: any }) => <RenderText value={cell.row.original.operationalBoundary} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>Emissions Source</p></div>,
        accessor: 'emissionsSource',
        Cell: ({ cell }: { cell: any }) => <RenderText value={cell.row.original.emissionsSource} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>Emissions Per Employee (tCO2e)</p></div>,
        accessor: 'emissionsPerEmployee',
        Cell: ({ cell }: { cell: any }) => <RenderNumber value={Math.round(cell.row.original?.emissionsPerEmployee || 0)/1000} />
      },
      {
        Header: () => <div className='d-flex justify-content-center'><p className='m-0' style={{color: 'black'}}>Total Emissions (tCO2e)</p></div>,
        accessor: 'totalEmissions',
        Cell: ({ cell }: { cell: any }) => <RenderNumber value={Math.round(cell.row.original?.totalEmissions || 0)/1000} />
      },
    ],
    []
  )

  if (listData?.length === 0) 
    return (
      <div className='d-flex justify-content-center align-items-center bordered-grey mt-4' style={{height: '140px'}}>
        <p className='m-0' style={{fontSize: '16px', fontWeight: 600}}>No Scope 2 emission data found</p>
      </div>
    )

  return (
    <div className='p-0 m-0'>
      <ReactTablePurple
        headerData={headers}
        tableData={listData}
        className='mt-6 custom-border'
        currentPageIndex={0}
        disableSort
        showPagination={false}
        pageSize={listData.length}
        message="No results found"
      />
    </div>
  )
}

export default ScopeTwoEmissionTable
