import { useEffect, useState } from 'react'
import FuelEmissionTable from './components/fuel-emission-table'
import { Dropdown } from '../../ui/components/dropdown'
import { useLazyGetProjectListQuery } from '../our-forest/store/api'
import { useLazyGetFuelSavingsDataQuery } from '../dashboard/store/api'


const transformFuelSavingsData = (data: any) => {
  // Transform API response to match the expected format for the table
  if (!data?.fuels || !Array.isArray(data.fuels)) return []
  
  return data.fuels.map((item: any) => ({
    fuelSource: item.fuelName,
    fuelConsumed: item.fuelConsumed,
    savedEmissions: item.emissionSaved,
    disabled: item.emissionSaved === 0,
    data: item.data ? item.data.map((entry: any) => ({
      date: entry.date,
      fuelConsumed: entry.fuelConsumed,
      savedEmissions: entry.emissionSaved
    })) : null
  }))
}

const FuelEmissionPage = () => {
  const [getProjectList, {data: projectListData, isLoading: isGetProjectListLoading}] = useLazyGetProjectListQuery()
  const [getFuelSavingsData, {data: fuelSavingsData, isLoading: isGetFuelSavingsDataLoading}] = useLazyGetFuelSavingsDataQuery()

  const [selectedProjectId, setSelectedProjectId] = useState(0)
  const [projectList, setProjectList] = useState([])
  const [fuelSavingsTableData, setFuelSavingsTableData] = useState([])

  useEffect(() => {
    getProjectList({})
  }, [])

  useEffect(() => {
    if (selectedProjectId === 0) {
      getFuelSavingsData({})
    } else {
      getFuelSavingsData({projectId: selectedProjectId})
    }
  }, [selectedProjectId])

  useEffect(() => {
    if (fuelSavingsData?.data) {
      setFuelSavingsTableData(transformFuelSavingsData(fuelSavingsData?.data))
    }
  }, [fuelSavingsData])

  useEffect(() => {
    if (projectListData) {
      setProjectList([{label: 'All Projects', value: 0}, ...projectListData.map((project: any) => ({label: project.name, value: project.id}))] as any)
    }
  }, [projectListData])

  const handleProjectChange = (value: string) => {
    setSelectedProjectId(Number(value))
  }

  return (
    <div className="blank-page-view pt-8 pb-16 px-6">
      <div className='p-0 m-0 d-flex justify-content-between align-items-end'>
        <h1 className='m-0' style={{fontWeight: 600}}>FUEL EMISSIONS</h1>
        <div className='d-flex align-items-center'>
          <h4 className='m-0' style={{color: 'black', fontWeight: 600, paddingRight: 20, paddingTop: 10}}>Project </h4>
          <Dropdown
            placeholder='Select Project'
            label=''
            options={projectList}
            selectedValue={selectedProjectId}
            onChange={handleProjectChange}
            dropdownStyle={{fontSize: 14, borderColor: '#bababa', color: 'black'}}
            containerStyle={{ width: 200, height: 40 }}
            disablePlaceholder
          />
        </div>
      </div>

      <div className='mt-8'>
        <FuelEmissionTable listData={fuelSavingsTableData} />
      </div>
    </div>
  )
}

export default FuelEmissionPage
