/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable */
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RoutesPath } from '../../routing/RoutesPath';
import HorizontalHeaderCard from '../forest/components/cards/horizontal-header-card';

import './styles.css';
import { ForestsPlantationChart } from './components/charts/forest-plantation-chart'
import { RootState } from '../../store/store';
import Loader from '../../../_metronic/partials/components/loader/Loader';
import SDGGoals from './components/cards/sdg-goals';
import { useSetForestPlantationChartData } from './hooks/useSetForestPlantationChartData';
import { Dropdown } from '../../ui/components/dropdown';
import { HeaderInfoCard } from './components/cards/header-info-card';
import { setSelectedProjectId } from '../../store/slices/forestSlice';
import { getProjectData as getProjectData2, GetProjectDataResponse, getTransformedTotalForestData } from './frontend-api2';
import { HeaderTitleCard } from './components/cards/header-title-card';
import { SpeciesInfoCarousel } from './components/carousels/species-info-carousel';
import { SurvivedPlantationChart } from './components/charts/survived-plantation-chart';
import { SpeciesSurvivedChart } from './components/charts/species-survived-chart';
import { GrowerInfoCards } from './components/cards/grower-info-cards';
import { getWidthClassForSpeciesChartAndMap } from './helper-forest-dashboard';
import MapProjectSites from './components/maps/map-project-sites';
import { commaSeparatedFormat, getEnvironment } from '../../utils/helpers';

import { useLazyGetTotalForestDataQuery, useLazyGetProjectListQuery, useLazyGetProjectDetailsQuery } from '../our-forest/store/api';
import { IconLocationSite, IconSapling, IconSearchFile, IconTrending } from '../../../_metronic/assets/icons';
import { PROD_LNT_ORG_ID } from '../../utils/dummyData';

type Props = {

}

const ForestDashboardPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {profile} = useSelector((state: RootState) => state.profile)
  const {selectedProjectId} = useSelector((state: RootState) => state.forest)

  const [getTotalForestData, {data: totalForestResponseData, isLoading: isGetTotalForestDataLoading}] = useLazyGetTotalForestDataQuery();
  const [getProjectList, {data: projectListResponseData, isLoading: isGetProjectListLoading}] = useLazyGetProjectListQuery();
  const [getProjectDetails, {data: projectDetailsResponseData, isLoading: isGetProjectDetailsLoading}] = useLazyGetProjectDetailsQuery();

  const [totalForestData, setTotalForestData] = useState<any>({
    plantation: {
      totalTreesPlanted: 0,
      totalTreesPlanned: 0,
      uniqueSpeciesPlanted: 0,
      speciesPlanted: [],
    },
    recapture: {
      recapturedPerYear: 0,
      recapturedThisYear: 0,
      recapturedToDate: 0,
    }
  })
  const [projectList, setProjectList] = useState([{label: 'All Projects', value: 0}])
  const [projectData, setProjectData] = useState<GetProjectDataResponse>()
  // const [chartForestPlantationData, setChartForestPlantationData] = useState<any>({series: [], categories: [], type: ''})
  
  const handleProjectChange = (value: number) => {
    dispatch(setSelectedProjectId(value))
  }

  const {chartForestPlantationData} = useSetForestPlantationChartData(totalForestResponseData?.forestPlantationChartData);
  
  useEffect(() => {
    if (selectedProjectId === 0) {
      getTotalForestData({});
    } else {
      getProjectDetails({projectId: selectedProjectId});
    }
  }, [selectedProjectId])

  useEffect(() => {
    getTotalForestData({});
    getProjectList({});
  }, [])

  useEffect(() => {
    if (totalForestResponseData) {
      setTotalForestData(getTransformedTotalForestData(totalForestResponseData));
    }
    if (totalForestResponseData && projectListResponseData && selectedProjectId === 0) {
      setProjectData(getProjectData2(totalForestResponseData, projectListResponseData, "total-forest"));
    }
    if (projectDetailsResponseData && projectListResponseData && selectedProjectId !== 0) {
      setProjectData(getProjectData2(projectDetailsResponseData, projectListResponseData, "project-details"));
    }
  }, [totalForestResponseData, projectDetailsResponseData, projectListResponseData, selectedProjectId])

  useEffect(() => {
    if (projectListResponseData?.length > 0) {
      setProjectList([
        {label: 'All Projects', value: 0}, 
        ...projectListResponseData.map((project:any) => ({label: project?.name, value: project?.id}))
      ]);
    }
  }, [projectListResponseData])

  const handleNavigateToPlantationAndUpdates = () => {
    navigate(
      RoutesPath.FOREST_DASHBOARD.PLANTATION_AND_UPDATES, 
      {
        state: {
          project: {
            ...projectListResponseData.find((project:any) => project.id === selectedProjectId),
            ...projectDetailsResponseData
          },
          projectId: selectedProjectId
        }
      }
    )
  }

  if (isGetProjectListLoading || isGetTotalForestDataLoading) 
    return (
      <div className='d-flex justify-content-center align-items-center' style={{height: '80vh'}}>
        <Loader />
      </div>
    )

  // if (!totalForestData && selectedProjectId === 0 && !isGetTotalForestDataLoading) 
  // return (
  //   <div className='d-flex justify-content-center align-items-center bordered-grey mt-12 mx-10 px-4' style={{height: '340px', background: 'linear-gradient(to bottom, #F8F8F8, #f1edff)'}}>
  //     <p className='m-0' style={{fontSize: '16px', fontWeight: 600, textAlign: 'center'}}>
  //       You have not purchased any forests yet. Please purchase a forest to view details. <br /> Contact support for more details.
  //       <a href='mailto:support@mynzocarbon.com' className='text-primary bold mx-2' style={{textDecoration: 'underline', fontSize: 16, display: 'inline'}}>
  //         support@mynzocarbon.com
  //       </a>
  //     </p>
  //   </div>
  // )
  
  return (
    <div className='blank-page-view pb-8 px-0 pt-0'>

      <div className='row px-6 mx-0 pt-6 pb-6' style={{backgroundColor: '#F8F8F8'}}>
        <div className='col-7 px-0 d-flex justify-content-between flex-column bordered-grey' style={{backgroundColor: 'white'}}>
          <ForestsPlantationChart 
            series={chartForestPlantationData.series} 
            categories={chartForestPlantationData.categories} 
            disableAnimation={false} 
            unit={chartForestPlantationData.type === 'PURCHASED' ? 'acres' : 'trees'} 
            />
        </div> 
        <div className='col-5' style={{padding: '0px 0px 0px 15px'}}>
          <HorizontalHeaderCard title='TOTAL TREES PLANTED' value={totalForestData.plantation.totalTreesPlanted} unit='trees' />
          <HorizontalHeaderCard title='RECAPTURE PER YEAR' value={totalForestData.recapture.recapturedPerYear} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' />
          <HorizontalHeaderCard title='RECAPTURE THIS YEAR' value={totalForestData.recapture.recapturedThisYear} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' />
          <HorizontalHeaderCard title='RECAPTURE TO DATE' value={totalForestData.recapture.recapturedToDate} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' isMarginBottom={false} />
        </div>
      </div>

      <SDGGoals showSDGGoals={getEnvironment() === 'production' && profile?.organization?.id === PROD_LNT_ORG_ID ? [3,4] : [1,2,3,4]} />

      <div className="px-6" style={{position: 'sticky', top: -10, left: 20, backgroundColor: 'white', zIndex: 1000}}>
        <div className='mx-0 mt-6 mb-4 d-flex justify-content-between align-items-end'>
          <h2 className='m-0'>PROJECTS</h2>
          <div className='d-flex align-items-center'>
            <Dropdown
              placeholder='Select Project'
              label=''
              options={projectList}
              selectedValue={selectedProjectId}
              onChange={handleProjectChange}
              dropdownStyle={{fontSize: 15, borderColor: '#bababa'}}
              containerStyle={{ width: 280, height: 40, marginRight: 20 }}
              disablePlaceholder
            />
            <button
              type='button'
              className='custom-primary-delete-button mb-0 mx-0'
              style={{padding: '8px 20px', fontWeight: 600}}
              onClick={()=> navigate(RoutesPath.FOREST.ATTRIBUTE_TO_EMPLOYEES)}
              disabled
              >
              ATTRIBUTE TO EMPLOYEES
            </button>
          </div>
        </div>

        <HeaderTitleCard 
          title={selectedProjectId === 0 ? 'OVERVIEW' : `${projectData?.name || ""} - OVERVIEW`}
          image={selectedProjectId === 0 ? '' : projectData?.image || 'https://mynzo.s3.ap-south-1.amazonaws.com/images/forest.svg'}
          icon={selectedProjectId === 0 ? <IconSearchFile style={{width: 50, height: 50}} color="#232b47" /> : null}
          location={selectedProjectId === 0 ? '' : projectData?.location}
          />
      </div>

      <div className='px-6 mx-0 mt-6 mb-2 d-flex justify-content-between gap-4'>
        <HeaderInfoCard 
          label={`Total Number Of ${selectedProjectId === 0 ? 'projects ' : ''}sites`}
          value={projectData?.plantation?.numberOfSites || '--'} 
          icon={<IconLocationSite style={{width: 40, height: 40}} />}
          />
        <HeaderInfoCard 
          label='Total Trees Planted / Planned' 
          value={`${commaSeparatedFormat(projectData?.plantation?.totalTreesPlanted || '--')}/${commaSeparatedFormat(projectData?.plantation?.totalTreesPlanned || '--')}`} 
          type='white' 
          />
        <HeaderInfoCard 
          label='Unique Species Planted' 
          value={projectData?.plantation?.uniqueSpeciesPlanted} 
          icon={<IconSapling style={{width: 40, height: 40}} />}
        />
        <HeaderInfoCard 
          label='Recapture Till Date' 
          value={projectData?.recapture?.recaptureToDate} 
          unit='kgCO2' 
          icon={<IconTrending style={{width: 40, height: 40}} />}
        />
      </div>

      {/* <div className='px-6 m-0 mb-8'>
        <SpeciesInfoCarousel data={projectData?.plantation?.speciesPlanted} />
      </div> */} 

      <div className='row px-4 mx-0 my-6' key={selectedProjectId}>
        <div className='col-5'>
          <h2 className='m-0 mb-4'>SPECIES PLANTED & SURVIVED</h2>
          <SpeciesSurvivedChart data={projectData?.speciesSurvivedChartData} />
        </div>
        <div className='col-7'>
          <h5 className='m-0 mb-4 d-flex align-items-center justify-content-end' style={{fontStyle: 'italic'}}>
            Drag or zoom out to view other locations
          </h5>
          <MapProjectSites mapPlotData={projectData?.mapPlotData} forceRerender={selectedProjectId} />
        </div>
      </div>

      {/* {
        projectData?.plantation?.speciesPlanted?.length <= 15 ? (
          <div className='row px-4 mx-0 mt-6 mb-4' key={selectedProjectId}>
            <div className={getWidthClassForSpeciesChartAndMap(projectData?.plantation?.speciesPlanted?.length).chartWidth}> 
              <SurvivedPlantationChart 
                disableAnimation
                chartData={projectData?.survivedSpeciesChartData}
                forceRerender={selectedProjectId}
              />
            </div>
            <div className={getWidthClassForSpeciesChartAndMap(projectData?.plantation?.speciesPlanted?.length).mapWidth}>
              <MapProjectSites mapPlotData={projectData?.mapPlotData} forceRerender={selectedProjectId} />
            </div>
          </div>
        ) : (
          <div className='px-6 mx-0 mt-6 mb-4' key={selectedProjectId}>
            <div className='mb-6 px-6 d-flex flex-grow-1 w-100' style={{height: '400px'}}>
              <MapProjectSites mapPlotData={projectData?.mapPlotData} forceRerender={selectedProjectId} />
            </div>
            <SurvivedPlantationChart 
              disableAnimation
              chartData={projectData?.survivedSpeciesChartData}
              forceRerender={selectedProjectId}
            />
          </div>
        )
      } */}

      <GrowerInfoCards growerInfo={projectData?.growerInfo} />

      {
        selectedProjectId !== 0 && (
          <div 
            className='px-6 mx-0 mt-6 mb-4 d-flex justify-content-end py-2'
            style={{position: 'sticky', bottom: 20, right: 20}}
          >
            <button 
              className='btn btn-primary remove-btn-styles'
              style={{ color: 'white', fontWeight: '600', fontSize: 15, backgroundColor: '#0491BD', padding: '8px 50px', borderRadius: 10, boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.4)' }}
              onClick={handleNavigateToPlantationAndUpdates}
            >
              VIEW PLANTATION AND UPDATES
            </button>
          </div>
        )
      }

      <div className="my-10" />
      
    </div>
  )
}

export default ForestDashboardPage
/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */