import moment from 'moment'
import { forestDashboardData } from './data-forest-dashboard'
import GrowerForestUpdateImagesPage from '../forest/grower-forest-update-images-page'

export const getProjectList = () => forestDashboardData.projectListData

export const getProjectDetails = (projectId: string | number) => forestDashboardData.projectListData.find((project) => project.id === projectId)

export type GetProjectDataResponse = {
  name?: string
  image?: string
  location?: {
    village?: string
    state?: string
    country?: string
  }
  plantation: {
    numberOfSites: number
    totalTreesPlanted: number
    totalTreesPlanned: number
    uniqueSpeciesPlanted: number
    speciesPlanted: {
      speciesName: string
      count: number
    }[]
  } 
  recapture: {
    recaptureToDate: number
  }
  survivedSpeciesChartData: {
    speciesPlanted: number[]
    survivedSpecies: number[]
    categories: string[]
  }
  speciesSurvivedChartData: {
    speciesPlanted: number[]
    percentSurvived: number[]
    categories: {
      speciesName: string
      image: string
    }[]
  }
  mapPlotData: {
    type: 'project-sites' | 'forest-sites'
    data: {
      id: string | number
      name: string
      data: {
        totalTreesPlanted: number
        totalTreesPlanned: number
        uniqueSpeciesPlanted: number
        forestData?: {
          recaptureToDate: number
          lastPlantationDate: string
          speciesPlanted: {
            speciesName: string
            image: string
            count: number
          }[]
          satelliteImages: {
            image: string
            date: string
          }[]
        }
      }
      latitude: string
      longitude: string
    }[]
  }
  growerInfo: {
    growersCount: number
    employmentGuaranteed: number
    growersList: {
      email: string
      projectId: string | number
    }[]
  }
}

export const getTransformedTotalForestData = (data: any): any => {
  const forestData = data;
  const result = {
    plantation: {
      totalTreesPlanted: forestData?.totalTreesPlanted || 0,
      totalTreesPlanned: forestData?.totalTreesPlanned || 0,
      uniqueSpeciesPlanted: forestData?.uniqueSpeciesCount || 0,
      speciesPlanted: forestData?.speciesPlanted || [],
    },
    recapture: {
      recapturedPerYear: forestData?.recapturedPerYear || 0,
      recapturedThisYear: forestData?.recapturedThisYear || 0,
      recapturedToDate: forestData?.recapturedToDate || 0,
    }
  }
  return result;
}

export const getProjectData = (data: any, projectListDataResponse: any, type: 'total-forest' | 'project-details'): GetProjectDataResponse => {
  /*
    "totalTreesPlanted": 0,
    "numberOfProjectSites": 1,
    "totalTreesPlanned": 0,
    "uniqueSpeciesCount": 0,
    "speciesPlanted": [],
    "forestPlantationChartData": {
        "type": "PLANNED",
        "data": []
    }
  */

  if (type === 'total-forest') {
    return {
      plantation: {
        numberOfSites: data?.numberOfProjectSites || 0,
        totalTreesPlanted: data?.totalTreesPlanted || 0,
        totalTreesPlanned: data?.totalTreesPlanned || 0,
        uniqueSpeciesPlanted: data?.uniqueSpeciesCount || 0,
        speciesPlanted: data?.speciesPlanted || [],
      },
      recapture: {
        recaptureToDate: data?.recapturedToDate || 0,
      },
      survivedSpeciesChartData: {
        speciesPlanted: data?.speciesPlanted?.map((species:any) => species?.treesPlanted || 0),
        survivedSpecies: data?.speciesPlanted?.map((species:any) => (species?.treesPlanted || 0) * (species?.survivedPercentage || 100) * 0.01),
        categories: data?.speciesPlanted?.map((species:any) => species?.speciesName),
      },
      speciesSurvivedChartData: {
        speciesPlanted: data?.speciesPlanted?.map((species:any) => species?.treesPlanted || 0),
        percentSurvived: data?.speciesPlanted?.map((species:any) => (species?.survivedPercentage || 100)),
        categories: data?.speciesPlanted?.map((species:any) => ({speciesName: species?.speciesName, image: species?.speciesImage})),
      },
      mapPlotData: {
        type: 'project-sites',
        data: projectListDataResponse?.map((project: any) => ({
          id: project.id,
          name: project.name,
          data: {
            totalTreesPlanted: project?.totalTreesPlanted || 0,
            totalTreesPlanned: project?.totalTreesPlanned || 0,
            uniqueSpeciesPlanted: project?.uniqueSpeciesCount || 0,
          },
          latitude: project.latitude,
          longitude: project.longitude,
        })),
      },
      growerInfo: {
        growersCount: data?.growersData?.length || 0,
        employmentGuaranteed: data?.employmentGenerated || 0,
        growersList: data?.growersData || [],
      },
    }
  }

  const projectListData = projectListDataResponse?.find((project: any) => project.id === data.id);
  const projectDetailsData = data
  return {
    name: projectListData?.name,
    image: projectListData?.image,
    location: {
      village: projectListData?.village,
      state: projectListData?.state,
      country: projectListData?.country,
    },
    plantation: {
      numberOfSites: projectDetailsData?.numberOfForestSites,
      totalTreesPlanted: projectListData?.totalTreesPlanted,
      totalTreesPlanned: projectListData?.totalTreesPlanned,
      uniqueSpeciesPlanted: projectListData?.uniqueSpeciesCount,
      speciesPlanted: projectDetailsData?.speciesPlanted,
    },
    recapture: {
      recaptureToDate: projectDetailsData?.recaptureToDate || 0,
    },
    survivedSpeciesChartData: {
      speciesPlanted: projectDetailsData?.speciesPlanted?.map((species: any) => species.treesPlanted || 0) || [],
      survivedSpecies: projectDetailsData?.speciesPlanted?.map((species: any) => (species.treesPlanted || 0) * (species.survivedPercentage || 100) * 0.01) || [],
      categories: projectDetailsData?.speciesPlanted?.map((species: any) => species.speciesName) || [],
    },
    speciesSurvivedChartData: {
      speciesPlanted: projectDetailsData?.speciesPlanted?.map((species: any) => species?.treesPlanted || 0) || [],
      percentSurvived: projectDetailsData?.speciesPlanted?.map((species: any) => (species?.survivedPercentage || 100)) || [],
      categories: projectDetailsData?.speciesPlanted?.map((species: any) => ({speciesName: species?.speciesName, image: species?.speciesImage})) || [],
    },
    mapPlotData: {
      type: 'forest-sites',
      data: projectDetailsData?.forestSiteDetails?.map((site: any, index: number) => ({
        id: index + 1,
        name: site.name,
        data: {
          totalTreesPlanted: site.totalTreesPlanted,
          totalTreesPlanned: site.totalTreesPlanned,
          uniqueSpeciesPlanted: site.uniqueSpeciesCount,
          forestData: {
            recaptureToDate: site?.recaptureToDate || 0,
            lastPlantationDate: site.lastPlantationDate,
            speciesPlanted: site.speciesPlanted?.map((species: any) => ({
              speciesName: species.speciesName,
              image: species.speciesImage,
              count: species.treesPlanted,
            })) || [],
            satelliteImages: site?.satelliteBoundryImages?.map((item: any) => ({
              image: item.image,
              date: item.date,
            })) || [],
          },
        },
        latitude: site.latitude,
        longitude: site.longitude,
      })),
    },
    growerInfo: {
      growersCount: forestDashboardData?.growersData?.reduce((acc, curr) => acc + (curr.projectId === data.id ? 1 : 0), 0),
      employmentGuaranteed: projectDetailsData?.employmentGenerated,
      growersList: forestDashboardData?.growersData?.filter((grower) => grower.projectId === data.id),
    },
  }
}

export type GetProjectPlantationDataResponse = {
  totalTreesPlanted: number
  totalTreesPlanned: number
  uniqueSpeciesPlanted: number
  speciesPlanted: {
    speciesName: string
    count: number
  }[]
}

export const getProjectPlantationData = (projectId: string | number): GetProjectPlantationDataResponse => {
  const projectDetailsData = forestDashboardData?.projectDetailsData?.[projectId]
  return {
    totalTreesPlanted: projectDetailsData?.plantation?.totalTreesPlanted,
    totalTreesPlanned: projectDetailsData?.plantation?.totalTreesPlanned,
    uniqueSpeciesPlanted: projectDetailsData?.plantation?.uniqueSpeciesPlanted,
    speciesPlanted: projectDetailsData?.plantation?.speciesPlanted,
  }
}

export type GetProjectPlantationTimeLineDataResponse = {
  dateWiseSpeciesPlanted: {
    date: string
    totalTreesPlanted: number
    speciesPlanted: {
      speciesName: string
      image: string
      count: number
      plantationImages: string[]
    }[]
  }[]
}

export const getProjectPlantationTimeLineData = (projectId: string | number): GetProjectPlantationTimeLineDataResponse => {
  const projectDetailsData = forestDashboardData.projectDetailsData[projectId]
  const dateWiseSpeciesPlanted = projectDetailsData?.plantation?.dateWiseSpeciesPlanted || []
  
  // Sort by date in descending order (latest first)
  const sortedData = [...dateWiseSpeciesPlanted].sort((a, b) => (
    new Date(b.date).getTime() - new Date(a.date).getTime()
  ))

  return {
    dateWiseSpeciesPlanted: sortedData,
  }
}

export type GetProjectPlantationSpeciesChartDataResponse = {
  details: {
    speciesName: string
    speciesImage: string
    speciesCount: number
  }
  chartData: {
    speciesPlanted: {speciesName: string, count: number, plantationImages: string[]}[]
    categories: string[]
  }
}

export const getProjectPlantationSpeciesChartData = (projectId: string | number, speciesName: string): GetProjectPlantationSpeciesChartDataResponse => {
  const projectDetailsData = forestDashboardData?.projectDetailsData?.[projectId]
  const dateWiseData = projectDetailsData?.plantation?.dateWiseSpeciesPlanted

  // Group data by month
  const monthlyData = dateWiseData?.reduce((acc: {[key: string]: any}, curr) => {
    const date = new Date(curr.date)
    const monthKey = `${date.getFullYear()}-${date.getMonth()}`
    
    const speciesData = curr.speciesPlanted.find(species => species.speciesName === speciesName)
    if (!speciesData) return acc

    if (!acc[monthKey]) {
      acc[monthKey] = {
        date,
        speciesName: speciesData.speciesName,
        image: speciesData.image,
        count: 0,
        plantationImages: []
      }
    }
    
    acc[monthKey].count += speciesData.count
    acc[monthKey].plantationImages = [...acc[monthKey].plantationImages, ...speciesData.plantationImages]
    
    return acc
  }, {})

  const monthlyDataArray = Object.values(monthlyData)
  
  if (!monthlyDataArray?.length) {
    return {
      details: {
        speciesName: '',
        speciesImage: '',
        speciesCount: 0,
      },
      chartData: {
        speciesPlanted: [],
        categories: [],
      },
    }
  }

  // Sort by date ascending
  monthlyDataArray.sort((a: any, b: any) => a.date.getTime() - b.date.getTime())

  return {
    details: {
      speciesName: monthlyDataArray[0]?.speciesName,
      speciesImage: monthlyDataArray[0]?.image,
      speciesCount: monthlyDataArray?.reduce((sum: number, item: any) => sum + item.count, 0),
    },
    chartData: {
      speciesPlanted: monthlyDataArray.map((data: any) => ({
        speciesName: data.speciesName,
        count: data.count,
        plantationImages: data.plantationImages,
      })),
      categories: monthlyDataArray.map((data: any) => 
        data.date.toLocaleString('en-US', { month: 'short', year: '2-digit' })
      ),
    },
  }
}

export const getProjectPlantationDateListOfSpeciesPlanted = (projectId: string | number): string[] => {
  const projectDetailsData = forestDashboardData.projectDetailsData[projectId]
  const dateWiseData = projectDetailsData?.plantation?.dateWiseSpeciesPlanted
  
  // Convert dates to Month YY format and remove duplicates
  const monthYearDates = dateWiseData.map((data) => {
    const date = moment(data.date).format('MMM YY')
    return date
  })

  return Array.from(new Set(monthYearDates))
}

export type GetProjectPlantationDateWiseSpeciesChartDataResponse = {
  details: {
    dateLabel: string
    totalPlanted: number
  }
  chartData: {
    speciesPlanted: {speciesName: string, count: number, plantationImages: string[]}[]
    categories: string[] // species names
  }
}

export const getProjectPlantationDateWiseSpeciesChartData = (projectId: string | number, date: string): GetProjectPlantationDateWiseSpeciesChartDataResponse => {
  const projectDetailsData = forestDashboardData.projectDetailsData[projectId]
  const dateWiseData = projectDetailsData?.plantation?.dateWiseSpeciesPlanted || []

  // Convert input date (e.g. "Jan 24") to match format YYYY-MM
  const [month, year] = date.split(' ')
  const targetMonth = moment(`${month} 20${year}`, 'MMM YYYY').format('YYYY-MM')

  // Filter data for the target month
  const monthlyData = dateWiseData.filter(data => 
    moment(data.date).format('YYYY-MM') === targetMonth
  )

  if (!monthlyData.length) {
    return {
      details: {
        dateLabel: date,
        totalPlanted: 0
      },
      chartData: {
        speciesPlanted: [],
        categories: []
      }
    }
  }

  // Aggregate species data for the month
  const speciesMap = new Map<string, {
    speciesName: string,
    count: number,
    plantationImages: string[]
  }>()

  let totalPlanted = 0

  monthlyData.forEach(data => {
    totalPlanted += data.totalTreesPlanted
    
    data.speciesPlanted.forEach(species => {
      const existing = speciesMap.get(species.speciesName)
      if (existing) {
        existing.count += species.count
        existing.plantationImages.push(...species.plantationImages)
      } else {
        speciesMap.set(species.speciesName, {
          speciesName: species.speciesName,
          count: species.count,
          plantationImages: [...species.plantationImages]
        })
      }
    })
  })

  const aggregatedSpecies = Array.from(speciesMap.values())

  return {
    details: {
      dateLabel: date,
      totalPlanted
    },
    chartData: {
      speciesPlanted: aggregatedSpecies,
      categories: aggregatedSpecies.map(species => species.speciesName)
    }
  }
}

export type GetProjectSpeciesUpdateListDataResponse = {
  speciesName: string
  image: string
  count: number
}[]

export const getProjectSpeciesUpdateListData = (projectId: string | number): GetProjectSpeciesUpdateListDataResponse => {
  const projectDetailsData = forestDashboardData.projectDetailsData[projectId]
  
  // Get all species from dateWiseSpeciesUpdated
  const allSpecies = projectDetailsData?.plantation?.dateWiseSpeciesUpdated?.flatMap(update => 
    update.speciesUpdated.map(species => ({
      speciesName: species.speciesName,
      image: species.image || '',
      count: 1 // Each occurrence counts as 1 update
    }))
  ) || []

  // Aggregate counts by species name
  const speciesMap = new Map<string, GetProjectSpeciesUpdateListDataResponse[0]>()
  
  allSpecies.forEach(species => {
    const existing = speciesMap.get(species.speciesName)
    if (existing) {
      existing.count += species.count
    } else {
      speciesMap.set(species.speciesName, species)
    }
  })

  return Array.from(speciesMap.values())
}

export const getProjectPlantationDateListOfSpeciesUpdated = (projectId: string | number): string[] => {
  const projectDetailsData = forestDashboardData.projectDetailsData[projectId]
  const dateWiseData = projectDetailsData?.plantation?.dateWiseSpeciesUpdated || []
  
  // Convert dates to Month YY format and remove duplicates
  const monthYearDates = dateWiseData.map((data) => {
    const date = moment(data.date).format('MMM YY')
    return date
  })

  return Array.from(new Set(monthYearDates))
}

export type GetProjectDateWiseSpeciesUpdateDataForSelectedMonthResponse = {
  allSpeciesUpdateData: {
    date: string
    speciesUpdated: {
      speciesName: string
      image: string
      count: number
      updateDetails: {
        circumference?: number;
        height?: number;
        circumferenceImage?: string;
        heightImage?: string;
        leafImage?: string;
        latitude: string;
        longitude: string;
      }[]
    }[]
  }[]
}

export const getProjectDateWiseSpeciesUpdateDataForSelectedMonth = (projectId: string | number, date: string, speciesList: string[]): GetProjectDateWiseSpeciesUpdateDataForSelectedMonthResponse => {
  const projectDetailsData = forestDashboardData.projectDetailsData[projectId]
  const dateWiseData = projectDetailsData?.plantation?.dateWiseSpeciesUpdated || []
  
  // Convert input date (MMM YY) to start/end of month for comparison
  const monthStart = moment(date, 'MMM YY').startOf('month')
  const monthEnd = moment(date, 'MMM YY').endOf('month')

  const filteredData = dateWiseData
    .filter((data:any) => {
      const dataDate = moment(data.date)
      return dataDate.isBetween(monthStart, monthEnd, 'day', '[]') // [] means inclusive
    })
    .map((data:any) => ({
      date: data.date,
      speciesUpdated: data.speciesUpdated
        .filter((species:any) => speciesList.length === 0 || speciesList.includes(species.speciesName))
        .map((species:any) => ({
          speciesName: species.speciesName,
          image: species.image,
          count: species.updateDetails.length,
          updateDetails: species.updateDetails
        }))
    }))

  return {
    allSpeciesUpdateData: filteredData
  }
}