import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { RoutesPath } from '../../../routing/RoutesPath'
import { SpeciesInfoCarousel } from '../components/carousels/species-info-carousel'
import SatelliteImagesCarousel from '../components/carousels/satellite-images-carousel'
import { HeaderInfoCard } from '../components/cards/header-info-card'

export const ForestSiteDetailsPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {data} = location.state as {data: any}

  return (
    <div className='blank-page-view py-2 px-8 mb-10'>
      <div className='back-header-btn mt-8 mb-6' style={{marginLeft: '0px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.FOREST_DASHBOARD.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h2 className='m-0 mx-4 bold'>{data?.name?.toUpperCase() || 'FOREST SITE DETAILS'}</h2>
      </div>

      <div className='m-0 mt-6 mb-2 d-flex justify-content-between gap-4'>
        <HeaderInfoCard 
          label='Total Trees Planted / Planned' 
          value={`${(data?.data?.totalTreesPlanted || '--')}/${(data?.data?.totalTreesPlanned || '--')}`} 
          />
        <HeaderInfoCard 
          label='Unique Species Planted' 
          value={data?.data?.uniqueSpeciesPlanted} 
        />
        <HeaderInfoCard 
          label='Recapture Till Date' 
          value={data?.data?.forestData?.recaptureToDate}
        />
        <HeaderInfoCard 
          label='Last Plantation' 
          value={moment(data?.data?.forestData?.lastPlantationDate).format('DD-MMM-YY')} 
          />
      </div>

      <SpeciesInfoCarousel data={data?.data?.forestData?.speciesPlanted} />

      <div className='mt-10'>
        <h2 className='m-0 mb-4'>SATELLITE IMAGES</h2>
        {
          data?.data?.forestData?.satelliteImages?.length > 0 ? (
            <SatelliteImagesCarousel data={data?.data?.forestData?.satelliteImages} />
          ) : (
            <div className='d-flex justify-content-center align-items-center bordered-grey' style={{height: '200px'}}>
              <h5 className='m-0'>No satellite images available</h5>
            </div>
          )
        }
      </div>

    </div>
  )
}