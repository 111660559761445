import { NoImageIcon } from "../../../../../_metronic/assets/icons"

type SpeciesSelectCardProps = {
  name: string
  image: string
  count: number
  isSelected: boolean
  onClick: () => void
  bkgColor?: string
}

export const SpeciesSelectCard = (props: SpeciesSelectCardProps) => {
  const {
    name,
    image,
    count,
    isSelected,
    onClick,
    bkgColor
  } = props;

  return (
    <button
      type="button"
      title={name || ""}
      onClick={onClick}
      className="remove-btn-styles py-6 d-flex align-items-center flex-column gap-2 self-center"
      style={{
        backgroundColor: isSelected ? '#342C53' : bkgColor || '#F4ECF8',
        borderRadius: 20,
        height: '100%',
        width: '100%',
      }}
    >
      {
        image ? (
          <img src={image} alt={name} style={{width: 60, height: 60, borderRadius: '50%'}} />
        ) : (
          <div className="p-4 d-flex justify-content-center align-items-center" style={{width: 60, height: 60, borderRadius: '50%', backgroundColor: '#d9d9d9'}}>
            <NoImageIcon height={35} width={35} color="#808080" />
          </div>
        )
      }
      <h2 className="m-0" style={{fontSize: 14, fontWeight: 600, color: isSelected ? '#ffffff' : '#000000'}}>{(name||"").slice(0, 8) + (name?.length > 8 ? "..." : "")}</h2>
      <h2 className="m-0" style={{fontSize: 14, fontWeight: 600, color: isSelected ? '#ffffff' : '#000000'}}>{count}</h2>
    </button>
  )
}