import { useState, useEffect, useRef } from "react";
import { styles } from "./styles";

interface DropdownProps {
  label: string;
  options: { label: string; value: string | number; }[];
  onChange: (value: any) => void;
  placeholder: string;
  selectedValue?: string | number | null;
  disabled?: boolean;
  dropdownStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  disablePlaceholder?: boolean;
}

export const Dropdown = (props: DropdownProps) => {
  const {
    options,
    onChange,
    placeholder,
    label,
    selectedValue,
    disabled = false,
    dropdownStyle,
    containerStyle,
    disablePlaceholder = false
  } = props;

  return (
    <div style={containerStyle}>
      {label && <p style={styles.label}>{label}</p>}
      <select
        disabled={disabled}
        className='form-select'
        onChange={(event: any) => {
          const value = !Number.isNaN(Number(event.target.value)) 
            ? Number(event.target.value) 
            : event.target.value;
          onChange(value);
        }}
        style={{...styles.greyBorder, padding: '8px 10px', paddingRight: '35px', fontSize: 15, marginTop: 6, ...dropdownStyle}}
        value={selectedValue || ''}
      >
        {!disablePlaceholder && <option value=''>{placeholder}</option>}
        {
          options.map((item: any) => (
            <option value={item.value} key={item.value}>
              {item.label}
            </option>
          ))
        }
      </select>
    </div>
  )
}

export type MultiSelectDropdownOptionType = {
  value: string
  label: string
}

export type MultiSelectDropdownProps = {
  placeholder?: string
  setSelectedOptions: (options: MultiSelectDropdownOptionType[]) => void
  selectedOptions: MultiSelectDropdownOptionType[]
  options: MultiSelectDropdownOptionType[]
  containerStyle?: React.CSSProperties
  dropdownBtnStyle?: React.CSSProperties
}

export const MultiSelectDropdown = (props: MultiSelectDropdownProps) => {
  const {
    placeholder = "Select Options",
    setSelectedOptions,
    selectedOptions,
    options = [],
    containerStyle,
    dropdownBtnStyle
  } = props;

  const dropdownRef = useRef<any>(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownLabelText, setDropdownLabelText] = useState<string>(placeholder);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  const handleCheckboxChange = (option: MultiSelectDropdownOptionType) => {
    const isOptionSelected = selectedOptions.findIndex(item => item.value === option.value) !== -1;
    if (isOptionSelected) {
      setSelectedOptions(selectedOptions.filter(item => item.value !== option.value));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (selectedOptions.length === 0) {
      setDropdownLabelText(placeholder);
    } else if (selectedOptions.length < 3) {
      setDropdownLabelText(selectedOptions.map((item) => item.label).join(', '));
    } else {
      setDropdownLabelText(`Selected (${selectedOptions.length})`);
    }
  }, [selectedOptions])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-100 multi-select-check-dropdown position-relative" ref={dropdownRef} style={containerStyle}>
      <button className="multi-select-check-dropdown-button" type="button" onClick={toggleDropdown} style={dropdownBtnStyle}>
        {dropdownLabelText}
      </button>
      {
        showDropdown && (
          <div className="multi-select-check-dropdown-content">
            {options.map((option: any) => (
              <label key={option.id} className='d-flex align-items-center'>
                <input
                  type="checkbox"
                  value={option.value}
                  checked={selectedOptions.findIndex((value: any) => value.value === option.value) !== -1}
                  onChange={() => handleCheckboxChange(option)}
                />
                {option.label}
              </label>
            ))}
          </div>
        )
      }
      <div 
        style={{
          position: 'absolute',
          height: '8px',
          width: '8px',
          bottom: '18px',
          right: '16px',
          border: '1px solid #202020',
          borderWidth: '0px 1px 1px 0px',
          rotate: '45deg',
        }} 
        />
    </div>
  );
};
