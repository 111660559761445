import {FC, useCallback, useEffect, useRef, useState} from 'react'
import moment from 'moment'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSS, getCSSVariableValue} from '../../../../../_metronic/assets/ts/_utils'
import { useThemeMode } from '../../../../../_metronic/partials'
import { commaSeparatedFormat } from '../../../../utils/helpers'
import { IconTreeBlack } from '../../../../../_metronic/assets/icons'

type SpeciesDatewisePlantationChartProps = {
  disableAnimation?: boolean
  chartData: {
    speciesPlanted: {speciesName: string, count: number, plantationImages: string[]}[]
    categories: string[]
  },
  details: {
    dateLabel: string
    totalPlanted: number
  }
  forceRerender?: number
}

const CHART_LABEL_COLORS = ['#2B3361'];

function getChartOptions(height: number, categories: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      // events: {
      //   click: (event, chartContext, config) => {
      //     console.log(chartContext, config)
      //   },
      // },
    },
    plotOptions: {},
    legend: {
      show: true,
      markers: {
        width: 15,
        height: 15,
        strokeColor: borderColor,
        strokeWidth: 0,
        fillColors: CHART_LABEL_COLORS,
        radius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      colors: CHART_LABEL_COLORS,
      opacity: 1,
    },
    xaxis: {
      categories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      title: {
        text: 'Number of Trees',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        formatter: (value) => commaSeparatedFormat(value),
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: (itm) => {
        const category = categories[itm.dataPointIndex]
        const data1 = (itm.series[0][itm.dataPointIndex])
        return `
          <div style="
            padding: 8px 12px 4px 12px;
            border-radius: 4px;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
            font-size: 13px;
            min-width: 200px;
          ">
            <div style="
              font-weight: 600;
              margin-bottom: 8px;
              display: flex;
              justify-content: space-between;
              color: #333;
            ">
              <span>${category}</span>
            </div>
            <div style="
              display: flex;
              justify-content: space-between;
              margin-bottom: 4px;
            ">
              <span style="color: #666;">Planted:</span>
              <span style="font-weight: 500;">${commaSeparatedFormat(data1)}</span>
            </div>
            <!-- div style="
              display: flex;
              justify-content: center;
            ">
              <p style="color: #555; margin-top: 4px;">(<span class="bold">Click</span> to view plantation images)</p>
            </div> -->
          </div>
        `
      },
      followCursor: false,
    },
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

const SpeciesDatewisePlantationChart: FC<SpeciesDatewisePlantationChartProps> = ({
  disableAnimation,
  chartData,
  details,
  forceRerender
}) => {
  const {speciesPlanted, categories} = chartData
  const chartref = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const [forceUpdate, setForceUpdate] = useState(0)

  const series = [
    {
      name: 'Species',
      data: speciesPlanted?.map((species) => species?.count) || [],
      type: 'bar', 
    },
  ]

  const refreshMode = useCallback(() => {
    if (!chartref.current) {
      return undefined
    }

    const height = parseInt(getCSS(chartref.current, 'height'), 10)
    const values = getChartOptions(height, categories)
    
    const options = {
      ...values,
      series,
      ...(disableAnimation && {
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
      ...(forceRerender && {
        rerender: true,
      }),
    }

    const chart = new ApexCharts(chartref.current, options)
    if (chart) {
      chart.render()
    }

    return chart
  }, [series, categories, disableAnimation, forceUpdate])

  useEffect(() => {
    const chart = refreshMode()
    return () => {
    if (chart) {
      chart.destroy()
      }
    }
  }, [chartref, mode, refreshMode, forceUpdate])

  useEffect(() => {
    setForceUpdate(forceUpdate + 1)
  }, [forceRerender])

  return (
    <div key={forceUpdate} className='card pt-2 bordered-grey'>
      <div className='card-body pt-4'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <div className='d-flex align-items-center gap-2' style={{marginLeft: '20px'}}>
            <h2 className='m-0'>{(details?.dateLabel)?.toUpperCase()}</h2>
          </div>
          <div className='d-flex align-items-center py-3 px-5' style={{backgroundColor: '#F8ECF5'}}>
            <IconTreeBlack style={{height: 24}} />
            <h5 className='m-0' style={{paddingLeft: 8}}>TOTAL PLANTED: {details?.totalPlanted}</h5>
          </div>
        </div>
        <div ref={chartref} id='kt_charts_widget_3_chart' />
      </div>
    </div>
  )
}

export {SpeciesDatewisePlantationChart}
