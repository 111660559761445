import { useEffect, useState } from "react"
import moment from "moment"
import SpeciesInfoCarousel from "../components/carousels/species-info-carousel"
import {
  GetProjectDateWiseSpeciesUpdateDataForSelectedMonthResponse
} from "../frontend-api"
import { Dropdown, MultiSelectDropdown, MultiSelectDropdownOptionType } from "../../../ui/components/dropdown"
import AccordionWrapper from "../../configuration/upload-data/components/acoordion-wrapper"
import SpeciesUpdateDetailsPage from "./species-update-details-page"
import { useLazyGetProjectUpdateDetailsQuery } from "../../our-forest/store/api"

type SpeciesUpdateData = {
  date: string
  speciesUpdated: {
    speciesName: string
    speciesType: string
    count: number
    image: string
    updateDetails: {
      circumference?: number;
      height?: number;
      circumferenceImage?: string;
      heightImage?: string;
      leafImage?: string;
      latitude: string;
      longitude: string;
    }[]
  }[]
}[]

// Helper function to convert YYYY-MM to start and end dates of the month
const getFormattedDate = (date: string) => {
  const dateObject = moment(date, 'YYYY-MM')
  const startDate =  dateObject.startOf('month').format('YYYY-MM-DD')
  const endDate = dateObject.endOf('month').format('YYYY-MM-DD')
  return {startDate, endDate}
}

const transformSpeciesUpdateData = (speciesList: any[], data: any): SpeciesUpdateData => {
  const result = data?.map((updates: any) => ({
    date: updates?.date,
    speciesUpdated: speciesList?.map((item: any) => {
      if (updates?.speciesUpdates?.some((update: any) => update?.speciesName === item?.speciesName)) {
        return {
          speciesName: item?.speciesName,
          speciesType: item?.speciesType,
          count: updates?.speciesUpdates?.filter((update: any) => update?.speciesName === item?.speciesName)?.length || 0,
          image: item?.image,
          updateDetails: updates?.speciesUpdates?.filter((update: any) => update?.speciesName === item?.speciesName).map((update: any) => ({
            circumference: update?.circumference,
            height: update?.height,
            circumferenceImage: update?.imageCircumference,
            heightImage: update?.imageHeight,
            leafImage: update?.imageLeaf,
            latitude: update?.latitude,
            longitude: update?.longitude,
          }))
        }
      } else return null;
    }).filter((item: any) => item !== null)
  })) || []
  return result;
}

export const ProjectUpdatePage = (props: {project: any}) => {
  const {project} = props

  const [getProjectUpdateDetails, {data: projectUpdateDetailsData, isLoading: isGetProjectUpdateDetailsLoading}] = useLazyGetProjectUpdateDetailsQuery()

  const [selectedDate, setSelectedDate] = useState<string>("")
  const [selectedSpeciesList, setSelectedSpeciesList] = useState<MultiSelectDropdownOptionType[]>([])

  const [showSpeciesDetailsPage, setShowSpeciesDetailsPage] = useState<boolean>(false)
  const [speciesDetailsPageData, setSpeciesDetailsPageData] = useState<any>(null)

  const [allSpeciesUpdateData, setAllSpeciesUpdateData] = useState<GetProjectDateWiseSpeciesUpdateDataForSelectedMonthResponse["allSpeciesUpdateData"]>([])

  const [dateListOfSpeciesUpdated, setDateListOfSpeciesUpdated] = useState<string[]>([])
  const [speciesListOfSpeciesUpdated, setSpeciesListOfSpeciesUpdated] = useState<any[]>([])

  useEffect(() => {
    if (project?.id) {
      getProjectUpdateDetails({projectId: Number(project?.id)})
    }
  }, [project?.id])

  useEffect(() => {
    if (projectUpdateDetailsData?.summary?.speciesUpdates?.length > 0 && speciesListOfSpeciesUpdated?.length === 0) {
      setSpeciesListOfSpeciesUpdated(projectUpdateDetailsData?.summary?.speciesUpdates?.map((item: any) => ({
        speciesName: item?.speciesName,
        speciesType: item?.speciesType,
        count: item?.treesUpdated,
        image: item?.speciesImage
      })))
    }
  }, [projectUpdateDetailsData])

  useEffect(() => {
    if (projectUpdateDetailsData?.summary?.availableMonthsData?.length > 0 && dateListOfSpeciesUpdated?.length === 0) {
      setDateListOfSpeciesUpdated(projectUpdateDetailsData?.summary?.availableMonthsData?.map((item: any) => item?.month))
      setSelectedDate(projectUpdateDetailsData?.summary?.availableMonthsData[0]?.month)
    }
  }, [projectUpdateDetailsData])

  useEffect(() => {
    if (selectedDate) {
      getProjectUpdateDetails({
        projectId: Number(project?.id),
        startDate: getFormattedDate(selectedDate).startDate,
        endDate: getFormattedDate(selectedDate).endDate,
        speciesTypes: selectedSpeciesList.map((item: MultiSelectDropdownOptionType) => item.value)
      })
      .then((res: any) => {
        setAllSpeciesUpdateData(transformSpeciesUpdateData(speciesListOfSpeciesUpdated, res?.data?.updates))
      })
    }
  }, [selectedDate, selectedSpeciesList])

  if (showSpeciesDetailsPage && speciesDetailsPageData) {
    return (
      <SpeciesUpdateDetailsPage data={speciesDetailsPageData} onClose={() => setShowSpeciesDetailsPage(false)} />
    )
  }

  return (
    <div className="pb-12">
      <div className='px-6 m-0 mt-4 d-flex justify-content-between gap-4'>
        <div className='d-flex flex-column gap-4 flex-grow-1'>
          <SpeciesInfoCarousel data={speciesListOfSpeciesUpdated} title='NUMBER OF UPDATES PER SPECIES TILL DATE' />
        </div>
      </div>
      <div className='px-6 m-0 mt-8 d-flex flex-row justify-content-between align-items-end gap-4'>
        <div className='m-0 mt-4 d-flex flex-column gap-4'>
          <h2>{selectedDate ? moment(selectedDate, 'YYYY-MM').format('MMMM YYYY').toUpperCase() : ""}</h2>
        </div>
        <div className='d-flex flex-row align-items-center gap-4'>
          {
            speciesListOfSpeciesUpdated?.length > 0 && (
              <MultiSelectDropdown
                options={speciesListOfSpeciesUpdated?.map((item: any) => ({
                  label: item.speciesName,
                  value: item.speciesType
                }))}
                selectedOptions={selectedSpeciesList}
                setSelectedOptions={setSelectedSpeciesList}
                placeholder="Select Species"
                containerStyle={{padding: '0px', margin: '0px'}}
                dropdownBtnStyle={{width: '280px', fontSize: '14px', padding: '8px 8px'}}
              />
            )
          }
          {
            dateListOfSpeciesUpdated?.length > 0 && (
              <Dropdown
                label=""
                options={dateListOfSpeciesUpdated?.map((item: any) => ({
                  label: moment(item, 'YYYY-MM').format('MMM YYYY'),
                  value: item
                }))}
                selectedValue={selectedDate}
                onChange={setSelectedDate}
                placeholder="Select Date"
                containerStyle={{padding: '0px', margin: '0px'}}
                dropdownStyle={{color: '#000', fontSize: '14px', width: '180px', padding: '8px 8px', borderColor: '#BABABA', margin: '0px'}}
                disablePlaceholder
              />
            )
          }
        </div>
      </div>
      {selectedDate && allSpeciesUpdateData?.length > 0 && (
        <div className='px-6 m-0 mt-8'>
          {allSpeciesUpdateData.map((data, index) => {
            const currentDate = moment(data.date)
            const accordionIndex = index
            return (
              <div key={currentDate.format('DD/MM/YYYY')}>
                <AccordionWrapper
                  title={currentDate.format('DD/MM/YYYY')}
                  index={accordionIndex}
                >
                  <div className='p-4'>
                    {data.speciesUpdated?.length === 0 && (
                      <div className="d-flex flex-grow-1 align-items-center justify-content-center py-8">
                        <h4 className="m-0 self-align-center">No updates found!</h4>
                      </div>
                    )}
                    {data.speciesUpdated.map((species) => (
                      <div key={species?.speciesName} className="d-flex align-items-center justify-content-between p-3 border-bottom">
                        <div className="d-flex align-items-center justify-content-between gap-3 flex-grow-1">
                          <div className="d-flex align-items-center gap-12">
                            <img 
                              src={species?.image} 
                              alt={species?.speciesName}
                              style={{width: '40px', height: '40px', borderRadius: '50%'}}
                            />
                            <h5 className="m-0">{species?.speciesName}</h5>
                          </div>
                          <div className="d-flex align-items-center justify-content-between gap-12" style={{width: '45%'}}>
                            <h5 className="m-0">{species?.count} <span className="text-muted small">updates</span></h5>
                            <button 
                              type="button"
                              className="btn btn-link"
                              style={{width: '100px', height: '40px', color: '#000'}}
                              onClick={() => {
                                setSpeciesDetailsPageData(species)
                                setShowSpeciesDetailsPage(true)
                              }}
                            >
                              <i className="bi bi-arrow-right fs-1" style={{color: '#000'}} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </AccordionWrapper>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}