/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
/* eslint-disable */
import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RoutesPath } from '../../routing/RoutesPath';
import HorizontalHeaderCard from './components/cards/horizontal-header-card';

import './styles.css';
import ForestCard from './components/cards/forest-card'
import SDGCard from './components/cards/sdg-card'
import { IconArrowRightCircle, IconForestContract, IconForestDonation, IconForestFarmerDetails, IconForestReceipt } from '../../../_metronic/assets/icons'
import ForestDocsPageCard from './components/cards/forest-docs-page-card'
import { ForestDocsType } from './types'
import { ForestsPlantationChart } from './components/charts/forest-plantation-chart'
import { ForestDetailIndividual } from '../our-forest/types'
import ForestBoundaryCarousel from './components/carousels/forest-boundary-carousel'
import { useLazyGetTotalForestV2Query } from '../our-forest/store/api'
import ForestMapCarousel from './components/carousels/forest-map-carousel'
import TimelineSlider from './timeline-slider'
import { RootState } from '../../store/store';
import Loader from '../../../_metronic/partials/components/loader/Loader';
import ComingSoonCardAfter14Days from '../our-forest/ComingSoonCardAfter14Days';
import { forestDummyData } from './docs-data';
import { DUMMY_ORG_ID } from '../../utils/dummyData';

type Props = {

}

const ForestPage: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {profile} = useSelector((state: RootState) => state.profile)

  const [getTotalForestV2Query, {data: totalForestData, isLoading: totalForestLoading}] = useLazyGetTotalForestV2Query()

  const [forests, setForests] = useState<ForestDetailIndividual[]>([])
  const [forestPlantationChartData, setForestPlantationChartData] = useState<any>({series: [{name: 'Acres Planted', data: []}, {name: 'Acres Purchased', data: []}], categories: []})
  
  useEffect(() => {
    getTotalForestV2Query({})
  }, [])

  useEffect(() => {
    // TODO: Remove this after DEMO
    if (Object.values(totalForestData?.data?.forestData || []).length > 0 || profile?.organization?.id === DUMMY_ORG_ID) {
      if (profile?.organization?.id === DUMMY_ORG_ID) {
        setForests(Object.values(forestDummyData?.forestData as any))
      } else {
        setForests(Object.values(totalForestData?.data?.forestData))
      }
    }
    // TODO: Remove this after DEMO
    if (totalForestData?.data?.totalForestUpdates?.length > 0 || profile?.organization?.id === DUMMY_ORG_ID) {
      // TODO: Remove this after DEMO
      const updates = profile?.organization?.id === DUMMY_ORG_ID ? 
        forestDummyData?.totalForestUpdates : 
        totalForestData?.data?.totalForestUpdates;
      const series: any[] = [{name: 'Acres Planted', data: []}, {name: 'Acres Purchased', data: []}];
      const categories: any[] = [];

      // Get first date from updates array (already ordered)
      const firstDate = new Date(updates[0].date);
      const today = new Date();

      let cumulativeArea = 0;
      const currentDate = firstDate;
      let updateIndex = 0;

      while (currentDate <= today) {
        const dateStr = currentDate.toISOString().split('T')[0];
        categories.push(moment(dateStr).format('MMM YYYY'));

        // Add areas from updates on this date
        while (updateIndex < updates.length && updates[updateIndex].date === dateStr) {
          cumulativeArea += (updates[updateIndex].area || 0);
          updateIndex += 1;
        }

        series[0].data.push(Number(cumulativeArea.toFixed(2)));
        series[1].data.push(Number(cumulativeArea.toFixed(2)));

        currentDate.setDate(currentDate.getDate() + 1);
      }

      setForestPlantationChartData({series, categories});
    }
  }, [totalForestData])

  if (totalForestLoading) 
    return (
      <div className='d-flex justify-content-center align-items-center' style={{height: '80vh'}}>
        <Loader />
      </div>
    )

  if (!totalForestLoading && Object.values(totalForestData?.data?.forestData || []).length === 0 && profile?.organization?.id !== DUMMY_ORG_ID) 
    return (
      <div className='d-flex justify-content-center align-items-center bordered-grey mt-12 mx-10 px-4' style={{height: '340px', background: 'linear-gradient(to bottom, #F8F8F8, #f1edff)'}}>
        <p className='m-0' style={{fontSize: '16px', fontWeight: 600, textAlign: 'center'}}>
          You have not purchased any forests yet. Please purchase a forest to view details. <br /> Contact support for more details.
          <a href='mailto:support@mynzocarbon.com' className='text-primary bold mx-2' style={{textDecoration: 'underline', fontSize: 16, display: 'inline'}}>
            support@mynzocarbon.com
          </a>
        </p>
      </div>
      // <ComingSoonCardAfter14Days />
    )
  
  return (
    <div className='blank-page-view pb-8 px-0 pt-0'>

      { profile?.organization?.id !== DUMMY_ORG_ID ?
      <div className='row px-6 mx-0 pt-6 pb-6' style={{backgroundColor: '#F8F8F8'}}>
        <div className='col-7 px-0 d-flex justify-content-between flex-column bordered-grey' style={{backgroundColor: 'white'}}>
          <ForestsPlantationChart series={forestPlantationChartData.series} categories={forestPlantationChartData.categories} disableAnimation={false} />
        </div> 
        <div className='col-5' style={{padding: '0px 0px 0px 15px'}}>
          {
            profile?.organization?.id !== DUMMY_ORG_ID ? (
              <>
                <HorizontalHeaderCard title='TOTAL ACRES PURCHASED' value={(totalForestData?.data?.totalAcresPurchased || 0).toFixed(1)} unit='acres' />
                <HorizontalHeaderCard title='RECAPTURE PER YEAR' value={((totalForestData?.data?.recaptureData?.recapturePerYear || 0)/1000).toFixed(1)} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' />
                <HorizontalHeaderCard title='RECAPTURE THIS YEAR' value={((totalForestData?.data?.recaptureData?.recaptureThisYear || 0)/1000).toFixed(1)} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' />
                <HorizontalHeaderCard title='RECAPTURE TO DATE' value={((totalForestData?.data?.recaptureData?.recaptureToDate || 0)/1000).toFixed(1)} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' isMarginBottom={false} />
              </>
            ) : (
              <>
                <HorizontalHeaderCard title='TOTAL ACRES' value={(forestDummyData?.totalAcresPurchased || 0).toFixed(1)} unit='acres' />
                <HorizontalHeaderCard title='RECAPTURE PER YEAR' value={((forestDummyData?.recaptureData?.recapturePerYear || 0)/1000).toFixed(1)} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' />
                <HorizontalHeaderCard title='RECAPTURE THIS YEAR' value={((forestDummyData?.recaptureData?.recaptureThisYear || 0)/1000).toFixed(1)} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' />
                <HorizontalHeaderCard title='RECAPTURE TO DATE' value={((forestDummyData?.recaptureData?.recaptureToDate || 0)/1000).toFixed(1)} unit='tCO2e' backgroundColor='#0A0E23' color='#ffffff' isMarginBottom={false} />
              </>
            )
          }
        </div>
      </div>
      : null }


      <div className='row px-6 mx-0 mt-14'>
          <h2 className='m-0 mb-4 mx-0 px-0'>MEETING SDG GOALS</h2>
        <div className='col-12 px-0 d-flex flex-column position-relative' style={{backgroundColor: 'white'}}>
          {/* <h2 className='m-0 mb-4'>DEMOGRAPHICS</h2>
          <div className='bordered-grey flex-grow-1'>
            <ForestDonutContributionChart chartHeight='270px' title='FARMER DIVERSITY' data={forestDonutContributionChartData} />
          </div> */}
            <div className='d-flex flex-row space-between justify-content-between flex-1 gap-4'>
              { profile?.organization?.id !== DUMMY_ORG_ID ? (
                <>
                  <SDGCard number={1} title='No Poverty' description='Agroforestry increases farmers income through diversified revenue streams such as timber, fruits, and crops.' />
                  <SDGCard number={2} title='Zero Hunger' description='Tree-based farming enhances food security by providing edible crops even during droughts.' />
                  <SDGCard number={3} title='Climate Action' description='Agroforests act as natural carbon sinks, mitigating climate change through long-term sequestration.' />
                  <SDGCard number={4} title='Life on Land' description='Agroforests mimic natural ecosystems, preserving biodiversity and restoring degraded lands.' />
                </>
              ) : (
                <>
                  <SDGCard number={1} title='Climate Action' description='Agroforests act as natural carbon sinks, mitigating climate change through long-term sequestration.' />
                  <SDGCard number={2} title='Life on Land' description='Agroforests mimic natural ecosystems, preserving biodiversity and restoring degraded lands.' />
                </>
              )
            }
            </div>
        </div>
      </div>

      <div className='px-6 mx-0 mt-6 mb-4 d-flex justify-content-between align-items-end'>
        <h2 className='m-0'>FORESTS</h2>
        <button
          type='button'
          className='custom-primary-delete-button mb-0 mx-0'
          style={{padding: '8px 20px', fontWeight: 600}}
          onClick={()=> navigate(RoutesPath.FOREST.ATTRIBUTE_TO_EMPLOYEES)}
          >
          ATTRIBUTE TO EMPLOYEES
        </button>
      </div>

      <div className='row px-6 mx-0 gap-4 flex-wrap'>
        {
          forests?.length > 0 ? (
            forests.map((forest:any, index:number) => {
              if (forest?.forestName.includes("Forest-9")) {
                return null
              }
              return (
                <ForestCard 
                  key={index} 
                  index={index}
                  forest={{...forest, boundry: [forest?.boundry]}} 
                  forestName={`${forest?.forestName}`} 
                  companyName={profile?.organization?.name || ""} 
                  forestNumber={`${index + 1}`} 
                  location={forest?.state} 
                />
              )
            })) : (
              <div className='d-flex justify-content-center align-items-center bordered-grey mt-4' style={{height: '140px'}}>
                <p className='m-0' style={{fontSize: '16px', fontWeight: 600}}>No forests found</p>
              </div>
            )
        }
        </div>

      { profile?.organization?.id !== DUMMY_ORG_ID ?
      <>
      <div className="px-6 mt-14">
        <h2 className='m-0 mb-4'>SATELLITE IMAGES <h4>(Forest 1)</h4></h2>
        <div className='d-flex flex-1 gap-4' style={{width: '100%', height: '600px'}}>
          <TimelineSlider/>
          {/* <div className='bordered-grey flex-1' style={{width: '50%', height: '50%', marginBottom: '60px'}}>
            {
              forests?.length > 0 && (
                <ForestMapCarousel forests={forests} />
              )
            }
          </div> */}

        </div>
      </div>

      <div className="px-6 mt-14">
        <h2 className='m-0 mb-4'>DETAILS</h2>
        <div className='d-flex flex-row gap-4' style={{height: '80px'}}>
          <ForestDocsPageCard title='CONTRACT' type={ForestDocsType.CONTRACT} icon={<IconForestContract height={28} width={28} />} />
          <ForestDocsPageCard title='FARMER DETAILS' type={ForestDocsType.FARMER_DETAILS} icon={<IconForestFarmerDetails height={28} width={28} />} />
          <ForestDocsPageCard title='DONATION' type={ForestDocsType.DONATION} icon={<IconForestDonation height={28} width={28} />} />
          {/* <ForestDocsPageCard title='RECEIPT' type={ForestDocsType.RECEIPT} icon={<IconForestReceipt height={28} width={28} />} /> */}
        </div>
      </div>
      </>
      : null }

      <div className="my-10" />
      
    </div>
  )
}

export default ForestPage
/* eslint-disable global-require */
/* eslint-disable react/no-unstable-nested-components */