import {useIntl} from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store/store'
import {
  DashboardTabIcon, 
  DivisionsTabIcon, 
  DepartmentTabIcon,
  LeaderboardTabIcon,
  LocationTabIcon, 
  EmployeeTabIcon, 
  ForestTreeIcon, 
  SettingsTabIcon, 
  ConfigurationTabIcon, 
  ReportsTabIcon,
  CustomGroupTabIcon,
  CustomTagTabIcon,
  AttributesTabIcon,
  ChallengesTabIcon,
  QuizzesTabIcon,
  PollsTabIcon,
  IconPieTopRight,
  IconPieBottomRight,
  IconUpload,
  IconEngagementTab,
} from '../../../assets/icons'
import {ModifySVG} from '../../../helpers/components/ModifySVG'
import {AsideMenuItem} from './AsideMenuItem'
import { RoutesPath } from '../../../../app/routing/RoutesPath'
import CollapsibleSection from './CollapsibleSection'
import { setIsOpenEmissionsSec, setIsOpenEngagementSec, setIsOpenForestSec } from '../../../../app/store/slices/asideMenu'
import { MYNZO_ORG_ID, PROD_LNT_ORG_ID, PROD_NAANDI_ORG_ID, STAGING_LNT_LIKE_ORG_ID, STAGING_NAANDI_ORG_ID } from '../../../../app/utils/dummyData'
import { getEnvironment } from '../../../../app/utils/helpers'

export function AsideMenuMain() {
  const intl = useIntl()

  const {profile} = useSelector((state: RootState) => state.profile)
  const asideMenu = useSelector((state: RootState) => state.asideMenu)
  const dispatch = useDispatch()

  return (
    <>
      <hr className='aside-menu-hr mb-6' />

      <h6 className='aside-menu-sec-title'>ANALYTICS</h6>

      <AsideMenuItem
        to={RoutesPath.DASHBOARD.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <DashboardTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.EMISSIONS'})}
      /> 

      <div style={{paddingLeft: 30}}>
        {
          (
            (getEnvironment() === 'production' && profile?.organization?.id === PROD_LNT_ORG_ID) || 
            (getEnvironment() === 'development' && profile?.organization?.id === STAGING_LNT_LIKE_ORG_ID)
          ) && (
            <AsideMenuItem
              to={RoutesPath.DASHBOARD.FUEL_EMISSION}
              MenuIcon={
                <ModifySVG className='svg-icon-2'>
                  <IconPieBottomRight />
                </ModifySVG>
              }
              title={intl.formatMessage({id: 'MENU.DASHBOARD.FUEL_EMISSION'})}
            />
          )
        }
        <AsideMenuItem
          to={RoutesPath.DASHBOARD.SCOPE_ONE}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <IconPieTopRight />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD.SCOPE_ONE'})}
        /> 
        <AsideMenuItem
          to={RoutesPath.DASHBOARD.SCOPE_TWO}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <IconPieBottomRight />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD.SCOPE_TWO'})}
        />
        <AsideMenuItem
          to={RoutesPath.DASHBOARD.SCOPE_THREE}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <IconPieBottomRight />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD.SCOPE_THREE'})}
        />
      </div>

      {
        (getEnvironment() === 'production' && [MYNZO_ORG_ID].includes(profile?.organization?.id || 0)) || 
        (getEnvironment() === 'development' && [MYNZO_ORG_ID].includes(profile?.organization?.id || 0)) ? (
          <AsideMenuItem
            to={RoutesPath.FOREST.ROOT}
            MenuIcon={
              <ModifySVG className='svg-icon-2'>
                <ForestTreeIcon />
              </ModifySVG>
            }
            title={intl.formatMessage({id: 'MENU.FOREST'})}
          />
        ) : (
          <AsideMenuItem
            to={RoutesPath.FOREST_DASHBOARD.ROOT}
            MenuIcon={
              <ModifySVG className='svg-icon-2'>
                <ForestTreeIcon />
              </ModifySVG>
            }
            title={intl.formatMessage({id: 'MENU.FOREST'})}
          /> 
        )
      }

      <AsideMenuItem
        to={RoutesPath.DASHBOARD_EMISSION.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <IconEngagementTab />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.DASHBOARD_ENGAGEMENT'})}
      /> 

      <div style={{paddingLeft: 30}}>
        <AsideMenuItem
          to={RoutesPath.DASHBOARD_EMISSION.DETAILS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <DepartmentTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD_EMISSION.DETAILS'})}
        /> 
        <AsideMenuItem
          to={RoutesPath.DASHBOARD_EMISSION.LEADERBOARD}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <LeaderboardTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD_EMISSION.LEADERBOARD'})}
        /> 
        {/* <AsideMenuItem
          to={RoutesPath.DASHBOARD_EMISSION.DIVISIONS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <DivisionsTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD_EMISSION.DIVISIONS'})}
        /> 
        <AsideMenuItem
          to={RoutesPath.DASHBOARD_EMISSION.DEPARTMENTS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <DepartmentTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD_EMISSION.DEPARTMENTS'})}
        /> 
        <AsideMenuItem
          to={RoutesPath.DASHBOARD_EMISSION.LOCATIONS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <LocationTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.DASHBOARD_EMISSION.LOCATIONS'})}
        />  */}
      </div>

      <hr className='aside-menu-hr mt-6 mb-6 ' />

      <h6 className='aside-menu-sec-title'>SET-UP</h6>

      <CollapsibleSection 
        title='Emissions' 
        icon={
          <ModifySVG className='svg-icon-2'>
            <IconEngagementTab color={asideMenu.isOpenEmissionsSec ? 'white' : '#4a4b68'} />
          </ModifySVG>
        }
        toggleOpen={(isOpen: boolean) => {dispatch(setIsOpenEmissionsSec(isOpen))}}
        isOpen={asideMenu.isOpenEmissionsSec}
        >
        
        <AsideMenuItem
          to={RoutesPath.SURVEY_QUESTIONS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <AttributesTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.SURVEY_QUESTIONS'})}
        />

        <AsideMenuItem
          to={RoutesPath.UPLOAD_DATA}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <IconUpload />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.UPLOAD_DATA'})}
        />

      </CollapsibleSection>

      <CollapsibleSection
        title='Forest' 
        icon={
          <ModifySVG className='svg-icon-2'>
            <ForestTreeIcon color={asideMenu.isOpenForestSec ? 'white' : '#4a4b68'} />
          </ModifySVG>
        }
        toggleOpen={(isOpen: boolean) => {dispatch(setIsOpenForestSec(isOpen))}}
        isOpen={asideMenu.isOpenForestSec}
        >
        <AsideMenuItem
          to={RoutesPath.OUR_FOREST.ROOT}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <ForestTreeIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.PURCHASE_FOREST'})}
        /> 
      </CollapsibleSection>

      <CollapsibleSection
        title='Engagement'
        icon={
          <ModifySVG className='svg-icon-2'>
            <DepartmentTabIcon color={asideMenu.isOpenEngagementSec ? 'white' : '#4a4b68'} />
          </ModifySVG>
        }
        toggleOpen={(isOpen: boolean) => {dispatch(setIsOpenEngagementSec(isOpen))}}
        isOpen={asideMenu.isOpenEngagementSec}
        >

        <AsideMenuItem
          to={RoutesPath.CHALLENGES.ROOT}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <ChallengesTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.CHALLENGES'})}
        />
        <AsideMenuItem
          to={RoutesPath.QUIZZES.ROOT}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <QuizzesTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.QUIZZES'})}
        />
        <AsideMenuItem
          to={RoutesPath.POLLS.ROOT}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <PollsTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.POLLS'})}
        />
        <AsideMenuItem
          to={RoutesPath.CUSTOM_GROUPS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <CustomGroupTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.CUSTOM_GROUPS'})}
        />
        <AsideMenuItem
          to={RoutesPath.CUSTOM_TAGS}
          MenuIcon={
            <ModifySVG className='svg-icon-2'>
              <CustomTagTabIcon />
            </ModifySVG>
          }
          title={intl.formatMessage({id: 'MENU.CUSTOM_TAGS'})}
        />

      </CollapsibleSection>

      <hr className='aside-menu-hr mt-6 mb-6 ' />

      <h6 className='aside-menu-sec-title'>ORGANIZATION</h6>

      <AsideMenuItem
        to={RoutesPath.EMPLOYEES.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <EmployeeTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.EMPLOYEES'})}
      />  

      <AsideMenuItem
        to={RoutesPath.MASTER_DATA}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <DepartmentTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.MASTER_DATA'})}
      />

      <AsideMenuItem
        to={RoutesPath.ATTRIBUTES.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <AttributesTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.ATTRIBUTES_TAB'})}
        />


      <AsideMenuItem
        to={RoutesPath.CONFIGURATION.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <ConfigurationTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.ADD_UTILITY'})}
      />

      <AsideMenuItem
        to={RoutesPath.ORG_REPORTS.ROOT}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <ReportsTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.REPORTS'})}
      />

      <hr className='aside-menu-hr mt-6 mb-6 ' />

      {/* <h6 className='aside-menu-sec-title'>OTHERS</h6> */}

      <AsideMenuItem
        to={RoutesPath.SETTINGS}
        MenuIcon={
          <ModifySVG className='svg-icon-2'>
            <SettingsTabIcon />
          </ModifySVG>
        }
        title={intl.formatMessage({id: 'MENU.SETTINGS'})}
      />
    </>
  )
}
