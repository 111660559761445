import SpeciesUpdateDetailsTable from "../components/tables/species-update-details-table"

const getSpeciesUpdateTableData = (data: any) => (
  data?.map((item: any) => ({
    circumference: {count: item.circumference, image: item.circumferenceImage},
    height: {count: item.height, image: item.heightImage},
    leaf: {image: item.leafImage},
    latLong: `${item.latitude || "--"}  ${item.longitude || "--"}`,
  }))
)

export default function SpeciesUpdateDetailsPage(props: {data: any, onClose: () => void}) {
  const {data, onClose} = props

  return (
    <div className='pb-12'>
      <div className='px-6 back-header-btn mt-8 mb-6 d-flex justify-content-between' style={{marginLeft: '0px'}}>
        <div className='d-flex align-items-center'>
          <button 
            type='button' 
            className='p-2 back-left-arrow-btn-wrap'
            onClick={onClose}
            >
            <div className='back-left-arrow-btn' /> 
          </button>
          <h3 className='mb-0 mx-4'>{data?.speciesName?.toUpperCase()}</h3>
        </div>
        <div className='d-flex align-items-center py-3 px-5' style={{backgroundColor: '#C0DDC0'}}>
          <h5 className='m-0'>TOTAL RECORDS: {data?.count}</h5>
        </div>
      </div>
      <div className='px-6'>
        <SpeciesUpdateDetailsTable listData={getSpeciesUpdateTableData(data?.updateDetails)} />
      </div>

    </div>
  )
}