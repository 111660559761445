import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import mixpanel from 'mixpanel-browser'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {NotificationType} from '../../../_metronic/partials/components/types'
import {IUpdatePassword, PasswordErrorResponse} from './types'
import {NO_WHITE_SPACE_REGEX} from '../../utils/regex'
import {updateNotification} from '../../store/slices/notifier'
import {useUpdatePasswordMutation} from './store/api'
import {RoutesPath} from '../../routing/RoutesPath'
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types'
import { RootState } from '../../store/store'

const passwordFormValidationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('Password is required'),
  newPassword: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required')
    .matches(NO_WHITE_SPACE_REGEX, 'Password cannot have white spaces')
    .test(
      'isNewPassword',
      'New password cannot be same as current password',
      (value, testContext) => {
        if (value && testContext.parent.currentPassword === value) return false
        return true
      }
    ),
  passwordConfirmation: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required')
    .matches(NO_WHITE_SPACE_REGEX, 'Password cannot have white spaces')
    .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
})

const ChangePassword = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const {profile} = useSelector((state:RootState) => state.profile)

  useEffect(() => {
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.ChangePasswordPage})
  }, [])

  const initialValues = {
    avatar: '',
    fName: '',
    lName: '',
    email: '',
    department: '',
  }

  const initialPasswordData: IUpdatePassword = {
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',
  }

  const [isUpdatingPasswordChange, setUpdatingPasswordChange] = useState(false)

  const [
    updatePassword,
    {
      isSuccess: isPasswordUpdateSuccess,
      error: passwordUpdateError,
      isError: isPasswordUpdateError,
    },
  ] = useUpdatePasswordMutation()

  const dispatch = useDispatch()

  useEffect(() => {
    if (isPasswordUpdateSuccess) {
      setUpdatingPasswordChange(false)
      dispatch(
        updateNotification({
          message: 'Password updated successfully',
          type: NotificationType.SUCCESS,
        })
      )
      navigate(RoutesPath.SETTINGS)
    }
  }, [isPasswordUpdateSuccess])

  useEffect(() => {
    if (isPasswordUpdateError) {
      setUpdatingPasswordChange(false)
      const passwordError = passwordUpdateError as PasswordErrorResponse
      if (passwordError.data.errors && passwordError.data.errors.length) {
        if (passwordError.data.errors[0].code) {
          dispatch(
            updateNotification({
              message: intl.formatMessage({id: passwordError.data.errors[0].code}),
              type: NotificationType.ERROR,
            })
          )
        }
      } else {
        dispatch(
          updateNotification({
            message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
            type: NotificationType.ERROR,
          })
        )
      }
    }
  }, [isPasswordUpdateError])

  const passwordForm = useFormik<IUpdatePassword>({
    initialValues: {
      ...initialPasswordData,
    },
    validationSchema: passwordFormValidationSchema,
    onSubmit: (passwordValues) => {
      // workshop handling
      if (profile?.organization?.isWorkshopOrg) {
        return;
      }
      setUpdatingPasswordChange(true)
      updatePassword({
        currentPassword: passwordValues.currentPassword,
        newPassword: passwordValues.newPassword,
      })

      passwordForm.handleReset(initialValues)
    },
  })

  const updateData = () => {
    passwordForm.handleSubmit()
  }

  return (
    <div className='position-relative' style={{backgroundColor: 'white', flex: 1, minHeight: '100%'}}>
      <div>
        <div className='card mb-5 mb-xl-8 pt-10'>
          <div className='back-header-btn mb-10' style={{marginLeft: '40px'}}>
            <button 
              type='button' 
              className='p-2 back-left-arrow-btn-wrap'
              onClick={()=>{navigate(RoutesPath.SETTINGS)}}
              >
              <div className='back-left-arrow-btn' /> 
            </button>
            <h6 className='m-0 mx-4 fs-4 font-weight-normal'>Back</h6>
          </div>
          <div>
            <h3 className='heading mb-5'>Change Password</h3>
            <p className='p-1 pt-2'>
              Update your password to enhance security and protect your account. Choose a <br />{' '}
              strong, unique combination of characters and ensure it remains confidential.
            </p>
          </div>

          <div id='kt_signin_password_edit' className='card-body'>
            <form
              onSubmit={passwordForm.handleSubmit}
              id='kt_signin_change_password'
              className='form'
              noValidate
            >
              <div className='col mb-1 password-form'>
                <div className='col-lg-3'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='currentpassword' className='form-label fs-6 mb-3'>
                      Current Password
                    </label>
                    <input
                      type='password'
                      className='form-control form-control-lg form-control-solid mb-3'
                      id='currentpassword'
                      placeholder='Enter current password'
                      {...passwordForm.getFieldProps('currentPassword')}
                    />
                    {passwordForm.touched.currentPassword && passwordForm.errors.currentPassword && (
                      <div className='fv-plugins-message-container mb-3'>
                        <div className='fv-help-block'>{passwordForm.errors.currentPassword}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-3 mb-5'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='newpassword' className='form-label fs-6 mb-3'>
                      New Password
                    </label>
                    <input
                      type='password'
                      className='form-control form-control-lg form-control-solid'
                      id='newpassword'
                      placeholder='Enter new password'
                      {...passwordForm.getFieldProps('newPassword')}
                    />
                    {passwordForm.touched.newPassword && passwordForm.errors.newPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{passwordForm.errors.newPassword}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='col-lg-3 mb-10'>
                  <div className='fv-row mb-0'>
                    <label htmlFor='confirmpassword' className='form-label fs-6 mb-3'>
                      Confirm New Password
                    </label>
                    <input
                      type='password'
                      className='form-control form-control-lg form-control-solid '
                      id='confirmpassword'
                      placeholder='Re-enter new password'
                      {...passwordForm.getFieldProps('passwordConfirmation')}
                    />
                    {passwordForm.touched.passwordConfirmation &&
                      passwordForm.errors.passwordConfirmation && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            {passwordForm.errors.passwordConfirmation}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className='background-image lock-bg mb-12' />
      </div>

      <div className='card-footer footer shadow mt-auto'>
        <div className='settings-btn-bottom px-8'>
          <div className='py-6 px-3'>
            <button
              onClick={() => navigate(RoutesPath.SETTINGS)}
              id='kt_password_cancel'
              type='button'
              className='custom-outline-button '
            >
              Cancel
            </button>
          </div>
          <div className='py-6 px-3'>
            <button type='submit' className='custom-primary-delete-button ' onClick={updateData}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChangePassword}
